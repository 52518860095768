import React from 'react';
import { Box, Typography } from "@mui/material";
import { configFile } from '../../../config';
const { imagesUrl } = configFile;

export default function NoMoreDataShow({type="scheduled"}) {
    return (
        <Box sx={{
            position: 'relative',
            top: 'calc(50% - 116px)',
            left: 'calc(50% - 100px)',
        }} >
            <img src={imagesUrl?.noScheduleWebinar} alt='No more schedule meeting' />
            <Typography sx={{
                mt: 3,
                color: 'grey.200',
                opacity: '.8',
                letterSpacing: 1.5,
                whiteSpace: "pre-line"
            }}>
                No more {type} <br /> meetings!
            </Typography>
        </Box>
    )
}
