import React, { memo, Component } from 'react';
import hark from 'hark';

class AudioLevelIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audioVolume: 0
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className={`voice-detector level-${this.state.audioVolume}`}>
                    <div className="dot-one"></div>
                    <div className="dot-two"></div>
                    <div className="dot-three"></div>
                </div>
            </React.Fragment>
        )
    }

    componentDidMount() {
        if (this.props.audioTrack) {
            this._runHark(this.props.audioTrack)
        }
    }

    _runHark(stream) {
        if (!stream.getAudioTracks()[0])
            throw new Error('_runHark() | given stream has no audio track');

        this._hark = hark(stream, { play: false });

        // eslint-disable-next-line no-unused-vars
        this._hark.on('volume_change', (dBs, threshold) => {
            // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
            //   Math.pow(10, dBs / 20)
            // However it does not produce a visually useful output, so let exagerate
            // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
            // minimize component renderings.
            let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

            if (audioVolume === 1)
                audioVolume = 0;

            if (audioVolume !== this.state.audioVolume) {
                if (this.state.audioVolume !== audioVolume) {
                    this.setState({ audioVolume });
                }

            }
        });
    }
}

export default memo(AudioLevelIndicator);

