import React, { useState } from 'react'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { configFile } from '../../../config';
import EndReminderModal from './Modal/EndReminderModal';

const RoomEndReminder = () => {
    const [open, setOpen] = useState(false);
    const peers = useSelector(state => state.peers);

    const { roomAutoClose: { showModalTime } } = configFile;

    useEffect(() => {
        let timeOutId = "";
        if (!Object.values(peers).length) {
            timeOutId = setTimeout(() => {
                setOpen(true)
            }, showModalTime);
        }

        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line
    }, [peers])

    return (
        <EndReminderModal
            open={open}
            close={() => setOpen(false)}
            peersCount={Object.values(peers).length}
        />
    )
}

export default RoomEndReminder