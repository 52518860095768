// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#5B5C5D",
          color: "#D0D0D1",
        },
        arrow: {
          color: "#5B5C5D",
        },
      },
    },
  };
}
