import React from 'react';
import { configFile } from '../../../../config';
import { Box } from '@mui/material';
import { isMobileDevice } from './util';
const { imagesUrl } = configFile;


const getImageUrl = (name) => {
  if (!name) return '';
  const baseUrl = imagesUrl?.[name];
  if (isMobileDevice() && baseUrl) {
    return baseUrl.replace('/web/', '/mobile/');
  }
  return baseUrl;
}

const ImageComp = ({ name = "", border = "1px solid #d2d2d2", borderRadius = "10px" }) => {
  const isMobile = isMobileDevice()
  const imageUrl = getImageUrl(name);
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img
        src={imageUrl}
        alt={name}
        draggable={false}
        style={{ border, borderRadius , width: isMobile ? '60%': '100%'}}
      />
    </Box>

  );
}

export default ImageComp;
