import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import jwt from 'jwt-decode';
import { useSelector } from 'react-redux';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';

// @mui
import { styled } from '@mui/material/styles';

// components
import { renderErrorMessage, renderSuccessMessage } from '../../components/ToastNotification';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import { getItem } from '../../lib/cookiesManager';
import ScheduleSection1 from '../../sections/common/ScheduleWebinar/ScheduleSection1';
import ScheduleSection2 from '../../sections/common/ScheduleWebinar/ScheduleSection2';
import AppTitleBar from '../../components/mobile/components/AppTitleBar';
import SuccessMessage from "../../utils/SuccessMessage.json";
import ErrorMessage from "../../utils/ErrorMessage.json";

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: "column",
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    padding: '94px 10px 10px 10px',
    [theme.breakpoints.down("sm")]: {
        alignItems: 'flex-start',
        height: 'calc(100vh - 119px)',
        padding: '10px 10px 0px 10px',
    },
}));

// const InnerMainSection = styled(Box)(({ theme }) => ({
//     display: "flex",
//     justifyContent: "center",
//     width: "100%",
//     padding: "10px 10px 30px 10px",
//     [theme.breakpoints.down("sm")]: {
//         padding: "30px 10px 0px 10px"
//     }
// }));

export default function ScheduleWebinar() {

    const [scheduledData, setScheduledData] = useState({});
    const [scheduleLoading, setScheduleLoading] = useState(false);
    const [page, setPage] = useState(1);

    const navigate = useNavigate();
    const location = useLocation();
    const { isTabletMin, isMobile, isPortrait } = useSelector(state => state?.deviceInfo || {});

    const scheduleWebinarSubmit = async (scheduleData = {}) => {
        setScheduleLoading(true);
        try {
            const {
                title, hr, min, record, inviteEmails, autoRecord, waitingRoom, sDate, des, meetType, recurrence, recurrData, e2ee,openRoom
            } = scheduleData;

            let token = getItem("token")
            let info = jwt(token);

            let duration = parseInt(hr) * 60
            let data = {
                title: title,
                startDate: +new Date(sDate),
                duration: duration + parseInt(min),
                userIds: (location?.state?.edit ? inviteEmails.map((email) => {
                    return {
                        email,
                        "role": "host"
                    }
                }) : [info?.email, ...inviteEmails].map((email) => {
                    return {
                        email,
                        "role": "host"
                    }
                })),
                meetType
            }

            if (record) data.record = true;
            if (autoRecord) data.autoRecord = true;
            if (waitingRoom) data.waitingRoom = true;
            if (openRoom) data.openRoom = true;

            if (des) data.description = des;
            if (location?.state?.roomId) data.roomId = location?.state?.roomId;
            if (recurrence) {
                data.recurrence = recurrence;
                data.recurrData = recurrData;
            }

            if (e2ee) data.e2ee = e2ee;

            const res = await axiosPostCall("scheduleWebinar", data);
            if (res) {
                setScheduleLoading(false);
                renderSuccessMessage(SuccessMessage?.["MEETING_SCHEDULED"]);
                return navigate('/');
            }
        } catch (error) {
            setScheduleLoading(false);
            renderErrorMessage(error?.message);
            return;
        }
    }

    // Fetch schedule
    const fetchWebinarDetails = async (roomId) => {
        try {
            let { result } = await axiosGetCall("detailsWebinar", { roomId });
            if (result) {
                setScheduledData({
                    title: result?.title,
                    des: result?.description,
                    inviteEmails: result?.hostIds,
                    sDate: result?.startDate,
                    min: result?.duration % 60,
                    hr: Math.trunc(result?.duration / 60),
                    record: result?.record,
                    autoRecord: result?.autoRecord,
                    waitingRoom: result?.waitingRoom,
                    openRoom: result?.openRoom,

                    recurrence: result?.recurrence,
                    recurrData: result?.recurrData,
                    e2ee: result?.e2ee,
                });
            }
        } catch (error) {
            renderErrorMessage(ErrorMessage?.["UNABLE_FETCH_MEETING"])
        }
    }

    useEffect(() => {
        if (location?.state?.edit) {
            fetchWebinarDetails(location?.state?.roomId)
        }
    }, [location?.state]);

    return (
        <>
            <ToastContainer />
            {(isTabletMin || isMobile || isPortrait) && <AppTitleBar title={"Schedule Meeting"} page={page} setPage={setPage} />}

            <MainSection>
               {/*  <InnerMainSection>
                    <Stepper sx={{ width: "700px" }} alternativeLabel>
                        <Step active={page >= 1} key={1}>
                            <StepLabel></StepLabel>
                        </Step>
                        <Step active={page >= 2} key={2}>
                            <StepLabel></StepLabel>
                        </Step>
                    </Stepper>
                </InnerMainSection> */}

                {page === 1 && <ScheduleSection1
                    setPage={setPage}
                    scheduledData={scheduledData}
                    setScheduledData={setScheduledData}
                    scheduleLoading={scheduleLoading}
                    setScheduleLoading={setScheduleLoading}
                    scheduleWebinarSubmit={scheduleWebinarSubmit}
                />}

                {page === 2 && <ScheduleSection2
                    setPage={setPage}
                    scheduleLoading={scheduleLoading}
                    scheduledData={scheduledData}
                    setScheduledData={setScheduledData}
                    scheduleWebinarSubmit={scheduleWebinarSubmit}
                />}
            </MainSection>
        </>

    )
}