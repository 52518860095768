import React, { memo } from 'react';
import { Box, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import AudioLevelIndicator from './AudioLevelIndicator';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import MicOffIconPeer from './MicOffIconPeer';

function PeerTopBar({ isPined, audioEnabled, micState, stream, focused }) {
    const iconSx = { width: '16px', height: '16px' };
    
    return (
        <Box sx={{
            position: 'absolute',
            top: 2,
            left: 2,
        }}>
            <Stack direction="row" sx={{
                position: 'relative',
                top: focused ? '2px' : '8px',
                left: focused ? '2px' : '8px',
                display: 'flex',
                alignItems: 'center',
            }}>
                {isPined && <IconButton sx={{
                    color: 'rgba(255,255,255,.8)',
                    mr: '5px',
                    // bgcolor: 'rgba(0,0,0,.3)',
                    '&:hover': {
                        color: 'rgba(255,255,255,.8)',
                        bgcolor: 'rgba(0,0,0,0)',
                    }
                }}>
                    <PushPinOutlinedIcon sx={{ ...iconSx, }} />
                </IconButton>}

                {((audioEnabled || micState === 'on') && stream) ?
                    <AudioLevelIndicator audioTrack={stream} />
                    : <MicOffIconPeer />
                }
            </Stack>
        </Box>
    )
}
export default memo(PeerTopBar);
