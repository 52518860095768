// import { Box, Typography, Link } from '@mui/material';
import React from 'react';


export default function BrandingSection() {
  return (
    <></>
    /*  <Box sx={{
         justifyContent: 'center',
         display: 'flex',
         alignItems: 'center',
         bottom: 0,
         left: "41%",
         position: 'absolute',
         flexDirection: 'row'
       }}>
         <Typography sx={{
           fontWeight: 500,
           fontSize: "11px",
           lineHeight: "13px",
           backgroundColor: '#fff',
           py: 1,
           px: 2,
           borderTopLeftRadius: '10px',
           borderTopRightRadius: '10px'
         }}>
           © 2022-{new Date().getFullYear()}. Proudly Powered By <Link>Sarv</Link>
         </Typography>
       </Box> */
  )
}
