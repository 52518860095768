import { setBulkItems } from "../lib/cookiesManager";
import { axiosPostCall } from "./apisCall";
import { getItem } from "../lib/cookiesManager";
import { getMicAndCamPermission, renewToken, /* ipv4Address */ generateFingerprint } from "../utils/common";
import { configFile } from "../config";
import * as cookiesManager from '../../src/lib/cookiesManager';
import { createRedirectPath } from "../utils/common";

const { webinarRole, renewTokenTime, getProtooUrl } = configFile;

const loginWithGoogle = async (credentials) => {
    try {
        const response = await axiosPostCall("loginWithGoogle", { token: credentials })
        const { exp, sessionToken, token } = response;
        setBulkItems({ exp, sessionToken, token });
        return true;
    } catch (error) {
        throw new Error(error);
    }
}

const joinWebianrCheck = async (roomId, name, peerId) => {
    const errorMessages = ["Meeting is not started yet!!", "Meeting is closed!!"];

    try {
        let exp = getItem("exp");
        if (renewTokenTime > exp) await renewToken();
        const displayName = name || getItem("name");
        const fp = await generateFingerprint();
        // const ip = await ipv4Address();
        const response = await axiosPostCall("joinRoom", { roomId, peerId, displayName, fp });

        const { title = "", description = "", isHostServer, record, autoRecord, disableRecordPlayPause, startDate, endDate, waitingRoom, e2ee, serverDetails, isWaitingRoom} = response;

        const role = isHostServer ? webinarRole?.produce : webinarRole?.consumer;
        const wssUrl = getProtooUrl({ roomId, peerId, serverDetails })
        if (isHostServer) getMicAndCamPermission();
        const redirectURL = `/meet?roomId=${roomId}&peerId=${peerId}`;
        const protooUrl = `${wssUrl}&h=${isHostServer}&fp=${fp}` || null;
        if (!protooUrl) throw new Error("Internal server error");
        const state = { protooUrl, peerId, role, name, title, description, meetType: "meet", record, autoRecord, disableRecordPlayPause, startDate, endDate, roomId, waitingRoom, e2ee, isWaitingRoom };
        return { redirectURL, state };
        // eslint-disable-next-line
        // return { protooUrl: `${wssUrl}&h=${isHostServer}&fp=${fp}`, role, title, description, meetType, bt }

    } catch (error) {
        if (errorMessages.includes(error.message)) {
            return { message: "The meeting has not started yet. Please wait for the host to start the meeting. Once the meeting starts, you will be redirected to the room automatically." };
        } else {
            throw new Error(error);
        }
    }
}

const recordingToggle = async (actionType, roomId, peerId, url = "") => {
    try {
        let data = {
            roomId,
            actionType,
            peerId,
        }

        let endPoint = "stopRecording";
        if (["start", "resume"].includes(actionType)) {
            endPoint = "startRecording";
            data.joinLink = url
        }
        return await axiosPostCall(endPoint, data);
    } catch (error) {
        throw new Error(error);
    }
}


const endRoom =  (roomId, peersCount) => {
    try {
        if (!peersCount) {
            axiosPostCall("endCall", { roomId });
        }
        const redirectTo = cookiesManager.getItem("sessionToken") ? "/" : "/end_meet";
        window.location.href = createRedirectPath(redirectTo);
    } catch (error) {
        throw new Error(error);
    }
};



export {
    loginWithGoogle,
    joinWebianrCheck,
    recordingToggle,
    endRoom
}