const initialState = {};

const pinFocus = (state = initialState, action) => {
	switch (action.type) {
		// case 'SET_SHARE_PIN_PEERS':
		// 	{
		// 		const peerIds = action.payload.state;

        //         return {...state, sharePin: peerIds}
		// 	}

		case 'SET_PIN_SHARE_SCREENS':
			{
				const peerIds = action.payload.data;

                return {...state, sharePin: JSON.parse(peerIds)}
			}

		// case 'SET_FOCUS_SCREENS':
		// 	{
		// 		const peerIds = action.payload.data;

        //         return {...state, focus: JSON.parse(peerIds)}
		// 	}

		default:
			{
				return state;
			}
	}
};

export default pinFocus;
