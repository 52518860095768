import React from 'react';
import Logger from '../../../../lib/Logger';
import { Chip, Box, IconButton } from '@mui/material';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import { axiosPostCall } from '../../../../services/apisCall';
import { renderErrorMessage } from '../../../ToastNotification';


const logger = new Logger('ScreenShareView');

export default class ScreenShare extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            videoResolutionWidth: null,
            videoResolutionHeight: null,
            videoCanPlay: false,
            videoElemPaused: false,
            maxSpatialLayer: null,
            updateUnPin: {},
            disableFrameCheck: false
        };

        this._videoTrack = null;

        // Periodic timer for reading video resolution.
        this._videoResolutionPeriodicTimer = null;

        // requestAnimationFrame for face detection.
        this._faceDetectionRequestAnimationFrame = null;
    }

    pinShareScreen = async(peerId, roomId, action) => {
        try {
            await axiosPostCall("pinScreen",{peerId, roomId, action, type: 'pinShareScreen'})
        } catch (error) {
            logger.error("@ScreenShareView.jsx pinShareScreen ",error);
            renderErrorMessage(error.message)
        }
    }

    handleMouseEnterEvent = () => {
        if (this.state.disableFrameCheck) return;
        this.setState({ updateUnPin: { display: "grid" }, disableFrameCheck: true })
        setTimeout(() => {
            this.setState({ updateUnPin: { display: "none" }, disableFrameCheck: false })
        }, 2000)
        return
    }

    render() {
        let { shareSx, showName = false, showPin, showUnpin, displayName, peerId, roomId } = this.props;
        return (
            <Box sx={shareSx} className={`video-container share`} onClick={() => this.handleMouseEnterEvent()}>
                <video
                    ref='videoElem'
                    width={'100%'}
                    height={'100%'}
                    style={{ borderRadius: '10px', maxWidth: '100%' }}
                    autoPlay
                    playsInline
                    muted
                    controls={false}
                />

                {showPin &&
                    <IconButton className='pin' sx={{
                        bgcolor: 'rgba(0,0,0,0.6)',
                        color: '#ffffff',
                        fontSize: '30',
                        ...this.state.updateUnPin
                    }}
                        onClick={() => this.pinShareScreen(peerId, roomId, 'add')}
                    >
                        <AddToQueueIcon fontSize='10%' />
                    </IconButton>
                }

                {showUnpin &&
                    <IconButton className='pin' sx={{
                        bgcolor: 'rgba(33, 150, 243, 1)',
                        color: 'rgba(255,255,255,1)',
                        fontSize: '30',
                        '&:hover': {
                            bgcolor: 'rgba(33, 150, 243, 1) !important',
                            color: 'rgba(255,255,255,1)',
                        },
                        ...this.state.updateUnPin
                    }}
                        onClick={() => this.pinShareScreen(peerId, roomId, 'remove')}
                    >
                        <RemoveFromQueueIcon fontSize='10%' />
                    </IconButton>
                }

                <Chip label={displayName || ''} sx={{
                    position: 'absolute',
                    bottom: showName ? 0 : 10,
                    left: showName ? 2 : 10,
                    bgcolor: 'rgba(0,0,0,0.5)',
                    color: '#ffffff',
                    fontSize: '12px',
                    height: '24px'
                }} />
            </Box>

        );
    }

    componentDidMount() {
        const { videoTrack } = this.props;

        this._setTracks(videoTrack);
    }

    componentWillUnmount() {
        clearInterval(this._videoResolutionPeriodicTimer);
        cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

        const { videoElem } = this.refs;

        if (videoElem) {
            videoElem.oncanplay = null;
            videoElem.onplay = null;
            videoElem.onpause = null;
        }
    }

    UNSAFE_componentWillUpdate() {
        const { videoTrack, videoRtpParameters } = this.props;
        const { maxSpatialLayer } = this.state;

        if (videoRtpParameters && maxSpatialLayer === null) {
            this.setState(
                {
                    maxSpatialLayer: videoRtpParameters.encodings.length - 1
                });
        }
        else if (!videoRtpParameters && maxSpatialLayer !== null) {
            this.setState({ maxSpatialLayer: null });
        }

        this._setTracks(videoTrack);
    }

    _setTracks(videoTrack) {
        if (this._videoTrack === videoTrack) return;

        this._videoTrack = videoTrack;
        this._stopVideoResolution();

        const { videoElem } = this.refs;

        if (videoTrack) {
            const stream = new MediaStream();

            stream.addTrack(videoTrack);
            videoElem.srcObject = stream;

            videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

            videoElem.onplay = () => {
                this.setState({ videoElemPaused: false });
            };

            videoElem.onpause = () => this.setState({ videoElemPaused: true });

            videoElem.play()
                .catch((error) => logger.warn('videoElem.play() failed:%o', error));

            this._startVideoResolution();
        }
        else {
            videoElem.srcObject = null;
        }
    }

    _startVideoResolution() {
        this._videoResolutionPeriodicTimer = setInterval(() => {
            const { videoResolutionWidth, videoResolutionHeight } = this.state;
            const { videoElem } = this.refs;

            if (
                videoElem?.videoWidth !== videoResolutionWidth ||
                videoElem?.videoHeight !== videoResolutionHeight
            ) {
                this.setState(
                    {
                        videoResolutionWidth: videoElem?.videoWidth,
                        videoResolutionHeight: videoElem?.videoHeight
                    });
            }
        }, 500);
    }

    _stopVideoResolution() {
        clearInterval(this._videoResolutionPeriodicTimer);

        this.setState(
            {
                videoResolutionWidth: null,
                videoResolutionHeight: null
            });
    }
}