import React, { useState, useRef, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { fabric } from 'fabric';
import { Box, IconButton } from '@mui/material';
import Toolbar from './toolbar';
import CloseIcon from '@mui/icons-material/Close';
import randomString from 'random-string';

import { canvaDataImport, undoRedoCanvasData } from './dataImport';
import RoomContext from '../../../../context/RoomContext';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PdfReader from './PdfReader';
import * as stateActions from '../../../../redux/actions/stateActions';

const options = {
    currentMode: '',
    currentColor: '#000000',
    currentWidth: 5,
    fill: false,
    group: {},
};

const CancelButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: '20px',
    top: '5px',
    width: '38px'
}));

const modes = {
    RECTANGLE: 'RECTANGLE',
    TRIANGLE: 'TRIANGLE',
    ELLIPSE: 'ELLIPSE',
    LINE: 'LINE',
    PENCIL: 'PENCIL',
    ERASER: 'ERASER',
};

const initCanvas = (width, height, data) => {
    const canvas = new fabric.Canvas('canvas', { height, width });
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.borderColor = '#4447A9';
    fabric.Object.prototype.cornerColor = '#4447A9';
    fabric.Object.prototype.cornerSize = 6;
    fabric.Object.prototype.padding = 10;
    fabric.Object.prototype.borderDashArray = [5, 5];
    fabric.Object.prototype.erasable = false;

    canvas.on('object:added', (e) => {
        e.target.on('mousedown', removeObject(canvas));
    });
    canvas.on('path:created', (e) => {
        e.path.on('mousedown', removeObject(canvas));
    });

    // if (data.length) {
    //     data.map(item => canvaDataImport(canvas, item));
    // }
    return canvas;
};

function removeObject(canvas) {
    return (e) => {
        if (options.currentMode === modes.ERASER) {
            canvas.remove(e.target);
        }
    };
}

function handleResize(callback) {
    const resize_ob = new ResizeObserver(callback);

    return resize_ob;
}

function resizeCanvas(canvas, whiteboard) {
    return () => {
        const ratio = canvas.getWidth() / canvas.getHeight();
        const whiteboardWidth = whiteboard.clientWidth;

        const scale = whiteboardWidth / canvas.getWidth();
        const zoom = canvas.getZoom() * scale;
        canvas.setDimensions({ width: whiteboardWidth, height: whiteboardWidth / ratio });
        canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    };
}

const WhiteBoard = ({ aspectRatio = 4 / 3, handleDrawerClose, order, styleMainGrid }) => {
    const roomClient = useContext(RoomContext) || {};
    const [canvas, setCanvas] = useState(null);
    const { data: whiteboardIncommingData = {}, undoRedoData = {}, scroll = 0 } = useSelector(state => state.whiteboard);
    const { url: pdfUrl = "" } = useSelector(state => state.pdfPage);
    const { whiteboardAccess = false } = useSelector(state => state?.webinarInfo);
    const [fileReaderInfo, setFileReaderInfo] = useState({
        file: '',
        totalPages: null,
        currentPageNumber: 1,
        currentPage: '',
    });
    const canvasRef = useRef(null);
    const whiteboardRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!canvas && canvasRef.current) {
            const canvas = initCanvas(
                whiteboardRef.current.clientWidth,
                whiteboardRef.current.clientWidth / aspectRatio,
            );

            whiteboardRef.current.addEventListener("scroll", () => {
                const scrollTop = whiteboardRef.current.scrollTop;
                const scrollHeight = whiteboardRef.current.scrollHeight;
                const clientHeight = whiteboardRef.current.clientHeight;

                const newScrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                if (Math.round(newScrollPercentage) % 5 === 0) {
                    // roomClient.whiteboardScroll(newScrollPercentage);
                }
            })

            setCanvas(() => canvas);

            handleResize(resizeCanvas(canvas, whiteboardRef.current)).observe(whiteboardRef.current);
        }
        // eslint-disable-next-line
    }, [canvasRef]);

    const getInitialData = async () => {
        try {
            const { data } = await roomClient.initialWhiteBoardData();
            // if (!data.length) return
            const jsonData = JSON.parse(data);
            jsonData.map((canvasData) => {
                return canvaDataImport(canvas, canvasData, updateFileReaderInfo, pdfUrl)
            })
            // setInitialData([jsonData]);

        } catch (error) {
            //logger
        }

    }
    function onFileChange(event) {
        const reader = new FileReader();
        const file = event.target.files[0];
        const objectId = randomString({ length: 8 }).toLowerCase();

        reader.addEventListener('load', () => {
            dispatch(stateActions.updatePDFUrl({ id: objectId, url: reader.result }));
            roomClient.updatePDFUrl({ id: objectId, url: reader.result });
            roomClient.sendWhiteBoardData({ clearCanvas: false, data: { type: "pdf", shadow: { color: objectId } , pdfPage: 1 } })
        });

        reader.readAsDataURL(file);
        updateFileReaderInfo({ file, currentPageNumber: 1 });
        event.target.value = null
    }

    function updateFileReaderInfo(data) {
        setFileReaderInfo({ ...fileReaderInfo, ...data });
    }

    useEffect(() => {
        if (canvas) {
            const center = canvas.getCenter();
            fabric.Image.fromURL(fileReaderInfo.currentPage, (img) => {
                img.scaleToHeight(canvas.height);
                // if (img.height < canvas.height * 2.5 && canvas.width * 1.8 > img.height) img.scaleToWidth(canvas.width);
                // if (img.width > canvas.width && img.height < canvas.height * 2.5) img.scaleToWidth(canvas.width);
                if (img.width > canvas.width && img.width > img.height) img.scaleToWidth(canvas.width);
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    top: center.top,
                    left: center.left,
                    originX: 'center',
                    originY: 'center',
                });

                canvas.renderAll();
            });
        }
        console.log("fileReaderInfo", fileReaderInfo)
        // eslint-disable-next-line
    }, [fileReaderInfo.currentPage]);

    useEffect(() => {
        getInitialData()
        // eslint-disable-next-line
    }, [canvas])

    useEffect(() => {
        if (canvas) {
            const { clearWhiteboard } = whiteboardIncommingData;
            if (clearWhiteboard) return canvaDataImport(canvas, { type: "clearCanvas" }, updateFileReaderInfo, pdfUrl);
            const { data: whiteboardData } = whiteboardIncommingData;
            return canvaDataImport(canvas, whiteboardData, updateFileReaderInfo, pdfUrl);
        }
        // eslint-disable-next-line
    }, [whiteboardIncommingData])

    useEffect(() => {
        if (canvas) {
            undoRedoCanvasData(canvas, undoRedoData, updateFileReaderInfo);
        }
        // eslint-disable-next-line
    }, [undoRedoData])

    useEffect(() => {
        if (whiteboardRef) {
            const scrollHeight = whiteboardRef.current.scrollHeight;
            const clientHeight = whiteboardRef.current.clientHeight;
            const newScrollTop = (scroll / 100) * (scrollHeight - clientHeight);
            whiteboardRef.current.scrollTop = newScrollTop;
        }
        // eslint-disable-next-line
    }, [scroll])

    useEffect(() => {
        // eslint-disable-next-line
    }, [whiteboardAccess]);

    function closeWhiteboard () {
        dispatch(stateActions.udpateWhitebaordOpenStatus(false));
        handleDrawerClose();
        roomClient.toggleWhiteboard({ open: false });
    }

    return (
        <Box sx={{
            position: 'absolute',
            top: order === 2 && styleMainGrid.width === "100%" ? `calc(100% - ${styleMainGrid.height})` : '0px',
            left: order === 2 && styleMainGrid.width === "96.2%" ? `calc(100% - ${styleMainGrid.width})` : '0px',
            bottom: order === 1 && styleMainGrid.width === "100%" ? `calc(100% - ${styleMainGrid.height})` : '0px',
            right: order === 1 && styleMainGrid.width === "96.2%" ? `calc(100% - ${styleMainGrid.width})` : '0px',
            zIndex: 999,
            background: "#edf0f7",
            width: styleMainGrid.width,
            height: styleMainGrid.height,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            gap: '6px',
        }}>
            {/* <CancelButton onClick={closeWhiteboard}>
                <CloseIcon />
            </CancelButton> */}
            {whiteboardAccess && <Toolbar
                canvas={canvas}
                canvasRef={canvasRef}
                roomClient={roomClient}
                onFileChange={onFileChange}
                setFileReaderInfo={setFileReaderInfo}
            />}

            <Box ref={whiteboardRef} sx={{
                // maxHeight: 'calc(100% - 50px)',//'680px',
                // height: 'calc(100% - 70px)',//'680px',
                maxWidth: styleMainGrid.width !== "100%" ? `calc(${window.innerWidth}px - 61px)` : "100%",
                overflow: 'hidden',
                backgroundColor: '#fff',
                overflowY: "scroll",
                // overflowX: 'scroll'
            }}>

                <canvas
                    ref={canvasRef}
                    id="canvas"
                    style={{
                        maxWidth: styleMainGrid.width !== "100%" ? `calc(${window.innerWidth}px - 61px)` : "100%",
                        // width: '300px'
                    }} />
            </Box>

            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mb: '6px'
            }}>
                <PdfReader fileReaderInfo={fileReaderInfo} updateFileReaderInfo={updateFileReaderInfo} />
            </Box>
            <CancelButton onClick={closeWhiteboard}>
                <CloseIcon />
            </CancelButton>
        </Box>
    )
}

export default WhiteBoard