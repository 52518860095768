import { Box, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { styled } from '@mui/material/styles';

const MenuBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    gap: "10px",
    width: "100%",
}));

const ZoomShareScreen = ({ anchorEl, handleClose, shareFullScreen, handleShareFullScreen }) => {
    const openFullScreen = () => {
        try {
            const element = document.fullscreenElement;
            if (element === null) {
                const element = document.documentElement; // Get the root element
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                }
                if (!shareFullScreen) handleShareFullScreen(true);
            }

            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Menu
            id="zoom-share"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
            PaperProps={{
                elevation: 0,
                sx: {
                    background: '#222222',
                    overflow: 'visible',
                    borderRadius: '10px',
                    color: '#7E7E7E',
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 8,
                        width: 10,
                        height: 10,
                        bgcolor: '#222222',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem>
                {!shareFullScreen && <MenuBox
                    onClick={() => { handleShareFullScreen(true); handleClose(); }}>
                    <ZoomOutMapIcon sx={{ color: '#ffffff', height: '20px', width: '20px' }} />
                    <Typography sx={{ color: '#ffffff'}}>Zoom Layout</Typography>
                </MenuBox>}

                {shareFullScreen && <MenuBox
                    onClick={() => { handleShareFullScreen(false); handleClose(); }}>
                    < ZoomInMapIcon sx={{ color: '#ffffff', height: '20px', width: '20px' }} />
                    <Typography sx={{ color: '#ffffff'}}>Exit to Normal Layout</Typography>
                </MenuBox>}
            </MenuItem>

            <MenuItem>
                <MenuBox
                    onClick={openFullScreen}>
                    <OpenInFullIcon sx={{ color: '#ffffff', height: '20px', width: '20px' }} />
                    <Typography sx={{ color: '#ffffff'}}>Full Screen</Typography>
                </MenuBox>
            </MenuItem>
        </Menu>
    )
}

export default ZoomShareScreen;