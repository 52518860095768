import React, { memo } from 'react';
import { Box, IconButton} from '@mui/material';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

function PinFocus({isMe, isPined, isFocused, id, updateFocus, showFocusSide}) {
  return (
    <Box sx={{
        // bgcolor: 'rgba(0,0,0,0.5)',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    }} className='pin'>
        <IconButton /* className='pin' */ onClick={() => isPined ? updateFocus(id, 'remove', 'pinScreen') : updateFocus(id, 'add', 'pinScreen')} sx={{
            bgcolor: isPined ? 'rgba(33, 150, 243, 1)' : 'rgba(0,0,0,0.5)',
            color: isPined ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',
            fontSize: isMe ? '16px' : '24px',
            mr: 0.5,
            '&:hover': {
                bgcolor: isPined ? 'rgba(33, 150, 243, 1) !important' : 'rgba(0,0,0,0.5)',
                color: isPined ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)',
            }
        }} >
            <PushPinOutlinedIcon fontSize='10%' />
        </IconButton>
        {!showFocusSide &&
            <IconButton /*  className='pin' */ onClick={() => isFocused ? updateFocus(id, 'remove', 'focusScreen') : updateFocus(id, 'add', 'focusScreen')} sx={{
                bgcolor: isFocused ? 'rgba(33, 150, 243, 1)' : 'rgba(0,0,0,0.5)',
                color: isFocused ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',
                fontSize: isMe ? '16px' : '24px',
                ml: 0.5,
                '&:hover': {
                    bgcolor: isFocused ? 'rgba(33, 150, 243, 1) !important' : 'rgba(0,0,0,0.5)',
                    color: isFocused ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)',
                }
            }} >
                <CenterFocusStrongIcon fontSize='10%' />
                {/* {peer?.isFocused ? <CenterFocusWeakIcon fontSize='10%' /> : <CenterFocusStrongIcon fontSize='10%' />} */}
            </IconButton>
        }
    </Box>
  )
}
export default memo(PinFocus);
