

export const waitingRoomMessage = (payload) => ({
	type: 'MESSAGE',
	payload
});

export const waitingRoomAction = (payload) => ({
	type: 'ACTION',
	payload
});

export const setUsers = (users) => ({
	type: 'SET_USERS',
	payload: { users }
});

export const setWaitingUsers = (waitingUsers) => ({
	type: 'SET_WAITING_USERS',
	payload: waitingUsers
});

export const hideWaitingRoomPopup = () => ({
	type: 'HIDE_WAITING_ROOM_POPUP',
	payload: {}
});