
import { fabric } from 'fabric';
import store from '../../../../store';
import * as stateActions from '../../../../redux/actions/stateActions';

export const canvaDataImport = (canvas, data = {}, updateFileReaderInfo, pdfUrl) => {
    const { type } = data;
    const updatedData = { ...data, editable: false, selectable: false }
    console.log("dataimport", updatedData)
    switch (type) {
        case 'rect':
            canvas.add(new fabric.Rect(updatedData))
            break;
        case 'line':
            const { x1, y1, x2, y2 } = updatedData;
            canvas.add(new fabric.Line([x1, y1, x2, y2], updatedData));
            break;
        case 'ellipse':
            canvas.add(new fabric.Ellipse(updatedData));
            break;
        case 'textbox':
            canvas.getObjects().forEach((item) => {
                if (item?.type === "textbox" && item?.shadow?.color === updatedData?.shadow?.color) {
                    canvas.remove(item);
                }
            })
            canvas.add(new fabric.Textbox(updatedData.text, updatedData));
            break;
        case 'path':
            const { path: pathData } = updatedData;
            canvas.add(new fabric.Path(pathData, updatedData));
            break;
        case 'triangle':
            canvas.add(new fabric.Triangle(updatedData));
            break;
        case "clearCanvas":
            canvas.getObjects().forEach((item) => {
                if (item !== canvas.backgroundImage) {
                    canvas.remove(item);
                }
            });
            store.dispatch(stateActions.updatePDFUrl(""));

            updateFileReaderInfo({
                file: '',
                totalPages: null,
                currentPageNumber: 1,
                currentPage: '',
            })
            break;
        case "image":
            canvas.getObjects().forEach((item) => {
                console.log(item);
                if (item?.type === "image" && item?.shadow?.color === updatedData?.shadow?.color) {
                    canvas.remove(item);
                }
            })
            const { src } = updatedData;
            fabric.Image.fromURL(src, (img) => {
                img.set(updatedData);
                canvas.add(img);
                // canvas.sendToBack(img)
            });
            break;
        case "pdf":
            const { pdfPage } = updatedData;
            updateFileReaderInfo({ file: pdfUrl, currentPageNumber: pdfPage })
            break;
        default:
            console.log("default")
            break

    }
};

export const undoRedoCanvasData = (canvas, data, updateFileReaderInfo) => {
    const { type } = data;
    switch (type) {
        case 'undo':
            const { data: undoData, replace = {} } = data;
            if (undoData?.type === "pdf") {
                store.dispatch(stateActions.updatePDFUrl(""));
                updateFileReaderInfo({
                    file: '',
                    totalPages: null,
                    currentPageNumber: 1,
                    currentPage: '',
                });
            } else {
                canvas.getObjects().forEach((item) => {
                    if (item?.type === undoData?.type && item?.shadow?.color === undoData?.shadow?.color) {
                        canvas.remove(item);
                    }
                })
            }
            if (Object.keys(replace).length) {
                const { url = "" } = replace || {};
                if (url) store.dispatch(stateActions.updatePDFUrl({ id: replace.shadow.color, url }));
                canvaDataImport(canvas, replace, updateFileReaderInfo, url);
            };
            break;
        case 'redo':
            const { data: redoData } = data;
            canvas.getObjects().forEach((item) => {
                if (item?.type === redoData?.type && item?.shadow?.color === redoData?.shadow?.color) {
                    canvas.remove(item);
                }
            })
            const { url = "" } = redoData || {};
            if (url) store.dispatch(stateActions.updatePDFUrl({ id: redoData.shadow.color, url }));
            canvaDataImport(canvas, redoData, updateFileReaderInfo, url);
            break;
        default:
            break;
    }
}

/*
NOTE: Temporarily mentaining id's of elements in shadow.color property 
      until a permamant solution is found to pass id's in objects. This flow is implemented like this
      in backend also.
*/


