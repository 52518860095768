import React, { memo } from 'react';
import Logger from '../../../../lib/Logger';
import { connect } from 'react-redux';
import { Box, Chip, Tooltip, Zoom } from '@mui/material';
import { withRoomContext } from '../../../../context/RoomContext';
import { Avatar, IconButton } from '@mui/material';
import hark from 'hark';
import Draggable from 'react-draggable';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import Stack from '@mui/material/Stack';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import AudioLevelIndicator from './AudioLevelIndicator';
import { axiosPostCall } from '../../../../services/apisCall';
import { renderErrorMessage } from '../../../ToastNotification';
import NetworkCellTwoToneIcon from '@mui/icons-material/NetworkCellTwoTone';
import SignalCellularConnectedNoInternet1BarTwoToneIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1BarTwoTone';
import SignalCellularConnectedNoInternet0BarTwoToneIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone';
import CloseIcon from '@mui/icons-material/Close';

const logger = new Logger('MeView');

class Me extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            videoResolutionWidth: null,
            videoResolutionHeight: null,
            videoCanPlay: false,
            videoElemPaused: false,
            maxSpatialLayer: null,
            networkS: 1,
            // showPFIcons: false,
            updateUnPin: {},
            disableFrameCheck: false,
            stream: false,
            tooltipOpen: false,
            tooltipDismissed: false,
        };

        // Latest received video track.
        // @type {MediaStreamTrack}
        this._audioTrack = null;

        // Latest received video track.
        // @type {MediaStreamTrack}
        this._videoTrack = null;

        // Periodic timer for reading video resolution.
        this._videoResolutionPeriodicTimer = null;

        // requestAnimationFrame for face detection.
        this._faceDetectionRequestAnimationFrame = null;
    }

    updateFocus = async (peerId, action, type) => {
        try {
            await axiosPostCall("pinScreen", { roomId: this.props.roomId, peerId, action, type })
        } catch (error) {
            logger.error("@Me.jsx ,updateFocus", error);
            renderErrorMessage(error.message)
        }
    }


    handleMouseEnterEvent = () => {
        if (this.state.disableFrameCheck) return;
        this.setState({ updateUnPin: { display: "flex" }, disableFrameCheck: true })
        setTimeout(() => {
            this.setState({ updateUnPin: { display: "none" }, disableFrameCheck: false })
        }, 2000)
        return
    }
    componentDidUpdate(prevProps) {
        if (prevProps.networkStrength !== this.props.networkStrength) {
            const { networkStrength } = this.props;
            if ((networkStrength === 1 || networkStrength === 2|| networkStrength===4) && !this.state.tooltipDismissed) {
                this.setState({ tooltipOpen: true });
            } else if (networkStrength === 3) {
                this.setState({ tooltipOpen: false });
            }
        }
    }
    getNetworkStatusText = (networkStrength) => {
        switch (networkStrength) {
            case 3:
                return "";
            case 2:
                return "Network status: Weak";
            case 1:
                return "Network status: Low";
            default:
                return "Network status: No Connection";
        }

    };
    handleTooltipClose = () => {
        this.setState({ tooltipOpen: false, tooltipDismissed: true });
    };
    render() {
        const {
            isMe,
            peer,
            audioMuted,
            videoVisible,
            // peerCount,
            audioEnabled,
            // checkShare,
            // focusedSX,
            // sx12,
            // focusCount,
            // showFocusSide,
            // role,
            micState = false,
            isPined,
            // isFocused,
            networkStrength,
        } = this.props;

        const { videoCanPlay, networkS, stream, tooltipOpen } = this.state;
        const networkStatusText = this.getNetworkStatusText(networkStrength);

        const iconSx = { width: '16px', height: '16px' };

        const getNetworkIcon = (networkStrength) => {
            switch (networkStrength) {
                case 3:
                    return < SignalCellularAltOutlinedIcon sx={iconSx} />
                case 2:
                    return < NetworkCellTwoToneIcon sx={{ ...iconSx, color: '#ed6c02' }} />
                case 1:
                    return < SignalCellularConnectedNoInternet1BarTwoToneIcon sx={{ ...iconSx, color: '#f50c0c' }} />
                default:
                    return < SignalCellularConnectedNoInternet0BarTwoToneIcon sx={{ ...iconSx, color: '#f50c0c' }} />
            }
        }


        return (
            <>
                <Draggable bounds="parent" cancel=".not-clickable" onStart={() => this.handleMouseEnterEvent()}>
                    <Box className='SelfScreen' sx={{
                        position: 'absolute',
                        bottom: '6px',
                        right: '6px',
                        height: '176px',
                        width: '120px',
                        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.75)',
                        background: '#000',
                        borderRadius: '10px',
                    }}>
                        <Box className='VideoScreen' sx={{
                            position: 'relative',
                            backgroundImage: 'url(./images/07.png)',// `url(${img1})`,
                            backgroundSize: 'contain',
                            height: '100%',
                            backgroundPosition: 'center center',
                            width: '100%',
                            backgroundRepeat: 'no-repeat',
                            borderRadius: '4px',
                        }}>
                            <video
                                ref='videoElem'
                                width={"120"}
                                height={'176'}
                                className={`${((!videoVisible || !videoCanPlay)) ? 'hideVideo' : 'show'} is_me_video`}
                                autoPlay
                                playsInline
                                muted
                                controls={false}
                            />
                            <audio
                                ref='audioElem'
                                autoPlay
                                playsInline
                                muted={isMe || audioMuted}
                                controls={false}
                            />
                            {((!videoVisible || !videoCanPlay)) &&
                                <Avatar variant="square"
                                    draggable="false"
                                    src="../../images/avatar.png"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        bgcolor: isMe ? '#27292B' : '#333333',
                                        borderRadius: '10px',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        color: '#454545'
                                    }}
                                />
                            }

                            {/* Show pined icon and audio status */}
                            <Box sx={{
                                position: 'absolute',
                                top: 2,
                                left: 2,
                            }}>
                                <Stack direction="row" sx={{
                                    position: 'relative',
                                    top: '8px',
                                    left: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    {/* Show network status on self screen */}
                                    {networkStatusText && (
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            title={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <span>{networkStatusText}</span>
                                                    <IconButton
                                                        sx={{ ml: 1, color: '#fff', fontSize: "10px" }}
                                                        onClick={this.handleTooltipClose}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            }
                                            placement="top"
                                            open={tooltipOpen}
                                            onOpen={() => this.setState({ tooltipOpen: true })}
                                            onClose={() => this.setState({ tooltipOpen: false })}
                                            PopperProps={{
                                                style: { zIndex: 1200 } 
                                            }}
                                        >
                                            <IconButton sx={{ color: networkS ? '#16f53b' : '#f50c0c' }}>
                                                {getNetworkIcon(networkStrength)}
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {!networkStatusText && (
                                        <IconButton sx={{ color: networkS ? '#16f53b' : '#f50c0c' }}>
                                            {getNetworkIcon(networkStrength)}
                                        </IconButton>
                                    )}

                                    {isPined && <IconButton sx={{
                                        color: 'rgba(255,255,255,.8)',
                                        mr: '5px',
                                        // bgcolor: 'rgba(0,0,0,.3)',
                                        '&:hover': {
                                            color: 'rgba(255,255,255,.8)',
                                            bgcolor: 'rgba(0,0,0,0)',
                                        }
                                    }}>
                                        <PushPinOutlinedIcon sx={{ ...iconSx, }} />
                                    </IconButton>}

                                    {((audioEnabled || micState === 'on') && stream) && <AudioLevelIndicator audioTrack={stream} />
                                        // <div className={`voice-detector level-${audioVolume}`}>
                                        //     <div className="dot-one"></div>
                                        //     <div className="dot-two"></div>
                                        //     <div className="dot-three"></div>
                                        // </div>
                                    }

                                </Stack>
                            </Box>

                            {/* Pin and focus icons */}
                            <Box sx={{
                                borderRadius: '20px',
                                display: 'none',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: 'calc(50% - 8px)',
                                left: '20%',
                                ...this.state.updateUnPin
                            }} className='pin not-clickable'>
                                <IconButton onClick={() => peer?.isPined ? this.updateFocus(peer.id, 'remove', 'pinScreen') : this.updateFocus(peer.id, 'add', 'pinScreen')} sx={{
                                    bgcolor: peer?.isPined ? 'rgba(33, 150, 243, 1)' : 'rgba(0,0,0,0.5)',
                                    color: peer?.isPined ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',
                                    fontSize: '16px',
                                    mr: 0.5,
                                    '&:hover': {
                                        bgcolor: peer?.isPined ? 'rgba(33, 150, 243, 1) !important' : 'rgba(0,0,0,0.5)',
                                        // color: peer?.isPined ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)',
                                    }
                                }} >
                                    <PushPinOutlinedIcon fontSize='10%' />
                                </IconButton>
                                <IconButton onClick={() => peer?.isFocused ? this.updateFocus(peer.id, 'remove', 'focusScreen') : this.updateFocus(peer?.id, 'add', 'focusScreen')} sx={{
                                    bgcolor: peer?.isFocused ? 'rgba(33, 150, 243, 1)' : 'rgba(0,0,0,0.5)',
                                    color: peer?.isFocused ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',
                                    fontSize: '16px',
                                    ml: 0.5,
                                    '&:hover': {
                                        bgcolor: peer?.isFocused ? 'rgba(33, 150, 243, 1) !important' : 'rgba(0,0,0,0.5)',
                                        // color: peer?.isFocused ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)',
                                    }
                                }} >
                                    <CenterFocusStrongIcon fontSize='10%' />
                                </IconButton>
                            </Box>

                            <Chip label="You" sx={{
                                position: 'absolute',
                                bottom: 4,
                                left: 10,
                                bgcolor: 'rgba(0,0,0,0.5)',
                                color: '#ffffff',
                                fontSize: '11px',
                                fontWeight: '500',
                                height: '24px'
                            }} />
                        </Box>
                    </Box>
                </Draggable>

            </>

        );
    }

    componentDidMount() {
        const { audioTrack, videoTrack } = this.props;

        this._setTracks(audioTrack, videoTrack);
        window.addEventListener('online', () => this.setState({ networkS: 1 }))
        window.addEventListener('offline', () => this.setState({ networkS: 0 }))
    }

    componentWillUnmount() {
        clearInterval(this._videoResolutionPeriodicTimer);
        cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

        const { videoElem } = this.refs;

        if (videoElem) {
            videoElem.oncanplay = null;
            videoElem.onplay = null;
            videoElem.onpause = null;
        }
    }

    UNSAFE_componentWillUpdate() {
        const {
            isMe,
            audioTrack,
            videoTrack,
            videoRtpParameters,
        } = this.props;
        const { maxSpatialLayer } = this.state;

        if (isMe && videoRtpParameters && maxSpatialLayer === null) {
            this.setState(
                {
                    maxSpatialLayer: videoRtpParameters.encodings.length - 1
                });
        }
        else if (isMe && !videoRtpParameters && maxSpatialLayer !== null) {
            this.setState({ maxSpatialLayer: null });
        }

        this._setTracks(audioTrack, videoTrack);
    }

    _setTracks(audioTrack, videoTrack) {
        if (this._audioTrack === audioTrack && this._videoTrack === videoTrack)
            return;

        this._audioTrack = audioTrack;
        this._videoTrack = videoTrack;

        this._stopVideoResolution();

        const { audioElem, videoElem } = this.refs;

        if (audioTrack) {
            const stream = new MediaStream();

            stream.addTrack(audioTrack);
            audioElem.srcObject = stream;


            audioElem.play()
                .catch((error) => logger.warn('audioElem.play() failed:%o', error));

            // this._runHark(stream);
            this.setState({ stream })

            // this._runHark(stream);
        } else {
            audioElem.srcObject = null;
        }

        if (videoTrack) {
            const stream = new MediaStream();

            stream.addTrack(videoTrack);
            videoElem.srcObject = stream;

            videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

            videoElem.onplay = () => {
                this.setState({ videoElemPaused: false });

                audioElem.play()
                    .catch((error) => logger.warn('audioElem.play() With video failed:%o', error));
            };

            videoElem.onpause = () => this.setState({ videoElemPaused: true });

            videoElem.play()
                .catch((error) => logger.warn('videoElem.play() failed:%o', error));

            this._startVideoResolution();
        }
        else {
            videoElem.srcObject = null;
        }
    }

    _startVideoResolution() {
        this._videoResolutionPeriodicTimer = setInterval(() => {
            const { videoResolutionWidth, videoResolutionHeight } = this.state;
            const { videoElem } = this.refs;

            if (
                videoElem?.videoWidth !== videoResolutionWidth ||
                videoElem?.videoHeight !== videoResolutionHeight
            ) {
                this.setState(
                    {
                        videoResolutionWidth: videoElem?.videoWidth,
                        videoResolutionHeight: videoElem?.videoHeight
                    });
            }
        }, 500);
    }

    _stopVideoResolution() {
        clearInterval(this._videoResolutionPeriodicTimer);

        this.setState(
            {
                videoResolutionWidth: null,
                videoResolutionHeight: null
            });
    }

    _runHark(stream) {
        if (!stream.getAudioTracks()[0])
            throw new Error('_runHark() | given stream has no audio track');

        this._hark = hark(stream, { play: false });

        // eslint-disable-next-line no-unused-vars
        this._hark.on('volume_change', (dBs, threshold) => {
            // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
            //   Math.pow(10, dBs / 20)
            // However it does not produce a visually useful output, so let exagerate
            // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
            // minimize component renderings.
            let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

            if (audioVolume === 1)
                audioVolume = 0;

            if (audioVolume !== this.state.audioVolume) {
                // this.setState({ audioVolume });
            }
        });
    }

}

const mapStateToProps = (state) => {
    const producersArray = Object.values(state.producers);
    const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
    const videoProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type !== "share");
    const { networkStrength } = state.networkStrength;

    return {
        isMe: true,
        peer: state.me,
        audioProducer,
        videoProducer,
        isPined: state.me?.isPined || false,
        // audioProducerId: audioProducer ? audioProducer.id : null,
        // videoProducerId: videoProducer ? videoProducer.id : null,
        // audioRtpParameters: audioProducer ? audioProducer.rtpParameters : null,
        videoRtpParameters: videoProducer ? videoProducer.rtpParameters : null,
        audioTrack: audioProducer ? audioProducer.track : null,
        videoTrack: videoProducer ? videoProducer.track : null,
        videoVisible: Boolean(videoProducer) && !videoProducer.paused,
        networkStrength,
        // audioCodec: audioProducer ? audioProducer.codec : null,
        // videoCodec: videoProducer ? videoProducer.codec : null,
        // audioScore: audioProducer ? audioProducer.score : null,
        // videoScore: videoProducer ? videoProducer.score : null,
        // roomId: roomId,
        // micState: micState,
        // screenShare: screenShare,

    };

};

const PeerViewContainer = withRoomContext(connect(
    mapStateToProps,
)(Me));

export default memo(PeerViewContainer)
