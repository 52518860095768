import { alpha } from '@mui/material/styles';

const GREY = {
  0: '#000000',
  70: '#242628',
  100: '#2C2D2F',
  200: '#434546',
  300: '#5B5C5D',
  400: '#727374',
  500: '#898A8B',
  600: '#A1A2A3',
  700: '#B8B9BA',
  800: '#D0D0D1',
  900: '#E7E8E8',
  1000: '#F0F0F0',
};

const CARD = {
  main: '#fff',
  // 0: '#FFFFFF',
  // 70: '#242628',
  // 100: '#2C2D2F',
  // 200: '#434546',
  // 300: '#5B5C5D',
  // 400: '#727374',
  // 500: '#898A8B',
  // 600: '#A1A2A3',
  // 700: '#B8B9BA',
  // 800: '#D0D0D1',
  // 900: '#E7E8E8',
  // 1000: '#F0F0F0',
};

const PRIMARY = {
  lighter: '#D1E9FC',
  light: '#5F98D6',
  main: '#0C8CE9',
  dark: '#0F61B9',
  darker: '#061B64',
  contrastText: '#fff',
};

// Not updated with theme
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#5FAD65',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

// Not updated with theme
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#62D08E',
  main: '#28A745',
  dark: '#1A742E',
  darker: '#08660D',
  contrastText: GREY[800],
};

// Amber
const WARNING = {
  lighter: '#FFF7CD',
  light: '#AF8756',
  main: '#D37F18',
  dark: '#9F6C2D',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#E77A6B',
  main: '#E2533F',
  dark: '#CB4431',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const YELLOW = {
  light: '#F0EB79',
  main: '#F2C55C',
  dark: '#C2B32D',
};

const PINK = {
  light: '#EFA4B9',
  main: '#EE819F',
  dark: '#D96B8A',
}

const ORANGE = {
  light: '#E4AD79',
  main: '#E28D3F',
  dark: '#CB7424',
}
const PURPLE = {
  light: '#B793D6',
  main: '#A26CD0',
  dark: '#8D4FC1',
}

const CUSTOM = {
  background: {
    light: "#F0F0F0",
    main: '#F7F8FA',
  },
  cardBg: '#fff'
  // sidebarIcon: '#727374'
}

const palette = {
  mode: 'light',
  common: {
    black: '#000000',
    white: '#fff',
    dark: "rgba(0,0,0,0.87)",
    light: "rgba(255,255,255,0.87)",
    primaryDark: "#1976D2",
    whiteDark: "#ffffff",
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: '#F7F8FA',
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  custom: CUSTOM,
  card: CARD,
  yellow: YELLOW,
  pink: PINK,
  orange: ORANGE,
  purple: PURPLE
  // breakpoints: {
  //   values: {
  //     laptop: 1024,
  //     tablet: 640,
  //     mobile: 0,
  //     desktop: 1280,
  //   },
  // },
};

export default palette;
