import React, { memo, useState } from 'react';
import {
    IconButton,
    Modal,
    Box,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { WaitingRoomSearchBar } from '../../../../sections/common/room/waitingRoom/searchBar'
import { WaitingUsersCount } from '../../../../sections/common/room/waitingRoom/count';
import { WaitingRoomActions } from '../../../../sections/common/room/waitingRoom/actionsButtons';
import { WaitingUsersList } from '../../../../sections/common/room/waitingRoom/list';

function WaitingRoom({ open, drawerWidth, theme, handleDrawerClose, DrawerHeader }) {
    const [searchTxt, setSearchTxt] = useState("");

    const waitingCss = {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 72px)',
        bgcolor: '#ffffff',
        color: '#000',
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleDrawerClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={waitingCss}>
                    {/* Modal Header */}
                    <Box className='ModalHeader' sx={{
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}>
                        <Typography id="modal-modal-title" sx={{
                            fontWeight: '600',
                            fontSize: '16px',
                        }}>Waiting Room</Typography>
                        <IconButton onClick={() => handleDrawerClose()} sx={{
                            p: 0,
                        }}>
                            <CloseIcon
                                sx={{
                                    color: '#999999',
                                    fontSize: '20px'
                                }}
                            />
                        </IconButton>
                    </Box>

                    {/* Modal Body */}
                    <Box className='ModalBody'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>


                            <WaitingRoomSearchBar searchTxt={(value) => setSearchTxt(value)} />

                            <WaitingUsersCount />

                            <WaitingRoomActions />

                            <WaitingUsersList
                                searchTxt={searchTxt}
                            />

                        </Box>
                    </Box>
                </Box>
            </Modal>
            {/* </Drawer> */}

        </>
    )
}

export default memo(WaitingRoom)
