import React, { memo, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip, Zoom, } from '@mui/material';
import Stack from '@mui/material/Stack';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import AudioLevelIndicator from './AudioLevelIndicator';
import MicOffIconPeer from './MicOffIconPeer';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import NetworkCellTwoToneIcon from '@mui/icons-material/NetworkCellTwoTone';
import SignalCellularConnectedNoInternet1BarTwoToneIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1BarTwoTone';
import SignalCellularConnectedNoInternet0BarTwoToneIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone';
import CloseIcon from '@mui/icons-material/Close';
function PeerTopBar({ isMe, networkS, networkStrength, isPined, audioEnabled, micState, stream }) {
    const iconSx = { width: '16px', height: '16px' };
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const getNetworkIcon = (networkStrength) => {
        switch (networkStrength) {
            case 3:
                return < SignalCellularAltOutlinedIcon sx={iconSx} />
            case 2:
                return < NetworkCellTwoToneIcon sx={{ ...iconSx, color: '#ed6c02' }} />
            case 1:
                return < SignalCellularConnectedNoInternet1BarTwoToneIcon sx={{ ...iconSx, color: '#f50c0c' }} />
            default:
                return < SignalCellularConnectedNoInternet0BarTwoToneIcon sx={{ ...iconSx, color: '#f50c0c' }} />
        }
    }

    const getNetworkStatusText = (networkStrength) => {
        switch (networkStrength) {
            case 3:
                return "";
            case 2:
                return "Network status: Weak";
            case 1:
                return "Network status: Low";
            default:
                return "Network status: No Connection";
        }

    };

    useEffect(() => {
        if (networkStrength === 1 || networkStrength === 2 ||networkStrength===4 ) {
            setTooltipOpen(true);
        } else if (networkStrength === 3) {
            setTooltipOpen(false);
        }
    }, [networkStrength]);
    const handleTooltipClose = (event) => {
        const isCloseIconClicked = event && event.target && event.target.closest('button[aria-label="Close"]');
        if (isCloseIconClicked) {
            setTooltipOpen(false);
        }
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };
    return (
        <Box sx={{
            position: 'absolute',
            top: 2,
            left: 2,
        }}>
            <Stack direction="row" sx={{
                position: 'relative',
                top: '8px',
                left: '8px',
                display: 'flex',
                alignItems: 'center',
            }}>
                {/* Show network status on self screen */}
                {isMe && (
                    <>
                        {networkStrength && (
                            <Tooltip
                                TransitionComponent={Zoom}
                                title={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{getNetworkStatusText(networkStrength)}</span>
                                        <IconButton
                                            sx={{ ml: 1, color: '#fff', fontSize: "10px" }}
                                            onClick={handleTooltipClose}
                                            aria-label="Close" 

                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                }
                                placement="top"
                                open={tooltipOpen}
                                
                            >
                                <IconButton sx={{ color: networkS ? '#16f53b' : '#f50c0c' }}>
                                    {getNetworkIcon(networkStrength)}
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}

                {isPined && <IconButton sx={{
                    color: 'rgba(255,255,255,.8)',
                    mr: '5px',
                    // bgcolor: 'rgba(0,0,0,.3)',
                    '&:hover': {
                        color: 'rgba(255,255,255,.8)',
                        bgcolor: 'rgba(0,0,0,0)',
                    }
                }}>
                    <PushPinOutlinedIcon sx={{ ...iconSx, }} />
                </IconButton>}

                {((audioEnabled || micState === 'on') && stream) ?
                    <AudioLevelIndicator audioTrack={stream} />
                    : !isMe && <MicOffIconPeer />
                }

            </Stack>
        </Box>
    )
}
export default memo(PeerTopBar);
