import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { useSelector } from 'react-redux';
import randomString from 'random-string';

import { styled } from "@mui/material/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography, MenuItem, FormControl, Select, Box, Radio, RadioGroup, TextField, FormGroup, Button, IconButton } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const MainSectionInner = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "700px",
  height: "580px",
  backgroundColor: theme.palette.card.main,
  padding: "24px 20px 40px 20px",
  borderRadius: "6px",
  [theme.breakpoints.down('md')]: {
    height: "calc(100vh - 108px)",
    width: "100%",
    backgroundColor: "inherit",
  },
  [theme.breakpoints.down('xs')]: {
    height: "calc(100vh - 200px)",
    width: "100%",
    backgroundColor: "inherit",
  },
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[70],
  fontSize: "24px",
  fontWeight: 800
}));

const RecurringHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[300],
  fontSize: "14px"
}));

const RecurringSubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: "14px"
}));

const BasicSelect = styled(Select)(({ theme }) => ({
  borderColor: theme.palette.grey[700],
  borderRadius: "3px",
  "& input": {
    color: theme.palette.grey[500],
  },
  width: "200px",
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));

const BasicSelectBox = styled(Select)(({ theme }) => ({
  borderColor: theme.palette.grey[700],
  borderRadius: "3px",
  borderBottomRightRadius: "0px",
  borderTopRightRadius: "0px",
  "& input": {
    color: theme.palette.grey[500],
  },
  width: "204px",
  [theme.breakpoints.down('sm')]: {
    width: "100%",
    minWidth: "100px"
  },
  [theme.breakpoints.down('xs')]: {
    width: "100%",
    minWidth: "100px"
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "5px",
  backgroundColor: "transparent",
}))

const LabelBox = styled(Box)(({ theme }) => ({
  height: "40px",
  width: "50px",
  color: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[800],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderBottomRightRadius: "3px",
  borderTopRightRadius: "3px",
  fontSize: "14px",
  fontWeight: 500
}));

const RowBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: 'center',
  gap: "10px"
}));

const ColumnBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
  borderRadius: "3px",
}));

const CopyBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid",
  borderColor: "grey.800",
  backgroundColor: "grey.800",
  padding: "10px",
  borderRadius: "3px"
}));

export default function ScheduleSection2({ setPage, scheduledData, setScheduledData, scheduleWebinarSubmit, scheduleLoading }) {
  const [rec, setRec] = useState(false);
  const [e2ee, setE2ee] = useState(false);
  const [recurrType, setRecurrType] = useState("Daily");
  const [repeatEvery, setRepeatEvery] = useState(1);
  const [dateOfMonth, setDateOfMonth] = useState(1);
  const [week, setWeek] = useState(1);
  const [monthWeek, /*setMonthWeek*/] = useState(["FIRST", "SECOND", "THIRD", "FOURTH", "LAST"]);
  const [montWeekDay, setMonthWeekDay] = useState("MON");
  const [recurrence, /*setRecurrence*/] = useState(["Daily", "Weekly", "Monthly", "No Fixed Time"]);
  const [days, /*setDays*/] = useState(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]);
  const [endDate, setEndDate] = useState(moment().add(15, "minutes").format());
  const [endOccur, setEndOccur] = useState(1);
  const [endBy, setEndBy] = useState("date");
  const [monthRecur, setMonthRecur] = useState("date");
  const [selectedDays, setSelectedDays] = useState([]);
  const [e2eeKey, setE2eeKey] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const { isMobile, isPortrait } = useSelector(state => state?.deviceInfo || {});

  useEffect(() => {
    const {
      recurrence,
      recurrData,
      e2ee,
    } = scheduledData;

    setRec(recurrence || false);
    setRecurrType(recurrData?.recurrType || "Daily");
    setRepeatEvery(recurrData?.repeatEvery || 1);
    setDateOfMonth(recurrData?.dateOfMonth || 1);
    setWeek(recurrData?.weekOfMonth || 1);
    setMonthWeekDay(recurrData?.dayOfWeek || "MON");
    setEndDate(recurrData?.endDate || moment().add(15, "minutes").format());
    setEndOccur(recurrData?.endOccur || 1);
    setEndBy(!recurrData?.endOccur ? "date" : "occurrence");
    setMonthRecur(recurrData?.dateOfMonth ? "date" : "day");
    setSelectedDays(recurrData?.selectedDays || []);
    setE2ee(e2ee || false);
    // eslint-disable-next-line
  }, [scheduledData]);

  const handleCheckboxChange = (day) => {
    // Toggle the selection state of the clicked day
    setSelectedDays((prevSelected) => {
      if (prevSelected.includes(day)) {
        return prevSelected.filter((selectedDay) => selectedDay !== day);
      } else {
        return [...prevSelected, day];
      }
    });
  };

  const maxDate = {
    "Daily": moment().add(6, 'months').toDate(),
    "Weekly": moment().add(2, 'years').toDate(),
    "Monthly": moment().add(3, 'years').toDate()
  }

  const generateE2eeKey = () => {
    if (!e2eeKey) setE2eeKey(randomString({ length: 10 }).toLowerCase())
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  const scheduleWebinar = async (e, skip = false) => {
    e.preventDefault();
    let recurrData = {};

    if (!skip && rec) {
      recurrData = { repeatEvery, recurrType };
      recurrData = endBy === "date" ? { ...recurrData, endDate } : { ...recurrData, endOccur };

      if (recurrType === "Weekly") recurrData = { ...recurrData, selectedDays };
      if (recurrType === "Monthly") recurrData = monthRecur === "date" ?
        { ...recurrData, dateOfMonth } :
        { ...recurrData, weekOfMonth: week, dayOfWeek: montWeekDay }
    }

    let data = {
      ...scheduledData,
      recurrence: rec,
      recurrData,
      e2ee,
    }

    if (e2ee && (scheduledData?.autoRecord || scheduledData?.record)) {
      data.autoRecord = false
      data.record = false
    }

    await scheduleWebinarSubmit(data);
  }

  return (
    <>
      <ToastContainer />

      <MainSectionInner>

        {!(isMobile || isPortrait) && <RowBox>
          <KeyboardBackspaceIcon sx={{ color: "grey.100", width: '30px', height: '30px' }} onClick={() => setPage(1)} />
          <HeadingText>
            Schedule Meeting
          </HeadingText>
        </RowBox>}
        

        <ColumnBox
          sx={{
            width: "100%",
            height: "100%",
            pt: '10px'
          }}>
          <Box>
            <FormControlLabel sx={{ gap: '3px' }} control={
              <Checkbox
                size="small"
                inputProps={{ "aria-label": "Checkbox demo" }}
                checked={rec}
                onChange={(event) => setRec(event.target.checked)}
              />
            } label={
              <RecurringHeading> Recurring Meeting </RecurringHeading>
            } />

            {rec && <ColumnBox sx={{ backgroundColor: "grey.1000" }}>
              <Box sx={{
                display: "flex",
                gap: "10px",
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: {
                    xs: "calc(100% / 2)",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto"
                  },
                  gap: '6px'
                }}>
                  <RecurringSubHeading>Reccurrence</RecurringSubHeading>
                  <FormControl size="small">
                    <BasicSelect
                      value={recurrType}
                      onChange={(event) => setRecurrType(event.target.value)}
                      displayEmpty
                      inputProps={{ sx: { color: "grey.400" } }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            backgroundColor: "card.main",
                            color: "grey.400",
                            "& .MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "grey.800"
                            },
                          }
                        }
                      }}
                    >
                      {recurrence.map((recType, index) => (
                        <MenuItem key={index + 1} value={recType}>{recType}</MenuItem>
                      ))}

                    </BasicSelect>
                  </FormControl>
                </Box>


                {recurrType !== "No Fixed Time" && <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: {
                    xs: "calc(100% / 2)",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto"
                  },
                  gap: '6px'
                }}>
                  <RecurringSubHeading>Repeat Every</RecurringSubHeading>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                    <FormControl size="small">
                      <BasicSelectBox
                        value={repeatEvery}
                        onChange={(event) => setRepeatEvery(event.target.value)}
                        displayEmpty
                        inputProps={{ sx: { color: "grey.400" } }}
                        MenuProps={{
                          style: {
                            maxHeight: 400
                          },
                          PaperProps: {
                            sx: {
                              backgroundColor: "card.main",
                              color: "grey.400",
                              "& .MuiMenuItem-root.Mui-selected": {
                                backgroundColor: "grey.800"
                              },
                            }
                          }
                        }}
                      >
                        {[...Array(5)].map((ele, index) => (
                          <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                        ))}
                      </BasicSelectBox>
                    </FormControl>
                    <LabelBox>{recurrType === "Daily" ? "Day" : recurrType === "Monthly" ? "Month" : "Weekly"}</LabelBox>
                  </Box>

                </Box>}
              </Box>

              {recurrType === "Weekly" && <FormGroup aria-label="position" row>
                {days.map((day, index) => (
                  <FormControlLabel
                    key={index}
                    value={day}
                    control={
                      <Checkbox
                        checked={selectedDays.includes(day)}
                        onChange={() => handleCheckboxChange(day)}
                      />
                    }
                    label={<RecurringHeading>{day}</RecurringHeading>}
                  />
                ))}
              </FormGroup>}

              {recurrType === "Monthly" && <Box sx={{
                display: "flex",
                gap: "10px"
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <RecurringSubHeading>Occurs On</RecurringSubHeading>

                  <RadioGroup
                    sx={{
                      display: "flex",
                      gap: "10px"
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={monthRecur}
                    onChange={(event) => setMonthRecur(event.target.value)}
                  >
                    <FormControlLabel value="date" control={<Radio />} label={<Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px"
                    }}>
                      <RecurringSubHeading>Day</RecurringSubHeading>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                        <FormControl size="small">
                          <BasicSelectBox sx={{ width: "70px" }}
                            value={dateOfMonth}
                            onChange={(event) => setDateOfMonth(event.target.value)}
                            displayEmpty
                            inputProps={{ sx: { color: "grey.400" } }}
                            MenuProps={{
                              style: {
                                maxHeight: 400
                              },
                              PaperProps: {
                                sx: {
                                  backgroundColor: "card.main",
                                  color: "grey.400",
                                  "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "grey.800"
                                  },
                                }
                              }
                            }}
                          >
                            {[...Array(31)].map((ele, index) => (
                              <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                            ))}
                          </BasicSelectBox>
                        </FormControl>
                        <LabelBox sx={{ width: "100px" }}>of the Month</LabelBox>
                      </Box>
                    </Box>} />
                    <FormControlLabel value="day" control={<Radio />} label={<Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "6px"
                    }}>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "10px" }}>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <FormControl size="small">
                              <BasicSelectBox sx={{ width: "115px" }}
                                value={week}
                                onChange={(event) => setWeek(event.target.value)}
                                displayEmpty
                                inputProps={{ sx: { color: "grey.400" } }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      backgroundColor: "card.main",
                                      color: "grey.400",
                                      "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: "grey.800"
                                      },
                                    }
                                  }
                                }}
                              >
                                {monthWeek.map((weekCount, index) => (
                                  <MenuItem key={index + 1} value={index + 1}>{weekCount}</MenuItem>
                                ))}
                              </BasicSelectBox>
                            </FormControl>
                            <LabelBox sx={{ width: "44px" }}>Week</LabelBox>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <FormControl size="small">
                              <BasicSelectBox sx={{ width: "115px" }}
                                value={montWeekDay}
                                onChange={(event) => setMonthWeekDay(event.target.value)}
                                displayEmpty
                                inputProps={{ sx: { color: "grey.400" } }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      backgroundColor: "card.main",
                                      color: "grey.400",
                                      "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: "grey.800"
                                      },
                                    }
                                  }
                                }}
                              >
                                {days.map((day, index) => (
                                  <MenuItem key={index + 1} value={day}>{day}</MenuItem>
                                ))}
                              </BasicSelectBox>
                            </FormControl>
                            <LabelBox sx={{ width: "44px" }}>Day</LabelBox>
                          </Box>
                        </Box>
                      </Box>
                    </Box>} />
                  </RadioGroup>
                </Box>
              </Box>}

              {recurrType !== "No Fixed Time" && <Box sx={{
                display: "flex",
                gap: "10px"
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <RecurringSubHeading>End</RecurringSubHeading>
                  <RadioGroup
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                      gap: "10px"
                    }}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="controlled-radio-buttons-group"
                    value={endBy}
                    onChange={(event) => setEndBy(event.target.value)}
                  >
                    <FormControlLabel value="date" control={<Radio />} label={
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px"
                      }}>
                        <RecurringSubHeading>By</RecurringSubHeading>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={1} >
                            <DateTimePicker
                              renderInput={(props) =>
                                <CustomTextField
                                  size="small"
                                  {...props}
                                />}
                              value={endDate}
                              onChange={(newValue) => {
                                setEndDate(newValue);
                              }}
                              minDate={new Date()}
                              maxDate={maxDate[recurrType]}
                              inputProps={{ sx: { color: "grey.400", } }}
                              PopperProps={{
                                sx: {
                                  ".MuiPaper-root": {
                                    backgroundColor: "card.main",
                                    color: "grey.400"
                                  },
                                  ".MuiPickersDay-root": {
                                    backgroundColor: "card.main",
                                    color: "grey.400"
                                  },
                                  ".MuiClockNumber-root": {
                                    color: "grey.400"
                                  },
                                },
                              }}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Box>} />
                    <FormControlLabel value="occurrence" control={<Radio />} label={<Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px"
                    }}>
                      <RecurringSubHeading>After</RecurringSubHeading>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                        <FormControl size="small">
                          <BasicSelectBox sx={{ width: "100px" }}
                            value={endOccur}
                            onChange={(event) => setEndOccur(event.target.value)}
                            displayEmpty
                            inputProps={{ sx: { color: "grey.400" } }}
                            MenuProps={{
                              style: {
                                maxHeight: 400,
                              },
                              PaperProps: {
                                sx: {
                                  backgroundColor: "card.main",
                                  color: "grey.400",
                                  "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "grey.800"
                                  },
                                }
                              }
                            }}
                          >
                            {[...Array(10)].map((ele, index) => (
                              <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                            ))}
                          </BasicSelectBox>
                        </FormControl>
                        <LabelBox sx={{ width: "100px" }}>Occurrence</LabelBox>
                      </Box>
                    </Box>} />
                  </RadioGroup>
                </Box>
              </Box>}
            </ColumnBox>}
          </Box>

          <Box>
            <FormControlLabel sx={{ gap: '3px' }} control={
              <Checkbox
                size="small"
                inputProps={{ "aria-label": "Checkbox demo" }}
                checked={e2ee}
                onChange={(event) => setE2ee(event.target.checked)}
              />
            } label={
              <RecurringHeading> End to End Encryption </RecurringHeading>
            } />

            {e2ee && <ColumnBox sx={{ backgroundColor: "grey.1000" }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center"
                }}>
                  <RecurringSubHeading> End to End Encryption </RecurringSubHeading>

                  <Typography sx={{ color: 'warning.main', fontSize: '14px' }}>
                    * (Recording feature will be disabled in case of e2e).
                  </Typography>

                </Box>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={generateE2eeKey}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "295px",
                      lg: "295px",
                      xl: "295px"
                    },
                    textTransform: "none"
                  }}
                >
                  Generate Meeting E2E Key
                </Button>
                {e2eeKey && <>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <RecurringSubHeading> Your Encryption Key </RecurringSubHeading>
                    <RecurringSubHeading sx={{ color: "#9F6C2D" }}> *( Please keep this key somewhere safe and accessible. ) </RecurringSubHeading>
                  </Box>

                  <CopyBox>
                    <RecurringHeading>{e2eeKey}</RecurringHeading>

                    <IconButton sx={{
                      background: copySuccess ? "#28B446" : "#1770F6",
                      '&:hover': {
                        bgcolor: copySuccess ? "#28B446" : "#1770F6",
                      }
                    }}
                      onClick={() => copyToClipboard(e2eeKey)}>
                      <ContentCopyIcon sx={{
                        color: '#fff',
                        fontSize: '12px'
                      }} />
                    </IconButton>
                  </CopyBox>
                </>}
              </Box>
            </ColumnBox>}
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row"
            },
            justifyContent: "flex-end",
            alignItems: "flex-end",
            height: "100%"
          }}>
            <LoadingButton
              size="small"
              variant="contained"
              loading={scheduleLoading ? true : false}
              loadingPosition="center"
              onClick={(e) => scheduleWebinar(e)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "295px",
                  lg: "295px",
                  xl: "295px"
                },
                textTransform: "none"
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </ColumnBox>

      </MainSectionInner >
    </>
  )
}