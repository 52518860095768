import React from 'react';
import { MenuItem, Menu, Divider } from '@mui/material';

export default function EndWebinarIconMenu({ anchorElEnd, handleCloseEnd, setOpenEndAll, setOpenLeave}) {
    return (
        <Menu
            anchorEl={anchorElEnd}
            id="end-menu"
            open={Boolean(anchorElEnd)}
            onClose={() => handleCloseEnd()}
            onClick={() => handleCloseEnd()}
            PaperProps={{
                elevation: 0,
                sx: {
                    mt: -3,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: -10,
                        right: 15,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                }
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            <MenuItem
                onClick={() => setOpenEndAll()}
                sx={{ fontSize: 14, }}>
                End for all
            </MenuItem>
            <Divider />
            <MenuItem
                onClick={() => setOpenLeave()}
                sx={{ fontSize: 14, }}>
                Leave
            </MenuItem>
        </Menu>
    )
}
