import React, { memo } from 'react';
import Logger from '../../../../lib/Logger';

const logger = new Logger('AudioOnlyPeerView');

class AudioOnlyPeerView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.speakerId=""

        // Latest received video track.
        // @type {MediaStreamTrack}
        this._audioTrack = null;
    }

    render() {
        return (
            <audio ref='audioElem' autoPlay playsInline controls={false} muted={false} /* muted="muted" */ />
        );
    }

    componentDidMount() {
        const { audioTrack, speaker } = this.props;
        this._setTracks(audioTrack, speaker);
    }

    UNSAFE_componentWillUpdate() {
        const { audioTrack, speaker } = this.props;

        this._setTracks(audioTrack, speaker);
    }

    async _setTracks(audioTrack, speakerId) {
        if (this._audioTrack === audioTrack && this.speakerId === speakerId?.speaker)
            return;

        if (this._audioTrack === audioTrack && this.speakerId !== speakerId?.speaker) {
            if (speakerId?.speaker) {
                logger.debug('audioTrack AudioOnlyPeerView speaker change root:%o', speakerId?.speaker)
                const { audioElem } = this.refs;
                await audioElem.setSinkId(speakerId?.speaker);
                this.speakerId = speakerId?.speaker;
            }
            return;
        }
        this._audioTrack = audioTrack;

        const { audioElem } = this.refs;

        if (audioTrack) {
            const stream = new MediaStream();

            stream.addTrack(audioTrack);
            audioElem.srcObject = stream;

            if (speakerId?.speaker) {
				logger.debug('audioTrack AudioOnlyPeerView speaker change:%o', speakerId.speaker)
				await audioElem.setSinkId(speakerId.speaker);
				this.speakerId = speakerId?.speaker;
			}

            document.addEventListener('click', audioPlay);

            function audioPlay() {
                audioElem.play().catch((error) => {
                    logger.warn('audioElem.play() AudioOnlyPeerView failed:%o', error)

                });
            }

        } else {
            audioElem.srcObject = null;
        }
    }

}

export default memo(AudioOnlyPeerView)