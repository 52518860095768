const initState = {
    mic: true,
    camera: true,
};

const micAndCamPermissions = (state = initState, action) => {
    switch (action.type) {
        case 'SET_MIC_AND_CAM_PERMISSIONS':
            return { mic: false, camera: false }
        default:
            return state;
    }
}

export default micAndCamPermissions;