import 'react-toastify/dist/ReactToastify.css';
import { toast, Zoom } from 'react-toastify';

const renderSuccessMessage = (msg) => {
    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar:true,
        autoClose: 1000,
        draggable: true,
        newestOnTop: true,
        pauseOnFocusLoss: true,
        rtl:false,
        limit:10,
        transition: Zoom
    });
}

const renderErrorMessage = (error) => {
    toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar:true,
        autoClose: 1000,
        draggable: true,
        newestOnTop: true,
        pauseOnFocusLoss: true,
        rtl:false,
        limit:10,
        transition: Zoom
    });
}

const renderLoadingToast = (msg) => {
    return toast(msg, { isLoading: true, autoClose: null })
}

const renderUpdateToast = (id, Type, msg) => {
    return toast.update(id, {
        render: msg,
        type: Type || "success",
        isLoading: false,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export {
    renderSuccessMessage,
    renderErrorMessage,
    renderLoadingToast,
    renderUpdateToast
}