import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar({ search }) {
    return (
        <>
            <Box sx={{
                my: '16px',
                px: 1,
            }} className='ListBoxSearch'>
                <TextField
                    disabled
                    id="outlined-size-small"
                    placeholder="Search meeting by name..."
                    size="small"
                    sx={{
                        width: '100%',
                        backgroundColor: 'grey.800',
                        borderRadius: '6px'
                    }}
                    className='ListTextFieldSearch'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start" className='ListInputAdornment'>
                                <SearchIcon size="small" />
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ sx: { color: "grey.400" } }}
                // onChange={(e) => search(e.target.value)}
                />
            </Box>
        </>
    )
}
