import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from './web/room/appPropTypes';
import * as stateActions from '../redux/actions/stateActions';
import { renderErrorMessage, renderSuccessMessage } from './ToastNotification';

const Notifications = ({ notifications, onClick }) => {
	return (
		<div data-component='Notifications'>
			{
				notifications.map((notification, index) => {
					return (
						<div key={index}>
							<div
								onClick={() => onClick(notification.id ?? index)}
							>
								{notification.type === 'info' ?
									renderSuccessMessage(notification.text)
									:
									renderErrorMessage(notification.text)
								}
							</div>
						</div>
					);
				})
			}
		</div>
	);
};

Notifications.propTypes =
{
	notifications: PropTypes.arrayOf(appPropTypes.Notification).isRequired,
	onClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	const { notifications } = state;

	return { notifications };
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClick: (notificationId) => {
			dispatch(stateActions.removeNotification(notificationId));
		}/* ,
		update: (notificationId) => {
			// dispatch(stateActions.removeNotification(notificationId));
		} */
	};
};

const NotificationsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Notifications);

export default NotificationsContainer;
