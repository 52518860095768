import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const DownloadOrSaveMeeting = () => {
  const baseurl = window.location.origin

  return (
    <>
      <Heading
        text='Download or Save Your Meetings/ Where to Find Recorded Meetings '
      />

      <ImageComp
        name={"downloadOrSaveRecordMeeting"}
      />

      <ListComp

        data={[

          {
            name: 'All the recorded meetings can be found in the ‘Report’ section.  ',
            id: '13',

          },
          {
            name: `To navigate there,visit '${baseurl}' and log in to your account.`,
            id: '13',
          }, {
            name: 'Click ‘Report’ in the header menu.  ',
            id: '15',

          }, {
            name: 'Here, you will see all the meetings you have attended. ',
            id: '16',


          }, {
            name: 'Look for the meeting you recorded.    ',
            id: '17',

          },
          {
            name: 'In the ‘Recordings’ tab, you will see the file size of the recording.  ',
            id: '18',

          },
          {
            name: 'Click on the drop-down arrow next to the file size.  ',
            id: '19',

          },
          {
            name: 'Here you will find the download button, save the file on your computer using that button.  ',
            id: '39',

          },

        ]}

      />

    </>
  );
};

export default DownloadOrSaveMeeting;
