import React from 'react';
import Heading from './components/heading';
import ListComp from './components/list';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AddOrRemoveAllParticipants = () => {
  return (
    <>
      <Heading
        text='Add or Remove All Participants at Once from the Waiting Room '
      />

      <ListComp
        data={[

          {
            name: (
              <>
                When you are in a meeting, click on the <MoreVertIcon style={{ verticalAlign: 'middle' }} />
                icon in the toolbar.
              </>
            ),
            id: '13',
          },
          {
            name: 'Now, click on the ‘Waiting Room.',
            id: '14',

          }, {
            name: 'This will open the list of all the participants in the waiting room.',
            id: '15',

          }, {
            name: 'Here. you will see two buttons, ',
            id: '16',
            child: [{
              name: 'Allow All: to accept the request from all the participants.   ',
              id: '12'
            }, {
              name: 'Decline All: to disallow all the participants from joining the meeting.  ',
              id: '13'
            },
            ]


          },

        ]}

      />

    </>
  );
};

export default AddOrRemoveAllParticipants;




