import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import URLParse from 'url-parse';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';
import { createRedirectPath } from '../../../utils/common';

export default function MobileKickPeer() {
    const navigate = useNavigate();
    const urlParser = new URLParse(window.location.href, true)

    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            if (event.state) {
                window.location.href = createRedirectPath('/');
            }
        }, false);

        return () => {
            // logger.debug('Webinar() useEffect component crash error handler');
            window.location.href = createRedirectPath('/');
        }

    }, [])

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            overflow: "hidden"
        }}>
            <Box sx={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: '10px',
                marginTop: '-75px',
                gap: '15px'
            }}>
                <MeetLogoSvg width='120px' />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '18px',
                    padding: "10px 10px 0px 10px",
                    color: "grey.70"
                }}>
                    {`You are removed from the room by ${urlParser?.query?.n || "Host"}`}
                </Typography>

                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: 'grey.400',
                }}>
                    You will be forwarded back to the home screen
                </Typography>


                <Button
                    variant="contained"
                    color='primary'
                    size='large'
                    sx={{
                        borderRadius: '10px',
                        textTransform: 'none',
                        width: '70%',
                    }}
                    onClick={() => navigate('/')}
                >Go to home</Button>
            </Box>

        </Grid>
    )
}
