export default function E2eModalIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 36 36" fill="none">
            <mask id="mask0_7427_16795" /*style="mask-type:alpha"*/ maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                <rect width="36" height="36" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_7427_16795)">
                <path d="M15.75 22.5H20.25L19.3875 17.6625C19.8875 17.4125 20.2813 17.05 20.5688 16.575C20.8563 16.1 21 15.575 21 15C21 14.175 20.7063 13.4688 20.1188 12.8813C19.5313 12.2937 18.825 12 18 12C17.175 12 16.4688 12.2937 15.8813 12.8813C15.2937 13.4688 15 14.175 15 15C15 15.575 15.1438 16.1 15.4312 16.575C15.7188 17.05 16.1125 17.4125 16.6125 17.6625L15.75 22.5ZM18 33C14.525 32.125 11.6562 30.1313 9.39375 27.0188C7.13125 23.9063 6 20.45 6 16.65V7.5L18 3L30 7.5V16.65C30 20.45 28.8687 23.9063 26.6063 27.0188C24.3438 30.1313 21.475 32.125 18 33ZM18 29.85C20.6 29.025 22.75 27.375 24.45 24.9C26.15 22.425 27 19.675 27 16.65V9.5625L18 6.1875L9 9.5625V16.65C9 19.675 9.85 22.425 11.55 24.9C13.25 27.375 15.4 29.025 18 29.85Z" fill="#898A8B" />
            </g>
        </svg>
    )
}