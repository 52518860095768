import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';
import { Typography } from '@mui/material';

const ScheduleMeeting = () => {
  const dynamicUrl = `${window.location.origin}/schedule_meet`;

  return (
    <>
      <Heading
        text='Schedule Meetings'
      />

      <ListComp

        data={[{
          name: <Typography>
            Visit: <a target="_blank" style={{ color: 'blue' }} >{dynamicUrl}</a>

          </Typography>,
          id: '12',
        },
        {
          name: 'To schedule a meeting, log in to your account.',
          id: '13',

        },
        {
          name: 'Now, click on the ‘Schedule’ button in the left half of the screen.',
          id: '14',

        }, {
          name: 'Start with entering the meeting title and description .',
          id: '15',

        }, {
          name: 'In the ‘Invite as Host’ section, paste or type the email addresses of the people you want to invite separated by commas.',
          id: '16',

        }, {
          name: 'In ‘Actions’ switch the toggle as per your need.',
          id: '17',
          child: [{
            name: 'Enable Start and Stop Recording: To allow other participants to record the meeting.',
            id: '12'
          }, {
            name: 'Auto Recording: Start recording the meeting as soon as it starts.',
            id: '13'
          },
          {
            name: 'Waiting Room: Allow or disallow participants to join the meeting.',
            id: '14'
          },]

        },
        {
          name: 'Start Date and Time: Select the date and time for the meeting.  ',
          id: '18',

        }, {
          name: 'Duration: Select how long the meeting will run.',
          id: '19',

        }, {
          name: ' To schedule the meeting after filling in all this information, hit ‘Skip & Schedule.’  ',
          id: '1',

        }, {
          name: 'To create a recurring meeting or enable end-to-end encryption, hit ‘Next.  ',
          id: '2',

        },
        {
          name: 'To create a recurring meeting, check the ‘Recurring Meeting’ and then fill in the entails according to your schedule.  ',
          id: '323',
          child: [{
            name: 'Set recurrence of the meeting to',
            id: '1293',
            child: [
              {
                name: 'Daily',
                id: '121'
              },
              {
                name: 'Weekly',
                id: '122'
              },
              {
                name: 'Monthly ',
                id: '123'
              },
              {
                name: 'No Fixed Time',
                id: '124'
              }
            ]
          }, {
            name: 'Repeat Every: Gap between two meetings. ',
            id: '33'
          },
          {
            name: 'End: Schedule when you want to end the recurrence',
            id: '34',
            child: [
              {
                name: 'By: End on a specific date and time ',
                id: '67'
              },
              {
                name: 'After: Meetings will stop recurring after reaching a certain count.',
                id: '68'
              }

            ],
          }]

        },
        {
          name: 'To enable End-to-End encryption, check the box before ‘End-to-End Encryption.’  ',
          id: '232',
          child: [{
            name: 'Now in the new dialogue box, click ‘Generate Meeting E2E Key.’ ',
            id: '12'
          },
          {
            name: 'This will give you an encrypted meeting key that you can share with others.  ',
            id: '199'
          },
          ]

        },
        {
          name: 'Once everything is finalized, Click ‘Submit.’  ',
          id: '2311',

        },

        ]}

      />

      <ImageComp
        name={"scheduleMeetings"}

      />
       <ImageComp
        name={"scheduleMeetings2"}

      />

    </>

  );

};

export default ScheduleMeeting;

