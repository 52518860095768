import React, { memo } from 'react';
import { connect } from 'react-redux';
import Peer from './Peer';
import AudioOnlyPeer from '../AudioOnly/AudioOnlyPeer';
import { Box, Grid, Avatar, AvatarGroup } from '@mui/material';
// import randomString from 'random-string';

const Peers = ({ peers, dimensions, share, roomId, fromPeers, toPeers, focus, focusCount, role, shareFullScreen = false }) => {

	return (
		<>
			{share === 5 ? <>
				{!shareFullScreen ? 
					<Grid container xs={12} sx={{
						height: '22%',
						p: 1.5,
						justifyContent: 'center',
						margin: 0,
					}}>
					<>
					{peers.slice(0, peers.length === 5 ? 5 : 4).map((peer) => {
						return (<Peer key={peer.id} id={peer.id} peerCount={peers.length} dimensions={dimensions} roomId={roomId} role={role} />);
					})}

					{/* Show Number of peers in top of screen share video card. */}
					{peers.length > 5 &&
						<Grid item={true} xs={2} sx={{
							padding: '5px',
							alignItems: 'stretch',
							width: 'calc(100% * 2 / 12)'
						}} >
							<Box sx={{
								bgcolor: 'text.disabled',
								position: 'relative',
								backgroundColor: '#333333',
								// backgroundImage: `url(${img01})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								height: '100%',
								width: '100%',
								borderRadius: '10px',
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex'

							}}>
								<AvatarGroup total={peers.length - 4} /* max={3} */>
									<Avatar alt={peers[4]?.displayName} sx={{
										bgcolor: '#333333',
										color: 'rgba(255,255,255,.5)',
										// border: 'none'
									}} src="../../images/avatar.png" />
									<Avatar alt={peers[5]?.displayName} sx={{
										bgcolor: '#333333',
										color: 'rgba(255,255,255,.5)'
									}} src="../../images/avatar.png" />
								</AvatarGroup>
							</Box>
						</Grid>
					}

					{/* Connect audio only of peers rest of primary screen. */}
					{peers.filter((_, index) => !(index >= 0 && index <= 3)).map((peer) => {
						return (<AudioOnlyPeer key={peer.id} id={peer.id} />);
					})}
				</> 
			</Grid>
				: 
				<>
				{peers.map((peer) => {
						return (<AudioOnlyPeer key={peer.id} id={peer.id} />);
					})}
				</>}
			</>		
				:

				// Render focus screens and remain peers.
				(focus && focusCount < 7 ?
					<>
						<Grid item={true} container xs={peers.filter((item) => !item?.isFocused).length > 0 ? 10 : 12} sx={{
							// background: '#333333',
							height: '100%',
							borderRadius: '10px',
							justifyContent: 'center',
							mx: 0,
						}}>
							{peers.slice(fromPeers, toPeers).map((peer) => {
								if (peer?.isFocused) {
									return (
										<Peer key={peer.id} id={peer.id} peerCount={peers.length} dimensions={dimensions} roomId={roomId} focusCount={focusCount} role={role} />
									);
								}
								return null;

							})}
						</Grid>
						{peers.filter((item) => !item?.isFocused).length > 0 &&
							<Grid item={true} container xs={2} sx={{ flexDirection: 'column' }} >
								{peers.filter((item) => !item?.isFocused).slice(0, peers.filter((item) => !item?.isFocused).length === 4 ? 4 : 3).map((peer, index) => {
									return (
										<Peer
											key={peer.id}
											id={peer.id}
											peerCount={peers.length}
											dimensions={dimensions}
											roomId={roomId}
											sx12={true}
											showFocusSide={focusCount >= 6 ? true : false}
											focusedSX={{
												bgcolor: 'text.disabled',
												position: 'relative',
												backgroundSize: 'cover',
												backgroundPosition: 'center center',
												minHeight: '22%',
												borderRadius: '10px',
												mb: 1.3,
												justifyContent: 'center',
												alignItems: 'center',

											}}
											role={role}
										/>
									);
								})}
								{/* Show Number of peers are not rendered */}
								{peers.filter((item) => !item?.isFocused).length > 4 &&
									<Grid item={true} sx={{
										bgcolor: 'text.disabled',
										position: 'relative',
										backgroundSize: 'cover',
										backgroundPosition: 'center center',
										minHeight: '22%',
										borderRadius: '10px',
										mb: 1.3,
										justifyContent: 'center',
										alignItems: 'center',
										padding: '5px',
									}} >
										<Box sx={{
											bgcolor: 'text.disabled',
											backgroundColor: '#333333',
											backgroundSize: 'cover',
											backgroundPosition: 'center center',
											height: '100%',
											width: '100%',
											borderRadius: '10px',
											alignItems: 'center',
											justifyContent: 'center',
											display: 'flex'

										}}>
											<AvatarGroup total={peers.filter((item) => !item?.isFocused).length - 3} /* max={3} */>
												<Avatar alt={peers.filter((item) => !item?.isFocused)[3]?.displayName} sx={{
													bgcolor: '#333333',
													color: 'rgba(255,255,255,.5)',
													// border: 'none'
												}} src="../../images/avatar.png" />
												<Avatar alt={peers.filter((item) => !item?.isFocused)[4]?.displayName} sx={{
													bgcolor: '#333333',
													color: 'rgba(255,255,255,.5)'
												}} src="../../images/avatar.png" />
											</AvatarGroup>
										</Box>
									</Grid>
								}
							</Grid>
						}


						{/* Connect audio only of peers rest of primary screen. */}
						{peers.filter((item) => !item?.isFocused).filter((_, index) => !(index >= 0 && index <= 2)).map((peer) => {
							return (<AudioOnlyPeer key={peer.id} id={peer.id} />);
						})}
					</>
					:
					<>
						{/* Default case to render peer on primary screen. */}
						{peers.slice(fromPeers, toPeers).map((peer, index, arr) => {
							return (
								<Peer
									key={peer.id}
									id={peer.id} peerCount={arr.filter(({ role }) => role !== "attendee").length}
									dimensions={dimensions}
									roomId={roomId}
									role={role}
								/>);
						})}

						{/* Connect audio only of peers rest of primary screen. */}
						{peers.filter((_, index) => !(index >= fromPeers && index < toPeers)).map((peer) => {
							return (<AudioOnlyPeer key={peer.id} id={peer.id} />);
						})}
					</>
				)

			}
		</>
	);
};

// Peers.propTypes =
// {
// 	peers: PropTypes.arrayOf(appPropTypes.Peer).isRequired,
// 	// activeSpeakerId: PropTypes.string
// };

const mapStateToProps = (state) => {
	const peersArray = Object.values(state.peers);
	const focus = peersArray.filter((item) => {
		if (item?.isFocused) return true;
		return false
	}).length;

	return {
		peers: peersArray,
		focus: focus > 0 ? true : false,
		focusCount: focus
		// activeSpeakerId: state.room.activeSpeakerId
	};
};

const PeersContainer = connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev.peers === next.peers /* &&
			prev.room.activeSpeakerId === next.room.activeSpeakerId */
			);
		}
	}
)(Peers);

export default memo(PeersContainer);
