const initialState =
{
    virtualBackground: "off",
    vbImageUrl: "",
};

const virtualBackground = (state = initialState, action) => {
    switch (action.type) {
        case 'VIRTUAL_BACKGROUND':
            {
                const data = action.payload;
                return { ...state, ...data };
            }

        default:
            return state;
    }
};

export default virtualBackground;
