import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const InvitePeople = () => {
  return (
    <>
      <Heading
        text='Invite People to a Meeting'
      />
      <ImageComp
        name={"invitePeopleMeeting"}

      />

      <ListComp
        data={[

          {
            name: ' While in a meeting, look for the ‘i’ icon in the top right corner of the screen.',
            id: '13',

          },
          {
            name: 'Click on the button.',
            id: '14',

          }, {
            name: 'In the pop-up, you will see the meeting invite link.',
            id: '15',

          }, {
            name: 'Click the ‘copy’ button in front of the invite link.',
            id: '16',

          }, {
            name: 'Send this link to anyone you want to invite to the meeting.',
            id: '17',

          },

        ]}

      />

    </>
  );

};

export default InvitePeople;

