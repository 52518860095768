import React from 'react';
import { Typography, Modal, Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { configFile } from '../../../../config';


export default function PermissionBlockModal({ open, handleClose, selfRole }) {
    const { webinarRole } = configFile;
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: '#222222',
                boxShadow: 24,
                borderRadius: '10px',
                p: 4,
                "&:focus": {
                    outline: "none"
                }

            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: '10px',
                }}>
                    <Typography variant="h6" component="h6" sx={{ color: '#FFFFFF', mt: 2 }}>
                        {selfRole === webinarRole.produce ? "You have blocked mic permis- sions. Check ok to continue" : "Welcome to the meeting. Check ok to continue"}
                    </Typography>
                    <Grid xs={12} item sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        width: '100%'
                    }}>
                        <LoadingButton
                            loading={null}
                            color='primary'
                            loadingPosition="center"
                            variant="contained"
                            onClick={() => handleClose()}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '60%',
                                textTransform: 'none',
                            }}
                        >
                            Ok
                        </LoadingButton>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    )
}
