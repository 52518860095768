import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment';
import LoaderOnList from '../components/LoaderOnList';
import NoMoreDataShow from '../components/NoMoreDataShow';
import ApiErrorImgShow from '../components/ApiErrorImgShow';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function WebinarList({ homeWebinarsList, reStartWebinar, reStartWLoading, startWebinar, startWLoading, startRoomId, loadingWList, apiError }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isLandscape = useSelector(state => state.deviceInfo?.isLandscape)

    return (
        <Box className='MeetingList' sx={{
            backgroundColor: theme.palette.mode === 'dark' ? 'grey.1000' : '#E4E7F4',
            borderRadius: '8px 8px 0px 0',
            height: isLandscape ? 'calc(100% - 151px)' : 'calc(100% - 263px)',
            padding: '0px 14px 70px 14px',
            display: 'flex',
            flexDirection: 'column',
            gap: "10px",
            overflowY: 'scroll',
            overflowX: 'hidden',
            borderTopWidth: '10px',
            borderColor: theme.palette.mode === 'dark' ? 'grey.1000' : '#E4E7F4',
            borderStyle: 'solid'
        }}>
            {homeWebinarsList.length > 0 && homeWebinarsList.map(({ title, status, roomId, canceled, endDate, startDate }, index) => (
                <Box className='CardsGroup' key={index} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: "4px",
                }}>
                    <Typography className='DateLable' sx={{
                        fontSize: '10px',
                        color: 'grey.400',
                        fontWeight: '600',
                        pl: '8px',
                    }}> {new Date(startDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ?
                        "Today"
                        :
                        `${moment(startDate).format('MMMM')} ${new Date(startDate).getDate()}, ${moment(startDate).year()}`}
                    </Typography>
                    <Box className='MeetingCard' sx={{
                        px: '14px',
                        py: '16px',
                        backgroundColor: 'card.main',
                        borderRadius: '10px',
                        // borderColor: '#D3D8EB',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: "14px",
                    }}>
                        <Box className='CardHeader' sx={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            color: 'grey.400',
                        }}>
                            <Typography sx={{
                                fontSize: '13px',
                                fontWeight: '600',
                                lineHeight: '16px',
                            }} onClick={() => navigate(`/view_meet_details`, { state: { roomId } })} >
                                {title.charAt(0).toUpperCase() + title.slice(1) || ""}
                            </Typography>

                            {(endDate >= +new Date() && !canceled) && (
                                /*  status === "closed" ?
                                     <LoadingButton
                                         variant='contained'
                                         size='small'
                                         loading={reStartWLoading && startRoomId === roomId ? true : null}
                                         loadingPosition="center"
                                         onClick={() => reStartWebinar(roomId)}
                                         sx={{
                                             textTransform: 'none',
                                             boxShadow: 'none',
                                             minWidth: '88px',
                                         }}
                                     >
                                         Re-Start
                                     </LoadingButton>
                                     : */
                                <LoadingButton
                                    variant='contained'
                                    size='small'
                                    loading={startWLoading && startRoomId === roomId ? true : null}
                                    loadingPosition="center"
                                    onClick={() => startWebinar(roomId)}
                                    sx={{
                                        textTransform: 'none',
                                        boxShadow: 'none',
                                        minWidth: '88px',
                                    }}
                                >
                                    Start
                                </LoadingButton>
                            )}
                        </Box>

                        <Box className='CardBody' sx={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            color: 'grey.400',
                        }}>
                            <Box className='MeetTime'>
                                <Typography sx={{
                                    fontSize: '11px',
                                    fontWeight: '500',
                                    color: 'grey.600'
                                }}>Start/End Time:</Typography>
                                <Typography sx={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                }}>{`${moment(startDate).format("hh:mm a")} to ${moment(endDate).format("hh:mm a")}`}</Typography>
                            </Box>
                            <Box className='MeetId' sx={{ pr: '30px' }}>
                                <Typography sx={{
                                    fontSize: '11px',
                                    fontWeight: '500',
                                    color: 'grey.600'
                                }}>Room ID:</Typography>
                                <Typography sx={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                }}>{roomId || ""}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ))}

            {/* show loader when data loading. */}
            {loadingWList && <LoaderOnList />}

            {/* Show image and text when no more data available. */}
            {(!homeWebinarsList.length && !loadingWList && !apiError) && <NoMoreDataShow />}

            {/* Show network image when api throw error or any other error */}
            {(apiError) && <ApiErrorImgShow />}
        </Box>
    )
}
