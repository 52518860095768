// export const setSharePinPeers = (peerIds) => {
// 	return {
// 		type: 'SET_SHARE_PIN_PEERS',
// 		payload: { peerIds }
// 	};
// };

export const setPinVideoScreens = (data) => {
	return {
		type: 'SET_PIN_PEERS',
		payload: { data }
	}
}

export const setPinShareScreens = (data) => {
	return {
		type: 'SET_PIN_SHARE_SCREENS',
		payload: { data }
	}
}

export const setFocusScreens = (data) => {
	return {
		type: 'SET_FOCUS_SCREENS',
		payload: { data }
	}
}

export const setPinSelfScreen = () => {
	return {
		type: 'SET_PIN_SELF_SCREEN',
		payload: {}
	}
}

export const removePinSelfScreen = () => {
	return {
		type: 'REMOVE_PIN_SELF_SCREEN',
		payload: {}
	}
}

export const setFocusSelfScreen = () => {
	return {
		type: 'SET_FOCUS_SELF_SCREEN',
		payload: {}
	}
}

export const removeFocusSelfScreen = () => {
	return {
		type: 'REMOVE_FOCUS_SELF_SCREEN',
		payload: {}
	}
}