import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import randomString from 'random-string';
import { Grid, Box, Typography, IconButton, Button, Link, Modal, Divider, TextField, useTheme } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
// import SideBar from '../../../layouts/sideBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { joinWebianrCheck } from '../../../services/commonApis'
import UrlParse from 'url-parse';
import LoadingButton from '@mui/lab/LoadingButton';
import { checkIframe, getItem, removeBulkItems, setItem } from '../../../lib/cookiesManager';
import jwt from 'jwt-decode';
import { configFile } from "../../../config";
import { validateText, getMicAndCamPermission } from '../../../utils/common';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { styled } from '@mui/material/styles';
import { ColorModeContext } from "../../../context/ThemeContext";

const MainSection = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.card.main,
    boxShadow: 24,
    borderRadius: '10px',
    padding: '25px',
}));

const InputOuterBox = styled('div')(() => ({
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: 400,
    color: theme.palette.grey[400]
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    backgroundColor: theme.palette.grey[800],
}))

export default function Dashboard() {
    const theme = useTheme()
    const colorMode = useContext(ColorModeContext);
    const { imagesUrl, getProtooUrl, webinarRole, consoleUrl } = configFile;
    const [peerId,] = useState(randomString({ length: 8 }).toLowerCase());
    const [time, setTime] = useState(moment(new Date()).format('hh:mm A'));
    const [join, setJoin] = useState(false);
    const [joinWebinarUrl, setJoinWebinarUrl] = useState("");
    const [homeWebinars, setHomeWebinar] = useState([]);
    const [loadingWList, setLoadingWList] = useState(true)
    const [startWLoading, setStartWLoading] = useState(false)
    // const [reStartWLoading, setReStartWLoading] = useState(false);
    const [startRoomId, setStartRoomId] = useState("")
    const [deleteRoomId, setDeleteRoomId] = useState("");
    const [deleteWLoading, setDeleteWLoading] = useState(false)
    const [joinLoading, setJoinLoading] = useState(false);
    const [name, setName] = useState(getItem("name") || "")
    const [updateToCookies, setUpdateToCookies] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [runQuickMeet, setRunQuickMeet] = useState(false);

    const navigate = useNavigate();

    const monthsStr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Handle join webinar model submit button click.
    const joinWebinar = async (e) => {
        e.preventDefault();
        setJoinLoading(true)
        if (!joinWebinarUrl) {
            setJoinLoading(false)
            return renderErrorMessage("Enter join meeting Link first!");
        }
        if (!name) {
            setJoinLoading(false)
            return renderErrorMessage("Enter your name!");
        }
        if (!validateText(name)) {
            setNameError(true)
            setJoinLoading(false)
            return renderErrorMessage("Only Letters and Numbers are allowed!");
        }
        try {
            if (updateToCookies) setItem("name", name)
            const urlParser = new UrlParse(joinWebinarUrl, true);
            // let pId = urlParser.query.peerId;
            const rId = urlParser.query.roomId;
            const e2eKey = urlParser.query?.key || "";
            let token = getItem("token");
            let pId = null;
            if (token) {
                let info = jwt(token);
                pId = info?.email;
            }
            if (!pId) pId = peerId;
            if (!rId) {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!");
            }
            if (!pId) pId = peerId;
            let { redirectURL, state } = await joinWebianrCheck(rId, name, pId) || {};
            if (redirectURL) {
                if (e2eKey) {
                    redirectURL = redirectURL + `&key=${encodeURIComponent(e2eKey.toString())}`;
                }
                navigate(redirectURL, { state });
                setJoinLoading(false)
            } else {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setJoinLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    // Fetch schedule webinar list.
    const fetchList = async (from = 0, size = 4, type = "all") => {
        try {
            let res = await axiosGetCall("listWebinar", { from, size, type })
            setLoadingWList(false)
            setHomeWebinar(res?.result)
        } catch (error) {
            setApiError(true)
            setLoadingWList(false);
            renderErrorMessage("Unable to fetch scheduled meeting list!")
        }
    }

    // Change name on join webinar popup.
    const changeName = (value) => {
        setName(value);
        setUpdateToCookies(true)
    }

    // For start scheduled webinar.
    const startWebinar = async (roomId) => {
        setStartRoomId(roomId)
        setStartWLoading(true)
        try {
            let token = getItem("token")
            let info = jwt(token);
            let pId = info?.email;
            setItem("name", info?.name)
            if (!roomId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid room Id!");
            }

            if (!pId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid token!");
            }

            let { redirectURL, state } = await joinWebianrCheck(roomId, name, pId) || {};
            if (redirectURL) {
                navigate(redirectURL, { state });
                return setStartWLoading(false)
            } else {
                setStartWLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setStartWLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    /*  // For re-start scheduled webinar.
     const reStartWebinar = async (roomId) => {
         setStartRoomId(roomId)
         setReStartWLoading(true)
         try {
             let res = await axiosPostCall("scheduleWebinar", { roomId, status: "inactive" })
             if (res) {
                 await startWebinar(roomId);
                 // renderSuccessMessage("Your webinar has been re-scheduled!");
                 // let updateStatus = homeWebinars.map((item, index) => {
                 //     return item?.roomId === roomId ? { ...item, status: "inactive" } : item;
                 // })
                 // setHomeWebinar(updateStatus)
                 setReStartWLoading(false)
             }
         } catch (error) {
             setReStartWLoading(false)
             return renderErrorMessage(error?.message)
         }
     } */

    // Delete canceled webinar.
    const deleteWebinar = async (roomId) => {
        setDeleteRoomId(roomId);
        setDeleteWLoading(true)
        try {
            await axiosPostCall("cancelWebinar", { roomId, deleted: true })
            setDeleteWLoading(false)
            // Refresh Webinar List on delete webinar
            fetchList();
        } catch (error) {
            setDeleteWLoading(false)
            renderErrorMessage("Unable to delete meeting!");
        }
    }

    const startQuickMeet = async () => {

        setRunQuickMeet(true)
        try {
            const token = getItem("token");
            const info = jwt(token);
            let pId = info?.email;

            setItem("name", info?.name);
            let { title = "", description = "", isHostServer, roomId, record, autoRecord, disableRecordPlayPause, serverDetails = {} } = await axiosPostCall("quickMeeting", pId) || {};
            const role = webinarRole?.produce;
            const wssUrl = getProtooUrl({ roomId, peerId: pId, serverDetails });
            if (isHostServer) getMicAndCamPermission();
            const protooUrl = `${wssUrl}&h=${isHostServer}&fp=""`;
            if (protooUrl) {
                navigate(`/meet?roomId=${roomId}&peerId=${pId}`, { state: { protooUrl, peerId: pId, role, name: info?.name, title, description, meetType: 'meet', record, autoRecord, disableRecordPlayPause } });
                setRunQuickMeet(false)
            } else {
                setRunQuickMeet(false)
                return renderErrorMessage("Unable to start quick meet. Try again!")
            }
        } catch (error) {
            setRunQuickMeet(false)
            return renderErrorMessage(error?.message)

        }
    }

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.origin === consoleUrl) {
                const { session = false, redirectPath, theme = "light" } = event.data;
                if (session) removeBulkItems(["sessionToken", "token", "exp"]);
                if (redirectPath) return window.location.href = redirectPath;
                const checkTheme = getItem("theme");
                if (checkTheme !== theme && !process.env.REACT_APP_DOMAIN_SUBDIRECTORY) {
                    setItem("theme", theme)
                    colorMode.toggleColorMode();
                };
            }
        });
        // let url = fetchData();
        fetchList(0, 4, "all");
        setInterval(() => {
            setTime(moment(new Date()).format('hh:mm A'))
        }, 10000);
        // eslint-disable-next-line
    }, [])

    return (
        // <Grid item={true} xs={12} container >
        // {/* <SideBar /> */}
        <Grid item={true} xs={12} sx={{
            height: '100vh',
            backgroundColor: 'background.main',
            display: 'flex',
            pt: '64px'
        }}>
            <ToastContainer />

            <Grid item={true} container xs={6} sx={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>

                {/* <Typography variant="body1" component="div" sx={{
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: "28px",
                        position: 'absolute',
                        top: '80px',
                        left: '120px',
                        color: '#ce165e',
                    }}>
                        Welcome{" "}
                        <Typography variant='inherit' component="span" sx={{
                            color: '#454545',
                        }}>
                            {name}
                        </Typography>
                    </Typography> */}
                <Typography variant="h1" component="h1" sx={{
                    fontSize: '64px',
                    color: 'grey.300',
                    fontWeight: 700,
                    // lineHeight: "77px",
                }}>
                    {time}
                </Typography>
                <Typography sx={{
                    fontSize: '19px',
                    color: 'grey.400',
                    fontWeight: 500,
                    // lineHeight: "23px",
                }}>
                    {moment().format('dddd')}, {moment().format('MMMM')} {new Date().getDate()}, {moment().year()}
                </Typography>

                <Grid item={true} container sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    display: 'grid-row',
                    mt: 3,
                    width: '70%',
                    gridTemplateRows: '25% 25% 25% 25%',
                    gridTemplateColumns: 'auto auto auto auto',
                    zIndex: 3
                }}>
                    <Box>
                        <Grid item={true} container sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mt: 3,
                        }}>
                            <Box sx={{
                                width: '16vh',
                                height: '15vh',
                                backgroundColor: 'rgb(64, 67, 81)',
                                borderRadius: '30px',
                                mx: 3,
                                '&:hover': {
                                    backgroundColor: 'rgb(64, 67, 81)',
                                },
                            }}
                                onClick={() => setJoin(true)}
                            >
                                <IconButton sx={{
                                    borderRadius: '20px',
                                    width: '16vh',
                                    height: '15vh',
                                    color: '#fff'
                                    // width: '30%'
                                }}>
                                    <AddBoxIcon sx={{ width: '6vh', height: '6vh' }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{
                                fontWeight: 'bold,',
                                fontSize: '19px',
                                color: 'grey.400',
                                // mx: 4,
                                // px: 1,
                                py: 2,
                            }}>
                                JOIN
                            </Typography>
                        </Grid>
                    </Box>
                    <Box >
                        <Grid item={true} container sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mt: 3,

                        }}>
                            <Box sx={{
                                width: '16vh',
                                height: '15vh',
                                backgroundColor: 'rgb(64, 67, 81)',
                                borderRadius: '30px',
                                mx: 3,
                                '&:hover': {
                                    backgroundColor: 'rgb(64, 67, 81)',
                                },
                            }}
                                onClick={() => navigate('/schedule_meet')}
                            >
                                <IconButton sx={{
                                    borderRadius: '20px',
                                    width: '16vh',
                                    height: '15vh',
                                    color: '#fff'
                                    // width: '30%'
                                }}>
                                    <EventNoteIcon sx={{ width: '6vh', height: '6vh' }} />
                                </IconButton>
                            </Box>
                            <Typography sx={{
                                fontWeight: 'bold,',
                                fontSize: '19px',
                                color: 'grey.400',
                                // px: 1,
                                py: 2,
                            }}>
                                SCHEDULE
                            </Typography>
                        </Grid>
                    </Box>

                    <Box >
                        <Grid item={true} container id="quickMeeting" sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mt: 3,

                        }}>
                            <Box sx={{
                                width: '16vh',
                                height: '15vh',
                                backgroundColor: '#029930',
                                borderRadius: '30px',
                                mx: 3,
                                '&:hover': {
                                    backgroundColor: '#029930',
                                },
                            }}
                                onClick={() => startQuickMeet()}
                                id='quickMeetBtn'
                            >
                                {runQuickMeet ?
                                    <Box sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        mt: 'calc(50% - 12px)'
                                    }}>
                                        <img src={imagesUrl?.loading} alt='loading...' width={'20%'} />
                                    </Box>
                                    : <IconButton sx={{
                                        borderRadius: '20px',
                                        width: '16vh',
                                        height: '15vh',
                                        color: '#fff'
                                        // width: '30%'
                                    }}>
                                        <VideoCallIcon sx={{ width: '6vh', height: '6vh' }} />
                                    </IconButton>}
                            </Box>
                            <Typography sx={{
                                fontWeight: 'bold,',
                                fontSize: '19px',
                                color: 'grey.400',
                                // px: 1,
                                py: 2,
                            }}>
                                QUICK MEETING
                            </Typography>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>

            <Grid item={true} container xs={6} sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    width: '95%',
                    height: '80%',
                    backgroundColor: 'card.main',
                    borderRadius: '10px',
                    boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
                }}>
                    <Typography sx={{
                        fontSize: '25px',
                        lineHeight: "29px",
                        color: 'grey.200',
                        px: 4,
                        py: 3,
                        fontWeight: 700,
                    }}>
                        Meetings
                    </Typography>

                    <Grid container item={true} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                        {homeWebinars.length > 0 && homeWebinars.map((data, index) => (
                            <Grid container item={true} key={index} sx={{
                                height: '20%',
                                width: '90%',
                                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)' /* : '0px 0px 0.5px 0.8px rgba(0, 0, 0, 0.25)' */,
                                mb: 2,
                                borderRadius: '10px',
                                position: 'relative',
                                alignItems: 'center',
                                backgroundColor: theme.palette.mode === "light" ? 'card.main' : 'grey.800'
                            }}>
                                <Box sx={{
                                    height: '13vh',
                                    width: '13vh',
                                    color: '#fff',
                                    backgroundColor: (index % 2) === 0 ? "rgb(85 108 141 / 60%)" : 'rgb(95 69 69 / 66%)',
                                    borderTopLeftRadius: '10px',
                                    borderBottomLeftRadius: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <Typography sx={{
                                        fontSize: '28px',
                                        fontWeight: 700
                                    }}>
                                        {new Date(data?.startDate).getDate()}
                                    </Typography>
                                    <Typography variant='h6'>
                                        {monthsStr[new Date(data?.startDate).getMonth()]}
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    px: 2,
                                }}>
                                    <Typography variant='h6' sx={{
                                        fontSize: '19px',
                                        color: 'grey.300',
                                        lineHeight: '22px',
                                        fontWeight: 600,
                                        mb: 1,
                                        overflowWrap: "break-word",
                                    }}>
                                        {data?.title.length > 20 ? `${data?.title.slice(0, 20)}...` : data?.title}
                                    </Typography>
                                    <Typography variant='h6' sx={{
                                        color: 'grey.400',
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        fontWeight: 400,
                                        mb: 1,
                                        // color: '#444444',
                                    }}>
                                        {`${moment(data?.startDate).format("hh:mm a")} to ${moment(data?.endDate).format("hh:mm a")}`}
                                    </Typography>
                                    <Typography variant='h6' sx={{
                                        fontSize: '14px',
                                        color: 'grey.300',
                                        lineHeight: '18px',
                                        fontWeight: 400,
                                    }}>
                                        Room ID: {data?.roomId}
                                    </Typography>
                                </Box>

                                {(data?.endDate >= +new Date() && !data?.canceled) &&
                                    <Box sx={{
                                        mx: 2,
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 10
                                    }}>
                                        {!data?.quickMeet && <Button
                                            variant='outlined'
                                            size='small'
                                            endIcon={<BorderColorIcon sx={{ width: '15px', height: '15px' }} />}
                                            sx={{
                                                float: 'right',
                                                textTransform: "none",
                                                background: 'rgba(23, 112, 247, 0.04)',
                                                boxShadow: 'none',
                                            }} onClick={() => navigate(`/schedule_meet`, { state: { edit: true, roomId: data?.roomId } })}
                                        >
                                            Edit
                                        </Button>}

                                        <LoadingButton
                                            variant='contained'
                                            size='small'
                                            loading={startWLoading && startRoomId === data?.roomId ? true : null}
                                            loadingPosition="center"
                                            onClick={() => startWebinar(data?.roomId)}
                                            sx={{
                                                float: 'right',
                                                mx: 1,
                                                textTransform: "none",
                                                boxShadow: 'none',
                                            }}
                                        >
                                            Start
                                        </LoadingButton>


                                    </Box>
                                }
                                <Box sx={{
                                    mx: 2,
                                    position: 'absolute',
                                    right: 0,
                                    top: 10
                                }}>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        sx={{
                                            float: 'right',
                                            textTransform: "none",
                                            color: 'grey.500',
                                            borderColor: 'grey.700',
                                            background: "transparent",//'rgba(23, 112, 247, 0.04)',
                                            '&:hover': {
                                                borderColor: 'grey.600',
                                                background: "transparent"
                                            }
                                        }}
                                        onClick={() => navigate(`/view_meet_details`, { state: { roomId: data?.roomId } })}
                                    > View details </Button>

                                </Box>

                                {data?.canceled &&
                                    <Box sx={{
                                        mx: 2,
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 10
                                    }}>
                                        <LoadingButton
                                            variant='outlined'
                                            color='error'
                                            size='small'
                                            loading={deleteWLoading && deleteRoomId === data?.roomId ? true : null}
                                            loadingPosition="center"
                                            onClick={() => deleteWebinar(data?.roomId)}
                                            sx={{
                                                float: 'right',
                                                mx: 1,
                                                textTransform: "none",
                                            }}
                                        >
                                            Delete
                                        </LoadingButton>
                                    </Box>
                                }

                            </Grid>
                        ))}

                        {/* Show network image when api throw error or any other error */}
                        {(apiError) && <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '-116px',
                            flexDirection: 'column',
                            ml: 3,
                        }} >
                            <img src={imagesUrl?.noInternet} alt='Somthing went wrong.' width={'40%'} height={'40%'} />
                        </Box>

                        }

                        {/* Show image and text when no more data available. */}
                        {(!homeWebinars.length && !loadingWList && !apiError) && <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // left: '50%',
                            marginTop: '-116px',
                            flexDirection: 'column',
                            ml: 3,
                        }} >
                            <img src={imagesUrl?.noScheduleWebinar} alt='No more scheduled meetings' />
                            <Typography sx={{
                                mt: 3,
                                fontSize: '20px',
                                fontWeight: 500,
                                color: 'grey.400'
                            }}>
                                No more scheduled meetings!
                            </Typography>

                        </Box>

                        }

                        {/* show loader when data loading. */}
                        {loadingWList && <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translate(-50 %, -50 %)',
                            webkitTransform: 'translate(-50%, -50%)',
                            moztransform: 'translate(-50%, -50%)',
                            oTransform: 'translate(-50%, -50%)',
                            msTransform: 'translate(-50%, -50%)',
                        }}>
                            <img src={imagesUrl?.loading} alt='loading...' />
                        </Box>}
                    </Grid>

                    {/* View more data section */}
                    {(homeWebinars.length > 4 && !loadingWList) && <Typography variant='h6' sx={{
                        fontSize: '14px',
                        lineHeight: "17px",
                        color: '#444444',
                        py: 2,
                        fontWeight: 700,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Link sx={{
                            color: '#444444',
                            '&:hover': {
                                color: '#1770F6',
                            },
                            cursor: 'pointer',
                        }} onClick={() => navigate('/list')}> View More </Link>
                    </Typography>}

                </Box>

            </Grid>

            <Box sx={{
                position: 'absolute',
                bottom: '0px',
                left: 0,
                width: "186px",
                height: '100vh',
                overflow: 'hidden',
                margin: '0px',
                padding: '0px'
            }}>
                <iframe
                    title='console'
                    src={!checkIframe() ? `${consoleUrl}/spinner?k=meet&s=true&spinner=${true}` : `${consoleUrl}/spinner?k=meet&s=true`}
                    // allow="camera *;microphone *"
                    style={{
                        top: " 0",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        width: "100%",
                        height: "100%",
                        border: '0px'
                    }}
                />

            </Box>

            {/* Join webinar modal */}
            <Modal
                open={join}
                onClose={() => setJoin(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MainSection>

                    <IconButton id="modal-modal-title" onClick={() => setJoin(false)} sx={{
                        display: 'flex',
                        position: 'absolute',
                        justifyContent: 'right',
                        alignItems: 'right',
                        right: 15,
                        top: 10
                    }}>
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: 'grey.300' }}
                    >
                        Join Your Meeting
                    </Typography>

                    <Divider id="modal-modal-title" sx={{ mt: 3 }} />

                    <InputOuterBox>
                        <InputLabel id="modal-modal-description" >
                            Meeting Link
                        </InputLabel>

                        <CustomTextField
                            className="inputRounded"
                            value={joinWebinarUrl}
                            onChange={(e) => setJoinWebinarUrl(e.target.value)}
                            id="filled-hidden-label-small"
                            placeholder="Type or Paste your link here"
                            inputProps={{ sx: { color: "grey.400" } }}
                        />
                    </InputOuterBox>

                    <InputOuterBox>
                        <InputLabel>
                            Name
                        </InputLabel>

                        <CustomTextField
                            className="inputRounded"
                            id="filled-hidden-label-small"
                            placeholder="Enter your name "
                            value={name}
                            onChange={(e) => changeName(e.target.value)}
                            onClick={() => setNameError(false)}
                            error={nameError ? true : false}
                            inputProps={{ sx: { color: "grey.400" } }}
                        />
                    </InputOuterBox>


                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '20px'
                    }}>
                        <LoadingButton
                            loading={joinLoading ? true : null}
                            color='success'
                            loadingPosition="center"
                            variant="outlined"
                            onClick={(e) => joinWebinar(e)}
                            sx={{
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none'
                            }}
                        >
                            Join Now
                        </LoadingButton>

                    </Box>
                </MainSection>
            </Modal>
        </Grid >
        // </Grid >
    )
}