import React, { memo } from 'react';
import { Avatar } from '@mui/material';

function UserAvatar({isMe}) {
    return (
        <Avatar variant="square"
            // draggable="false"
            src="../../images/avatar.png"
            sx={{
                width: '100%',
                height: '100%',
                bgcolor: isMe ? '#27292B' : '#333333',
                borderRadius: '10px',
                alignItems: 'center',
                position: 'absolute',
                color: '#454545'
            }}
        />
    )
}
export default memo(UserAvatar);
