import { Grid, Typography, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react';
// import SideBar from '../../../layouts/sideBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { getItem } from '../../../lib/cookiesManager';
import BrandingSection from '../../../components/brandingSection';
import { configFile } from '../../../config';

export default function Profile() {
    const { imagesUrl } = configFile;
    const navigate = useNavigate();
    const [profileInfo, setProfileInfo] = useState({})

    useEffect(() => {
        let token = getItem('token');
        let info = jwt(token);
        setProfileInfo(info);
        // eslint-disable-next-line
    }, [])

    return (
        // <Grid item={true} xs={12} container>
        //     <SideBar />
        <Grid item={true} xs={12} container sx={{
            height: '100vh',
            backgroundColor: '#cccccc66',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            pt: '64px'
        }}>
            <ToastContainer />

            <Grid item={true} xs={6} sx={{
                bgcolor: '#FFFFFF',
                width: '90%',
                display: 'flex',
                borderRadius: '10px',
                flexDirection: 'column',
                mx: 2,
                p: '15px'
            }}>
                {/* <Box > */}
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '29px',
                    color: '#444444',
                    // mx: 2,
                    my: 2
                }}>
                    Profile Info
                </Typography>
                <TextField
                    id="outlined-read-only-input"
                    label="Name"
                    InputProps={{
                        readOnly: true,
                    }}
                    // defaultValue={profileInfo?.name || ""}
                    placeholder={profileInfo?.name || ""}
                    value={profileInfo?.name || ""}
                    sx={{
                        my: 2,
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: "#e0e0e0"
                            }
                        }
                    }}
                    focused
                />
                <TextField
                    id="outlined-read-only-input"
                    label="Email"
                    // defaultValue={profileInfo?.email || ""}
                    placeholder={profileInfo?.email || ""}
                    value={profileInfo?.email || ""}
                    // focused
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{
                        my: 2,
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: "#e0e0e0"
                            }
                        }
                    }}
                    focused
                />
                <TextField
                    id="outlined-read-only-input"
                    label="Mobile"
                    // defaultValue={profileInfo?.mobile || ""}
                    InputProps={{
                        readOnly: true,
                    }}
                    placeholder={profileInfo?.mobile || ""}
                    value={profileInfo?.mobile || "N/A"}
                    sx={{
                        my: 2,
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: "#e0e0e0"
                            }
                        }
                    }}
                    focused
                />
                <Button variant='contained' color='success' onClick={() => navigate('/')} sx={{ my: 2 }}>Home</Button>
                {/* </Box> */}
            </Grid>
            <Grid item={true} xs={5} sx={{
                // bgcolor: '#EDEDED',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={imagesUrl?.profilePage} alt='feedback' />

            </Grid>

            {/* Branding section component. */}
            <BrandingSection />

        </Grid>

        // </Grid>
    )
}
