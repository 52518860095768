import React from 'react';
import Heading from './components/heading';
import ListComp from './components/list';
import { capitalizeFirstLetter } from './components/util';
import { useSelector } from 'react-redux';

const EditAMeeting = () => {
  const baseurl = window.location.origin;
  const { branding = {} } = useSelector(state => state?.branding);;
  const { companyBrandName = "" } = branding || {}; 
  const capitalizedCompanyBrandName = capitalizeFirstLetter(companyBrandName);

  return (
    <>
      <Heading
        text='Edit A Scheduled Meeting '
      />

      <ListComp
        headingText='There are two ways of doing that. '

      />

      <ListComp
        headingText='Process 1'

        data={[

          {
            name: `Visit '${baseurl}' and log in to your account.`,
            id: '13',
          },
          {
            name: 'In the meetings section, you will see all kinds of meetings (scheduled and attended ones). ',
            id: '14',

          }, {
            name: 'Go to the meeting you want to edit.',
            id: '15',

          }, {
            name: 'Here, you will see three options;',
            id: '16',
            child: [{
              name: 'View Details  ',
              id: '12'
            }, {
              name: 'Start',
              id: '13'
            }, {
              name: 'Edit',
              id: '14'
            },]

          }, {
            name: 'Click on the ‘Edit’ button. ',
            id: '17',

          },
          {
            name: 'Now, you can make the required changes and click on the save button.  ',
            id: '18',

          },

        ]}

      />

      <ListComp
        headingText='Process 2'
        data={[

          {
            name: `Visit the ‘Meeting’ page once you have logged into your  ${capitalizedCompanyBrandName} Account.`,
            id: '20',

          },
          {
            name: 'On this page, you will all of your meetings.  ',
            id: '114',

          }, {
            name: 'You can filter scheduled meetings by clicking on the ‘Upcoming’ tab. ',
            id: '215',

          }, {
            name: 'Go to the meeting you want to edit.',
            id: '160',

          }, {
            name: 'In the action section of that meeting, three buttons are there;    ',
            id: '170',
            child: [{
              name: 'Start  ',
              id: '142'
            }, {
              name: 'Edit',
              id: '103'
            }, {
              name: 'View Details ',
              id: '184'
            },]

          },
          {
            name: 'Click on the ‘Edit’ button.  ',
            id: '100',

          },

        ]}

      />

    </>
  );

};

export default EditAMeeting;
