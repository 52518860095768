import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import jwt from 'jwt-decode';
import UrlParse from 'url-parse';

// @mui 
import { Grid, Box, Typography, IconButton, Button, FormControlLabel, Checkbox, TextField, FormGroup, Switch } from '@mui/material'
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Stack } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// @mui icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// components
import { renderErrorMessage } from '../../../components/ToastNotification';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { joinWebianrCheck } from '../../../services/commonApis';
import { getItem } from '../../../lib/cookiesManager';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { createRedirectPath } from '../../../utils/common';

const MainSection = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  padding: '94px 10px 30px 10px',
}));

const SwitchButton = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const MainSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '850px',
  height: '700px',
  backgroundColor: theme.palette.card.main,
  padding: '25px 25px 40px 25px',
  borderRadius: '6px',
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[70],
  fontSize: '16px',
  fontWeight: 500
}));

const ColSm12 = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: '10px'
}));

const ColSm6 = styled('div')(() => ({
  display: 'flex',
  width: 'calc(100% / 2)'
}));

const InputOuterBox = styled('div')(() => ({
  paddingTop: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: '100%'
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette.grey[400]
}));

const CustomTextField = styled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: '5px',
  backgroundColor: theme.palette.grey[800],
  padding: '5px 10px',
  color: theme.palette.grey[400],
}));

const ScrollBox = styled(Box)(() => ({
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const BorderBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[700],
  width: '100%',
  backgroundColor: theme.palette.grey[1000],
  padding: '15px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px'
}));

export default function ViewWebinarDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [copySuccess, setCopySuccess] = useState(false);
  const [startWLoading, setStartWLoading] = useState(false);
  const [deleteWLoading, setDeleteWLoading] = useState(false);
  const [e2eKey, setE2eKey] = useState("");
  const [e2eErr, setE2eErr] = useState(false);
  const [openE2e, setOpenE2e] = useState(false);
  const [joinUrl, setJoinUrl] = useState(`${window.location.origin}${createRedirectPath("/join_meet?roomId=")}${location?.state?.roomId}`);
  // const [reStartWLoading, setReStartWLoading] = useState(false);
  const [{
    title = "N/A",
    createdAt,
    // createdByName,
    description = "N/A",
    // duration,
    endDate,
    hostIds = [],
    canceled,
    // lastUpdatedAt,
    startDate,
    _id = "N/A",
    quickMeet = false,
    e2ee = false,
    record = false,
    autoRecord = false,
    recurrence = false,
    waitingRoom = false,
    openRoom= false
    // status,
    // meetType = "N/A"
  }, setDetails] = useState([]);

  const fetchWebinarDetails = async (roomId) => {
    try {
      let res = await axiosGetCall("detailsWebinar", { roomId })
      if (res) setDetails(res?.result)
    } catch (error) {
      renderErrorMessage("Unable to fetch meeting details!")
    }
  }

  const cancelCheckWebinar = async (roomId) => {
    try {
      await axiosPostCall("cancelWebinar", { roomId, canceled: true })
      setDetails({
        title,
        createdAt,
        description,
        endDate,
        hostIds,
        canceled: true,
        startDate,
        _id
      })
      // setLoadingWList(false)
      // setHomeWebinar(res?.result)
    } catch (error) {
      // setLoadingWList(false)
      renderErrorMessage("Unable to cancel meeting!");
    }
  }

  // Delete canceled webinar.
  const deleteWebinar = async (roomId) => {
    setDeleteWLoading(true)
    try {
      await axiosPostCall("cancelWebinar", { roomId, deleted: true })
      setDeleteWLoading(false)
      // Navigate to dashboard on delete webinar
      navigate('/');
    } catch (error) {
      setDeleteWLoading(false)
      renderErrorMessage("Unable to delete meeting!");
    }
  }

  // Stuff to copy text in clip board.
  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false)
      }, 9000);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  // For start scheduled webinar.
  const startWebinar = async (roomId) => {
    setStartWLoading(true)
    try {
      let token = getItem("token")
      let info = jwt(token);
      let pId = info?.email;
      if (!roomId) {
        setStartWLoading(false)
        return renderErrorMessage("Invalid room Id!");
      }

      if (!pId) {
        setStartWLoading(false)
        return renderErrorMessage("Invalid token!");
      }

      let { redirectURL, state } = await joinWebianrCheck(roomId, info?.name, pId) || {};
      if (redirectURL) {
        navigate(redirectURL, { state });
        setStartWLoading(false)
      } else {
        setStartWLoading(false)
        return renderErrorMessage("Invalid link!")
      }
    } catch (error) {
      setStartWLoading(false)
      return renderErrorMessage(error?.message)
    }
  }

  // // For re-start scheduled webinar.
  // const reStartWebinar = async (roomId) => {
  //   setReStartWLoading(true)
  //   try {
  //     let res = await axiosPostCall("scheduleWebinar", { roomId, status: "inactive" })
  //     if (res) {
  //       await startWebinar(roomId);
  //       // renderSuccessMessage("Your webinar has been re-scheduled!");
  //       // let updateStatus = wList.map((item, index) => {
  //       //   return item?.roomId === roomId ? { ...item, status: "inactive" } : item;
  //       // })
  //       // setWList(updateStatus)
  //       setReStartWLoading(false)
  //     }
  //   } catch (error) {
  //     setReStartWLoading(false)
  //     return renderErrorMessage(error?.message)
  //   }
  // }

  const updateJoinUrl = () => {
    if (!e2eKey) return setE2eErr(true);
    const url = new UrlParse(joinUrl, true);
    const urlQuery = url.query;
    url.set("query", { ...urlQuery, key: encodeURIComponent(e2eKey) });
    return setJoinUrl(url.toString());
  }

  useEffect(() => {
    if (location?.state?.roomId) {
      fetchWebinarDetails(location?.state?.roomId);
      return;
    }
    renderErrorMessage("Internel server error!");

    // navigate('/');
    return;
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <ToastContainer />

      <MainSection>
        <MainSectionInner>

          <HeadingText>
            Detail of Meeting
          </HeadingText>

          <ScrollBox>
            {/* Section 1 for title and room Id */}
            <ColSm12>
              <ColSm6>
                <InputOuterBox>
                  <InputLabel>
                    Title
                  </InputLabel>

                  <CustomTextField>
                    {title}
                  </CustomTextField>
                </InputOuterBox>
              </ColSm6>

              <ColSm6>
                <InputOuterBox>
                  <InputLabel>
                    Room ID
                  </InputLabel>

                  <CustomTextField>
                    {location?.state?.roomId}
                  </CustomTextField>
                </InputOuterBox>
              </ColSm6>
            </ColSm12>

            {/* section 2 for description */}
            <InputOuterBox>
              <InputLabel>
                Description
              </InputLabel>

              <CustomTextField>
                {description}
              </CustomTextField>
            </InputOuterBox>

            {/* section 3 start on and end on. */}
            <ColSm12>
              <ColSm6>
                <InputOuterBox>
                  <InputLabel>
                    Start On
                  </InputLabel>

                  <CustomTextField>
                    {moment(startDate).format('MMMM')} {new Date(startDate).getDate()}, {moment(startDate).year()} {`${moment(startDate).format("hh:mm a")}`}
                  </CustomTextField>
                </InputOuterBox>
              </ColSm6>

              <ColSm6>
                <InputOuterBox>
                  <InputLabel>
                    End On
                  </InputLabel>

                  <CustomTextField>
                    {moment(endDate).format('MMMM')} {new Date(endDate).getDate()}, {moment(endDate).year()} {`${moment(endDate).format("hh:mm a")}`}
                  </CustomTextField>
                </InputOuterBox>
              </ColSm6>
            </ColSm12>

            <InputOuterBox>
              <InputLabel>
                Actions
              </InputLabel>

              <FormGroup sx={{ flexDirection: "row", gap: "15px" }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <SwitchButton
                    inputProps={{ "aria-label": "ant design" }}
                    checked={record}
                  />
                  <InputLabel>Recording</InputLabel>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <SwitchButton
                    inputProps={{ "aria-label": "ant design" }}
                    checked={autoRecord}
                  />
                  <InputLabel>Auto recording</InputLabel>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <SwitchButton
                    inputProps={{ "aria-label": "ant design" }}
                    checked={waitingRoom}
                  />
                  <InputLabel>Waiting Room</InputLabel>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <SwitchButton
                    inputProps={{ "aria-label": "ant design" }}
                    checked={openRoom}
                  />
                  <InputLabel>Open Room</InputLabel>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <SwitchButton
                    inputProps={{ "aria-label": "ant design" }}
                    checked={recurrence}
                  />
                  <InputLabel>Recurring Meeting</InputLabel>
                </Stack>
              </FormGroup>
            </InputOuterBox>

            <InputOuterBox sx={{ paddingTop: "12px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <FormControlLabel sx={{ marginRight: "2px" }} control={
                  <Checkbox
                    size="small"
                    inputProps={{ "aria-label": "Checkbox demo" }}
                    checked={e2ee}
                  />
                } label={
                  <InputLabel> End to End Encryption </InputLabel>
                } />

                {e2ee && <ExpandMoreIcon
                  onClick={() => { setOpenE2e(!openE2e) }}
                  sx={{
                    fontSize: "18px",
                    color: "grey.400",
                    transform: openE2e ? "rotate(180deg)" : ""
                  }} />}
              </Box>

              {(e2ee && openE2e) && <BorderBox>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}>
                  <InputLabel> Your Encryption Key: </InputLabel>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                    <TextField
                      id="outlined-size-small"
                      placeholder="Please type here.."
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: 'grey.800',
                        borderRadius: '6px'
                      }}
                      className='ListTextFieldSearch'
                      inputProps={{ sx: { color: "grey.400" } }}
                      onChange={(e) => setE2eKey(e.target.value)}
                      onClick={() => setE2eErr(false)}
                      error={e2eErr}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={updateJoinUrl}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "340px",
                          lg: "340px",
                          xl: "340px"
                        },
                        textTransform: "none"
                      }}
                    >
                      Update Invite URL With E2E Key
                    </Button>
                  </Box>
                </Box>
              </BorderBox>}
            </InputOuterBox>

            {/* section 4 Invited host */}
            <InputOuterBox>
              <InputLabel>
                Invited Hosts
              </InputLabel>

              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: '100%',
                maxHeight: '110px',
                pb: '5px',
                overflow: 'hidden',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}>
                {!!hostIds.length && hostIds.map(item => (
                  <Box
                    key={item}
                    sx={{
                      backgroundColor: 'grey.1000',
                      border: '1px solid',
                      borderColor: 'grey.800',
                      fontSize: '14px',
                      borderRadius: '30px',
                      height: '30px',
                      padding: '0 14px 0 1rem',
                      display: 'inline-flex',
                      alignItems: 'center',
                      margin: '0 0.3rem 0.3rem 0',
                      color: 'grey.300',
                    }}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            </InputOuterBox>

            {/* Section 5 invite link */}
            <InputOuterBox>
              <BorderBox>
                <InputLabel >
                  Invite Link
                </InputLabel>

                <Box sx={{ display: 'flex', justifyContent: 'end', mr: 2 }}>
                  <Box sx={{
                    bgcolor: 'rgba(220, 160, 0, 0.1)',
                    display: 'flex',
                    borderRadius: '10px',
                  }}>
                    <Typography sx={{
                      fontWeight: 700,
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#DCA000',
                      ml: 2,
                      mb: 1,
                      mt: 1,
                      paddingRight: '10px'
                    }}>
                      Note: Login required to join as a host.
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{
                  borderRadius: '5px',
                  backgroundColor: 'grey.800',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Grid item={true} xs={10}>
                    <Typography sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      color: 'grey.500',
                      px: 2,
                    }}>
                      {joinUrl}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item={true} sx={{ display: 'flex', justifyContent: 'end', p: '5px' }}>
                    <IconButton sx={{
                      color: copySuccess ? '#4BB543' : 'grey.400',
                    }}
                      onClick={() => copyToClipboard(joinUrl)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Grid>
                </Box>
              </BorderBox>
            </InputOuterBox>

            {/* Section 6 created date */}
            <InputOuterBox sx={{ alignItems: 'flex-end', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <InputLabel>
                Create Date:
              </InputLabel>

              <InputLabel>
                {moment(createdAt).format('MMMM')} {new Date(createdAt).getDate()}, {moment(createdAt).year()} {`${moment(createdAt).format("hh:mm a")}`}
              </InputLabel>
            </InputOuterBox>
          </ScrollBox>

          {/* Section 7 for actions */}
          <InputOuterBox sx={{ justifyContent: 'center', flexDirection: 'row' }}>

            {(!canceled && endDate > + new Date()) &&
              <ColSm12>

                <ColSm6>
                  <LoadingButton
                    variant='contained'
                    size='small'
                    loading={startWLoading ? true : null}
                    loadingPosition="center"
                    onClick={() => startWebinar(location?.state?.roomId)}
                    sx={{
                      textTransform: "none",
                      width: '100%',
                      boxShadow: 'none'
                    }}
                  >
                    Start
                  </LoadingButton>

                </ColSm6>

                <ColSm6 sx={{ gap: '10px' }}>
                  {!quickMeet && <Button
                    size='small'
                    variant='outlined'
                    sx={{
                      textTransform: "none",
                      width: '100%',
                      boxShadow: 'none'
                    }}
                    endIcon={<BorderColorIcon sx={{ height: '14px' }} />}
                    onClick={() => navigate(`/schedule_meet`, { state: { edit: true, roomId: location?.state?.roomId } })}
                  >
                    Edit
                  </Button>}

                  <Button
                    size='small'
                    variant='outlined'
                    color='error'
                    sx={{
                      textTransform: "none",
                      width: '100%',
                      boxShadow: 'none'
                    }}
                    onClick={() => cancelCheckWebinar(location?.state?.roomId)}
                  >
                    Cancel Meeting
                  </Button>
                </ColSm6>
              </ColSm12>
            }


            {canceled &&

              <LoadingButton
                variant='outlined'
                color='error'
                size='small'
                loading={deleteWLoading ? true : null}
                loadingPosition="center"
                onClick={() => deleteWebinar(location?.state?.roomId)}
                sx={{
                  textTransform: "none",
                  boxShadow: 'none'
                }}
              >
                Delete
              </LoadingButton>
            }

          </InputOuterBox>

        </MainSectionInner>
      </MainSection>
    </>
  )
}
