import React from 'react';
import { Typography } from '@mui/material';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const Signup = () => {
  const dynamicUrl = `${window.location.origin}/register`;

  return (
    <>
      <Heading
        text='SignUp Process'
      />

      <ListComp
        data={[{
          name: <Typography>
            Visit: <a target="_blank" style={{ color: 'blue' }} >{dynamicUrl}</a>

          </Typography>,
          id: '12',
        },
        {
          name: <Typography>
            To quickly sign up with your Google Account, Click<span style={{ color: '#434546' }}>“Sign up with Google.”</span>
          </Typography>,
          id: '12',
        },

        {
          name: 'Fill out the signup form with details like:',
          id: '13',
          child: [{
            name: 'Name',
            id: '12'
          }, {
            name: 'Email Address',
            id: '13'
          }, {
            name: 'Phone Number',
            id: '14'
          }, {
            name: 'Password',
            id: '16'
          }]
        }, {
          name: <Typography>
            Check the, <span style={{ color: '#434546', textDecoration: 'none' }}>‘I agree with Privacy Policy and Terms of Services’</span>
          </Typography>,
          id: '14'
        },
        {
          name: <Typography>
            Click <span style={{ color: '#434546', textDecoration: 'none' }}>‘Create Account’</span>
          </Typography>,
          id: '15'
        }]}
      />

      <ImageComp
        name={"signup"}

      />

    </>

  );

};

export default Signup;


