import axios from "axios";
import { consoleApiRoutes, consoleApiUrl } from "../config/config";
import { LOCAL_STORAGE } from "../config/Enums";

const getBrandingFormBackend = async () => {
    const { data = {} } = await axios.get(`${consoleApiUrl}${consoleApiRoutes?.branding}?domain=${window.location.hostname}`) || {};

    localStorage.setItem(LOCAL_STORAGE?.BRANDING, JSON.stringify({
        exp: + new Date() + 86400 * 1000,
        ...data,
    }));

    return data;
}

const checkBranding = async () => {
    try {
        const cookiesBranding = localStorage.getItem(LOCAL_STORAGE?.BRANDING);
        let newTitle = "HD Video Conferencing Solution";
        let data = {}

        if (cookiesBranding) {

            data = JSON.parse(cookiesBranding);

            if (+new Date() > data?.exp) {
                data = await getBrandingFormBackend()
            }
        } else {
            data = await getBrandingFormBackend()

        }

        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        link.href = data?.faviconUrl;
        document.title = data?.title ? data?.title : newTitle;

        return data;

    } catch (error) {
        return {}
    }
}

export default checkBranding;
