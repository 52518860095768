const initialState = {
    status: "",
    duration: 0
}

const recording = (state = initialState, action) => {
	switch (action.type) {
        case 'RECORDING':
			const { status, duration } = action.payload;
			return { ...state, status, duration };

		default:
			return state;
	}
};

export default recording;