import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const SetVirtualBackGround = () => {
  return (
    <>
      <Heading
        text='How to set a virtual background during a meeting?  '
      />

      <ImageComp
        name={"howtosetvirtualbackground"}

      />

      <ListComp

        data={[

          {
            name: 'To set a virtual background during a meeting for yourself, click on the ‘More Option’ icon on the ‘Meeting Control Bar.’            ',
            id: '13',

          },
          {
            name: 'Click on the ‘Virtual Background’ option.',
            id: '14',

          }

        ]}

      />

      <ImageComp
        name={"virtualBackgroundOption"}

      />

      <ListComp

        data={[

          {
            name: 'It will open a pop-up with various options.',
            id: '15',

          },
          {
            name: 'You can either select from the available options or your can upload you own image that you want to set as a background.',
            id: '16',

          },
          {
            name: 'Once done, click Save.',
            id: '17',

          }

        ]}

      />

    </>
  );
};

export default SetVirtualBackGround;
