import rootReducer from '../redux/reducers';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware} from "redux";
// import { configureStore } from '@reduxjs/toolkit';

// const store = configureStore({ 
//     reducer: rootReducer,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//       serializableCheck: false,
//     }).concat(logger, applyMiddleware(thunk)),
//     devTools: process.env.NODE_ENV !== 'production',
//     // preloadedState
//   })

const store = createStore(rootReducer, applyMiddleware(thunk/* , logger */))

  // store.subscribe(() => {
  //     console.log("store data", store.getState());
  // })

export default store;