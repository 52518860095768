import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const CreateQuickMeeting = () => {
  const baseurl = window.location.origin

  return (

    <>
      <Heading
        text='Create a Quick Meeting '
      />

      <ListComp
        headingText='   To create a meeting in an instant,'
        data={[

          {
            name: `Visit '${baseurl}' and log in to your account.`,
            id: '13',
          },
          {
            name: 'Click the ‘Quick Meeting’ button on the screen’s left half.',
            id: '14',

          }, {
            name: 'This will jump you right into the meeting.',
            id: '15',

          },

        ]}

      />

      <ImageComp
        name={"quickMeeting"}

      />

    </>

  );

};

export default CreateQuickMeeting;
