import React from 'react';
import { Typography } from '@mui/material';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const Login = () => {
  const dynamicUrl = `${window.location.origin}/login`;

  return (
    <>
      <Heading
        text='Login Process'
      />

      <ListComp
        data={[{
          name: <Typography>
            Visit: <a target="_blank" style={{ color: 'blue' }} >{dynamicUrl}</a>

          </Typography>,
          id: '12',
        },
        {
          name: <Typography>
            If you registered yourself with a Google Account, click on the <span style={{ color: '#434546', textDecoration: 'none' }}>  ‘Sign-in With Google.’</span>
          </Typography>,
          id: '12',
        },
        {
          name: 'If you created an account using your email address, enter',
          id: '13',
          child: [{
            name: 'Email ID',
            id: '12'
          }, {
            name: 'Password',
            id: '13'
          },]
        },
        {
          name: <Typography>
            Click, <span style={{ color: '#434546', textDecoration: 'none' }}>‘Login’</span>
          </Typography>,
          id: '14'
        },

        ]}
      />

      <ImageComp
        name={"login"}

      />

    </>
  );

};

export default Login;


