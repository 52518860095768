import React, { memo, useContext, useEffect, useState } from 'react'
import { Button, IconButton, Avatar, Box, Typography, AvatarGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import CustomSnackbar from './snakeBar';
import { manageMediaStop, removeAudioNotification } from '../../../redux/actions/stateActions';
import { WaitingRoomContext } from '../../../context/waitingRoomContext'
import { axiosPostCall } from '../../../services/apisCall';
import { renderErrorMessage } from '../../ToastNotification';
import { hideWaitingRoomPopup } from '../../../redux/actions/waitingRoomActions';
import RoomEndReminder from './roomEndReminder';
import { configFile } from '../../../config';

function ManageAllExternelCases({ roomClient, handleEnableMic, handleEnableCam }) {
    const [audioStopPopup, setAudioStopPopup] = useState(false);
    const [videoStopPopup, setVideoStopPopup] = useState(false);
    const [audioStartPopup, setAudioStartPopup] = useState(false);
    const [videoStartPopup, setVideoStartPopup] = useState(false);
    const [waitingRoomPopup, setWaitingRoomPopup] = useState(false);
    const { audioStart, audioStop, videoStart, videoStop } = useSelector(state => state.media);
    const { users, showPopup } = useSelector(state => state?.waitingRoom);
    const { notifications } = useSelector(state => state?.audioNotifications);

    const waitingRoomContext = useContext(WaitingRoomContext);

    const { join } = configFile?.audioPath;
    const joinAudio = new Audio(join);

    const dispatch = useDispatch()
    const location = useLocation();

    const { roomId = "" } = location?.state || {};

    const handleAllowClick = async (peerId, action) => {
        try {
            await axiosPostCall("waitingRoomPeerActions", { roomId, peerId, action });
            return
        } catch (error) {
            return renderErrorMessage(error?.message || `Unable to send ${action} request`);
        }
    };

    const closeWaitingRoomPopup = () => {
        setWaitingRoomPopup(false);
        hideWaitingRoomPopup();
    }

    const handleWaitingViewBtn = () => {
        waitingRoomContext?.openWaitingRoomlist()
        closeWaitingRoomPopup()
    }

    const actionAudio = (
        <>
            <Button variant="contained" size='small' color="success" sx={{ mr: 2 }} onClick={() => {
                handleEnableMic()
                setAudioStartPopup(false)
            }}>Turn on mic</Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setAudioStartPopup(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    )

    const actionVideo = (
        <>
            <Button variant="contained" size='small' sx={{ mr: 2 }} color="success" onClick={() => {
                handleEnableCam();
                setVideoStartPopup(false);
            }}>Turn on camera</Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setVideoStartPopup(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    )

    const actionWaitingRoom = (
        <>
            {users?.length === 1 ?
                users.map(({ peerId }) => (
                    <Box key={peerId} sx={{ display: 'flex', gap: '10px' }}>
                        <Button
                            variant='outlined'
                            size='small'
                            sx={{
                                color: '#0C8CE9',
                                borderColor: '#0C8CE9',
                                width: '100%',
                                height: '30px',
                                "&:hover": {
                                    color: '#0C8CE9',
                                    borderColor: '#0C8CE9',
                                }
                            }}
                            onClick={() => handleAllowClick(peerId, 'allow')}
                        >
                            Allow
                        </Button>

                        <Button
                            variant='outlined'
                            size='small'
                            sx={{
                                color: '#E16C77',
                                borderColor: '#E16C77',
                                width: '100%',
                                height: '30px',
                                "&:hover": {
                                    color: '#E16C77',
                                    borderColor: '#E16C77',
                                    backgroundColor: 'transparent'
                                }
                            }}
                            onClick={() => handleAllowClick(peerId, 'decline')}
                        >
                            Decline
                        </Button>

                    </Box>
                ))
                :
                <Button
                    variant='outlined'
                    size='small'
                    sx={{
                        color: '#fff',
                        borderColor: '#434546',
                        backgroundColor: '#434546',
                        width: '100%',
                        height: '30px',
                        "&:hover": {
                            color: '#fff',
                            borderColor: '#434546',
                            backgroundColor: '#434546',
                        }
                    }}
                    onClick={() => handleWaitingViewBtn()}
                >
                    View
                </Button>
            }
        </>
    );

    useEffect(() => {
        if (audioStop) {
            setAudioStopPopup(true)
            roomClient.muteMic()
            dispatch(manageMediaStop("audioStop"));
        } else if (videoStop) {
            setVideoStopPopup(true)
            roomClient.disableWebcam();
            dispatch(manageMediaStop("videoStop"));
        }
        // eslint-disable-next-line
    }, [audioStop, videoStop])

    useEffect(() => {
        if (audioStart) {
            setAudioStartPopup(true)
            dispatch(manageMediaStop("audioStart"));
        } else if (videoStart) {
            setVideoStartPopup(true)
            dispatch(manageMediaStop("videoStart"));
        }
        // eslint-disable-next-line
    }, [audioStart, videoStart])

    useEffect(() => {
        if (Boolean(users.length) && showPopup) setWaitingRoomPopup(true)

        // eslint-disable-next-line
    }, [users])

    useEffect(() => {
        if (Object.keys(notifications).length) {
            for (let key in notifications) {
                const data = notifications[key];
                if (data.type === 'join') {
                    joinAudio.play().catch(error => console.log("handled error ignore1: ", error))
                    dispatch(removeAudioNotification({ peerId: key, type: data?.type }))
                }
            }
        }
    }, [notifications])

    useEffect(() => {
        joinAudio.play().catch(error => console.log("handled error ignore: ", error))
    }, [])

    return (
        <>
            {audioStopPopup &&
                <CustomSnackbar
                    open={audioStopPopup}
                    close={() => setAudioStopPopup(false)}
                    message={"Your mic has beed disabled by host!"}
                    icon={true}
                />}
            {videoStopPopup &&
                <CustomSnackbar
                    open={videoStopPopup}
                    close={() => setVideoStopPopup(false)}
                    message={"Your camera has beed disabled by host!"}
                    icon={true}
                />
            }
            {audioStartPopup &&
                <CustomSnackbar
                    open={audioStartPopup}
                    close={() => setAudioStartPopup(false)}
                    message={"Host wants to turn on you mic!"}
                    icon={false}
                    customAction={actionAudio}
                    duration={5 * 60 * 1000}
                />
            }
            {videoStartPopup &&
                <CustomSnackbar
                    open={videoStartPopup}
                    close={() => setVideoStartPopup(false)}
                    message={"Host wants to turn on you camera!"}
                    icon={false}
                    customAction={actionVideo}
                    duration={5 * 60 * 1000}
                />
            }
            {users?.length > 0 && (
                <CustomSnackbar
                    open={waitingRoomPopup}
                    close={() => closeWaitingRoomPopup()}
                    message={
                        <>
                            {users.length === 1 ? <Box sx={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'center'
                            }}>
                                <Avatar sx={{ color: '#fff', backgroundColor: '#D37F18', textTransform: 'uppercase' }}> {users[0]?.displayName[0]} </Avatar>
                                <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: 500 }}>
                                    {users[0]?.displayName} want to join this call
                                </Typography>
                            </Box>

                                :
                                <Box sx={{
                                    display: 'flex',
                                    gap: '12px',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                }}>
                                    {users.length > 1 && (
                                        <AvatarGroup max={3} total={users?.length || 0}>
                                            {users.slice(0, 3).map((user, index) => (
                                                <Avatar key={index} sx={{ color: '#fff', backgroundColor: '#D37F18', textTransform: 'uppercase' }}>
                                                    {user.displayName[0]}
                                                </Avatar>
                                            ))}
                                        </AvatarGroup>
                                    )}

                                    <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: 500 }}>
                                        Some users want to join this call
                                    </Typography>
                                </Box>
                            }
                        </>
                    }
                    icon={false}
                    customAction={actionWaitingRoom}
                    duration={2 * 60 * 1000}
                    sx={{
                        background: '#2C2D2F'
                    }}
                />
            )}

            {/* Show reminder if no one persent in room */}
            <RoomEndReminder />
        </>
    )
}
export default memo(ManageAllExternelCases);
