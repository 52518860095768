import React, { useEffect, useState } from 'react';
import ScreenShareView from './ScreenShareView'
import { useSelector } from 'react-redux';
import { Grid, Box, AvatarGroup, Avatar, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import { axiosPostCall } from '../../../../services/apisCall';
import Logger from '../../../../lib/Logger';
import { renderErrorMessage } from '../../../ToastNotification';
import ZoomShareScreen from './ZoomShareScreen';

// import img01 from '../../images/01.png'
const logger = new Logger('screenShare');

const ScreenShare = ({ roomId, peerId, shareFullScreen, handleShareFullScreen }) => {
    const producersArray = useSelector(state => state.producers);
    const pinedShare = useSelector(state => state.pinFocus);
    const consumers = useSelector(state => state.consumers);
    const peers = useSelector(state => state.peers);
    const [sShareArr, setSShareArr] = useState([]);
    const [sShareArrMain, setSShareArrMain] = useState([]);
    const [xSMain, setXSMain] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const pinScreen = async (roomId, peerId) => {
        try {
            await axiosPostCall("pinScreen", { roomId, peerId, action: 'add', type: 'pinShareScreen' });
        } catch (error) {
            logger.error("@ScreenSahre.jsx  pinScreen", error)
            renderErrorMessage(error.message)
        }
    };

    useEffect(() => {
        let producers = Object.values(producersArray);
        const videoProducer = producers.find((producer) => producer.track.kind === 'video' && producer?.type === "share");
        let checkByMe = videoProducer ? true : null;

        // eslint-disable-next-line
        let checkShare = Object.values(consumers).map(({ id, type, track, rtpParameters, locallyPaused = false, remotelyPaused = true }) => {
            if (type === "share") {
                const videoVisible = !locallyPaused && !remotelyPaused;
                // if(!videoVisible) return;
                let nameObj = Object.values(peers).filter(({ consumers }) => consumers.includes(id));
                let displayName = nameObj[0]?.displayName;
                return { type, track, rtpParameters, displayName, peerId: nameObj[0]?.id, videoVisible };
            }
            // return;
        }).filter(notUndefined => notUndefined !== undefined);

        let concatArr = videoProducer ? [...checkShare, { ...videoProducer, byMe: checkByMe, peerId }] : checkShare;
        if (concatArr.length === 1) {
            setXSMain(12);
            if (checkByMe && !pinedShare?.sharePin?.length) {
                pinScreen(roomId, peerId)
            }
        } else if(!shareFullScreen){
            setXSMain(10);
        }

        if (pinedShare?.sharePin?.length) {
            let updatePined = pinedShare?.sharePin.map((item) => {
                return concatArr.find(({ peerId }) => peerId === item);
            }).filter(notUndefined => notUndefined !== undefined);
            setSShareArrMain(updatePined.slice(0, 4));

            let sideShare = concatArr.filter((item) => {
                let check = pinedShare?.sharePin.includes(item.peerId);
                if (!check) {
                    return item
                }
                return null;
            })

            setSShareArr(sideShare);

            if (!sideShare.length) {
                setXSMain(12);
            }
        }
    // eslint-disable-next-line
    }, [producersArray, consumers, peers, pinedShare, roomId, peerId])


    useEffect(() => {
      if (shareFullScreen) {
          setXSMain(12);
      } else if (sShareArr?.length){
        setXSMain(10);
      }
    // eslint-disable-next-line
    }, [shareFullScreen]);

    return (
        <>
            <Grid item={true} container xs={12} sx={{
                height: shareFullScreen ? "100%" : '78%',
                borderRadius: '10px',
                // margin: 0,
                // flexDirection: 'column',
                // bgcolor: '#333333',
                mt: 0
            }}
            >

                {sShareArrMain?.length > 0 &&
                    <Grid item={true} container /* spacing={2} */ xs={xSMain} sx={{
                        position: "relative",
                        background: '#333333',
                        height: '100%',
                        // width: '100%',
                        borderRadius: '10px',
                        display: 'flex',
                        // alignItems: 'center',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        mt: 0,
                        ml: 0
                        // p: 0,
                    }}>
                        <IconButton sx={{
                            position: 'absolute',
                            right: !shareFullScreen ? 5 : 100,
                            top: 10,
                            width: "30px",
                            height: "30px",
                            bgcolor: !shareFullScreen ? '#333333' : '#5D5D5D',
                            color: '#ffffff',
                            zIndex: 1,
                            borderRadius: '100%',
                            "&:hover":{
                                backgroundColor: !shareFullScreen ? '#333333' : '#5D5D5D',
                            }
                        }}
                            aria-owns={anchorEl ? "zoom-share" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon sx={{ height: "20px", width: "20px" }}/>
                        </IconButton>

                        <ZoomShareScreen
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            shareFullScreen={shareFullScreen}
                            handleShareFullScreen={handleShareFullScreen}
                        />
                        {sShareArrMain.map((item) => (
                            <Grid item={true} container xs={sShareArrMain?.length > 1 ? 6 : 12} key={item?.peerId} sx={{
                                maxHeight: sShareArrMain?.length > 2 ? '50%' : '100%',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                // ml: 1
                                // background: '#fff',
                            }}>
                                <ScreenShareView
                                    videoTrack={item.track}
                                    byMe={item?.byMe}
                                    displayName={item?.displayName}
                                    showUnpin={sShareArrMain?.length > 1 ? true : false}
                                    peerId={item?.peerId}
                                    roomId={roomId}
                                    showName={true}
                                    pinned={true}
                                    shareSx={{
                                        bgcolor: 'text.disabled',
                                        position: 'relative',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center center',
                                        height: item?.byMe ? sShareArrMain.length > 1 ? '60%' : '40%' : '100%',
                                        // width: item?.byMe ? sShareArrMain.length > 1 ? '60%' : '40%' : '100%',
                                        borderRadius: '10px',
                                        // alignItems: 'stretch',
                                        background: '#333333',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        //     bgcolor: 'text.disabled',
                                        // position: 'relative',
                                        // background: '#333333',
                                        // backgroundSize: 'cover',
                                        // borderRadius: '10px',
                                        // height: '100%',
                                        // alignItems: 'stretch',
                                    }}
                                    nameSx={{
                                        position: 'absolute',
                                        bottom: item?.byMe && sShareArrMain.length === 2 ? "-33%" : 3,
                                        left: 10,
                                        bgcolor: 'rgba(0,0,0,0.5)',
                                        color: '#ffffff',
                                        fontSize: '12px',
                                        height: '24px'
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                }

                {/* side share screens */}
                {(sShareArr?.length > 0 && xSMain === 10 && !shareFullScreen) &&
                    <Grid item={true} xs={2} sx={{
                        py: 0,
                    }}>
                        {sShareArr.slice(0, sShareArr.length === 4 ? 4 : 3).map((item) => (
                            <ScreenShareView
                                key={item?.peerId}
                                videoTrack={item.track}
                                byMe={item?.byMe}
                                displayName={item?.displayName}
                                peerId={item?.peerId}
                                roomId={roomId}
                                showName={true}
                                pinned={false}
                                showPin={sShareArrMain?.length > 3 ? false : true}
                                shareSx={{
                                    bgcolor: 'text.disabled',
                                    position: 'relative',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    minHeight: '20%',
                                    width: '90%',
                                    borderRadius: '10px',
                                    mb: 1.3,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    ml: 1.5,
                                }}
                                nameSx={{
                                    position: 'absolute',
                                    bottom: 3,
                                    left: 10,
                                    bgcolor: 'rgba(0,0,0,0.5)',
                                    color: '#ffffff',
                                    fontSize: '12px',
                                    height: '24px'
                                }}
                            />
                        ))}

                        {sShareArr?.length > 4 &&
                            <Box sx={{
                                bgcolor: 'text.disabled',
                                position: 'relative',
                                backgroundSize: 'cover',
                                // backgroundImage: `url(${img01})`,
                                backgroundPosition: 'center center',
                                minHeight: '20.7%',
                                borderRadius: '10px',
                                mb: 1.3,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                backgroundColor: '#333333',
                            }}>
                                <IconButton sx={{
                                    position: 'absolute',
                                    left: 10,
                                    top: 10,
                                    bgcolor: '#1976d2',
                                    color: '#ffffff',
                                    p: .5,
                                }}
                                    disableRipple
                                >
                                    <ScreenShareOutlinedIcon sx={{
                                        width: '16px',
                                        height: '16px',
                                    }} />
                                </IconButton>
                                <AvatarGroup total={sShareArr.length - 3} >
                                    <Avatar alt={sShareArr[3]?.displayName} sx={{
                                        bgcolor: '#333333',
                                        color: 'rgba(255,255,255,.5)',
                                        // border: 'none'
                                    }} src="../../images/avatar.png" />
                                    <Avatar alt={sShareArr[4]?.displayName} sx={{
                                        bgcolor: '#333333',
                                        color: 'rgba(255,255,255,.5)'
                                    }} src="../../images/avatar.png" />
                                </AvatarGroup>
                            </Box>
                        }

                    </Grid>
                }
            </Grid>
        </>
    )
}

export default ScreenShare;