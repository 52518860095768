import React, { useContext } from 'react';
import { Menu, MenuItem, ListItemIcon, Typography, IconButton } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { configFile } from '../../../../config';
import { RecordContext } from '../../../../context/recordContext';
import RoomContext from '../../../../context/RoomContext';
import { setRecordingInfo } from '../../../../redux/actions/recordingActions';
import { WaitingRoomContext } from '../../../../context/waitingRoomContext';
import { VirtualBackgroundContext } from '../../../../context/vbContext';

import { renderErrorMessage, renderSuccessMessage } from '../../../ToastNotification';
import { recordingToggle } from "../../../../services/commonApis";
import { WhiteBoardContext } from '../../../../context/whiteboard';
import { udpateWhitebaordOpenStatus } from "../../../../redux/actions/stateActions";

export default function MoreOptionsMenu({ anchorElMore, handleClose, deviceMenuSx, transformOrigin, anchorOrigin, }) {
    const { imagesUrl } = configFile;
    const dispatch = useDispatch();
    const waitingRoomContext = useContext(WaitingRoomContext);
    const whiteBoardContext = useContext(WhiteBoardContext);

    const roomContext = useContext(RoomContext);
    const { isWaitingRoom } = useContext(WaitingRoomContext);

    const { handleVirtualBackground } = useContext(VirtualBackgroundContext);
    const location = useLocation();
    const { url } = useSelector(state => state?.webinarInfo);
    const { toggleRecording, openRecording, record } = useContext(RecordContext) || {};

    const startRecording = async () => {
        try {
            if (!openRecording) {
                const { message } = await recordingToggle("start", roomContext._roomId, location?.state?.peerId, url);

                if (message) {
                    renderSuccessMessage(message || "Recording strated successfully!");
                    toggleRecording(true);
                    dispatch(setRecordingInfo({ status: "start", duration: 0 }))
                }
            }
        } catch (error) {
            renderErrorMessage(error.message || "Internal Server Error");
            // throw Error(error);
        }
    }

    const openWhiteboard = () => {
        try {
            dispatch(udpateWhitebaordOpenStatus(true));
            whiteBoardContext.openWhiteBoard()
        } catch (error) {
            renderErrorMessage(error.message || "Unable to open whiteboard!");
        }
    }

    return (
        <Menu
            anchorEl={anchorElMore}
            id="account-menu"
            open={Boolean(anchorElMore)}
            onClose={() => handleClose()}
            onClick={() => handleClose()}
            PaperProps={{
                elevation: 0,
                sx: {
                    ...deviceMenuSx,
                    marginTop: anchorOrigin.vertical === 'top' ? 0 : deviceMenuSx.mt,
                    ml: anchorOrigin.vertical === 'top' ? deviceMenuSx?.ml : 0,
                }
            }}
            transformOrigin={transformOrigin}
            anchorOrigin={anchorOrigin}
        >

            {/* <MenuItem sx={{ fontSize: 14, }}>
                <ListItemIcon>
                    <img src={imagesUrl?.qAndA} alt='' width={'60%'} height={'60%'} />
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -1 }}>Q&amp;A</Typography>
            </MenuItem> */}
            {record && <MenuItem sx={{ fontSize: 14, }} onClick={() => startRecording()}>
                <ListItemIcon >
                    <IconButton sx={{
                        height: '24px',
                        width: '24px',
                        backgroundImage: 'linear-gradient(135deg, #DF2525 14.64%, #C02A2A 85.35%)',
                        color: '#fff'
                    }}>
                        <RadioButtonCheckedIcon sx={{ height: '16px', width: '16px', }} />
                    </IconButton>
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -0.5, color: '#727374' }} >Start recording</Typography>
            </MenuItem>}

            {isWaitingRoom && <MenuItem sx={{ fontSize: 14, }} onClick={() => waitingRoomContext.openWaitingRoomlist()}>
                <ListItemIcon>
                    <img src={imagesUrl?.waitingRoom} alt='' width={'60%'} height={'60%'} />
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -1, color: '#727374' }}>Waiting Room</Typography>
            </MenuItem>}

            <MenuItem sx={{ fontSize: 14, }} onClick={() => openWhiteboard()}>
                <ListItemIcon>
                    <img src={imagesUrl?.whiteboardPng} alt='' width={'18px'} height={'20px'} />
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -1, color: '#727374' }}>Whiteboard</Typography>
            </MenuItem>

            <MenuItem sx={{ fontSize: 14, }} onClick={() => handleVirtualBackground(true)}>
                <ListItemIcon>
                    <img src={imagesUrl?.visualEffectIcon} alt='' width={'18px'} height={'20px'} />
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -1, color: '#727374' }}>Virtual Background</Typography>
            </MenuItem>

        </Menu>

    )
}
