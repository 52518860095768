import { waitingRoomMessage, waitingRoomAction } from '../redux/actions/waitingRoomActions';
import { configFile } from '../config';
import Logger from './Logger';
const logger = new Logger("SSE")

let es = null;

export function WaitingRoomSseConnectEvents(peerId, store, roomId,displayName) {

  try {
    if (typeof EventSource !== "undefined") {
      const url = configFile.sseEventUrl();
      const eventSourceUrl = `${url}?peerId=${peerId}&roomId=${roomId}&displayName=${displayName}&isWaiting=true`;
      es = new EventSource(eventSourceUrl);

      es.onopen = function () {
        logger.debug("waiting room sse connected");

        es.addEventListener("waitingRoomAttendeeAction", function (event) {
          logger.debug("ACTION", event.data);
          store.dispatch(waitingRoomAction(JSON.parse(event.data||{})))
        });

        es.addEventListener("waitingRoomMessage", function (event) {
          logger.debug("ACTION_REQUEST", event.data);
          store.dispatch(waitingRoomMessage(event.data))
        });
      };

      es.onerror = (error) => {
        logger.error("Error : sse is not supported ", error); 
      };
    }

  } catch (error) {
    logger.error("Error sse is not supported! Error: ", error);
    throw new error(error)
  }
}

export function sseClose() {
    es.close();
}
