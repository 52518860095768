import React, { useRef, useState } from 'react'
import {
    IconButton,
    Stack, Popper, MenuItem, MenuList, Grow, Paper, ClickAwayListener,
} from '@mui/material';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';

export default function ProducersListOptions({ data, roomId, promoteRole, kickPeer, redirectToPrivateChat, handleMediaRequests }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const iconSx = { width: '16px', height: '16px' };

    // Open more menu option.
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // Close menu on close hit.
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Stack direction="row">
                {data?.share && <IconButton>
                    <ScreenShareOutlinedIcon sx={iconSx} />
                </IconButton>}
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle()}
                >
                    <MoreVertIcon sx={iconSx} />
                </IconButton>
            </Stack>

            <Popper
                sx={{
                    zIndex: 1,
                    p: 0,
                    margin: 0
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper sx={{
                            border: '1px solid #F0F0F0'
                        }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem
                                        key="audio"
                                        onClick={() => handleMediaRequests(roomId, data?.peerId, "audio", `${data?.audioEnabled ? "stop" : "start"}`)}
                                        sx={{ color: '#727374' }}
                                    >
                                        {data?.audioEnabled ? "Disable audio" : "Enable audio"}
                                    </MenuItem>
                                    <MenuItem
                                        key="video"
                                        // selected={selectedIndex === 2}
                                        onClick={() => handleMediaRequests(roomId, data?.peerId, "video", `${data?.videoVisible ? "stop" : "start"}`)}
                                        sx={{ color: '#727374' }}
                                    >
                                        {data?.videoVisible ? "Disable video" : "Enable video"}
                                    </MenuItem>
                                    <MenuItem
                                        key="chat"
                                        // selected={selectedIndex === 2}
                                        onClick={() => redirectToPrivateChat(data?.peerId)}
                                        sx={{ color: '#727374' }}
                                    >
                                        Chat
                                    </MenuItem>
                                    <MenuItem
                                        key="removePeer"
                                        // selected={selectedIndex === 2}
                                        onClick={() => kickPeer(roomId, data?.peerId, data.name, "host")}
                                        sx={{ color: '#727374' }}
                                    >
                                        Remove
                                    </MenuItem>
                                    {data?.role === 'tempCoHost' &&
                                        <MenuItem
                                            key="demote"
                                            // selected={selectedIndex === 2}
                                            onClick={() => promoteRole(roomId, data?.peerId, data.name, "demote")}
                                            sx={{ color: '#727374' }}
                                        >
                                            Demote
                                        </MenuItem>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
