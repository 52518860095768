import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { createRedirectPath } from "../utils/common";
// import virtualBackground1 from "./v2.png"

let selfieSegmentation = null;
let video = null;
let canvas = null;
let context = null;
let animationFrameId = null;
let mediaStream = null;
let worker = null;
let dynamicImage = null;

function getVirtualBgScreen(stream, videoDimension, vb, imageUrl) {
    try {
        mediaStream = stream;
        video = document.createElement("video");
        canvas = document.createElement("canvas");
        dynamicImage = document.createElement('img');

        dynamicImage.src = imageUrl;
        context = canvas.getContext("2d");
        video.width = videoDimension.width.ideal
        video.height = videoDimension.height.ideal
        video.autoplay = true;

        canvas.width = video.width;
        canvas.height = video.height;

        video.srcObject = stream;

        const onResults = (results) => {
            context.save();
            clearCanvas();

            context.globalCompositeOperation = "copy";
            context.filter = "none";

            if (vb !== "off") {
                context.filter = `blur(10px)`;
                drawSegmentationMask(results.segmentationMask);
                context.globalCompositeOperation = "source-in";
                context.filter = "none";
            }

            context.drawImage(results.image, 0, 0, canvas.width, canvas.height);

            if (vb === "on") {
                blurBackground(dynamicImage, 0);
            }

            if (vb === "blur") {
                blurBackground(results.image, 20);
            }

            context.restore();
        }

        video.addEventListener('play', () => {
            cancelAnimationFrame(animationFrameId);
            if (!selfieSegmentation) {
                selfieSegmentation = new SelfieSegmentation({
                    locateFile: (file) =>
                        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
                });

                selfieSegmentation.setOptions({
                    modelSelection: 1,
                });

                selfieSegmentation.onResults(onResults);
                sendToMediaPipe();
            }
        });

        document.addEventListener("visibilitychange", visiblityChange);

        const outputStream = canvas.captureStream(200);
        return outputStream;

    } catch (error) {
        console.log(error);
    }
}

const sendToMediaPipe = async () => {
    cancelAnimationFrame(animationFrameId);
    if (!video?.height) {
        console.log(video?.height);
    } else {
        // console.log("IS ANIMATION FRAME!")
        await selfieSegmentation?.send({ image: video });
    }

    animationFrameId = requestAnimationFrame(sendToMediaPipe);
};

const sendToMediaPipeWorker = async () => {
    cancelAnimationFrame(animationFrameId);
    if (!video?.height) {
        console.log(video?.height);
    } else {
        // console.log("IS WORKER FRAME!")
        await selfieSegmentation?.send({ image: video });
    }
};

const visiblityChange = () => {
    if (document.visibilityState === "visible") {
        if (worker) {
            console.log("WORKER TERMINATED!")
            worker.terminate()
        }
        if (video?.width) {
            sendToMediaPipe()
        }
    } else {
        if (video?.width) {
            console.log("WORKER CONNECTED!")
            worker = new Worker(new URL(`${window.location.origin}${createRedirectPath('/js/virtualBackgroundWorker.js')}`), { name: 'virtual background worker' });
            worker.postMessage({ message: "start" })
            worker.onmessage = (event) => {
                if (event?.data?.message) {
                    sendToMediaPipeWorker();
                    worker.postMessage({ message: "start" })
                }
            }
        }
    }
}

function clearCanvas() {
    context.clearRect(0, 0, canvas.width, canvas.height);
}

function drawSegmentationMask(segmentation) {
    context.drawImage(segmentation, 0, 0, canvas.width, canvas.height);
}

function blurBackground(image, blurAmount) {
    context.globalCompositeOperation = "destination-over";
    context.filter = `blur(${blurAmount}px)`;
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
}

function endVirtualSegmentation() {
    try {
        if (mediaStream) {
            const tracks = mediaStream.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
            mediaStream = null;
        }
        document.removeEventListener("visibilitychange", visiblityChange);

        // Optionally, if you want to remove all references to the stream
        console.log("WORKER TERMINATED!")
        if (worker) worker.terminate();
        video = null;
        canvas = null;
        context = null;
        cancelAnimationFrame(animationFrameId);
        if (selfieSegmentation) {
            selfieSegmentation.close();
            selfieSegmentation = null;
        }
    } catch (error) {
        console.log(error)
    }
}

export {
    getVirtualBgScreen,
    endVirtualSegmentation,
}
