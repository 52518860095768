import React, { useEffect, useState } from 'react';
import { MenuItem, Menu, Typography, Box } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MicIcon from '@mui/icons-material/Mic';
import { useDispatch } from 'react-redux';
import { selectedSpeakerId } from '../../../../redux/actions/stateActions';
import Logger from '../../../../lib/Logger';

const logger = new Logger("DevicesMenu")

export default function DevicesMenu({ anchorElDevices, handleCloseDevices, type = "video", title = "", roomClient }) {
    const dispatch = useDispatch()
    const [devices, setDevices] = useState([]);
    const [selectedVideoCam, setSelectedVideoCam] = useState("");
    const [selectedMic, setSelectedMic] = useState("");
    const [selectedSpeaker, setSelectedSpeaker] = useState("");

    const handleDeviceChange = async (forceUpdate) => {
        try {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            setSelectedMic("");
            setSelectedSpeaker("");
            logger.debug("All audio video devices", mediaDevices)
            let filterDevice = mediaDevices.filter((item) => item.deviceId !== "")
            filterDevice = mediaDevices.filter((item) => item.label !== "")
            setDevices(filterDevice);
            if (forceUpdate) {
                const defaultVabCam = mediaDevices.filter(({ kind: kindFilter }) => kindFilter === "videoinput")[0]?.deviceId
                setSelectedVideoCam(defaultVabCam)
            }
        } catch (error) {
            logger.error("Audio video device is missing", error);
        }
    };

    const changeCamera = (deviceId) => {
        setSelectedVideoCam(deviceId)
        roomClient.changeWebcam(deviceId)
    }
    const changeMic = (deviceId) => {
        setSelectedMic(deviceId)
        roomClient.changeMic(deviceId)
    }

    const changeSpeaker = (deviceId) => {
        setSelectedSpeaker(deviceId);
        dispatch(selectedSpeakerId(deviceId))
    }

    useEffect(() => {
        navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);
        handleDeviceChange(true)

        return () => {
            navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
        };
    }, []);

    useEffect(() => {
        if (Boolean(anchorElDevices) && type === "video" && devices?.filter(({ kind }) => kind === "videoinput").length === 0) {
            handleDeviceChange()
        }
        if (Boolean(anchorElDevices) && type === "audio" && devices?.filter(({ kind }) => kind === "audioinput").length === 0) {
            handleDeviceChange()
        }
        // eslint-disable-next-line
    }, [anchorElDevices]);

    return (
        <Menu
            anchorEl={anchorElDevices}
            id="video-menu"
            open={Boolean(anchorElDevices)}
            onClose={handleCloseDevices}
            onClick={handleCloseDevices}
            PaperProps={{
                elevation: 0,
                sx: {
                    mt: -4.4,
                    mb: 3,
                    ml: type === "audio" ? -4 : 0,
                    width: '230px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: -10,
                        left: type === "audio" ? 38 : 110,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },

                }
            }}
            transformOrigin={{ horizontal: (type === "audio" ? 'left' : 'center'), vertical: 'bottom' }}
            anchorOrigin={{ horizontal: (type === "audio" ? 'left' : 'center'), vertical: "top" }}
        >
            <Box sx={type === "audio" ? {
                borderBottom: 1,
                borderColor: '#DADADA',
                pb: '10px',
                mb: '10px',
            } : {}}>
                <Typography sx={{
                    padding: '0 16px',
                    fontSize: '12px',
                    color: '#757575',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    py: '6px'
                }}>
                    {type === "video" ? <VideocamIcon sx={{ fontSize: '18px' }} />
                        :
                        <MicIcon sx={{ fontSize: '18px' }} />
                    } {title}
                </Typography>
                {devices.length > 0 && devices.map(({ kind, deviceId, label }, index) => (
                    <Box key={index}>
                        {(kind === (type === "video" ? "videoinput" : "audioinput") && (selectedMic === "" ? deviceId === "default" : selectedMic === deviceId)) &&
                            <MenuItem className={'checkMark'} sx={{
                                fontSize: 14,
                                paddingRight: '50px',
                                color: '#444444',
                                fontWeight: '600',
                                pl: '38px'
                            }}>{label.length > 20 ? `${label.slice(0, 20)}...` : label}</MenuItem>}
                        {(deviceId === selectedVideoCam && type === 'video') &&
                            <MenuItem className={'checkMark'} sx={{
                                fontSize: 14,
                                paddingRight: '50px',
                                color: '#444444',
                                fontWeight: '600',
                                pl: '38px'
                            }}>{label.length > 20 ? `${label.slice(0, 20)}...` : label}</MenuItem>}

                        {(kind === (type === "video" ? "videoinput" : "audioinput") && (selectedMic === "" ? deviceId !== "default" : selectedMic !== deviceId) && deviceId !== selectedVideoCam) &&
                            <MenuItem onClick={() => type === "video" ? changeCamera(deviceId) : changeMic(deviceId)} sx={{
                                fontSize: 14,
                                paddingRight: '50px',
                                color: '#757575',
                                pl: '38px'
                            }}>{label.length > 20 ? `${label.slice(0, 20)}...` : label}</MenuItem>}
                    </Box>
                ))}
            </Box>
            {type === "audio" &&
                <Box>
                    <Typography sx={{
                        padding: '0 16px',
                        fontSize: '12px',
                        color: '#757575',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        py: '6px'
                    }}>
                        <VolumeUpIcon sx={{
                            fontSize: '18px'
                        }} /> Speakers
                    </Typography>

                    {devices.length > 0 && devices.map(({ kind, deviceId, label }, index) => (
                        <Box key={index}>
                            {(kind === "audiooutput" && (selectedSpeaker === "" ? deviceId === "default" : deviceId === selectedSpeaker)) &&
                                <MenuItem className={'checkMark'} sx={{
                                    fontSize: 14,
                                    paddingRight: '50px',
                                    color: '#444444',
                                    fontWeight: '600',
                                    pl: '38px'
                                }}>{label.length > 20 ? `${label.slice(0, 20)}...` : label}</MenuItem>}

                            {(kind === "audiooutput" && (selectedSpeaker === "" ? deviceId !== "default" : selectedSpeaker !== deviceId)) &&
                                <MenuItem onClick={() => changeSpeaker(deviceId)} sx={{
                                    fontSize: 14,
                                    paddingRight: '50px',
                                    color: '#757575',
                                    pl: '38px'
                                }}>{label.length > 20 ? `${label.slice(0, 20)}...` : label}</MenuItem>}
                        </Box>
                    ))}
                </Box>
            }
        </Menu>
    )
}