import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, IconButton, MenuItem, Menu } from '@mui/material';
import { FixedSizeList } from 'react-window';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import GetStarted from './GetStarted';
import Signup from './Signup';
import Login from './Login';
import Forgot from './Forgot';
import JoinMeeting from './JoinMeeting';
import CreateQuickMeeting from './CreateQuickMeeting';
import InvitePeople from './InvitePeople';
import ScheduleMeeting from './ScheduleMeetings';
import MeetingControl from './MeetingCOntrol';
import AddOrRemove from './AddOrRemove';
import AddOrRemoveAllParticipants from './AddOrRemoveAllParticipants';
import CheckAllMeetings from './CheckAllMeetings';
import CheckAllScheduleMeetings from './CheckAllScheduleMeeting';
import EditAMeeting from './EditAMeeting';
import CancelAScheduleMeeting from './CancelAScheduleMeeting';
import RecordAMeeting from './RecordAMeeting';
import DownloadOrSaveMeeting from './DownloadOrSaveYourMeetings';
import EnableOrDisablePermisions from './EnableOrDisablePermissions';
import WhiteBoard from './WhiteBoard';
import ChatWithParticipants from './ChatWithParticipants';
import ShareYourScreen from './ShareYourScreen';
import ParticipantsInWaitingRoom from './ParticipantsInWaitingRoom';
import SetVirtualBackGround from './SetVirtualBackGround';
import RemoveVirtualBackGround from './RemoveVirtualBackGround';
import WhiteBoradToolBar from './WhitBoardToolBar';
import FooterMenu from '../../../layouts/mobile/FooterMenu';
import { capitalizeFirstLetter } from './components/util';
import { useSelector } from 'react-redux';

const linkStyles = {
    display: 'inline-block',
    marginRight: '10px',
    color: '#727374',
    cursor: 'pointer',
};

const filteredLinkItems = (isMobile, capitalizedCompanyBrandName) => {
    const items = [
        { id: 1, label: `Get Started With ${capitalizedCompanyBrandName}` },
        { id: 2, label: "SignUp Process" },
        { id: 3, label: "Login Process" },
        { id: 4, label: "Forgot Password" },
        { id: 5, label: "Join Meeting" },
        { id: 6, label: "Create a Quick Meeting" },
        { id: 7, label: "Invite People to a Meeting" },
        { id: 8, label: "Schedule Meetings" },
        { id: 9, label: "Meeting Controls" },
        { id: 10, label: "How to Chat with participants during a video call? " },
        { id: 11, label: "How to share your screen while in meeting?" },
        { id: 12, label: "Participants in the Waiting Room " },
        { id: 13, label: "Add or Remove participants From the Waiting Room" },
        { id: 14, label: "Add or Remove All Participants at Once from the Waiting Room" },
        { id: 15, label: "Check All Meeting" },
        { id: 16, label: "Check All Scheduled Meetings" },
        { id: 17, label: "Edit A Scheduled Meeting" },
        { id: 18, label: "Cancel a Scheduled Meeting" },
        { id: 19, label: "Record a Meeting" },
        { id: 20, label: "Download or Save Your Meetings/ Where to Find Recorded Meetings" },
        { id: 21, label: "Enable or Disable Permissions" },
        { id: 22, label: "Whiteboard" },
        { id: 23, label: "Whiteboard Tool Bar" },
        { id: 24, label: "How to set a virtual background during a meeting? " },
        { id: 25, label: "How to Remove a Virtual Background during a Meeting? " },
    ];
    
    if (isMobile) {
        return items.filter(item => ![11, 20, 24, 25, 22, 23].includes(item.id));
    }
    return items;
};

const RelatedLinksDropdown = ({ linkItems, onSelect }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLinkClick = (linkId) => {
        handleCloseMenu();
        onSelect(linkId);
    };

    return (
        <>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                border: '1px solid',
                borderColor: 'inherit',
                borderRadius: '8px',
                position: 'fixed',
                right: '10px',
                top: '5px',
                zIndex: 1000,
                padding: '5px'
            }}>
                <IconButton
                    aria-controls="related-links-menu"
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                    color="grey.500"
                >
                    <MenuOpenIcon />
                </IconButton>
            </Box>
            <Menu
                id="related-links-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: '80vw',
                        width: 'auto',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        overflowY: 'auto',
                    },
                }}
            >
                {linkItems.map((item) => (
                    <MenuItem key={item.id} onClick={() => handleLinkClick(item.id)}
                        sx={{
                            color: '#727374',
                            cursor: 'pointer',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};


const RelatedLinksGrid = ({ onSelect, linkItems }) => {
    const [selectedLink, setSelectedLink] = useState(1);

    const handleLinkClick = (linkId) => {
        setSelectedLink(linkId);
        onSelect(linkId);
    };

    const totalHeight = linkItems.length * 46;

    const renderRow = ({ index, style }) => {
        const item = linkItems[index];
        const isSelected = selectedLink === item.id;
        const itemStyle = {
            ...style,
            background: isSelected ? '#f0f0f0' : 'none',
        };

        return (
            <Box style={itemStyle} key={item.id}>
                <Typography
                    onClick={() => handleLinkClick(item.id)}
                    sx={{
                        ...linkStyles,
                        cursor: 'pointer',
                        padding: '10px',
                        color: isSelected ? "grey.200" : "grey.400"
                    }}
                >
                    {item.label}
                </Typography>
            </Box>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <FixedSizeList
                height={totalHeight}
                itemSize={46}
                itemCount={linkItems.length}
                overscanCount={5}
            >
                {renderRow}
            </FixedSizeList>
        </Box>
    );
};

const componentsMap = {
    1: <GetStarted />,
    2: <Signup />,
    3: <Login />,
    4: <Forgot />,
    5: <JoinMeeting />,
    6: <CreateQuickMeeting />,
    7: <InvitePeople />,
    8: <ScheduleMeeting />,
    9: <MeetingControl />,
    10: <ChatWithParticipants />,
    11: <ShareYourScreen />,
    12: <ParticipantsInWaitingRoom />,
    13: <AddOrRemove />,
    14: <AddOrRemoveAllParticipants />,
    15: <CheckAllMeetings />,
    16: <CheckAllScheduleMeetings />,
    17: <EditAMeeting />,
    18: <CancelAScheduleMeeting />,
    19: <RecordAMeeting />,
    20: <DownloadOrSaveMeeting />,
    21: <EnableOrDisablePermisions />,
    22: <WhiteBoard />,
    23: <WhiteBoradToolBar />,
    24: <SetVirtualBackGround />,
    25: <RemoveVirtualBackGround />
}

export default function Help() {
    const [selectedLinkId, setSelectedLinkId] = useState(1);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { branding = {} } = useSelector(state => state?.branding);
    const { companyBrandName = "" } = branding || {};
    const capitalizedCompanyBrandName = capitalizeFirstLetter(companyBrandName);


    const handleLinkSelect = (linkId) => {
        setSelectedLinkId(linkId);
    };

    const linkItems = filteredLinkItems(isMobile, capitalizedCompanyBrandName);  

    return (
        <Box sx={{
            padding: isMobile ? '50px 10px 10px 10px' : '108px 10px 10px 10px',
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '30px',
            paddingBottom: isMobile ? '70px' : '40px',
        }}>
            {isMobile && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '10px 20px',
                    position: 'fixed',
                    top: '0px',
                    left: '0',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    textAlign: 'center',
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Typography variant="h3" sx={{ color: 'black' }}>
                            FAQ
                        </Typography>
                        <RelatedLinksDropdown
                            linkItems={linkItems}
                            onSelect={handleLinkSelect}
                        />
                    </Box>
                </Box>
            )}

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : 'calc(100% / 1.9)',
                gap: '20px',
                pl: isMobile ? '0px' : '60px',
                paddingTop: isMobile ? '60px' : '0',
                overflowY: 'auto',
            }}>
                {selectedLinkId && componentsMap[selectedLinkId]}
            </Box>

            {!isMobile && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'calc(100% - 100% / 1.9)',
                    pl: '50px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        pl: '50px',
                        justifyContent: 'flex-end'
                    }}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: 'grey.200',
                                width: '40%'
                            }}
                        >
                            Related Links:
                        </Typography>
                        <RelatedLinksGrid onSelect={handleLinkSelect} linkItems={linkItems} />
                    </Box>
                </Box>
            )}
            {isMobile && <FooterMenu />}
        </Box>
    );
}
