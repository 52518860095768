import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from '../appPropTypes';
import { withRoomContext } from '../../../../context/RoomContext';
import * as stateActions from '../../../../redux/actions/stateActions';
import PeerView from './PeerView';
// import Logger from '../../../config/Logger';

// const logger = new Logger("Peer");

const Peer = (props) => {
	const {
		isPined,
		peer,
		audioConsumer,
		videoConsumer,
		audioMuted,
		peerCount,
		pipePeerRole,
		dimensions,
		roomId,
		sx12,
		focusedSX,
		focusCount,
		showFocusSide,
		role,
		isFocused,
		speaker
	} = props;

	const audioEnabled = (Boolean(audioConsumer) && !audioConsumer.locallyPaused && !audioConsumer.remotelyPaused);
	const videoVisible = (Boolean(videoConsumer) && !videoConsumer.locallyPaused && !videoConsumer.remotelyPaused);
	return (
		<>
			{
				pipePeerRole !== "attendee" && <PeerView
					peer={peer}
					isPined={isPined}
					isFocused={isFocused}
					audioEnabled={audioEnabled}
					videoRtpParameters={videoConsumer ? videoConsumer.rtpParameters : null}
					audioTrack={audioConsumer ? audioConsumer.track : null}
					videoTrack={videoConsumer ? videoConsumer.track : null}
					audioMuted={audioMuted}
					videoVisible={videoVisible}
					audioCodec={audioConsumer ? audioConsumer.codec : null}
					videoCodec={videoConsumer ? videoConsumer.codec : null}
					audioScore={audioConsumer ? audioConsumer.score : null}
					videoScore={videoConsumer ? videoConsumer.score : null}
					peerCount={peerCount}
					dimensions={dimensions}
					roomId={roomId}
					focusedSX={focusedSX}
					sx12={sx12}
					focusCount={focusCount}
					showFocusSide={showFocusSide}
					role={role}
					speaker={speaker}
				/>
			}
		</>
	);
};

Peer.propTypes =
{
	roomClient: PropTypes.any.isRequired,
	peer: appPropTypes.Peer.isRequired,
	audioConsumer: appPropTypes.Consumer,
	videoConsumer: appPropTypes.Consumer,
	audioMuted: PropTypes.bool,
	onSetStatsPeerId: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { id, peerCount }) => {
	const me = state.me;
	const peer = state.peers[id];
	const consumersArray = peer.consumers.map((consumerId) => state.consumers[consumerId]).filter((item) => item !== undefined);
	const audioConsumer = consumersArray.find((consumer) => consumer.track.kind === 'audio');
	const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === 'video' && consumer?.type !== "share");
	const pipePeerRole = /* peer?.pipePeerRole || */ peer?.role  || consumersArray[0]?.role || false;
	const speaker = state.speaker || "";

	// if (pipePeerRole === "attendee") {// if(!pipePeers)
	// 	peerCount = peerCount - 1;
	// }

	// logger.debug("peer.jsx peer on mapStateToProps", speaker);

	return {
		peer,
		isPined: peer?.isPined || false,
		isFocused: peer?.isFocused || false,
		audioConsumer,
		videoConsumer,
		audioMuted: me.audioMuted,
		pipePeerRole,
		peerCount,
		speaker
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetStatsPeerId: (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId))
	};
};

const PeerContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Peer));

export default memo(PeerContainer);