

import React, { useContext } from 'react';
import { Typography, Modal, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { configFile } from '../../../../config';
import { endRoom } from '../../../../services/commonApis';
import RoomContext from '../../../../context/RoomContext';

const MainSection = styled('div')(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 385,
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    borderRadius: '10px',
    padding: "20px",
    "&:focus": {
        outline: "none"
    }
}));

const MainSectionInner = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '10px',
    gap: '15px'
}));

const ActionSection = styled('div')(() => ({
    paddingTop: "18px",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
    gap: '14px'
}));

const Heading = styled(Typography)(() => ({
    color: '#454545',
    fontSize: '20px',
    fontWeight: 700
}));

const SubHeading = styled(Typography)(() => ({
    color: '#757575',
    fontSize: '14px',
    fontWeight: 500
}));

const ActionButton = styled(Button)(() => ({
    '&:hover': {
        backgroundColor: 'transparent'
    }
}));

const EndCounterSection = styled('div')(() => ({
    display: 'flex',
    width: '42px',
    height: '42px',
    backgroundColor: "#e8f1fd",
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
}));

const CounterHeading = styled(Typography)(() => ({
    color: '#577ba5',
    fontSize: '16px',
    fontWeight: 700
}));

const EndReminderModal = ({ open, close, peersCount }) => {
    const { roomAutoClose: { autoCloseTime } } = configFile;
    const [counter, setCounter] = useState(autoCloseTime);
    const { _roomId: roomId } = useContext(RoomContext);
    
    const handleLeaveRoom = () => {
        endRoom(roomId, peersCount)
    }

    useEffect(() => {
        let intervalId = "";
        if (open) {
            let count = autoCloseTime;

            intervalId = setInterval(() => {
                if (count > 0 && open) {
                    count = count - 1
                    setCounter(count)
                } else {
                    //redirect to main page.
                    handleLeaveRoom()
                }

            }, 1000);
        }

        if (peersCount) {
            setCounter(autoCloseTime)
            clearInterval(intervalId);
            return close();
        }

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [open, peersCount])
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MainSection>
                <MainSectionInner>
                    <EndCounterSection>
                        <CounterHeading>
                            {counter}
                        </CounterHeading>
                    </EndCounterSection>

                    <Heading>
                        Are you still there?
                    </Heading>

                    <SubHeading>
                        You've are the only one here, so this call will end in {autoCloseTime / 60} minutes. do you want to stay in this call?
                    </SubHeading>

                    <ActionSection >
                        <ActionButton onClick={() => handleLeaveRoom()}>
                            Leave now
                        </ActionButton>

                        <ActionButton onClick={() => close()}>
                            Stay in the call
                        </ActionButton>
                    </ActionSection>
                </MainSectionInner>
            </MainSection>
        </Modal>
    )
}

export default EndReminderModal;