import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const ShareYourScreen = () => {
  return (
    <>
      <Heading
        text='How to share your screen while in meeting? '
      />

      <ImageComp
        name={"shareScreen"}

      />

      <ImageComp
        name={"shareYourScreen"}

      />

      <ListComp

        data={[

          {
            name: 'In the meeting control bar, look for screen share button.',
            id: '13',

          },
          {
            name: 'Clicking on it will open a pop up.',
            id: '14',

          }, {
            name: 'Here you need to select what you want to share in the meeting. ',
            id: '15',

          }, {
            name: 'Once you have decided that, click on the share button.',
            id: '16',


          },

        ]}

      />
    </>
  );
};

export default ShareYourScreen;
