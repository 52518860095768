import { Typography, TextField, Box, Container, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jwt-decode';
import { getItem } from '../../../lib/cookiesManager';
import RateReviewIcon from '@mui/icons-material/RateReview';

export default function MobileProfile() {
    const [profileInfo, setProfileInfo] = useState({})

    useEffect(() => {
        let token = getItem('token');
        let info = jwt(token);
        setProfileInfo(info);
        // eslint-disable-next-line
    }, [])

    return (
        <Box className='MobileWraper'>
            <ToastContainer />
            <Container maxWidth="xl" className='ProfileContainer'>
                <Box sx={{ p: 1, }} className='ProfileGlobalBox'>
                    <Box sx={{
                        flexDirection: 'row',
                        pt: 3,
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }} className='ProfileGlobalBox'>
                        <IconButton sx={{ width: '28px', height: '28px', color: '#1976d2' }} className='ProfileIconBtn'>
                            <RateReviewIcon />
                        </IconButton>
                        <Typography className='ProfileTitle' sx={{
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '18px',
                            color: '#141919',
                            ml: 1
                        }}>
                            Profile Info
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', flexDirection: "column", gap: '15px', pt: 3 }}>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: "column", gap: '5px' }}>
                            <Typography className='ProfileSubTitle' sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '18px',
                                color: '#757575',
                            }}>
                                Name
                            </Typography>

                            <TextField
                                id="outlined-read-only-input"
                                // label="Name"
                                InputProps={{
                                    readOnly: true,
                                }}
                                // defaultValue={profileInfo?.name || ""}
                                placeholder={profileInfo?.name || ""}
                                value={profileInfo?.name || ""}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: "#e0e0e0",
                                            borderRadius: '10px'
                                        }
                                    },

                                }}
                                focused
                            />
                        </Box>

                        <Box sx={{ display: 'flex', width: '100%', flexDirection: "column", gap: '5px' }}>
                        <Typography className='ProfileSubTitle' sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#757575',
                        }}>
                            Email
                        </Typography>
                        <TextField
                            id="outlined-read-only-input"
                            // label="Email"
                            // defaultValue={profileInfo?.email || ""}
                            placeholder={profileInfo?.email || ""}
                            value={profileInfo?.email || ""}
                            // focused
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: "#e0e0e0",
                                        borderRadius: '10px'
                                    }
                                }
                            }}
                            focused
                        />
                        </Box>

                        <Box sx={{ display: 'flex', width: '100%', flexDirection: "column", gap: '5px' }}>
                        <Typography className='ProfileSubTitle' sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '18px',
                            color: '#757575',
                        }}>
                            Mobile
                        </Typography>
                        <TextField
                            id="outlined-read-only-input"
                            // label="Mobile"
                            // defaultValue={profileInfo?.mobile || ""}
                            InputProps={{
                                readOnly: true,
                            }}
                            placeholder={profileInfo?.mobile}
                            value={profileInfo?.mobile || "N/A"}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '&.Mui-focused fieldset': {
                                        borderColor: "#e0e0e0",
                                        borderRadius: '10px'
                                    }
                                }
                            }}
                            focused
                        />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
