const initState = {
    chat: null,
    newMsg: false
};

const publicChat = (state = initState, action) => {
    switch (action.type) {
        case 'PUBLIC_CHAT':
            state = {
                ...state,
                chat: JSON.parse(action?.payload?.data),
                newMsg: true,
            }
            break;
        case 'REMOVE_NEW_PRIVATE_CHAT':
            state = {
                ...state,
                chat: null,
                newMsg: false,
            }
            break;
        default:
            break;
    }
    return state;
}

export default publicChat;