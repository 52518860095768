import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';

export default function EndWebianr() {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.hash = "no-back-button";

        // Again because Google Chrome doesn't insert
        // the first hash into the history
        window.location.hash = "Again-No-back-button";

        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
        }}>
            <Box sx={{
                backgroundColor: 'card.main',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '423px',
                width: '523px',
                flexDirection: 'column',
                borderRadius: '10px'
            }}>
                <MeetLogoSvg width='120px' />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '25px',
                    mb: 2,
                    mt: 4,
                    color: "grey.70"
                }}>
                    Thank You for Joining the Meeting.
                </Typography>
                <Typography sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: "grey.400",
                    mb: 3
                }}>
                    You can manage your meeting sessions by Login
                </Typography>


                <Button variant="contained" color='primary' size='large' sx={{
                    mt: 2,
                    borderRadius: '10px',
                    textTransform: 'none',
                    width: '55%',
                }}
                    onClick={() => navigate('/login')}
                >Login</Button>
            </Box>

        </Grid>
    )
}
