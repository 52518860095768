import React from 'react';
import { Typography, Box } from '@mui/material';
import Heading from './components/heading';
import ListComp from './components/list';
import ImageComp from './components/imageComp';
import Pragraph from './components/pragraph';

const CheckAllMeetings = () => {
  const dynamicUrl = window.location.origin;

  return (
    <>
      <Heading
        text='Check All Meeting '
      />
      <Box sx={{ width: '80%' }}>
        <Pragraph
          text='To check all meetings                    '
        />
      </Box>

      <ListComp
        data={[{
          name: <Typography>
            Visit: <a target="_blank" style={{ color: 'blue' }} >{dynamicUrl}</a> and log in to your account.


          </Typography>,
          id: '12',
        },
        {
          name: 'In the header menu, click the ‘Meeting.’',
          id: '13'
        },
        {
          name: 'This will open a new page containing all the meetings you attended/ hosted and will attend in the future.  ',
          id: '14'
        },
        {
          name: 'In the ‘Action’ column, you will see the ‘View Details’ button. ',
          id: '15'
        },
        {
          name: 'Click on it to have a full detail of the meeting.',
          id: '16'
        },

        ]}

      />
      <ImageComp
        name={"checkAllMeeting"}

      />

    </>
  );

};

export default CheckAllMeetings;


