import React, { useEffect, useState } from 'react';
import ScreenShareView from './ScreenShareView'
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import AudioOnlyPeer from '../AudioOnly/AudioOnlyPeer';
import randomString from 'random-string';
import { axiosPostCall } from '../../../../services/apisCall';
import Logger from '../../../../lib/Logger';
import { renderErrorMessage } from '../../../ToastNotification';

const logger = new Logger('screenShare');

const ScreenShare =  ({ roomId, peerId }) => {
    const producersArray = useSelector(state => state.producers);
    const pinedShare = useSelector(state => state.pinFocus);
    const consumers = useSelector(state => state.consumers);
    const peers = useSelector(state => state.peers);
    const isLandscape = useSelector(state => state.deviceInfo?.isLandscape);
    const [sShareArr, setSShareArr] = useState([]);
    const [sShareArrMain, setSShareArrMain] = useState([]);
    const [xSMain, setXSMain] = useState(10);

    const pinScreen = async (roomId, peerId) => {
        try {
            await axiosPostCall("pinScreen", { roomId, peerId, action: 'add', type: 'pinShareScreen' });
        } catch (error) {
            logger.error("@ScreenSahre.jsx  pinScreen",error)
            renderErrorMessage(error.message)
        }
    };

    useEffect(() => {
        let producers = Object.values(producersArray);
        const videoProducer = producers.find((producer) => producer.track.kind === 'video' && producer?.type === "share");
        let checkByMe = videoProducer ? true : null;

        // eslint-disable-next-line
        let checkShare = Object.values(consumers).map(({ id, type, track, rtpParameters, locallyPaused = false, remotelyPaused = true }) => {
            if (type === "share") {
                const videoVisible = !locallyPaused && !remotelyPaused;
                // if(!videoVisible) return;
                let nameObj = Object.values(peers).filter(({ consumers }) => consumers.includes(id));
                let displayName = nameObj[0]?.displayName;
                return { type, track, rtpParameters, displayName, peerId: nameObj[0]?.id, videoVisible };
            }
            // return;
        }).filter(notUndefined => notUndefined !== undefined);

        let concatArr = videoProducer ? [...checkShare, { ...videoProducer, byMe: checkByMe, peerId }] : checkShare;
        if (concatArr.length === 1) {
            setXSMain(12);
            if (checkByMe && !pinedShare?.sharePin?.length) {
                pinScreen(roomId,peerId)
            }
        } else {
            setXSMain(10);
        }

        if (pinedShare?.sharePin?.length) {
            let updatePined = pinedShare?.sharePin.map((item) => {
                return concatArr.find(({ peerId }) => peerId === item);
            }).filter(notUndefined => notUndefined !== undefined);
            setSShareArrMain(updatePined.slice(0, 4));

            let sideShare = concatArr.filter((item) => {
                let check = pinedShare?.sharePin.includes(item.peerId);
                if (!check) {
                    return item
                }
                return null;
            })

            if (sideShare.length) {
                setSShareArr(sideShare);
                return;
            }
            setXSMain(12);
        }

    }, [producersArray, consumers, peers, pinedShare, roomId, peerId])

    return (
        <>
            {/* Screens Row */}
            <Box className='ScreensRow' sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                {sShareArrMain?.length > 0 &&
                    sShareArrMain.slice(0, 2).map((item, index, arr) => (
                        <ScreenShareView
                            key={index}
                            videoTrack={item.track}
                            byMe={item?.byMe}
                            displayName={item?.displayName}
                            showUnpin={sShareArrMain?.length > 1 ? true : false}
                            peerId={item?.peerId}
                            roomId={roomId}
                            shareSx={{
                                position: 'relative',
                                backgroundImage: 'url(./images/07.png)',
                                backgroundSize: 'contain',
                                height: isLandscape ? '100%' : (sShareArrMain.length > 1 ? 'calc(100% / 2)' : 'calc(100% / 1)'),
                                backgroundPosition: 'center center',
                                width: isLandscape ? (sShareArrMain.length > 1 ? 'calc(100% / 2)' : 'calc(100% / 1)') : '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: '#757575',
                                border: 1,
                                borderColor: '#151515',
                                boxSizing: 'border-box',
                            }}
                        />
                    ))}
            </Box>

            {/* FocusBar */}
            {(sShareArr?.length > 0 && xSMain === 10) &&
                <Box className='FocusBar' sx={{
                    display: 'flex',
                    flexDirection: isLandscape ? "column" : "row",
                    width: isLandscape ? '123px' : 'calc(100% - 6px)',
                    height: isLandscape ? 'calc(100% - 6px)' : '123px',
                    // width: 'calc(100% - 6px)',
                    // height: '123px',
                    alignItems: 'center',
                    background: '#444444',
                    gap: '3px',
                    padding: '3px'
                }}>
                    {(sShareArr?.length > 0 && xSMain === 10) &&
                        sShareArr.slice(0, sShareArr.length === 3 ? 3 : 2).map((item, index) => (
                            <ScreenShareView
                                key={index}
                                videoTrack={item.track}
                                displayName={item?.displayName}
                                peerId={item?.peerId}
                                roomId={roomId}
                                showName={true}
                                showPin={sShareArrMain?.length > 1 ? false : true}
                                shareSx={{
                                    backgroundImage: 'url(./images/07.png)',
                                    backgroundSize: 'contain',
                                    height: isLandscape ? 'calc(100% / 3)' : '100%',
                                    backgroundPosition: 'center center',
                                    width: isLandscape ? "100%" : 'calc(100% / 3)',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#757575',
                                    border: '1px solid #444444'
                                }}
                            />
                        ))}

                    {sShareArr?.length > 3 &&
                        <Box sx={{
                            height: isLandscape ? 'calc(100% / 3)' : '100%',
                            backgroundPosition: 'center center',
                            width: isLandscape ? "100%" : 'calc(100% / 3)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#757575',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px',
                            color: 'rgb(255,255,255,.7)'
                        }}>
                            +{sShareArr.length - 2} More
                        </Box>
                    }
                </Box>
            }

            {Object.values(peers).map((peer) => {
                return (<AudioOnlyPeer key={randomString({ length: 8 }).toLowerCase()} id={peer.id} />);
            })}
        </>
    )
}

export default ScreenShare;