import React, { memo } from 'react';
import Logger from '../../../../lib/Logger';
import { connect } from 'react-redux';
import { Box, IconButton } from '@mui/material';
import { withRoomContext } from '../../../../context/RoomContext';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import hark from 'hark';
import DisplayName from './DisplayName';
import PeerTopBar from './PeerTopBar';
import UserAvatar from './UserAvatar';
import { renderErrorMessage } from '../../../ToastNotification';
import { axiosPostCall } from '../../../../services/apisCall';

const logger = new Logger('MobilePeerView');

class MobilePeerView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoResolutionWidth: null,
            videoResolutionHeight: null,
            videoCanPlay: false,
            videoElemPaused: false,
            maxSpatialLayer: null,
            displayHeight: 0,
            peer_sx: {
                bgcolor: 'text.disabled',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                height: '100%',
                borderRadius: '10px',
                alignItems: 'stretch',
            },
            networkS: 1,
            // showPFIcons: false,
            updateUnPin: {},
            disableFrameCheck: false,
            stream: false
        };

        // Latest received video track.
        // @type {MediaStreamTrack}
        this._audioTrack = null;

        // Latest received video track.
        // @type {MediaStreamTrack}
        this._videoTrack = null;

        // Periodic timer for reading video resolution.
        this._videoResolutionPeriodicTimer = null;

        // requestAnimationFrame for face detection.
        this._faceDetectionRequestAnimationFrame = null;

        this.speakerId = ""
    }

    updateFocus = async (peerId, action, type) => {
        try {
            await axiosPostCall("pinScreen",{roomId: this.props.roomId ,peerId,action,type})
        } catch (error) {
            logger.error("@PeerView.jsx updateFocus",error);
            renderErrorMessage(error.message)
        }
    }

    handleMouseEnterEvent = () => {
        if (this.state.disableFrameCheck) return;
        this.setState({ updateUnPin: { display: "flex" }, disableFrameCheck: true })
        setTimeout(() => {
            this.setState({ updateUnPin: { display: "none" }, disableFrameCheck: false })
        }, 2000)
        return
    }

    render() {
        const {
            isMe,
            peer,
            audioMuted,
            videoVisible,
            peerCount,
            audioEnabled,
            // checkShare,
            // sx12,
            focusCount,
            showFocusSide,
            role,
            micState = false,
            isPined,
            isFocused,
            focused = false,
            focusPeerDevide = false,
            isLandscape,
            videoScore
        } = this.props;

        const { videoCanPlay, videoElemPaused, stream, /* showPFIcons */ } = this.state;

        if (isPined) {
            peer.isPined = true
        }

        if (isFocused) {
            peer.isFocused = true
        }

        return (
            <>
                {/* Screens Row */}
                <Box className='VideoScreen' sx={{
                    position: 'relative',
                    backgroundImage: 'url(./images/07.png)',
                    backgroundSize: 'contain',
                    height: isLandscape ?
                        (focusPeerDevide ? `calc(100% ${focusCount > 3 ? "/ 2" : ""})` : focused ? "calc(100%/ 3)" : `calc(100% ${peerCount > 3 ? "/ 2" : ""})`)
                        : (focusPeerDevide ? `calc(100% ${focusCount > 4 ? "/ 3" : focusCount === 1 ? "" : "/ 2"})` : focused ? "calc(100%)" : `calc(100% ${peerCount > 4 ? "/ 3" : peerCount === 1 ? "" : "/ 2"})`),
                    backgroundPosition: 'center center',
                    width: isLandscape ?
                        (focused ? '100%' : (peerCount === 1) ? '100%' : peerCount === 2 ? '50%' : 'calc(100% / 3)')
                        : (focused ? 'calc(100% / 3)' : (peerCount === 1 || peerCount === 2) ? '100%' : '50%'),
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#27292B',
                    border: 1,
                    borderColor: '#151515',
                    boxSizing: 'border-box',
                }} onClick={() => this.handleMouseEnterEvent() /* {
                    this.setState({ showPFIcons: true })
                    let timeOut = setTimeout(() => {
                        this.setState({ showPFIcons: false })
                    }, 2000);
                    return () => clearTimeout(timeOut)
                    } */}>
                    <video
                        ref='videoElem'
                        width={'100%'}
                        height={'100%'}
                        className={`${((!videoVisible || !videoCanPlay)) ? 'hideVideo' : 'show'} mobilePeerViewVideo`}
                        style={{ transform: 'scaleX(-1)' }}
                        autoPlay
                        playsInline
                        muted
                        controls={false}
                    />

                    <audio
                        ref='audioElem'
                        autoPlay
                        playsInline
                        muted={audioMuted}
                        controls={false}
                    />

                    {videoVisible && videoScore < 5 &&
                        <UserAvatar />
                    }

                    {((!videoVisible || !videoCanPlay)) &&
                        <UserAvatar />
                    }

                    {videoElemPaused &&
                        <UserAvatar />
                        // <div className='video-elem-paused' />
                    }

                    {/* Show display of user */}
                    <DisplayName displayName={peer?.displayName} focused={focused} />

                    {/* Show pined icon and audio status */}
                    <PeerTopBar
                        isPined={peer?.isPined}
                        audioEnabled={audioEnabled}
                        micState={micState}
                        stream={stream}
                        focused={focused}
                    />

                    {/* Pin and focus icons */}
                    {(role !== 'consumer') && <Box sx={{
                        borderRadius: '20px',
                        display: 'none',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 'calc(50% - 14px)',
                        left: 'calc(50% - 40px)',
                        ...this.state.updateUnPin
                    }} className='pin'>
                        <IconButton onClick={() => peer?.isPined ? this.updateFocus(peer.id, 'remove', 'pinScreen') : this.updateFocus(peer.id, 'add', 'pinScreen')} sx={{
                            bgcolor: peer?.isPined ? 'rgba(33, 150, 243, 1)' : 'rgba(0,0,0,0.5)',
                            color: peer?.isPined ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',
                            fontSize: isMe ? '16px' : '24px',
                            mr: 0.5,
                            '&:hover': {
                                bgcolor: peer?.isPined ? 'rgba(33, 150, 243, 1) !important' : 'rgba(0,0,0,0.5)',
                                color: peer?.isPined ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)',
                            }
                        }} >
                            <PushPinOutlinedIcon fontSize='10%' />
                        </IconButton>
                        {!showFocusSide &&
                            <IconButton onClick={() => peer?.isFocused ? this.updateFocus(peer.id, 'remove', 'focusScreen') : this.updateFocus(peer?.id, 'add', 'focusScreen')} sx={{
                                bgcolor: peer?.isFocused ? 'rgba(33, 150, 243, 1)' : 'rgba(0,0,0,0.5)',
                                color: peer?.isFocused ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.5)',
                                fontSize: isMe ? '16px' : '24px',
                                ml: 0.5,
                                '&:hover': {
                                    bgcolor: peer?.isFocused ? 'rgba(33, 150, 243, 1) !important' : 'rgba(0,0,0,0.5)',
                                    color: peer?.isFocused ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,1)',
                                }
                            }} >
                                <CenterFocusStrongIcon fontSize='10%' />
                            </IconButton>
                        }
                    </Box>}
                </Box>
            </>

        );
    }

    componentDidMount() {
        const { audioTrack, videoTrack, speaker } = this.props;

        this._setTracks(audioTrack, videoTrack, speaker);
    }

    componentWillUnmount() {
        clearInterval(this._videoResolutionPeriodicTimer);
        cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

        const { videoElem } = this.refs;

        if (videoElem) {
            videoElem.oncanplay = null;
            videoElem.onplay = null;
            videoElem.onpause = null;
        }
    }

    UNSAFE_componentWillUpdate() {
        const {
            isMe,
            audioTrack,
            videoTrack,
            videoRtpParameters,
            speaker
        } = this.props;
        const { maxSpatialLayer } = this.state;

        if (isMe && videoRtpParameters && maxSpatialLayer === null) {
            this.setState(
                {
                    maxSpatialLayer: videoRtpParameters.encodings.length - 1
                });
        }
        else if (isMe && !videoRtpParameters && maxSpatialLayer !== null) {
            this.setState({ maxSpatialLayer: null });
        }

        this._setTracks(audioTrack, videoTrack, speaker);
    }

    async _setTracks(audioTrack, videoTrack, speakerId) {
        if (this._audioTrack === audioTrack && this._videoTrack === videoTrack && this.speakerId === speakerId?.speaker)
            return;
        if (this._audioTrack === audioTrack && this._videoTrack === videoTrack && this.speakerId !== speakerId?.speaker) {
            if (speakerId?.speaker) {
                logger.debug('audioTrack speaker change root:%o', speakerId?.speaker)
                const { audioElem } = this.refs;
                await audioElem.setSinkId(speakerId?.speaker);
                this.speakerId = speakerId?.speaker;
            }
            return;
        }

        this._audioTrack = audioTrack;
        this._videoTrack = videoTrack;

        this._stopVideoResolution();

        const { audioElem, videoElem } = this.refs;

        if (audioTrack) {
            logger.debug('audioTrack speaker:%o', speakerId, audioTrack)
            const stream = new MediaStream();

            stream.addTrack(audioTrack);
            audioElem.srcObject = stream;

            if (speakerId?.speaker) {
                logger.debug('audioTrack speaker change:%o', speakerId.speaker)
                await audioElem.setSinkId(speakerId.speaker);
                this.speakerId = speakerId?.speaker;
            }

            audioElem.play()
                .catch((error) => logger.warn('audioElem.play() failed:%o', error));

            // this._runHark(stream);
            this.setState({ stream })

            // this._runHark(stream);
        } else {
            audioElem.srcObject = null;
        }

        if (videoTrack) {
            const stream = new MediaStream();

            stream.addTrack(videoTrack);
            videoElem.srcObject = stream;

            videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

            videoElem.onplay = () => {
                this.setState({ videoElemPaused: false });

                audioElem.play()
                    .catch((error) => logger.warn('audioElem.play() With video failed:%o', error));
            };

            videoElem.onpause = () => this.setState({ videoElemPaused: true });

            videoElem.play()
                .catch((error) => logger.warn('videoElem.play() failed:%o', error));

            this._startVideoResolution();
        }
        else {
            videoElem.srcObject = null;
        }
    }

    _startVideoResolution() {
        this._videoResolutionPeriodicTimer = setInterval(() => {
            const { videoResolutionWidth, videoResolutionHeight } = this.state;
            const { videoElem } = this.refs;

            if (
                videoElem?.videoWidth !== videoResolutionWidth ||
                videoElem?.videoHeight !== videoResolutionHeight
            ) {
                this.setState(
                    {
                        videoResolutionWidth: videoElem?.videoWidth,
                        videoResolutionHeight: videoElem?.videoHeight
                    });
            }
        }, 500);
    }

    _stopVideoResolution() {
        clearInterval(this._videoResolutionPeriodicTimer);

        this.setState(
            {
                videoResolutionWidth: null,
                videoResolutionHeight: null
            });
    }

    _runHark(stream) {
        if (!stream.getAudioTracks()[0])
            throw new Error('_runHark() | given stream has no audio track');

        this._hark = hark(stream, { play: false });

        // eslint-disable-next-line no-unused-vars
        this._hark.on('volume_change', (dBs, threshold) => {
            // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
            //   Math.pow(10, dBs / 20)
            // However it does not produce a visually useful output, so let exagerate
            // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
            // minimize component renderings.
            let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

            if (audioVolume === 1)
                audioVolume = 0;

            if (audioVolume !== this.state.audioVolume)
                this.setState({ audioVolume });
        });
    }

}

const mapStateToProps = (state) => {
    const producersArray = Object.values(state.producers);
    const screenShareProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type === "share");
    const checkShare = Object.values(state.consumers).find(({ type }) => type === "share");
    const isLandscape = state.deviceInfo?.isLandscape || false
    return {
        checkShare: checkShare ? true : (screenShareProducer ? true : null),
        isLandscape,
    };
};

const PeerViewContainer = withRoomContext(connect(
    mapStateToProps,
)(MobilePeerView));

export default memo(PeerViewContainer)