import { Box, Typography } from '@mui/material';
import { configFile } from '../../../config'; 

const { imagesUrl } = configFile;

const LoaderWithMessage = ({ message }) => (
    <Box className="loader" sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems:'center',
        justifyContent: 'center',
        mt: '10px',
        maxWidth: '402px'
    }}>
        <img src={imagesUrl.loading} alt="Loading..." width={'30px'} /> 
        <Typography className="message" sx={{color: 'grey.200',}}>{message}</Typography>
    </Box>
);

export default LoaderWithMessage;
