import React from 'react';
import { Snackbar, IconButton,Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

export default function CustomSnackbar({ open, close, message, icon = true, customAction, duration,  sx = {} }) {
    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => close()}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    )

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={() => close()}
                message={message || ""}
                key={'topcenter'}
                action={icon && !customAction ? action : customAction}
                autoHideDuration={duration || 10000}
                TransitionComponent={TransitionDown}
                ContentProps={{
                    sx: sx
                }}
            />
        </div>
    );
}
