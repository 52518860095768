// import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles';

import StartWebinarButton from '../../actionButtons/startWebinar';
import DeleteWebinarButton from '../../actionButtons/deleteWebinar';
import EditWebinarButton from '../../actionButtons/editWebinar';
import ViewWebinarButton from '../../actionButtons/viewWebinar';

const WebinarActionsBox = styled(Box)(() => ({
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   gap: "7px"
}));

const WebinarActionsJsx = ({ rowData, active, fetchList, setWList }) => (
   <WebinarActionsBox>
      {(rowData?.endDate >= +new Date() && !rowData?.canceled) && <>
         <StartWebinarButton roomId={rowData?.roomId} />
         {!rowData?.quickMeet && <EditWebinarButton roomId={rowData?.roomId} />}
      </>}
      {rowData?.canceled && <DeleteWebinarButton
         roomId={rowData?.roomId}
         active={active}
         fetchList={fetchList}
         setWList={setWList}
      />}
      <ViewWebinarButton
         roomId={rowData?.roomId}
         sx={{
            border: '1px solid',
            borderColor: 'grey.600',
            width: 'auto'
         }} />
   </WebinarActionsBox>
)

export default WebinarActionsJsx;