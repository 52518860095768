
import React from 'react';
import { Typography, Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { configFile } from '../../../../config';

const { imagesUrl } = configFile;

export default function MicAndVideoPermissionBlock({ open, close }) {
    return (
        <Modal
            open={open}
            // handleClose={() => this.setState({ openEndAll: false })}
            // onClose={() => this.setState({openEndWeb: false})}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: '#FFFFFF',
                // border: '2px solid #000',
                boxShadow: 24,
                borderRadius: '10px',
                p: 4,
                "&:focus": {
                    outline: "none"
                }
            }}>
                <IconButton id="modal-modal-title" onClick={() => close()} sx={{
                    display: 'flex',
                    position: 'absolute',
                    justifyContent: 'right',
                    alignItems: 'right',
                    right: 15,
                    top: 10
                }}>
                    <CloseIcon />
                </IconButton>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: '10px',
                }}>
                    <Typography sx={{ color: '#454545', mt: 2, fontSize: '20px', fontWeight: 700 }}>
                        Camera and microphone are blocked
                    </Typography>
                    <Typography sx={{ color: '#757575', mt: 2, fontSize: '14px', fontWeight: 500 }}>
                        Follow these steps to allow access to
                        your Camera and Mic
                    </Typography>
                    <Box sx={{pt: 3}}>
                        <img src={imagesUrl?.micAndVideoBlock} alt="Permission Block warning" />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
