
const initialState = {
    speaker: ""
};

const speaker = (state = initialState, action) => {
    switch (action.type) {
        case 'SELECTED_SPEAKER':
            {
                const speaker = action.payload.info;
                return { ...state, speaker };
            }

        default:
            return state;
    }
};

export default speaker;
