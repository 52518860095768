const initialState = {
  message: "",
  actions: "",
  users: [],
  showPopup: true,
};

const waitingRoom = (state = initialState, action) => {
  switch (action.type) {
      case 'MESSAGE':
          {
              const { message } = action.payload;
              return { ...state, message };
          }

      case 'ACTION':
          {
              const { action: actions } = action.payload;
              return { ...state, actions };
          }

      case 'SET_USERS':
          {
              const { users = [] } = action.payload;
              return { ...state, users }
          }

      case 'SET_WAITING_USERS':
          {
              const { method, peerId, displayName = "" } = action.payload;
              
              let updatedPeers = []

              if (method === "add") {
                  updatedPeers = [...state.users, { peerId, displayName }];
                  return { ...state, users: updatedPeers, showPopup: true };
              }
              if (method === "remove") updatedPeers = state.users.filter(({ peerId: userPeerId }) => userPeerId !== peerId)
              return { ...state, users: updatedPeers, showPopup: false };
          }

      case 'HIDE_WAITING_ROOM_POPUP':
          {
              return { ...state, showPopup: false };
          }


      default:
          return state;
  }
};

export default waitingRoom;
