import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

const EditWebinarLoading = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: theme.palette?.primary.main,
    height: '22px',
    width: '50px',
    fontSize: '10px',
    fontWeight: 500,
    textTransform: 'uppercase',
    boxShadow: 'none'
}));

const EditWebinarButton = ({ roomId }) => {
    const navigate = useNavigate();

    return (
        <EditWebinarLoading
            variant='outlined'
            sx={{ color: "primary.main", backgroundColor: 'transparent' }}
            onClick={() => navigate(`/schedule_meet`, { state: { edit: true, roomId } })}
        >
            Edit
        </EditWebinarLoading>
    )
}

export default EditWebinarButton;