export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || !string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

