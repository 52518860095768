import React, { useContext } from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import RoomContext from '../../../../context/RoomContext';
import * as stateActions from '../../../../redux/actions/stateActions';
import { renderErrorMessage, renderSuccessMessage } from '../../../../components/ToastNotification';

const WhiteboardAccessMenu = ({ anchorEl, handleClose }) => {
    const roomClient = useContext(RoomContext) || {};
    const peers = useSelector(state => state.peers);
    const peerData = Object.values(peers).filter((peer) => peer?.role !== "attendee");
    const dispatch = useDispatch();

    const peerAccess = async (peerId, whiteboardAccess) => {
        const { err, message } = await roomClient.whiteboardAccess({ peerId, whiteboardAccess });
        if (err) {
            return renderErrorMessage(message)
        }
        dispatch(stateActions.setPeerWhiteboardAccess({ peerId, whiteboardAccess }));
        renderSuccessMessage(message);
    }

    return (
        <Menu
            id="access-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
        >
            {peerData.length ? peerData.map((peer, index) => (
                <MenuItem
                    key={index}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: '12px',
                        justifyContent: 'space-between'
                    }}>
                    <Typography sx={{
                        color: '#757575',
                        fontSize: '16px',
                        fontWeight: 500
                    }}>
                        {peer?.displayName || "User"}
                    </Typography>
                    {!peer?.whiteboardAccess && <Button
                        onClick={() => peerAccess(peer?.id, true)}
                        variant="outlined"
                        sx={{
                            height: '25px'
                        }}
                    >
                        Grant
                    </Button>}

                    {peer?.whiteboardAccess && <Button
                        onClick={() => peerAccess(peer?.id, false)}
                        variant="outlined"
                        sx={{
                            height: '25px'
                        }}
                    >
                        Remove
                    </Button>}
                </MenuItem>
            ))
                :
                <Box sx={{
                    padding: '10px 18px'
                }}>
                    <Typography sx={{
                        color: '#757575',
                        fontSize: '14px',
                        fontWeight: 500
                    }}>
                        No peers present in the room.
                    </Typography>
                </Box>
            }
        </Menu>
    )
}

export default WhiteboardAccessMenu;