import React from 'react';
import { Box } from "@mui/material";
import { configFile } from '../../../config';
const { imagesUrl } = configFile;

export default function ApiErrorImgShow() {
    return (
        <Box sx={{
            position: 'relative',
            top: 'calc(50% - 113px)',
            left: 'calc(50% - 150px)',
        }} >
            <img src={imagesUrl?.noInternet} alt='Somthing went wrong.' width={'80%'} height={'80%'} />
        </Box>
    )
}
