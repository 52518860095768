import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';
import { capitalizeFirstLetter } from './components/util';
import { useSelector } from 'react-redux';
const EnableOrDisablePermisions = () => {
  const { branding = {} } = useSelector(state => state?.branding);
  const { companyBrandName = "" } = branding || {};
  const capitalizedCompanyBrandName = capitalizeFirstLetter(companyBrandName);
  return (
    <>
      <Heading
        text='Enable or Disable Permissions        '
      />

      <ImageComp
        name={"enableOrDisablePermissions"}
      />

      <ListComp
        headingText='Note: Upon joining a meeting, poor video quality indicates a connection to the server. However, as the connection stabilizes, video quality will be set to the best.       '

        data={[

          {
            name: 'Check if the toggle button on the address button is on or off. Turn on it, if it s off.',
            id: '13',

          },
          {
            name: 'Refresh the page to make the changes effective.',
            id: '14',

          }, {
            name: 'Mic and Camera Permissions can be enabled or disabled from the toggle button on the left of the address bar.',
            id: '15',

          }, {
            name: 'Turn on the radio button to enable the mic, and disable it if you want to restrict the permission for the microphone usage.',
            id: '16',


          }, {
            name: 'If you are connected using a browser on a mobile phone, go to the browser’s Settings> App Permission and turn on the radio button to grant microphone and camera accessibility for audio and video usability.  ',
            id: '17',

          },
          {
            name: `Or while using  ${capitalizedCompanyBrandName} over the Chrome browser, click on the microphone and video icon. A dialog box will pop up on the screen.`,
            id: '18',

          },
          {
            name: 'Click ‘Allow’ to enable microphone and camera permission for audio and video usability.  ',
            id: '19',

          },

        ]}

      />

    </>
  );
};

export default EnableOrDisablePermisions;
