import React, { useEffect, useState } from 'react';
import { Box, Link, ListItemIcon, Avatar } from '@mui/material';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import DescriptionIcon from '@mui/icons-material/Description';
// import RateReviewIcon from '@mui/icons-material/RateReview';
import HomeIcon from '@mui/icons-material/Home';
import { getItem } from '../../lib/cookiesManager';
import jwt from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileMenu from './profileMenu';
import { getConosleEnv } from "../../config/Enums";
import DateRangeIcon from '@mui/icons-material/DateRange';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export default function FooterMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const [profileUrl, setProfileUrl] = useState(null);
    const [anchorElProfile, setAnchorElProfile] = useState(null);
    const [showProfile, setShowProfile] = useState(false);
    const consoleEnv = getConosleEnv();

    const handleClickProfileMenu = (event) => {
        if (!event) return setAnchorElProfile(null)
        setAnchorElProfile(event.currentTarget)
    };
    const handleCloseProfileMenu = () => {
        setAnchorElProfile(null)
    };

    const homeSideBar = [{
        title: 'Home',
        icon: <HomeIcon color={location?.pathname === '/' ? "primary" : "disabled"} />,
        path: "/",
    },
    {
        title: 'Meeting',
        icon: <VideoCameraFrontIcon color={location?.pathname === '/list' ? "primary" : "disabled"} />,
        path: "/list",
    },
    {
        title: 'Report',
        icon: <DescriptionIcon color={location?.pathname === '/reports' ? "primary" : "disabled"} />,
        path: "/reports",
    },
    // {
    //     title: 'Feedback',
    //     icon: <RateReviewIcon color={location?.pathname === '/feedback' ? "primary" : "disabled"} />,
    //     path: "/feedback",
    // },
    {
        title: 'Schedule',
        icon: <DateRangeIcon color={location?.pathname === '/schedule_webinar' ? "primary" : "disabled"} />,
        path: "/schedule_meet",
    },
    {
        title: 'Help',
        icon: <HelpCenterIcon color={location?.pathname === '/help' ? "primary" : "disabled"} />,
        path: "/help",
    },
    ];

    // Get profile pick url from token.
    const fetchUserInfo = async () => {
        let data = getItem("token");
        let info = jwt(data);
        setProfileUrl(info?.picture);
    }

    useEffect(() => {
        if (!consoleEnv.includes(process.env.REACT_APP_ENV)) setShowProfile(true);
        fetchUserInfo()
        // eslint-disable-next-line
    }, [])

    return (
        <Box className='MenuBar' sx={{
            p: '14px',
            zIndex: 999,
            backgroundColor: 'grey.1000'
        }}>
            <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#B1AEC0'
            }}>
                {homeSideBar.map(({ icon, path }, index) => (
                    <Link key={index} onClick={() => navigate(path)} sx={{ display: 'flex' }}> {icon} </Link>
                ))}

                {showProfile && <Avatar
                    alt="Remy Sharp"
                    src={profileUrl || "/images/avatar.svg"}
                    sx={{ width: 32, height: 32 }}
                    onClick={(event) => handleClickProfileMenu(Boolean(anchorElProfile) ? false : event)}
                    aria-label="add"
                    aria-controls={Boolean(anchorElProfile) ? 'end-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorElProfile) ? 'true' : undefined}
                />}
            </ListItemIcon>

            {/* prifile icon menu. */}
            {showProfile && <ProfileMenu
                anchorElEnd={anchorElProfile}
                handleCloseProfileMenu={handleCloseProfileMenu}
            />}
        </Box>
    )
}
