import { driver } from "driver.js";
import "driver.js/dist/driver.css";

// import jwt from 'jwt-decode';
import { getItem, setItem } from "./cookiesManager";
import { axiosPostCall } from "../services/apisCall";

const UserGuides = () => {
    let data = getItem("guides");
    let token = getItem("sessionToken")
    let exp = getItem("exp")
    // let info = jwt(data);


    if (!data && token && window.location.pathname == '/' && exp > +new Date()) {

        const driverObj = driver({
            showProgress: true,
            showButtons: [
                'next',
                'previous',
                'close'
            ],
            steps: [
                {
                    element: '#start-guide',
                    popover: {
                        title: 'Quick guide',
                        description: 'Check next to start quick guide or cancel to exit quick guide',
                        side: "left",
                        align: 'start',
                        onNextClick: () => {
                            driverObj.moveNext();
                        },
                    }
                },
                {
                    element: '#quickMeeting',
                    popover: {
                        title: 'Quick meeting',
                        description: 'Quickly create a meeting by clicking this button.',
                        side: "right",
                        align: 'center',
                        onNextClick: () => {
                            if (window.location.pathname !== '/meet') {
                                const doc = document.getElementById('quickMeetBtn');
                                doc.click();
                            }
                            const interval = setInterval(() => {
                                if (window.location.pathname === '/meet') {
                                    driverObj.moveNext();
                                    clearInterval(interval);
                                }
                            }, 1000)
                        },
                    }
                },
                {
                    element: '.micGroup',
                    popover: {
                        title: 'Audio section',
                        description: 'Turn your microphone on and off from here.',
                        side: "right",
                        align: 'center',
                        showButtons: ['next'],


                    },


                },


                {
                    element: '.videoGroup',
                    popover: {
                        title: 'Video section',
                        description: 'From here, turn your camera on or off to cut your feed.',
                        side: "right",
                        align: 'center',
                    }
                },
                {
                    element: '.participants',
                    popover: {
                        title: 'Participants section',
                        description: 'You can manage your participant list and actions from here.',
                        side: "right",
                        align: 'center',
                    }
                },
                {
                    element: '.chat',
                    popover: {
                        title: 'Chat section',
                        description: 'You can manage your public and private (one-to-once) chat from here.',
                        side: "right",
                        align: 'center',
                    }
                },
                {
                    element: '.screenShare',
                    popover: {
                        title: 'Screen Share section',
                        description: 'You can share your screen from here.',
                        side: "right",
                        align: 'center',
                    }
                },
                {
                    element: '.floating-buttons',
                    popover: {
                        title: 'Live users and general room information section',
                        description: 'You can check total count of connected users and general room information by check info icon.',
                        side: "bottom",
                        align: 'center',
                    }
                },
            ]
            ,
            onDestroyStarted: () => {
                // if (!driverObj.hasNextStep() || window.confirm("Are you sure want to exit quick guide?")) {
                //     driverObj.destroy();
                // }
                axiosPostCall("userGuide", {}, false);

                setItem("guide", true);
                driverObj.destroy();
            },
        });

        const interval = setInterval(() => {
            if (driverObj.getActiveIndex() === undefined) clearInterval(interval);

            if (window.location.pathname === '/meet' && driverObj.getActiveIndex() === 1) {
                driverObj.moveTo(2);
                clearInterval(interval)
            }

            if (window.location.pathname === '/meet' && driverObj.getActiveIndex() > 1 && interval) clearInterval(interval);
        }, 1000);

        driverObj.drive();
    }

    return true;
}

export default UserGuides;