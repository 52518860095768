import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const ViewWebinarLoading = styled(LoadingButton)(() => ({
    display: "flex",
    width: "50px",
    height: "22px",
    padding: "5px 15px",
    borderRadius: "5px",
}));

const ViewWebinarText = styled(Typography)(({ theme }) => ({
    fontSize: "10px",
    fontWeight: 500,
    color: theme.palette.grey[500],
    boxShadow: "none"
}));

const ViewWebinarButton = ({ roomId, sx }) => {
    const navigate = useNavigate();

    return (
        <ViewWebinarLoading
            onClick={() => navigate(`/view_meet_details`, { state: { roomId } })}
            sx={sx}
        >
            <ViewWebinarText> View Details </ViewWebinarText>
        </ViewWebinarLoading>
    )
}

export default ViewWebinarButton;