import React, { useContext } from 'react';
import { Menu, MenuItem, ListItemIcon, Typography, IconButton } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import store from '../../../../store';
import { configFile } from '../../../../config';
import { RecordContext } from './../../../../context/recordContext';
import RoomContext from '../../../../context/RoomContext';
import { WaitingRoomContext } from '../../../../context/waitingRoomContext';
import { setRecordingInfo } from '../../../../redux/actions/recordingActions';
import { renderErrorMessage, renderSuccessMessage } from '../../../ToastNotification';
import { recordingToggle } from "../../../../services/commonApis";

export default function MoreOptionsMenu({ open, anchorElMore, handleClose }) {
    const { imagesUrl } = configFile;
    const location = useLocation();
    const roomContext = useContext(RoomContext);
    const waitingRoomContext = useContext(WaitingRoomContext);
    const { url } = useSelector(state => state?.webinarInfo);
    const { toggleRecording, openRecording, record } = useContext(RecordContext);

    const startRecording = async () => {
        try {
            if (!openRecording) {
                const { message } = await recordingToggle("start", roomContext._roomId, location?.state?.peerId, url);

                if (message) {
                    renderSuccessMessage(message || "Recording strated successfully!");
                    toggleRecording(true);
                    store.dispatch(setRecordingInfo({ status: "start", duration: 0 }))
                }
            }
        } catch (error) {
            renderErrorMessage(error.message || "Internal Server Error");
            throw Error(error);
        }
    }

    return (
        <Menu
            anchorEl={anchorElMore}
            id="more-options-menu"
            open={open}
            onClose={() => handleClose()}
            onClick={() => handleClose()}
            PaperProps={{
                elevation: 0,
                sx: {
                    // padding: '20px',
                    marginLeft: 0.9,
                    marginTop: 3.2,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    minWidth: '100px',
                    borderRadius: '10px',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 10,
                        width: 10,
                        height: 10,
                        bgcolor: '#fff',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            {record && <MenuItem sx={{ fontSize: 14, }} onClick={() => startRecording()}>
                <ListItemIcon >
                    <IconButton sx={{
                        height: '24px',
                        width: '24px',
                        backgroundImage: 'linear-gradient(135deg, #DF2525 14.64%, #C02A2A 85.35%)',
                        color: '#fff'
                    }}>
                        <RadioButtonCheckedIcon sx={{ height: '16px', width: '16px', }} />
                    </IconButton>
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -0.5, color: '#727374' }} >Start recording</Typography>
            </MenuItem>}
            <MenuItem sx={{ fontSize: 14, }} onClick={() => waitingRoomContext.openWaitingRoomlist()}>
                <ListItemIcon>
                    <img src={imagesUrl?.waitingRoom} alt='' width={'60%'} height={'60%'} />
                </ListItemIcon>
                <Typography variant="inherit" sx={{ ml: -1, color: '#727374' }}>Waiting Room</Typography>
            </MenuItem>
        </Menu>

    )
}
