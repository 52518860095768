import React, { memo, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getBulkItems, getItem, removeBulkItems } from './cookiesManager';
import { createRedirectPath, renewToken } from "../utils/common";
import { configFile } from "../config";

const PrivateRoute = ({ children }) => {
  const { renewTokenTime } = configFile;

  let { sessionToken, exp } = getBulkItems(["sessionToken", "exp"]);
  if (renewTokenTime > exp) renewToken();

    useEffect(() => {
        let duration = exp - +new Date();
        const timerId = setTimeout((delay) => {
            if (delay) {
                let exp = getItem("exp");
                if (!exp || exp <= +new Date()) {
                    removeBulkItems(["sessionToken", "exp", "token"]);
                    alert("Your session has expired. Please login again!");
                    window.location.href = createRedirectPath("/login");
                }
            }
        }, duration, duration);

        return () => {
            clearTimeout(timerId);
        }
        // eslint-disable-next-line
    }, []);

  if (!sessionToken || !exp || exp <= +new Date()) return <Navigate to="/login" />
  return children;
}

export default memo(PrivateRoute);