
import { styled } from '@mui/material/styles';
import { IconButton, Box, Tooltip, Zoom, } from '@mui/material';
import { SketchPicker } from "react-color";

// @mui icons
import TitleIcon from '@mui/icons-material/Title';
import CreateIcon from '@mui/icons-material/Create';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import randomString from 'random-string';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReplayIcon from '@mui/icons-material/Replay';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';

import EraserImg from './images/eraser.svg'
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
// import getCursor from './cursors';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import UploadMenu from './uploadMenu';
import WhiteboardAccessMenu from './whiteboardAccess';

import store from '../../../../store';
import * as stateActions from '../../../../redux/actions/stateActions';

// import './eraserBrush';

let drawInstance = null;
let origX;
let origY;
let mouseDown = false;

const options = {
    currentMode: '',
    currentColor: '#000000',
    currentWidth: 5,
    fill: false,
    group: {},
};

const modes = {
    RECTANGLE: 'RECTANGLE',
    TRIANGLE: 'TRIANGLE',
    ELLIPSE: 'ELLIPSE',
    LINE: 'LINE',
    PENCIL: 'PENCIL',
    ERASER: 'ERASER',
};

const MainSection = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    width: '864px',
    height: '40px',
    alignItems: 'center',
    backgroundColor: '#fff',
    gap: '8px',
    borderRadius: '20px'
}));

const CustomIconButton = styled(IconButton)(() => ({
    color: 'rgb(99, 115, 129)',
    width: '32px',
    height: '32px',
    borderRadius: '6px',
}));

function stopDrawing() {
    mouseDown = false;
}

function removeCanvasListener(canvas) {
    const eventTypes = [
        'object:modified', 'object:rotating', 'object:scaling', 'object:moving', 'object:skewing',
        'mouse:down', 'mouse:move', 'mouse:up',
    ];

    // Register event listeners for each event type
    eventTypes.forEach(eventType => {
        canvas.off(eventType)
    });
}

function getDataFormCanva(canvas, roomClient) {
    const { objects } = canvas.toJSON() || {}
    const lastObj = objects.at(-1) || {};
    console.log(lastObj, roomClient)
    roomClient.sendWhiteBoardData({ clearCanvas: false, data: lastObj })
}

/*  ==== line  ==== */
function createLine(canvas, roomClient) {
    if (modes.currentMode !== modes.LINE) {
        options.currentMode = modes.LINE;

        removeCanvasListener(canvas);
        canvas.on('mouse:down', startAddLine(canvas));
        canvas.on('mouse:move', startDrawingLine(canvas));
        canvas.on('mouse:up', () => {
            getDataFormCanva(canvas, roomClient)
            stopDrawing()
        });

        canvas.selection = false;
        canvas.hoverCursor = 'auto';
        canvas.isDrawingMode = false;
        canvas.getObjects().map((item) => item.set({ selectable: false }));
        canvas.discardActiveObject().requestRenderAll();
    }
}

function startAddLine(canvas) {
    return ({ e }) => {
        mouseDown = true;
        const objectId = randomString({ length: 8 }).toLowerCase();

        let pointer = canvas.getPointer(e);
        drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
            strokeWidth: options.currentWidth,
            stroke: options.currentColor,
            selectable: false,
            shadow: objectId,
        });

        canvas.add(drawInstance);
        console.log("canvas11", canvas, drawInstance)
        canvas.requestRenderAll();
    };
}

function startDrawingLine(canvas) {

    return ({ e }) => {
        if (mouseDown) {
            const pointer = canvas.getPointer(e);
            drawInstance.set({
                x2: pointer.x,
                y2: pointer.y,
            });
            drawInstance.setCoords();
            canvas.requestRenderAll();
        }
    };
}

/* ==== rectangle ==== */
function createRect(canvas, roomClient) {
    if (options.currentMode !== modes.RECTANGLE) {
        options.currentMode = modes.RECTANGLE;

        removeCanvasListener(canvas);

        canvas.on('mouse:down', startAddRect(canvas));
        canvas.on('mouse:move', startDrawingRect(canvas));
        canvas.on('mouse:up', () => {
            getDataFormCanva(canvas, roomClient)
            stopDrawing()
        });

        canvas.selection = false;
        canvas.hoverCursor = 'auto';
        canvas.isDrawingMode = false;
        canvas.getObjects().map((item) => item.set({ selectable: false }));
        canvas.discardActiveObject().requestRenderAll();
    }
}

function startAddRect(canvas) {
    return ({ e }) => {
        mouseDown = true;
        const objectId = randomString({ length: 8 }).toLowerCase();

        const pointer = canvas.getPointer(e);
        origX = pointer.x;
        origY = pointer.y;

        drawInstance = new fabric.Rect({
            stroke: options.currentColor,
            strokeWidth: options.currentWidth,
            fill: options.fill ? options.currentColor : 'transparent',
            left: origX,
            top: origY,
            width: 0,
            height: 0,
            selectable: false,
            shadow: objectId
        });

        canvas.add(drawInstance);

        drawInstance.on('mousedown', (e) => {
            if (options.currentMode === modes.ERASER) {
                canvas.remove(e.target);
            }
        });
    };
}

function startDrawingRect(canvas) {
    return ({ e }) => {
        if (mouseDown) {
            const pointer = canvas.getPointer(e);

            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }
            if (pointer.y < origY) {
                drawInstance.set('top', pointer.y);
            }
            drawInstance.set({
                width: Math.abs(pointer.x - origX),
                height: Math.abs(pointer.y - origY),
            });
            drawInstance.setCoords();
            canvas.renderAll();
        }
    };
}

/* ==== Ellipse ==== */
function createEllipse(canvas, roomClient) {
    if (options.currentMode !== modes.ELLIPSE) {
        options.currentMode = modes.ELLIPSE;

        removeCanvasListener(canvas);

        canvas.on('mouse:down', startAddEllipse(canvas));
        canvas.on('mouse:move', startDrawingEllipse(canvas));
        canvas.on('mouse:up', () => {
            getDataFormCanva(canvas, roomClient)
            stopDrawing()
        });

        canvas.selection = false;
        canvas.hoverCursor = 'auto';
        canvas.isDrawingMode = false;
        canvas.getObjects().map((item) => item.set({ selectable: false }));
        canvas.discardActiveObject().requestRenderAll();
    }
}

function startAddEllipse(canvas) {
    return ({ e }) => {
        mouseDown = true;
        const objectId = randomString({ length: 8 }).toLowerCase();

        const pointer = canvas.getPointer(e);
        origX = pointer.x;
        origY = pointer.y;
        drawInstance = new fabric.Ellipse({
            stroke: options.currentColor,
            strokeWidth: options.currentWidth,
            fill: options.fill ? options.currentColor : 'transparent',
            left: origX,
            top: origY,
            cornerSize: 7,
            objectCaching: false,
            selectable: false,
            shadow: objectId,
        });

        canvas.add(drawInstance);
    };
}

function startDrawingEllipse(canvas) {
    return ({ e }) => {
        if (mouseDown) {
            const pointer = canvas.getPointer(e);
            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }
            if (pointer.y < origY) {
                drawInstance.set('top', pointer.y);
            }
            drawInstance.set({
                rx: Math.abs(pointer.x - origX) / 2,
                ry: Math.abs(pointer.y - origY) / 2,
            });
            drawInstance.setCoords();
            canvas.renderAll();
        }
    };
}

/* === triangle === */
function createTriangle(canvas, roomClient) {
    removeCanvasListener(canvas);

    canvas.on('mouse:down', startAddTriangle(canvas));
    canvas.on('mouse:move', startDrawingTriangle(canvas));
    canvas.on('mouse:up', () => {
        getDataFormCanva(canvas, roomClient)
        stopDrawing()
    });

    canvas.selection = false;
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
}

function startAddTriangle(canvas) {
    return ({ e }) => {
        mouseDown = true;
        options.currentMode = modes.TRIANGLE;
        const objectId = randomString({ length: 8 }).toLowerCase();

        const pointer = canvas.getPointer(e);
        origX = pointer.x;
        origY = pointer.y;
        drawInstance = new fabric.Triangle({
            stroke: options.currentColor,
            strokeWidth: options.currentWidth,
            fill: options.fill ? options.currentColor : 'transparent',
            left: origX,
            top: origY,
            width: 0,
            height: 0,
            selectable: false,
            shadow: objectId
        });

        canvas.add(drawInstance);
    };
}

function startDrawingTriangle(canvas) {
    return ({ e }) => {
        if (mouseDown) {
            const pointer = canvas.getPointer(e);
            if (pointer.x < origX) {
                drawInstance.set('left', pointer.x);
            }
            if (pointer.y < origY) {
                drawInstance.set('top', pointer.y);
            }
            drawInstance.set({
                width: Math.abs(pointer.x - origX),
                height: Math.abs(pointer.y - origY),
            });

            drawInstance.setCoords();
            canvas.renderAll();
        }
    };
}

function createText(canvas, roomClient) {
    removeCanvasListener(canvas);
    canvas.isDrawingMode = false;
    const objectId = randomString({ length: 8 }).toLowerCase();

    const text = new fabric.Textbox('text', {
        left: 100,
        top: 100,
        fill: options.currentColor,
        editable: true,
        shadow: objectId,
        id: objectId,
        objectId
    });

    // canvas.on('mouse:down', () => {
    //     const current = canvas.getActiveObject()
    //     console.log("c1", current, text.get('id'), text.get('objectId'))
    // });

    canvas.on('mouse:up', () => {
        // const current = canvas.getActiveObject()
        getDataFormCanva(canvas, roomClient);
    });

    canvas.add(text);
    getDataFormCanva(canvas, roomClient);
    canvas.renderAll();
    canvas.getObjects().map((item) => {
        if (item?.type !== "textbox") return item.set({ selectable: false });
        return item.set({ selectable: true });
    });
}

function changeToErasingMode(canvas, roomClient) {
    if (options.currentMode !== modes.ERASER) {
        removeCanvasListener(canvas);
        canvas.on('mouse:up', () => {
            getDataFormCanva(canvas, roomClient)
        });

        options.currentMode = modes.ERASER;
        canvas.freeDrawingBrush.width = parseInt(options.currentWidth, 20) || 1;
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.color = "#fff"
        canvas.getObjects().map((item) => item.set({ selectable: false }));
    }
    // if (options.currentMode !== modes.ERASER) {
    //     removeCanvasListener(canvas);

    //     canvas.isDrawingMode = false;

    //     options.currentMode = modes.ERASER;
    //     // canvas.hoverCursor = `url(${getCursor({ type: 'eraser' })}), default`;

    //     // canvas.freeDrawingBrush = new fabric.BaseBrush(canvas); //EraserBrush
    //     // canvas.freeDrawingBrush.width = 10;
    //     // canvas.isDrawingMode = true;
    //     console.log("done1")
    // }
}

// function onSelectMode(canvas) {
//     options.currentMode = '';
//     canvas.isDrawingMode = false;

//     removeCanvasListener(canvas);

//     canvas.getObjects().map((item) => item.set({ selectable: true }));
//     canvas.hoverCursor = 'all-scroll';
// }

// function canvasToJson(canvas) {
//     console.log(canvas, canvas.toJSON())
//     alert(JSON.stringify(canvas.toJSON()));
// }

function draw(canvas, roomClient) {
    if (options.currentMode !== modes.PENCIL) {
        removeCanvasListener(canvas);
        let objectId = randomString({ length: 8 }).toLowerCase();

        canvas.on('mouse:up', () => {
            getDataFormCanva(canvas, roomClient)
        });

        canvas.on('mouse:down', () => {
            objectId = randomString({ length: 8 }).toLowerCase();
            canvas.freeDrawingBrush.shadow = objectId;
        });

        options.currentMode = modes.PENCIL;
        canvas.freeDrawingBrush.color = options.currentColor
        canvas.freeDrawingBrush.shadow = objectId;
        // canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush.width = parseInt(options.currentWidth, 10) || 1;
        canvas.isDrawingMode = true;
    }
}

// function handleResize(callback) {
//     const resize_ob = new ResizeObserver(callback);

//     return resize_ob;
// }

// function resizeCanvas(canvas, whiteboard) {
//     return () => {
//         const ratio = canvas.getWidth() / canvas.getHeight();
//         const whiteboardWidth = whiteboard.clientWidth;

//         const scale = whiteboardWidth / canvas.getWidth();
//         const zoom = canvas.getZoom() * scale;
//         canvas.setDimensions({ width: whiteboardWidth, height: whiteboardWidth / ratio });
//         canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
//     };
// }

const pickerStyle = {
    default: {
        picker: {
            position: "absolute",
            top: '40px',
            zIndex: 9999
        }
    }
};

const Toolbar = ({ canvas, canvasRef, roomClient, onFileChange, setFileReaderInfo }) => {
    const [color, setColor] = useState("#000000");
    const [currentMode, setCurrentMode] = useState("");
    const [brushWidth, setBrushWidth] = useState(3);
    const [isFill, setIsFill] = useState(false);
    const [openCPicker, setOpenCPicker] = useState(false)
    const uploadImageRef = useRef(null);
    const uploadPdfRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [accessAnchorEl, setAccessAnchorEl] = useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleAccessClick(event) {
        if (accessAnchorEl !== event.currentTarget) {
            setAccessAnchorEl(event.currentTarget);
        }
    }

    function handleAccessClose() {
        setAccessAnchorEl(null);
    }

    function clearCanvas(canvas, roomClient) {
        canvas.getObjects().forEach((item) => {
            if (item !== canvas.backgroundImage) {
                canvas.remove(item);
            }
        });
        roomClient.sendWhiteBoardData({ clearWhiteboard: true });
        store.dispatch(stateActions.updatePDFUrl(""));
        setFileReaderInfo({
            file: '',
            totalPages: null,
            currentPageNumber: 1,
            currentPage: '',
        })
    }

    function uploadImage(e) {
        options.currentMode = '';
        setCurrentMode("");
        canvas.isDrawingMode = false;
        removeCanvasListener(canvas);
        const reader = new FileReader();
        const file = e.target.files[0];
        const objectId = randomString({ length: 8 }).toLowerCase();

        reader.addEventListener('load', () => {
            fabric.Image.fromURL(reader.result, (img) => {
                img.scaleToHeight(canvas.height - 100);
                img.scaleToWidth(canvas.width - 400);
                img.set("shaodw", objectId);
                canvas.getObjects().map((item) => item.set({ selectable: false }));
                canvas.add(img);
                canvas.renderAll();
                canvas.on('mouse:up', () => {
                    getDataFormCanva(canvas, roomClient);
                });
                getDataFormCanva(canvas, roomClient);
            });
        });

        reader.readAsDataURL(file);
        e.target.value = null;
    }

    function changeCurrentWidth(method, currentVal) {
        let intValue = currentVal;
        if (method === "add") {
            if (currentVal >= 30) return;
            intValue = parseInt(currentVal + 1);
        }

        if (method === "remove") {
            if (currentVal <= 1) return;
            intValue = parseInt(currentVal - 1);
        }

        options.currentWidth = intValue;
        canvas.freeDrawingBrush.width = intValue;
        setBrushWidth(() => intValue);
    }

    function changeCurrentColor(e) {
        options.currentColor = e/* .target.value */;
        canvas.freeDrawingBrush.color = e/* .target.value */;
        setColor(e)
        if (options.currentMode === modes.ERASER) {
            options.currentMode = modes.PENCIL
            setCurrentMode(modes.PENCIL)
        }
    }

    function changeFill(value) {
        options.fill = value;
        setIsFill(() => value);
    }

    function onSaveCanvasAsImage() {
        canvasRef.current.toBlob(function (blob) {
            saveAs(blob, 'image.png');
        });
    }

    const iconSx = { width: '32px', height: '32px' };
    const activeColor = "rgba(96, 122, 147, 0.12)";
    const defaultIconColor = "transparent"

    return (
        <Box sx={{
            width: 'calc(100%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '6px'
        }}>

            <MainSection>
                <Tooltip
                    TransitionComponent={Zoom}
                    title="Pencil"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}>
                    <CustomIconButton
                        onClick={() => {
                            draw(canvas, roomClient)
                            setCurrentMode(modes.PENCIL)
                        }}
                        sx={{
                            backgroundColor: currentMode === modes.PENCIL ? activeColor : defaultIconColor
                        }}>
                        <CreateIcon alt="Pencil" />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Text"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => createText(canvas, roomClient)}>
                        <TitleIcon />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Eraser"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => {
                        changeToErasingMode(canvas, roomClient)
                        setCurrentMode(modes.ERASER)
                    }}
                        sx={{
                            backgroundColor: currentMode === modes.ERASER ? activeColor : defaultIconColor
                        }}
                    >
                        <img src={EraserImg} alt="Eraser" style={iconSx} />
                        {/* <AutoFixHighOutlinedIcon alt="Eraser" /> */}
                    </CustomIconButton>
                </Tooltip>

                <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Line"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => {
                        createLine(canvas, roomClient)
                        setCurrentMode(modes.LINE)
                    }}
                        sx={{
                            backgroundColor: currentMode === modes.LINE ? activeColor : defaultIconColor
                        }}>
                        <HorizontalRuleIcon alt="Line" sx={{ transform: 'rotate(135deg)' }} />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Rectangle"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => {
                        createRect(canvas, roomClient)
                        setCurrentMode(modes.RECTANGLE)
                    }}
                        sx={{
                            backgroundColor: currentMode === modes.RECTANGLE ? activeColor : defaultIconColor
                        }}
                    >
                        <CheckBoxOutlineBlankIcon alt="Rectangle" />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Ellipse"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}>
                    <CustomIconButton onClick={() => {
                        createEllipse(canvas, roomClient)
                        setCurrentMode(modes.ELLIPSE)
                    }}
                        sx={{
                            backgroundColor: currentMode === modes.ELLIPSE ? activeColor : defaultIconColor
                        }}>
                        <CircleOutlinedIcon alt="Ellipse" />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Triangle"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => {
                        createTriangle(canvas, roomClient, options)
                        setCurrentMode(modes.TRIANGLE)
                    }}
                        sx={{
                            backgroundColor: currentMode === modes.TRIANGLE ? activeColor : defaultIconColor
                        }}>
                        <ChangeHistoryOutlinedIcon alt="Triangle" />
                    </CustomIconButton>
                </Tooltip>

                {/* <CustomIconButton onClick={() => onSelectMode(canvas)}>
                    <HighlightAltOutlinedIcon alt="Selection mode" />
                </CustomIconButton> */}

                <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Undo"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => roomClient.undoRedoWhiteboard({ type: "undo" })}>
                        <ReplayIcon alt="Undo" />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Redo"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => roomClient.undoRedoWhiteboard({ type: "redo" })}>
                        <ReplayIcon alt="Redo" sx={{ transform: 'scaleX(-1)' }} />
                    </CustomIconButton>
                </Tooltip>

                <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Filled Box"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}>
                    <CustomIconButton id="fill" checked={isFill} onClick={() => changeFill(!isFill)}>
                        <FormatColorFillOutlinedIcon alt="Fill" />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Change Color"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => setOpenCPicker(!openCPicker)} alt="Fill">
                        <Box sx={{
                            width: '16px',
                            height: '16px',
                            border: '1px solid rgb(99, 115, 129)',
                            borderRadius: '3px',
                            backgroundColor: color
                        }}>

                        </Box>
                        {openCPicker && (
                            <SketchPicker
                                styles={pickerStyle}
                                color={color}
                                onChange={(updatedColor) => changeCurrentColor(updatedColor.hex)}
                            />
                        )}
                    </CustomIconButton>
                </Tooltip>

                <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />

                {/* <Tooltip TransitionComponent={Zoom} title="Increase/Decrese Line height" arrow placement={"bottom"}> */}
                <RemoveIcon sx={{ color: 'rgb(99, 115, 129)' }} onClick={() => changeCurrentWidth("remove", brushWidth)} />
                <Typography sx={{
                    borderRadius: '6px',
                    border: '1px solid rgb(99, 115, 129)',
                    padding: '0px 9px',
                    color: 'rgb(99, 115, 129)'
                }}>
                    {brushWidth}
                </Typography>
                <AddIcon sx={{ color: 'rgb(99, 115, 129)' }} onClick={() => changeCurrentWidth("add", brushWidth)} />
                {/* </Tooltip> */}

                <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Delete Page"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}
                >
                    <CustomIconButton onClick={() => clearCanvas(canvas, roomClient)}>
                        <DeleteForeverOutlinedIcon alt="Delete" />
                    </CustomIconButton>
                </Tooltip>

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Save as Image"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}>
                    <CustomIconButton onClick={onSaveCanvasAsImage}>
                        <ArrowCircleDownOutlinedIcon alt="Save as image" />
                    </CustomIconButton>
                </Tooltip>

                <CustomIconButton
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    onMouseOver={handleClick}
                    sx={{ width: 'auto' }}
                >
                    <CloudUploadIcon />
                    <KeyboardArrowDownIcon sx={{/* ml: '-2px', */ width: '18px', height: '15px' }} />
                </CustomIconButton>

                <Divider orientation="vertical" style={{ height: '70%', width: '1px' }} />

                <Tooltip
                    TransitionComponent={Zoom}
                    title="Open Whiteboard for all"
                    arrow
                    placement={"bottom"}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: '#fff'
                            },
                        },
                    }}>
                    <CustomIconButton onClick={() => { roomClient.toggleWhiteboard({ open: true }) }}>
                        <OpenInNewIcon alt="Open Whiteboard for all" />
                    </CustomIconButton>
                </Tooltip>

                <CustomIconButton
                    aria-owns={anchorEl ? "access-menu" : undefined}
                    aria-haspopup="true"
                    onClick={handleAccessClick}
                    sx={{ width: 'auto' }}
                >
                    <SettingsAccessibilityIcon />
                    <KeyboardArrowDownIcon sx={{/* ml: '-2px', */ width: '18px', height: '15px' }} />
                </CustomIconButton>

                <UploadMenu
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    // uploadImage={uploadImage}
                    // onFileChange={onFileChange}
                    uploadImageRef={uploadImageRef}
                    uploadPdfRef={uploadPdfRef}
                />

                <WhiteboardAccessMenu
                    anchorEl={accessAnchorEl}
                    handleClose={handleAccessClose}
                />

                {/* <CustomIconButton onClick={() => canvasToJson(canvas)}>
                    <DataObjectOutlinedIcon alt="To Json" />
                </CustomIconButton> */}

                {/* <CustomIconButton onClick={() => canvasToJson(canvas)}>
                <FileUploadOutlinedIcon alt="+Upload" />
                <ArrowDropDownOutlinedIcon />
            </CustomIconButton> */}

                {/* <div>
                    <input type="checkbox" name="fill" id="fill" checked={isFill} onChange={changeFill} />
                    {/* <label htmlFor="fill">fill</label> 
                </div> */}

                {/* <input
                    type="range"
                    min={1}
                    max={20}
                    step={1}
                    value={brushWidth}
                    onChange={changeCurrentWidth}
                /> */}

                {/* <input ref={uploadImageRef} accept="image/*" type="file" onChange={uploadImage} />
            <input ref={uploadPdfRef} accept=".pdf" type="file" onChange={onFileChange} />
            <button className={styles.dropdownButton}>+Upload</button>
            <div className={styles.dropdownContent}>
                <span onClick={() => uploadImageRef.current.click()}>Image</span>
                <span onClick={() => uploadPdfRef.current.click()}>PDF</span>
            </div> */}

                {/*   <div className={styles.toolbar}>
                <div>
                    <input type="checkbox" name="fill" id="fill" checked={isFill} onChange={changeFill} />
                    <label htmlFor="fill">fill</label>
                </div>
                <div>
                    <input type="color" name="color" id="color" onChange={changeCurrentColor} />
                </div>
                <input
                    type="range"
                    min={1}
                    max={20}
                    step={1}
                    value={brushWidth}
                    onChange={changeCurrentWidth}
                />
                <div className={styles.uploadDropdown}>
                    <input ref={uploadImageRef} accept="image/*" type="file" onChange={uploadImage} />
                    <input ref={uploadPdfRef} accept=".pdf" type="file" onChange={onFileChange} />
                    <button className={styles.dropdownButton}>+Upload</button>
                    {/* <div className={styles.dropdownContent}>
                        <span onClick={() => uploadImageRef.current.click()}>Image</span>
                        <span onClick={() => uploadPdfRef.current.click()}>PDF</span>
                    </div> 
                </div>

                <button onClick={() => canvasToJson(canvas)}>To Json</button>
                <button onClick={onSaveCanvasAsImage}>Save as image</button>
            </div> */}

                {/* <CustomIconButton>
                    <ShareIcon />
                </CustomIconButton> */}

                <input ref={uploadImageRef} accept="image/*" type="file" onChange={uploadImage} style={{ display: 'none' }} />
                <input ref={uploadPdfRef} accept=".pdf" type="file" onChange={(e) => onFileChange(e)} style={{ display: 'none' }} />
                {/* <PdfReader fileReaderInfo={fileReaderInfo} updateFileReaderInfo={updateFileReaderInfo} /> */}
            </MainSection>
        </Box >

    )
}

export default Toolbar