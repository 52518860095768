const initialState =
{
    notifications: {}
};

const audioNotifications = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_AUDIO_NOTIFICATIONS':
            {
                const { peerId, type } = action.payload;
                const notifications = {
                    ...state?.notifications, [peerId]: { type }
                };

                return { ...state, notifications };
            }

        case 'REMOVE_AUDIO_NOTIFICATIONS':
            {
                const { peerId } = action.payload;
                let notifications = state?.notifications;

                delete notifications[peerId]

                return { ...state, notifications };
            }

        default:
            return state;
    }
};

export default audioNotifications;
