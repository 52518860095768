import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isMobileDevice } from './components/util';


const RecordAMeeting = () => {
  return (
    <>
      <Heading
        text='Record a Meeting        '
      />

      <ListComp
        headingText='You can use this feature only if a host allows its participants to record meetings.'

      />

      <ImageComp
        name={"recordMeeting1"}

      />

      {!isMobileDevice() && (
        <ImageComp name="recordMeeting2" />
      )}

      <ListComp

        data={[

          {
            name: (
              <>
                While in a meeting, click the ‘<MoreVertIcon style={{ verticalAlign: 'middle' }} />’ icon.
              </>
            ),
            id: '13'
          },
          {
            name: 'In the more option, click on the ‘Start Recording.’',
            id: '14',

          }, {
            name: 'The recording timer will appear now in the top left corner and a recording notification on the top right corner. ',
            id: '15',

          }, {
            name: 'Once you are done with recording, click on the red button in the top left corner.',
            id: '16',

          }, {
            name: 'Click on the ‘Stop’ button.    ',
            id: '17',

          },
          {
            name: 'The recording will save automatically.  ',
            id: '18',

          },

        ]}

      />

    </>
  );
};

export default RecordAMeeting;
