import React from 'react';
import { Typography } from '@mui/material';

const Paragraph = ({ text = "", highlightWords = [] }) => {
  return (
    <Typography
      variant='body1'
      component="p"
      sx={{ color: 'grey.400' }}
    >
      {text.split(' ').map((word, index) => (
        <span key={index} style={highlightWords.includes(word) ? { color: '#434546' } : {}}>
          {word}{' '}
        </span>
      ))}
    </Typography>
  )
}

export default Paragraph;

