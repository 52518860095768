import React from 'react';
import Heading from './components/heading';
import ListComp from './components/list';

const CheckAllScheduleMeetings = () => {
  return (
    <>
      <Heading
        text='Check All Scheduled Meetings '
      />

      <ListComp
        data={[{
          name: 'Go to the meeting page by clicking ‘Meeting’ in the header menu.',
          id: '12',

        }, {
          name: 'On this page, select the ‘Upcoming’ tab from the list. ',
          id: '13'
        },
        {
          name: 'Here, you will see all your scheduled meetings.  ',
          id: '14'
        },

        ]}
      />

    </>
  );

};

export default CheckAllScheduleMeetings;
