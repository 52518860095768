import React, { memo } from 'react';
import { IconButton } from '@mui/material';
import MicOffIcon from '@mui/icons-material/MicOff';

function MicOffIconPeer() {
    return (
        <IconButton sx={{
            color: 'rgba(255,255,255,.8)',
            mr: '5px',
            '&:hover': {
                color: 'rgba(255,255,255,.8)',
                bgcolor: 'rgba(0,0,0,0)',
            }
        }}>

            <MicOffIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
    )
}
export default memo(MicOffIconPeer);