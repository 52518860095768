import React from 'react';
import Heading from './components/heading';
import ListComp from './components/list';
import { capitalizeFirstLetter } from './components/util';
import { useSelector } from 'react-redux';

const CancelAScheduleMeeting = () => {
  const { branding = {} } = useSelector(state => state?.branding);
  const { companyBrandName = "" } = branding || {};
  const capitalizedCompanyBrandName = capitalizeFirstLetter(companyBrandName);
  return (
    <>
      <Heading
        text='Cancel a Scheduled Meeting  '
      />

      <ListComp

        data={[

          {
            name: `Visit the ‘Meeting’ page once you have logged into your  ${capitalizedCompanyBrandName} Account.`,
            id: '13',

          },
          {
            name: 'On this page, you will all of your meetings.',
            id: '14',

          }, {
            name: 'You can filter scheduled meetings by clicking on the ‘Upcoming’ tab. ',
            id: '15',

          }, {
            name: 'Go to the meeting you want to cancel.',
            id: '16',


          },
          {
            name: 'In the action section of that meeting, three buttons are there; ',
            id: '18',
            child: [{
              name: 'Start',
              id: '13'
            }, {
              name: 'Edit',
              id: '14'
            },
            {
              name: 'View Details  ',
              id: '12'
            },]

          },
          {
            name: 'Click on the ‘View Details’ button.  ',
            id: '160',


          },
          {
            name: 'Now, click on the ‘Cancel’ button.  ',
            id: '136',


          },
          {
            name: 'After that, click on the ‘Delete’ button.  ',
            id: '126',


          },

        ]}

      />

    </>
  );

};

export default CancelAScheduleMeeting;
