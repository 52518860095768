import React from 'react';
import { Box, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function AppTitleBar({ title, page, setPage }) {
    const navigate = useNavigate();

    const navigateToPage = () => {
        if (page) {
            if (page === 1) return navigate(-1);
            return setPage(page - 1)
        };
        return navigate(-1);
    }

    return (
        <Box sx={{ flexGrow: 1 }} className="ScheduleAppBarBoxGlobal">
            <AppBar position="static" sx={{ backgroundColor: 'grey.900', }} className="ScheduleAppBar">
                <Toolbar className="ScheduleToolBar">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, color: "grey.70", }}
                        onClick={navigateToPage}
                        className="ScheduleIconButton"
                    >
                        <KeyboardBackspaceIcon sx={{ fontSize: '35px' }} className="ScheduleKeyBoardBackspaceIcon" />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'grey.70' }} className="ScheduleAppBarTitle">
                        {title || " "}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
