import { Box, Button } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import {  useSelector } from 'react-redux';


import { axiosPostCall } from '../../../../services/apisCall';
import { renderErrorMessage } from '../../../../components/ToastNotification';

export const WaitingRoomActions = () => {

    const location = useLocation();

    const { roomId = "" } = location?.state || {};
    const { users } = useSelector(state => state.waitingRoom);


    const handleAllowClick = async (actionAll = "") => {
        try {
            if(!users?.length) return renderErrorMessage("No users in the waitng room");
            
            return await axiosPostCall("waitingRoomPeerActions", { roomId, actionAll });
        } catch (error) {
            return renderErrorMessage(error?.message || `Unable to send ${actionAll} request`);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: '5px 15px 5px 15px',
            gap: '10px'
        }}>
            <Button variant='outlined' sx={{ color: '#0C8CE9', borderColor: '#0C8CE9', width: '100%' }} onClick={() => handleAllowClick("allowAll")}>
                Allow all
            </Button>
            <Button variant='outlined' sx={{ color: '#E16C77', borderColor: '#E16C77', width: '100%' }} onClick={() => handleAllowClick("declineAll")}>
                Decline all
            </Button>
        </Box>
    )
}
