const initialState =
{
    openWboard: false,
    data: "{}",
    undoRedoData: {},
    scroll: ""
};

const whiteboard = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_WHITEBOARD_DATA':
            {
                const { data } = action.payload;
                return { ...state, data };
            }
        
        case 'UNDO_REDO_WHITEBOARD':
            {
                return { ...state, undoRedoData: action.payload };
            }
        
        case 'WHITEBOARD_SCROLL': 
            {
                return { ...state, scroll: action.payload }
            }

        case 'WHITEBOARD_STATUS':
            {
                return { ...state, openWboard: action.payload  }
            }

        default:
            return state;
    }
};

export default whiteboard;
