import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddIcon from '@mui/icons-material/Add';
import { getItem, setItem } from '../../../lib/cookiesManager';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { axiosPostCall } from '../../../services/apisCall';
import { configFile } from '../../../config';
import { getMicAndCamPermission } from '../../../utils/common';
import { renderErrorMessage } from '../../ToastNotification';

export default function DashboardHeader({ joinCheck }) {
    const [name, setName] = useState(null);
    const navigate = useNavigate();
    const isLandscape = useSelector(state => state.deviceInfo?.isLandscape);
    const { getProtooUrl, webinarRole, imagesUrl } = configFile;
    const [runQuickMeet, setRunQuickMeet] = useState(false)

    const fetchUserInfo = async () => {
        let data = getItem("token");
        let info = jwt(data);
        setItem("name", info?.name);
        setName(info?.name.split(" ")[0]);
    }

    const startQuickMeet = async () => {
        setRunQuickMeet(true)
        try {
            const token = getItem("token");
            const info = jwt(token);
            let pId = info?.email;

            setItem("name", info?.name);
            let { title = "", description = "", isHostServer, roomId, record, autoRecord, disableRecordPlayPause, serverDetails = {} } = await axiosPostCall("quickMeeting", pId) || {};
            const role = webinarRole?.produce;
            const wssUrl = getProtooUrl({ roomId, peerId: pId, serverDetails });
            if (isHostServer) getMicAndCamPermission();
            const protooUrl = `${wssUrl}&h=${isHostServer}&fp=""`;
            if (protooUrl) {
                navigate(`/meet?roomId=${roomId}&peerId=${pId}`, { state: { protooUrl, peerId: pId, role, name: info?.name, title, description, meetType: 'meet', record, autoRecord, disableRecordPlayPause } });
                setRunQuickMeet(false)
            } else {
                setRunQuickMeet(false)
                return renderErrorMessage("Unable to start quick meet. Try again!")
            }
        } catch (error) {
            setRunQuickMeet(false)
            return renderErrorMessage(error?.message)
        }
    }

    useEffect(() => {
        fetchUserInfo()
    }, [])

    return (
        <Box className='DashboardHeader' sx={
            isLandscape ? {
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                p: "10px 10px 10px 30px"
            }
                : {}}>
            <Box className='UserHeading' sx={{
                textAlign: isLandscape ? "" : 'center',
                color: '#fff',
                pt: isLandscape ? 3 : 8,
            }}>
                <Typography sx={{
                    opacity: '.8',
                    letterSpacing: 1.5,
                }}>It's nice to see you,</Typography>
                <Typography sx={{
                    fontSize: '30px',
                    fontWeight: '500',
                }}>{name || "User"}</Typography>
            </Box>

            <Box className='HeaderButtons' sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2,
                pb: 2,
            }}>
                <ListItem sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '93px'
                }}
                    onClick={() => joinCheck(true)}>
                    <ListItemAvatar sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Avatar sx={{
                            backgroundColor: 'card.main',
                            height: 56,
                            width: 56,
                            boxShadow: '4px 6px 0px 0px rgba(0,0,0,0.15)',
                        }}>
                            <AddIcon sx={{
                                color: '#0053CC'
                            }} />
                        </Avatar>
                    </ListItemAvatar>
                    <Typography sx={{
                        fontSize: '12px',
                        opacity: '.6',
                        pt: 1,
                        fontWeight: 500,
                        color: '#ffffff',
                    }}>Join Now</Typography>
                </ListItem>

                <ListItem sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '93px',
                    // px: .5,
                }} onClick={() => navigate('/schedule_meet')}>
                    <ListItemAvatar sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <Avatar sx={{
                            backgroundColor: 'card.main',
                            height: 56,
                            width: 56,
                            boxShadow: '4px 6px 0px 0px rgba(0,0,0,0.15)',
                        }}>
                            <DateRangeIcon sx={{
                                color: '#0053CC'
                            }} />
                        </Avatar>
                    </ListItemAvatar>
                    <Typography sx={{
                        fontSize: '12px',
                        opacity: '.6',
                        pt: 1,
                        fontWeight: 500,
                        color: '#ffffff',
                    }}>Schedule</Typography>
                </ListItem>

                <ListItem sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '93px',
                    // px: .5,
                }} onClick={() => startQuickMeet()}>
                    <ListItemAvatar sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>

                        <Avatar sx={{
                            backgroundColor: 'card.main',
                            height: 56,
                            width: 56,
                            boxShadow: '4px 6px 0px 0px rgba(0,0,0,0.15)',
                        }}>
                            {runQuickMeet ?
                                <img src={imagesUrl?.loading} alt='loading...' width={'20%'} />
                                :
                                <VideoCallIcon sx={{
                                    color: '#0053CC'
                                }} />
                            }
                        </Avatar>

                    </ListItemAvatar>

                    <Typography sx={{
                        fontSize: '12px',
                        opacity: '.6',
                        pt: 1,
                        fontWeight: 500,
                        color: '#ffffff',
                    }}>Quick meet</Typography>
                </ListItem>
            </Box>
        </Box>
    )
}
