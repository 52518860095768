
import { useTheme, css } from '@emotion/react';
import React from 'react';
import { Box } from '@mui/material';
import Heading from './heading';
import { isMobileDevice } from './util';

const ListComp = ({ data = [], headingText = "", type = "subHeading", listType = "unordered", disableMobileMargin = false }) => {
  const theme = useTheme();
  const isMobile = isMobileDevice()
  const { palette: { grey } } = theme;
  const ListTag = listType === "ordered" ? "ol" : "ul";
  const alphaListStyle = css`
    list-style-type: lower-alpha;
    margin-left: 20px;
  `;
  const marginLeft = isMobile ? (!disableMobileMargin ? '35px' : '0px') : '35px';

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    }}>
      <Heading
        text={headingText}
        type={type}
      />

      <ListTag style={{ marginLeft }} css={listType === 'ordered' && alphaListStyle}>
        {data.map(({ name, id, child = [] }, index) => (
          <li key={id || index} style={{ color: grey[400] }}>
            {name}

            <>
              {!!child.length && <ListComp
                data={child}
                type="listItem"
                listType={listType === 'ordered' ? 'alpha' : 'unordered'}
              />}
            </>

          </li>
        ))}
      </ListTag>

    </Box>
  )
}

export default ListComp