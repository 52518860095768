const initialState = { count: 0 };

const room = (state = initialState, action) => {
	switch (action.type) {
		case 'TOTAL_WATCHING':
			{
				const { count } = action.payload;

				return { ...state, count };
			}

		default:
			return state;
	}
};

export default room;
