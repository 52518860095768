import React, { memo } from 'react';
import { Chip } from '@mui/material';

function DisplayName({ focused, displayName }) {
    return (
        <Chip
            label={displayName || "User"}
            sx={{
                position: 'absolute',
                bottom: focused ? 0 : 10,
                left: focused ? 2 : 10,
                bgcolor: 'rgba(0,0,0,0.5)',
                color: '#ffffff',
                fontSize: '12px',
                height: '24px'
            }} />
    )
}

export default memo(DisplayName);
