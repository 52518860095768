import React from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { IconButton } from '@mui/material';

const UploadMenu = ({ anchorEl, handleClose, uploadImageRef, uploadPdfRef }) => {

    return (
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{ onMouseLeave: handleClose }}
        >
            <MenuItem onClick={() => uploadImageRef.current.click()} sx={{ color: 'rgb(99, 115, 129)', gap: '3px' }} >
                <IconButton sx={{ color: 'rgb(99, 115, 129)' }}>
                    <ImageIcon />
                </IconButton>
                Image
            </MenuItem>
            <MenuItem onClick={() => uploadPdfRef.current.click()} sx={{ color: 'rgb(99, 115, 129)', gap: '3px' }}>
                <IconButton sx={{ color: 'rgb(99, 115, 129)' }}>
                    <PictureAsPdfIcon />
                </IconButton>
                PDF
            </MenuItem>
        </Menu>
    )
}

export default UploadMenu