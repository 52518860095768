import { useEffect } from 'react';
import parse from 'url-parse';
import jwt from 'jwt-decode';
import { setBulkItems } from '../../lib/cookiesManager';
import { renderErrorMessage } from '../../components/ToastNotification';
import { createRedirectPath } from '../../utils/common';

const CustomSession = () => {
  useEffect(() => {
    const url = parse(window.location.href, true);
    const { t, s, e, m, j, r } = url.query;

    /* 
      j = join,
      t = token,
      s = session token,
      m = theme,
      r = roomId
    */
    if (!t || !s || !e || !m) return renderErrorMessage("Invalid data!")

    const { guide = false } = jwt(t);

    if (guide) {
      setBulkItems({
        token: t,
        sessionToken: s,
        exp: e,
        theme: m,
        guide,
      });
    } else {
      setBulkItems({
        token: t,
        sessionToken: s,
        exp: e,
        theme: m,
      });
    }

    if (j && r) {
      let redirectUrl = url?.origin;
      if (j === "join") redirectUrl = redirectUrl + "/join_meet?roomId=" + r;
      if (j === "list") redirectUrl = redirectUrl + "/list"
      window.location.href = createRedirectPath(redirectUrl);
      return
    }

    window.location.href = createRedirectPath('/');
  }, []);
  return (<> </>)
}

export default CustomSession;

