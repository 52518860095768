import React, { useEffect, useState, memo } from 'react';
import UrlParse from 'url-parse';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
    IconButton,
    Divider,
    List,
    Avatar,
    Tabs,
    Tab,
    Stack,
    Typography,
    Drawer,
    Box,
    InputBase,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Button,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { renderErrorMessage } from '../../../components/ToastNotification';
import moment from 'moment';
import { removeNewPrivateChat, removeNewPublicChat } from '../../../redux/actions/chatActions';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { getItem, setItem } from '../../../lib/cookiesManager';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import randomString from 'random-string';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { encryptMessage, decryptMessage } from '../../../utils/common';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`panellistpanel-${index}`}
            aria-labelledby={`panellist-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `panellist-${index}`,
        'aria-controls': `panellistpanel-${index}`,
    };
};

function Chat({ open, drawerWidth, theme, handleDrawerClose, DrawerHeader, roomId, peerId, displayName, share, forceOpenChat, data, updateForceOpenChat }) {
    const [value, setValue] = useState(0);
    const peerState = useSelector(state => state.peers);
    const publicChatState = useSelector(state => state.publicChat);
    const privateChatState = useSelector(state => state.privateChat);
    const consumers = useSelector(state => state.consumers);
    const { meetType, userId, e2ee } = useSelector(state => state.webinarInfo);
    const [producers, setProducers] = useState([]);
    const [panelistSearchTxt, setPanelistSearchTxt] = useState("");
    const [sPrivateChat, setSPrivateChat] = useState(false);
    const [activePChat, setActivePChat] = useState({});
    const [pMessage, setPMessage] = useState([]);
    const [publicMessage, setPublicMessage] = useState([]);
    const [currentTypePublicMsg, setCurrentTypePublicMsg] = useState("");
    const [currentTypePrivateMsg, setCurrentTypePrivateMsg] = useState("");
    const [showNotePopup, setShowNotePopup] = useState(false);
    const [popupCheck, setPopupCheck] = useState(false);
    const [publicMessageQueue, setPublicMessageQueue] = useState([]);
    const chatIconSx = { width: '11px', height: '11px', ml: -0.5, mr: -1, color: "#969696" };
    const urlParser = new UrlParse(window.location.href, true);
    const e2eKey = urlParser.query?.key || "";

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const iconSx = { width: '16px', height: '16px' };

    // Fetch public chat 
    async function fetchPublicChat(size = 100) {
        try {
            let publicChatArr = await axiosGetCall("fetchChat", { size, roomId, });
            if (e2ee) {
                publicChatArr.chats = publicChatArr?.chats.map((chat) => {
                    const { message } = chat;
                    return { ...chat, message: decryptMessage(message, e2eKey) }
                })
            }
            if (typeof publicChatArr === 'object') {
                setPublicMessage(publicChatArr?.chats || []);
            }
        } catch (error) {
            // render error
        }
    }

    async function fetchPrivateChat(size = 100, peerId) {
        try {
            let publicChatArr = await axiosGetCall("fetchChat", { size, roomId, type: 'privateChat', peerId, userId, meetType });
            if (e2ee) {
                publicChatArr.chats = publicChatArr?.chats.map((chat) => {
                    const { message } = chat;
                    return { ...chat, message: decryptMessage(message, e2eKey) }
                })
            }
            if (typeof publicChatArr === 'object') {
                setPMessage(publicChatArr?.chats || []);
            }
        } catch (error) {
            // render error
        }
    }

    // Filter data from panelist data according to search text.
    const searchFilterPanelist = (item) => {
        if (panelistSearchTxt.replace(/\s+/g, " ").trim() === "") {
            return item;
        } else if (item.name.toLowerCase().includes(panelistSearchTxt.replace(/\s+/g, " ").trim().toLowerCase())) {
            return item
        }
    }

    // Enable private chat section for one to one chat
    const showPrivateChat = (data) => {
        setPMessage([])
        setSPrivateChat(true);
        setActivePChat(data);
        fetchPrivateChat(100, data?.peerId);
        if (privateChatState && privateChatState?.ids.includes(data?.peerId)) {
            dispatch(removeNewPrivateChat(data?.peerId))
        }
    }

    const sendMessage = async (data, isPrivate = false) => {
        try {
            const { message = "" } = data;
            if (e2ee) {
                data = { ...data, message: e2ee ? encryptMessage(message, e2eKey) : message }
            }
            
            let res = await axiosPostCall("chat", data)
            // if (res && !isPrivate) return setCurrentTypePublicMsg('');
            if (res && isPrivate) {
                return setPMessage([...pMessage, { ...data, message, status: "success" }])
                //  setCurrentTypePrivateMsg('');
            }
        } catch (error) {
            return renderErrorMessage(error?.message);
        }
    }

    // Send private message one to one.
    const sendPMsg = (e, search = false) => {
        const messageId = randomString({ length: 8 }).toLowerCase();
        if (!search) {
            let msg = e.target.value.trim();
            if (e.key === 'Enter' && msg !== "") {
                setPMessage([...pMessage, { "type": "privateChat", roomId, from: peerId, to: activePChat?.peerId, "message": msg, displayName, messageId, status: "pending" }])
                setCurrentTypePrivateMsg('')
                sendMessage({ "type": "privateChat", roomId, from: peerId, to: activePChat?.peerId, "message": msg, displayName, messageId }, true);
            } return;
        }
        if (!currentTypePrivateMsg) return;
        setPMessage([...pMessage, { "type": "privateChat", roomId, from: peerId, to: activePChat?.peerId, "message": currentTypePrivateMsg, displayName, messageId, status: "pending" }])
        setCurrentTypePrivateMsg('')
        sendMessage({ "type": "privateChat", roomId, from: peerId, to: activePChat?.peerId, "message": currentTypePrivateMsg, displayName, messageId }, true);
    }

    // Send public message one to one.
    const sendPublicMsg = (e, search = false) => {
        const messageId = randomString({ length: 8 }).toLowerCase();
        if (!search) {
            let msg = e.target.value.trim();
            if (e.key === 'Enter' && msg !== "") {
                setPublicMessageQueue([...publicMessageQueue, { messageId, "message": msg, from: peerId }])
                setCurrentTypePublicMsg('')
                sendMessage({ "type": "groupChat", roomId, from: peerId, to: roomId, "message": msg, displayName, messageId });
            }
            return;
        }
        if (!currentTypePublicMsg) return;
        setPublicMessageQueue([...publicMessageQueue, { messageId, "message": currentTypePublicMsg, from: peerId }])
        setCurrentTypePublicMsg('')
        sendMessage({ "type": "groupChat", roomId, from: peerId, to: roomId, "message": currentTypePublicMsg, displayName, messageId });
    }

    // const activePrivateCheck = () => {
    //     dispatch(removeNewPrivateChat())
    // }

    const popupSubmit = () => {
        if (popupCheck) {
            setItem("closeChatPopup", true)
        }
        setShowNotePopup(false);

        if (forceOpenChat && data) {
            let findData = producers.find((item) => item?.peerId === data)
            setValue(1);
            showPrivateChat({ ...findData });
            updateForceOpenChat()
        }

    }

    function scrollToBottomMessage(idName) {
        const el = document.getElementById(idName);
        if (el) {
            el.scrollTop = el.scrollHeight;
        }
    }

    // Scroll to bottom when message added to queue receive new message.
    useEffect(() => {
        scrollToBottomMessage('chat-feed')
    }, [publicMessageQueue, publicMessage])

    // Scroll to bottom when message added private message queue.
    useEffect(() => {
        scrollToBottomMessage('chat-feed-private')
    }, [pMessage])

    // Update producers data when data change and default initial producers data.
    useEffect(() => {
        let host = [];
        Object.entries(peerState).forEach((peer) => {
            const consumersArray = peer[1].consumers.map((consumerId) => consumers[consumerId]);
            if (peer[1]?.role === "host" || consumersArray[0]?.role === "host") {
                const name = peer[1].displayName;
                const peerId = peer[1].id;
                host.push({ name, peerId })
                return;
            }
            return;
        })
        setProducers(host);
    }, [peerState, consumers])

    // update msg when new message receive
    useEffect(() => {
        if (open && publicChatState?.chat && !value) {
            let { chat = {} } = publicChatState;
            if (e2ee) {
                const { message } = chat;
                chat = { ...chat, message: e2ee ? decryptMessage(message, e2eKey) : message }
            }
            const updateQueue = publicMessageQueue.filter(({ messageId }) => messageId !== chat?.messageId)
            setPublicMessageQueue(updateQueue)
            setPublicMessage([...publicMessage, chat])
            dispatch(removeNewPublicChat())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicChatState?.chat])

    // update msg when new message receive to private message
    useEffect(() => {
        if (sPrivateChat && privateChatState?.chat && privateChatState?.chat?.from === activePChat?.peerId) {
            let { chat = {} } = privateChatState;
            if (e2ee) {
                const { message } = chat;
                chat = { ...chat, message: e2ee ? decryptMessage(message, e2eKey) : message }
            }
            setPMessage([...pMessage, chat])
            dispatch(removeNewPrivateChat(activePChat?.peerId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateChatState?.chat])

    // Initial fetch public chat data
    useEffect(() => {
        if (open && !value) {
            fetchPublicChat()
            if (publicChatState?.chat) {
                setPublicMessage([...publicMessage, publicChatState?.chat])
                dispatch(removeNewPublicChat())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, value])

    useEffect(() => {
        if (forceOpenChat && data) {
            if (share) {
                if (!getItem("closeChatPopup")) {
                    setShowNotePopup(true)
                    return;
                }
            }
            let host = [];
            Object.entries(peerState).forEach((peer) => {
                const consumersArray = peer[1].consumers.map((consumerId) => consumers[consumerId]);
                if (peer[1]?.role === "host" || consumersArray[0]?.role === "host") {
                    const name = peer[1].displayName;
                    const peerId = peer[1].id;
                    host.push({ name, peerId })
                    return;
                }
                return;
            })
            let findData = host.find((item) => item?.peerId === data)
            // setActivePChat({ ...findData });
            setValue(1);
            showPrivateChat({ ...findData });
            updateForceOpenChat()
        }
        // eslint-disable-next-line
    }, [forceOpenChat])
    return (
        <>
            <Drawer sx={{
                width: drawerWidth,
                flexShrink: 0,
                // overflowY: "hidden",
                '& .MuiDrawer-paper': { width: drawerWidth },
            }}
                variant="persistent"
                anchor="right"
                open={open}>
                <DrawerHeader sx={{ color: "#000000" }}>
                    Chat
                    <IconButton onClick={() => handleDrawerClose()}>
                        {theme === 'rtl' ? <CloseIcon sx={{
                            width: '18px',
                            height: '18px',
                        }} /> : <CloseIcon sx={{
                            width: '18px',
                            height: '18px',
                        }} />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {/* Title of side bar tabs */}
                <Tabs value={value} onChange={handleChange} aria-label="Public tabs ">
                    <Tab label="Public " {...a11yProps(0)} sx={{
                        width: '50%'
                    }} />
                    <Tab label="Private " onClick={() => share ? getItem("closeChatPopup") ? "" : setShowNotePopup(true) : ""} {...a11yProps(1)} sx={{
                        width: '50%'
                    }} />
                </Tabs>
                <Divider />

                {/* Public chat section */}
                <TabPanel value={value} index={0}>
                    <Stack sx={{
                        background: '#000'
                    }}>
                    </Stack>

                    <List id="chat-feed" className="chat-feed" style={{
                        height: window.innerHeight - 165,
                        overflowY: 'scroll',
                        background: "#e4e4e4",
                        padding: '0 12px',
                        px: 1,
                    }}>

                        {publicMessage.length > 0 && publicMessage.concat(publicMessageQueue).map(({ from = null, message = "", createdAt = new Date(), displayName = "", messageId = "" }, i) => <Box key={i}>
                            {/* Receiver chat section (receiver)*/}
                            {from !== peerId &&
                                <Box className='chat-left' sx={{
                                    width: "100%",
                                    float: "left",
                                    alignItems: "flex-start",
                                    display: "flex",
                                    flexDirection: "column",
                                    pt: 1,
                                }}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "flex-start",
                                    }}>
                                        <Avatar
                                            sx={{ marginRight: '8px', backgroundColor: "#ccd4de", color: '#242628' }}
                                        >
                                            {displayName?.charAt(0).toUpperCase()}
                                        </Avatar>

                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "start",
                                            flexDirection: "column",
                                        }}>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ color: '#727374' }}
                                            >
                                                {displayName?.charAt(0).toUpperCase() + displayName.slice(1)}
                                            </Typography>
                                            <Typography sx={{
                                                background: "#fff",
                                                borderRadius: "10px 10px 10px 0px",
                                                mt: .7,
                                                px: 2,
                                                py: .5,
                                                fontSize: "14px",
                                                maxWidth: "180px",
                                                color: '#434546',
                                                "-webkitHyphens": "auto",
                                                "- mozHyphens": "auto",
                                                "-msHyphens": "auto",
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                                hyphens: "auto",
                                            }}>{message}</Typography>
                                            <Typography sx={{
                                                fontSize: 11,
                                                color: "#969696",
                                                pt: .5,
                                                fontWeight: "500",
                                            }}>{moment(createdAt).format('hh:mm A')}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {/* Self chat section (sender)*/}
                            {from === peerId &&
                                <Box className='chat-right' sx={{
                                    width: "100%",
                                    alignItems: "flex-end",
                                    display: "flex",
                                    flexDirection: "column",
                                    pt: 1,
                                }}>
                                    <Typography sx={{
                                        background: "#1976d2",
                                        color: "#ffffff",
                                        borderRadius: "10px 10px 0px 10px",
                                        mt: 1,
                                        px: 2,
                                        py: .5,
                                        fontSize: "14px",
                                        textAlign: "right",
                                        maxWidth: "180px",
                                        "-webkitHyphens": "auto",
                                        "- mozHyphens": "auto",
                                        "-msHyphens": "auto",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        hyphens: "auto",
                                    }}>{message}</Typography>

                                    <Typography sx={{
                                        fontSize: 11,
                                        color: "#969696",
                                        pt: .5,
                                        fontWeight: "500",
                                    }}>{moment(createdAt).format('hh:mm A')} <IconButton>
                                            {publicMessageQueue.find(obj => obj.messageId === messageId) ?
                                                <AccessTimeIcon sx={{ ...chatIconSx }} /> :
                                                <CheckIcon sx={{ ...chatIconSx, color: "#49B815" }} />}
                                        </IconButton>
                                    </Typography>
                                </Box>
                            }
                        </Box>

                        )}
                    </List>

                    <ThemeProvider theme={theme}>
                        <Stack direction="row" spacing={1} sx={{
                            background: '#fff',
                            position: 'fixed',
                            bottom: '0',
                            width: '-webkit-fill-available',
                        }}>
                            <Box
                                // component="form"
                                sx={{ p: '4px', display: 'flex', alignItems: 'center', width: 400 }}
                            >
                                <InputBase
                                    sx={{
                                        ml: 1,
                                        flex: 1,
                                        fontSize: 14,
                                        fontWeight: 500,
                                    }}
                                    placeholder="Enter message here..."
                                    value={currentTypePublicMsg}
                                    onChange={(e) => setCurrentTypePublicMsg(e.target.value)}
                                    onKeyDown={(e) => sendPublicMsg(e)}
                                    inputProps={{ sx: { color: "#2C2D2F" } }}
                                />
                                <IconButton type="button" color="primary" sx={{ p: '10px' }} aria-label="search" onClick={(e) => sendPublicMsg(e, true)}>
                                    <SendIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </ThemeProvider>
                </TabPanel>

                {/* Private Chat section */}
                <TabPanel value={value} index={1}>
                    {!sPrivateChat &&
                        <>
                            <Stack sx={{
                                px: 1,
                                pt: 1,
                            }}>
                                <TextField
                                    id="outlined-size-small"
                                    placeholder="Search panelists here..."
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon size="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => setPanelistSearchTxt(e.target.value)}
                                    inputProps={{ sx: { color: "#5B5C5D" } }}
                                />
                            </Stack>
                            <List sx={{
                                // height: /* '100%',// */'592px',
                                height: window.innerHeight - 180,
                                overflowY: 'scroll',
                                paddingBottom: 6,
                            }} >
                                {
                                    (producers.length > 0) && producers.filter((item) => searchFilterPanelist(item)).map((data, index) => (
                                        <ListItem key={index} onClick={() => showPrivateChat(data)} sx={{ bgcolor: '#F4F5F8', borderBottom: '1px solid #fff' }}>
                                            <ListItemIcon sx={{
                                                minWidth: 46,
                                            }}>
                                                <Avatar alt={data.name} src="./images/01.png" sx={{
                                                    width: 32,
                                                    height: 32,
                                                    backgroundColor: "#ccd4de",
                                                    color: '#5B5C5D'
                                                }} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={{
                                                    fontWeight: '500',
                                                    color: '#454545'
                                                }}>{data.name}
                                                </Typography>
                                            </ListItemText>
                                            {privateChatState?.ids.includes(data.peerId) && <FiberManualRecordIcon sx={{ width: '16px', height: '16px', color: '#28B446' }} />}
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </>
                    }

                    {/* Single private chat section */}
                    {sPrivateChat &&
                        <>
                            <Stack sx={{
                                px: 1,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                                bgcolor: '#E0EDFF'
                            }}>
                                <IconButton sx={{ color: '#5B5C5D' }} onClick={() => setSPrivateChat(false)} > <ArrowBackOutlinedIcon /></IconButton>
                                <Typography variant="subtitle2" sx={{ color: '#5B5C5D' }}>{activePChat?.name}</Typography>
                            </Stack>
                            <Divider />
                            <List id="chat-feed-private" className="chat-feed-private" style={{
                                height: window.innerHeight - 220,
                                overflowY: 'scroll',
                                background: "#e4e4e4",
                                padding: '0 12px',
                                px: 1,
                            }} >
                                {pMessage.length > 0 && pMessage.map(({ from = null, message = "", createdAt = new Date(), status = "" }, i) =>
                                    <Box key={i}>
                                        {/* Receiver chat section (receiver)*/}
                                        {from !== peerId &&
                                            <Box className='chat-left' sx={{
                                                width: "100%",
                                                float: "left",
                                                alignItems: "flex-start",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    justifyContent: "flex-start",
                                                }}>
                                                    <Box sx={{
                                                        display: "flex",
                                                        alignItems: "start",
                                                        flexDirection: "column",
                                                    }}>
                                                        <Typography sx={{
                                                            background: "#fff",
                                                            borderRadius: "10px 10px 10px 0px",
                                                            mt: .7,
                                                            px: 2,
                                                            py: .5,
                                                            fontSize: "14px",
                                                            maxWidth: "180px",
                                                            color: '#434546',
                                                            "-webkitHyphens": "auto",
                                                            "- mozHyphens": "auto",
                                                            "-msHyphens": "auto",
                                                            wordWrap: "break-word",
                                                            overflowWrap: "break-word",
                                                            hyphens: "auto",
                                                        }}>{message}</Typography>

                                                        <Typography sx={{
                                                            fontSize: 11,
                                                            color: "#969696",
                                                            pt: .5,
                                                            fontWeight: "500",
                                                        }}>{moment(createdAt).format('hh:mm A')}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                        {from === peerId &&
                                            <Box className='chat-right' sx={{
                                                width: "100%",
                                                alignItems: "flex-end",
                                                display: "flex",
                                                flexDirection: "column",
                                                pt: 2,
                                            }}>
                                                <Typography sx={{
                                                    background: "#1976d2",
                                                    color: "#ffffff",
                                                    borderRadius: "10px 10px 0px 10px",
                                                    mt: 1,
                                                    px: 2,
                                                    py: .5,
                                                    fontSize: "14px",
                                                    textAlign: "right",
                                                    maxWidth: "180px",
                                                    "-webkitHyphens": "auto",
                                                    "- mozHyphens": "auto",
                                                    "-msHyphens": "auto",
                                                    wordWrap: "break-word",
                                                    overflowWrap: "break-word",
                                                    hyphens: "auto",
                                                }}>{message}</Typography>
                                                <Typography sx={{
                                                    fontSize: 11,
                                                    color: "#969696",
                                                    pt: .5,
                                                    fontWeight: "500",
                                                }}>{moment(createdAt).format('hh:mm A')}<IconButton>
                                                        {status === "pending" ?
                                                            <AccessTimeIcon sx={{ ...chatIconSx }} /> :
                                                            <CheckIcon sx={{ ...chatIconSx, color: "#49B815" }} />}
                                                    </IconButton>
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                )}
                            </List>

                            <ThemeProvider theme={theme}>
                                <Stack direction="row" spacing={1} sx={{
                                    background: '#fff',
                                    position: 'fixed',
                                    bottom: '0',
                                    width: '-webkit-fill-available',
                                }}>
                                    <Box
                                        // component="form"
                                        sx={{ p: '4px', display: 'flex', alignItems: 'center', width: 400 }}
                                    >
                                        <InputBase
                                            sx={{
                                                ml: 1,
                                                flex: 1,
                                                fontSize: 14,
                                                fontWeight: 500,
                                            }}
                                            placeholder="Enter message here..."
                                            onKeyDown={(e) => sendPMsg(e)}
                                            value={currentTypePrivateMsg}
                                            onChange={(e) => setCurrentTypePrivateMsg(e.target.value)}
                                            inputProps={{ sx: { color: "#2C2D2F" } }}
                                        />
                                        <IconButton type="button" color="primary" sx={{ p: '10px' }} aria-label="search" onClick={(e) => sendPMsg(e, true)}>
                                            <SendIcon />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </ThemeProvider>
                        </>
                    }
                </TabPanel>
            </Drawer>

            {/* show warning on show private chat when screen share */}
            <Modal
                open={showNotePopup}
                onClose={() => setShowNotePopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: '#CCCCCC',
                    // border: '2px solid #000',
                    boxShadow: 24,
                    borderRadius: '10px',
                    p: 4,

                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <IconButton sx={{
                            bgcolor: "rgba(220, 160, 0, 0.1)",
                            color: '#DCA000',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            '&:hover': {
                                bgcolor: "rgba(220, 160, 0, 0.1)",
                                color: '#DCA000',
                            }
                        }}>
                            <WarningAmberOutlinedIcon sx={{ width: '70px', height: '70px' }} />
                        </IconButton>
                    </Box>
                    <Typography id="modal-modal-description" sx={{ mt: 2, color: "#000000" }}>
                        Screen sharing is on, Everyone is able to view your chat screen.
                    </Typography>

                    <Typography sx={{ mt: 2, color: '#2C2D2F' }}>
                        Do you want to continue?
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        // alignItems: 'center',
                        mt: 2,
                        mb: 1
                    }}>
                        <Button variant="outlined" color='success' sx={{
                            ml: 1,
                            borderRadius: '10px',
                            width: '20%'
                        }} onClick={() => popupSubmit()}>Yes</Button>
                        <Button variant="outlined" color='error' sx={{
                            ml: 1,
                            borderRadius: '10px',
                            width: '20%'
                        }} onClick={() => {
                            setShowNotePopup(false);
                            setValue(0)
                            handleDrawerClose()
                        }}>No</Button>

                    </Box>
                    <FormControlLabel control={<Checkbox
                        size="small"
                        inputProps={{ 'aria-label': 'Checkbox demo' }}
                        checked={popupCheck}
                        onChange={(e) => setPopupCheck(e.target.checked)} />
                    } label={<Typography sx={{ fontSize: '14px', fontWeight: 300, color: '#2C2D2F' }}>Disable popup for this meeting</Typography>} />

                </Box>
            </Modal>
        </>
    )
}

export default memo(Chat)
