import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';
import { isMobileDevice } from './components/util';


const MeetingControl = () => {
  const mobileData = [
    {
      name: 'The counter in the top right corner represents the number of participants in the meeting.',
      id: '13',
    },
    {
      name: 'Click this button to see the meeting details and invite link.',
      id: '14',
    },
    {
      name: 'More option:',
      id: '2',
      child: [
        { name: 'Start Recording: to record the meeting for future reference.', id: '12' },
        { name: 'Waiting Room: Participants who need permission to join the meeting will show up here.', id: '13' },
      ],
    },
    {
      name: 'Use this to mute or unmute yourself.',
      id: '16',
    },
    {
      name: 'Use this to turn your webcam on and off during the meeting.',
      id: '17',
    },
    {
      name: 'To see and edit all the participants who are in the meeting.',
      id: '18',
    },
    {
      name: 'Use this to chat with the whole group or individuals during the meeting.',
      id: '19',
    },
    {
      name: 'Use it to end the meeting.',
      id: '232',
    },
    {
      name: 'This is your self-view of the current meeting.',
      id: '2311',
    },
    {
      name: 'Pin any user, so that they stay in your vision.',
      id: '201',
    },
    {
      name: 'To keep any particular participants in the focus.',
      id: '291',
    },
  ];

  const webData = [
    {
      name: 'The counter in the top right corner represents the number of participants in the meeting.',
      id: '13',
    },
    {
      name: 'Click this button to see the meeting details and invite link.',
      id: '14',
    },
    {
      name: 'Hold and drag to reposition the toolbar.',
      id: '15',
    },
    {
      name: 'Use this to mute or unmute yourself.',
      id: '16',
    },
    {
      name: 'Use this to turn your webcam on and off during the meeting.',
      id: '17',
    },
    {
      name: 'To see and edit all the participants who are in the meeting.',
      id: '18',
    },
    {
      name: 'Use this to chat with the whole group or individuals during the meeting.',
      id: '19',
    },
    {
      name: 'To enable the share screen, click this button.',
      id: '1',
    },
    {
      name: 'More option:',
      id: '2',
      child: [
        { name: 'Start Recording: to record the meeting for future reference.', id: '12' },
        { name: 'Waiting Room: Participants who need permission to join the meeting will show up here.', id: '13' },
        { name: 'Whiteboard: Create a better presentation and understanding among your audience, just like a real whiteboard.', id: '14' },
      ],
    },
    {
      name: 'Use it to end the meeting.',
      id: '232',
    },
    {
      name: 'This is your self-view of the current meeting.',
      id: '2311',
    },
    {
      name: 'Pin any user, so that they stay in your vision.',
      id: '201',
    },
    {
      name: 'To keep any particular participants in the focus.',
      id: '291',
    },
  ];

  const data = isMobileDevice() ? mobileData : webData;

  return (
    <>
      <Heading text="Meeting Controls" />
      <ImageComp name="meetingControls" />
      <ListComp data={data} listType="ordered" disableMobileMargin />
    </>
  );
};

export default MeetingControl;
