import React, { useState, useEffect, memo } from 'react';
import { Menu, Typography, Box, IconButton, Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSelector } from 'react-redux';
import { configJson as config } from '../../../../config';

function InfoIconMenu({ handleClose, anchorEl, open, roomClient }) {
    const [copySuccess, setCopySuccess] = useState(false);
    const roomInfo = useSelector(state => state.room);
    const webinarInfo = useSelector(state => state.webinarInfo);
    const [states, setStates] = useState({ bytesReceived: "0 Bytes", bytesSent: "0 Bytes" });
    const { branding = {} } = useSelector(state => state?.branding);
    const { companyBrandName = "" } = branding || {};

    // Stuff to copy text in clip board.
    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            setCopySuccess(true);
        } catch (err) {
            setCopySuccess(false);
        }
    };

    //Convert data in size
    const formatBytesData = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const getStates = async () => {
        const { bytesReceived = 0 } = await roomClient.getSendTransportRemoteStats().catch(() => { }) || {};
        const { bytesSent = 0 } = await roomClient.getRecvTransportRemoteStats().catch(() => { }) || {};
        const formateSent = formatBytesData(bytesReceived)
        const formateReveived = formatBytesData(bytesSent)
        setStates({ bytesReceived: formateReveived, bytesSent: formateSent })

        // await roomClient.getAudioRemoteStats().catch(() => { });

        // await roomClient.getVideoRemoteStats().catch(() => { });
    }

    // useEffect(() => {
    //     setInterval(() => {
    //         getStates()
    //     }, 10000);
    //     // eslint-disable-next-line
    // }, [])

    useEffect(() => {
        if (open && roomInfo?.state !== "disconnected") {
            getStates()
        }
        // eslint-disable-next-line
    }, [open])

    return (
        <Menu
            anchorEl={anchorEl}
            id="webinar-info"
            open={open}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    background: '#222222',
                    padding: '20px',
                    marginLeft: 1,
                    marginTop: 1,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    minWidth: '300px',
                    borderRadius: '10px',
                    color: '#7E7E7E',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 43,
                        width: 10,
                        height: 10,
                        bgcolor: '#222222',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <Typography sx={{
                color: '#7E7E7E',
                fontSize: '12px'
            }}>{`Welcome to the ${companyBrandName} Meeting`}</Typography>
            <Typography variant='h6' sx={{
                fontWeight: '700',
                color: '#fff',
            }}>{webinarInfo?.title}</Typography>
            <Typography sx={{
                color: '#7E7E7E',
                fontSize: '12px',
            }}>Copy and share this link with participants.</Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'space-between',
                py: '14px'
            }}>
                <Box>
                    <Box sx={{
                        background: 'rgba(220, 160, 0, 0.1)',
                        borderRadius: '20px',
                        mt: 1
                    }}>
                        <Typography sx={{
                            color: '#DCA000',
                            fontSize: '12px',
                            ml: 1
                            // mb: 2
                        }}> <IconButton>
                                <WarningAmberIcon sx={{ color: '#DCA000' }} />
                            </IconButton>Note: Login required to join as a host.</Typography>
                    </Box>

                    <Typography sx={{
                        color: '#fff',
                        fontSize: '13px',
                        fontWeight: '600',
                        mt: 2,
                    }}>Team Invite Link </Typography>

                    <Typography sx={{
                        color: '#7E7E7E',
                        fontSize: '12px',
                    }}>{webinarInfo?.url}</Typography>
                </Box>
                <IconButton sx={{
                    background: copySuccess ? "#28B446" : "#1770F6",
                    '&:hover': {
                        bgcolor: copySuccess ? "#28B446" : "#1770F6",
                    }
                }} onClick={() => copyToClipboard(webinarInfo?.url)}>
                    <ContentCopyIcon sx={{
                        color: '#fff',
                        fontSize: '12px'
                    }} />
                </IconButton>
            </Box>
            <Box>
                <Typography variant='h6' sx={{
                    fontWeight: '600',
                    color: '#fff',
                }}>Network</Typography>

                <Box >
                    <Grid xs={12} item={true} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1%', width: '100%' }}>
                        <Grid xs={6} item={true} sx={{ width: '100%' }}>  <Typography variant='h6' sx={{
                            fontWeight: '600',
                            color: '#7E7E7E',
                            fontSize: '12px',
                        }}>Data Received : {states?.bytesReceived}</Typography></Grid>
                        <Grid xs={6} item={true} sx={{ width: '100%' }}>  <Typography variant='h6' sx={{
                            fontWeight: '600',
                            color: '#7E7E7E',
                            fontSize: '12px',
                        }}>Data Sent : {states?.bytesSent}</Typography></Grid>
                    </Grid>
                    <Grid xs={12} item={true} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1%', width: '100%' }}>
                        <Grid xs={12} item={true} sx={{ width: '100%' }}>  <Typography variant='h6' sx={{
                            fontWeight: '600',
                            color: '#7E7E7E',
                            fontSize: '12px',
                        }}>Room State : {roomInfo?.state}</Typography></Grid>
                    </Grid>

                </Box>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Typography sx={{
                    color: '#7E7E7E',
                    fontSize: '12px',
                    textAlign: 'right',
                }}>{roomClient?._ipNPort?.ip && roomClient?._ipNPort?.ip.substr(roomClient?._ipNPort?.ip.length - 6)}</Typography>
                <Typography sx={{
                    color: '#7E7E7E',
                    fontSize: '12px',
                    textAlign: 'right',
                }}>Version: {config.version}</Typography>
            </Box>
        </Menu>
    )
}
export default memo(InfoIconMenu);
