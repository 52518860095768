import { authConstants } from "../constants/authConstants";

const initState = {
    token: null,
    authenticate: false,
    loading: false,
    error: null,
};

const auth = (state = initState, action) => {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case authConstants.LOGIN_SUCCESS:
            state = {
                ...state,
                token: action.payload.token,
                authenticate: true,
                loading: false
            }
            break;
        case authConstants.LOGIN_FAILURE:
            console.log("action",action)
            state = {
                ...state,
                error: action.payload.error?.data?.error ?? `Network Error!`,
                loading: false
            }
            break;
        default:
            break;
    }
    return state;
}

export default auth;