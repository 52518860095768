import React from 'react';
import { TextField, InputAdornment, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar({ searchTxt, placeholder }) {
    return (
        <Stack sx={{
            py: 1,
            px: 1,
        }}>
            <TextField
                id="outlined-size-small"
                placeholder={placeholder}
                size="small"
                className='SearchBar'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" sx={{
                            borderRadius: '20px',
                        }}>
                            <SearchIcon size="small" />
                        </InputAdornment>
                    ),
                    sx: {
                        borderRadius: '20px',
                    }
                }}
                onChange={(e) => searchTxt(e.target.value)}
            />
        </Stack>
    )
}
