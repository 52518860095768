import React, { memo } from 'react';
import { connect, useSelector } from 'react-redux';
import Peer from './Peer';
import AudioOnlyPeer from '../AudioOnly/AudioOnlyPeer';
import { Box } from '@mui/material';
// import shortid from "shortid";
// import randomString from 'random-string';

const Peers = ({ peers, roomId, fromPeers, toPeers, focus, focusCount, role }) => {
	const isLandscape = useSelector(state => state.deviceInfo?.isLandscape);

	return (
		<>
			{/* // Render focus screens and remain peers. */}
			{(focus && focusCount < 6 ?
				<>
					<Box className='ScreensRow' sx={{
						display: 'flex',
						width: isLandscape ? 'calc(100% - 123px)' : '100%',
						height: '100%',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}>
						{peers.filter((item) => item?.isFocused).map((peer, index, arr) => {
							return (
								<Peer
									key={peer.id}
									id={peer.id}
									peerCount={arr.length}
									roomId={roomId}
									role={role}
									focusPeerDevide={true}
									focusCount={focusCount}
								/>
							);
						})}
					</Box>

					{peers.filter((item) => !item?.isFocused && item.role !== 'attendee').length > 0 &&
						<Box className='FocusBar' sx={{
							display: 'flex',
							flexDirection: isLandscape ? "column" : "row",
							width: isLandscape ? '123px' : 'calc(100% - 6px)',
							height: isLandscape ? 'calc(100% - 6px)' : '123px',
							alignItems: 'center',
							background: '#444444',
							gap: '3px',
							padding: '3px'
						}}>
							{peers.filter((item) => !item?.isFocused).slice(0, peers.filter((item) => !item?.isFocused).length === 3 ? 3 : 2).map((peer, index, arr) => {
								return (
									<Peer
										key={peer.id}
										id={peer.id}
										peerCount={arr.length}
										roomId={roomId}
										role={role}
										focused={true}
									/>
								);
							})}

							{/* Show Number of peers are not rendered */}
							{peers.filter((item) => !item?.isFocused).length > 3 && <Box sx={{
								height: isLandscape ? 'calc(100% / 3)' : '100%',
								backgroundPosition: 'center center',
								width: isLandscape ? '100%' : 'calc(100% / 3)',
								backgroundRepeat: 'no-repeat',
								backgroundColor: '#757575',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '12px',
								color: 'rgb(255,255,255,.7)'
							}}>
								+{peers.filter((item) => !item?.isFocused).length - 2} More
							</Box>}
						</Box>
					}


					{/* Connect audio only of peers rest of primary screen. */}
					{peers.filter((item) => !item?.isFocused).filter((_, index) => !(index >= 0 && index < 2)).map((peer) => {
						return (<AudioOnlyPeer key={peer.id} id={peer.id} />);
					})}
				</>
				:
				<>
					<Box className='ScreensRow' sx={{
						display: 'flex',
						width: '100%',
						height: '100%',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}>
						{/* Default case to render peer on primary screen. */}
						{peers.slice(fromPeers, toPeers).map((peer, index, arr) => {
							return (<Peer key={peer.id} id={peer.id} peerCount={arr.filter(({ role }) => role !== "attendee").length} roomId={roomId} role={role} />);
						})}
					</Box>

					{/* Connect audio only of peers rest of primary screen. */}
					{peers.filter((_, index) => !(index >= fromPeers && index < toPeers)).map((peer) => {
						return (<AudioOnlyPeer key={peer.id} id={peer.id} />);
					})}
				</>
			)

			}
		</>
	);
};

// Peers.propTypes =
// {
// 	peers: PropTypes.arrayOf(appPropTypes.Peer).isRequired,
// 	// activeSpeakerId: PropTypes.string
// };

const mapStateToProps = (state) => {
	const peersArray = Object.values(state.peers);
	const focus = peersArray.filter((item) => {
		if (item?.isFocused) return true;
		return false
	}).length;

	return {
		peers: peersArray,
		focus: focus > 0 ? true : false,
		focusCount: focus
		// activeSpeakerId: state.room.activeSpeakerId
	};
};

const PeersContainer = connect(
	mapStateToProps,
	null,
	null,
	{
		areStatesEqual: (next, prev) => {
			return (
				prev.peers === next.peers /* &&
			prev.room.activeSpeakerId === next.room.activeSpeakerId */
			);
		}
	}
)(Peers);

export default memo(PeersContainer);
