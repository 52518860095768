import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
// import SideBar from '../../../layouts/sideBar';
import { renderErrorMessage } from '../../../components/ToastNotification';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

import { styled } from '@mui/material/styles';
import { axiosGetCall } from '../../../services/apisCall';
import PaginationTable from '../../../components/web/Tables/paginationTable';
import ReportActionsJsx from "../../../sections/web/Report/reportActions";

// import dayjs from 'dayjs';
// import Stack from '@mui/material/Stack';
// import Divider from '@mui/material/Divider';
// import AddBoxIcon from '@mui/icons-material/AddBox';

const MainSection = styled('div')(() => ({
    padding: '68px 10px 10px 10px',
    height: '100vh',
    // height: "calc(100vh - 108px)",
    width: "100%",
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: 500,
    color: theme.palette.grey[100]
}));

const TableSection = styled('div')(({ theme }) => ({
    borderRadius: "12px",
    background: theme.palette.card.main, // "#2C2D2F",
    overflow: 'hidden',
    boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
    [theme.breakpoints.up('md')]: {
        height: '615px',
    },
    [theme.breakpoints.up('lg')]: {
        height: '615px',
    },
    [theme.breakpoints.up('xl')]: {
        height: '801px',
    },
}));

export default function Reports() {
    // const navigate = useNavigate();
    const [wList, setWList] = useState([]);
    const [loadingWList, setLoadingWList] = useState(true)
    const [{ skip, size }, setListRange] = useState({ skip: 0, size: 100 });
    // const [apiError, setApiError] = useState(false)

    const columns = [
        { id: 'title', label: 'Title', minWidth: 300, align: 'center' },
        { id: 'createdAt', label: 'Scheduled Date and Time', minWidth: 110, align: 'center' },
        { id: 'createdByName', label: 'Created By', minWidth: 110, align: 'center' },
        { id: 'duration', label: 'Meeting Duration', minWidth: 110, align: 'center' },
        { id: 'roomId', label: 'RoomId', minWidth: 110, align: 'center' },
        { id: 'startDate', label: 'Start Date', minWidth: 110, align: 'center' },
        { id: 'endDate', label: 'End Date', minWidth: 20, align: 'center' },
        { id: 'recordings', label: 'Recordings', minWidth: 100, align: 'center' },
    ];

    const modifyListData = (webinars) => {
        return webinars.map((webinar) => {
            const { createdAt, startDate, endDate, duration } = webinar;
            return {
                ...webinar,
                duration: duration + ' Min',
                startDate: moment(startDate).format('D MMM, YYYY, h:mm A'),
                endDate: moment(endDate).format('D MMM, YYYY, h:mm A'),
                createdAt: moment(createdAt).format('D MMM, YYYY, h:mm A'),
            };
        })
    }

    // Fetch schedule webinar list.
    const fetchList = async (skip = 0, size = 100, search = null) => {
        try {
            let data = { from: skip, size };
            if (search) data.search = search;
            let res = await axiosGetCall("reportWebinar", data)
            setLoadingWList(false)
            return modifyListData(res?.result)
        } catch (error) {
            // setApiError(true)
            setLoadingWList(false)
            renderErrorMessage("Unable to fetch meet reports!")
        }
    }

    // const handleChangeFrom = (newValue) => {
    //     setFromDate(newValue);
    // };
    // const handleChangeTo = (newValue) => {
    //     setToDate(newValue);
    // };

    /*    const search = (e) => {
            if (e.key === 'Enter' && (e.target.value).trim() !== "") {
                setActive("all");
                setLoadingWList(true);
                setWList([])
                let txt = e.target.value
                let searchText = txt.toString().trim()
                fetchList(0, 20, "all", searchText);
            }
        } */

    // Change table data sty

    const loadMoreData = async () => {
        setLoadingWList(true);
        return await fetchList(skip, size);
    }

    useEffect(() => {
        (async () => {
            setLoadingWList(true)
            setWList([])
            setListRange({ skip: 0, size: 10 })
            const listData = await fetchList(0, 100);
            setWList(listData);
        })();
        //eslint-disable-next-line
    }, []);

    return (
        <MainSection>
            <ToastContainer />
            <Box sx={{
                padding: "18px 10px",
                // '&::-webkit-scrollbar': {
                //   width: 0,
                // }
            }}>

                <Heading>
                    Reports
                </Heading>

            </Box>

            <TableSection>
                <PaginationTable
                    size={size}
                    loadMoreData={loadMoreData}
                    isLoading={loadingWList}
                    ActionJsx={ReportActionsJsx}
                    // setLoad={setLoadingWList}
                    // error={"networkError"}
                    rows={wList}
                    columns={columns}
                    maxHeight={{
                        md: "615px",
                        lg: "615px",
                        xl: "801px"
                    }}
                />
            </TableSection>
        </MainSection>
    )
}
