import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

import { renderErrorMessage } from '../../components/ToastNotification';
import { getItem } from '../../lib/cookiesManager';
import { joinWebianrCheck } from '../../services/commonApis';

const StartWebinarLoading = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: theme.palette?.primary.main,
    height: '22px',
    width: '50px',
    fontSize: '10px',
    fontWeight: 500,
    textTransform: 'uppercase',
    boxShadow: 'none'
}));

const StartWebinarButton = ({ roomId }) => {
    const navigate = useNavigate();
    const [startRoomId, setStartRoomId] = useState("");
    const [startWLoading, setStartWLoading] = useState(false);

    const startWebinar = async (roomId) => {
        setStartRoomId(roomId)
        setStartWLoading(true)
        try {
            let token = getItem("token")
            let info = jwt(token);
            let pId = info?.email;
            if (!roomId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid room Id!");
            }

            if (!pId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid token!");
            }

            let { redirectURL, state } = await joinWebianrCheck(roomId, info?.name, pId) || {};
            if (redirectURL) {
                navigate(redirectURL, { state });
                setStartWLoading(false);
            } else {
                setStartWLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setStartWLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    return (
        <StartWebinarLoading
            variant='contained'
            loading={startWLoading && startRoomId === roomId ? true : null}
            loadingPosition="center"
            onClick={() => startWebinar(roomId)}
        > Start
        </StartWebinarLoading>
    )
}

export default StartWebinarButton;