import React, { memo, useState, useEffect } from 'react';
import { Box, Zoom, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InfoIconMenu from './Menu/InfoIconMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import MoreOptionsMenu from '../../mobile/room/Menu/MoreOptionsMenu'
import { demoteRoleUpdateWss } from '../../../redux/actions/stateActions';
import E2eIcon from '../../../assets/svgs/e2eIcon';
import E2eModal from '../../common/room/Modal/E2eModal';

function TopBar({ roomClient }) {
    const countState = useSelector(state => state.totalWatching);
    const [anchorEl, setAnchorEl] = useState(null);
    const [moreOptionsEl, setMoreOptionsEl] = useState(null);
    const [e2eModal, setE2eModal] = useState(false);
    const webinarInfo = useSelector(state => state.webinarInfo);

    const { informWss } = useSelector(state => state.media);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const openMoreOptions = Boolean(moreOptionsEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMoreOptionsClick = (event) => {
        setMoreOptionsEl(event.currentTarget);
    }

    const handleMoreOptionsClose = () => {
        setMoreOptionsEl(null);
    }

    useEffect(() => {
        if (informWss) {
            roomClient.demoteRoleToAttendee()
            dispatch(demoteRoleUpdateWss())
        }
        // eslint-disable-next-line
    }, [informWss]);

    return (
        <>
            <Box className='UprIcons' sx={{
                position: 'absolute',
                top: '10px',
                right: '8px'
            }}>
                <Stack sx={{ alignItems: "center" }} direction="row" spacing={1.5}>
                    <Box component="span" sx={{
                        bgcolor: '#5D5D5D',
                        color: '#FFFFFF',
                        fontSize: '12px',
                        height: '24px',
                        borderRadius: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        px: 1,
                        fontWeight: 700,
                    }}><VisibilityIcon sx={{
                        color: '#fff',
                        fontSize: '16px',
                        marginRight: '4px'
                    }} /> {countState?.count || 0}</Box>

                    {webinarInfo?.e2ee && <Tooltip TransitionComponent={Zoom} title="E2E Key" arrow >
                        <IconButton
                            onClick={() => setE2eModal(true)}
                            aria-controls={e2eModal ? 'e2e-key' : undefined}
                            aria-haspopup="true"
                            aria-expanded={e2eModal ? 'true' : undefined}>
                            <E2eIcon />
                        </IconButton>
                    </Tooltip>}

                    <IconButton sx={{
                        p: '0'
                    }}
                        onClick={handleClick}
                        aria-controls={open ? 'webinar-info' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}>
                        <InfoIcon sx={{
                            color: '#fff',
                            fontSize: '24px',
                        }} />
                    </IconButton >
                    {/* <IconButton sx={{
                p: '0'
            }}>
                <MoreVertIcon sx={{
                    color: '#fff',
                    fontSize: '24px',
                }} />
            </IconButton> */}
            {roomClient?._role === 'produce' && <IconButton
                        onClick={handleMoreOptionsClick}
                        sx={{
                            p: '0'
                        }}
                        aria-controls={openMoreOptions ? 'more-options-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMoreOptions ? 'true' : undefined}>
                        <MoreVertIcon sx={{
                            color: '#fff',
                            fontSize: '24px',
                        }} />
                    </IconButton>}

                </Stack>

                <E2eModal openModal={e2eModal} setOpenModal={setE2eModal} initial={false} />
            </Box>

            <InfoIconMenu
                open={open}
                handleClose={handleClose}
                anchorEl={anchorEl}
                roomClient={roomClient}
            />
            <MoreOptionsMenu
                open={openMoreOptions}
                anchorElMore={moreOptionsEl}
                handleClose={handleMoreOptionsClose}
            />
        </>
    )
}
export default memo(TopBar);
