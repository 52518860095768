import jsCookie from 'js-cookie';
// import PropTypes from 'prop-types';

const USER_COOKIE = 'webinar.user';
const DEVICES_COOKIE = 'webinar.devices';

export function getUser() {
	return jsCookie.get(USER_COOKIE);
}

export function setUser({ displayName }) {
	jsCookie.set(USER_COOKIE, { displayName },{ path: '/', sameSite: 'none', secure: true });
}

export function getDevices() {
	return JSON.parse(jsCookie.get(DEVICES_COOKIE) || '{}');
}

export function setDevices({ webcamEnabled = false, audioEnabled = false }) {
	jsCookie.set(DEVICES_COOKIE, JSON.stringify({ webcamEnabled, audioEnabled }|| '{}'));
}

export function setItem(key, value, exp = 1, path = "/") {
	jsCookie.set(key, value, { expires: exp, path, sameSite: 'none',secure:true })
	return
}

export function getItem(key) {
    if (checkIframe()) {
        const refData = jsCookie.get("consoleRef");
        const data = JSON.parse(refData);
        return data[key];
    }
    return jsCookie.get(key);
}

export function removeItem(key, path = '/') {
	return jsCookie.remove(key, { path });
}

export function setBulkItems(items) {
    Object.keys(items).forEach(key => {
        setItem(key, items[key]);
    })
}

export function removeBulkItems(items) {
    items.forEach(item => {
        removeItem(item);
    })
}

export function getBulkItems(items) {
	let values = {};
	items.forEach(item => {
		values[item] = getItem(item);
	})
	return values;
}

export function checkIframe() {
    if (window !== window.top) {
        return true;
    }
    return false;
}
/* // Get Single cookie data.
const getItem = (key) => {
    return jsCookie.get(key);
}

getItem.propTypes = {
    key: PropTypes.string.isRequired, // String type key requried
}

// Get multiple cookees data.
const getBulkItems = (keyNamesArray) => {
    return keyNamesArray.map((key) => {
        const value = getItem(key);
        return { key: value };
    });
}

getBulkItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    // required array formate which is array of strings i.e ["key1", "key2", ...]
}

// Set single cookie.
const setItem = (key, value, exp = 1, path = "/") => {
    return jsCookie.set(key, value, { expires: exp, path });
}

setItem.propTypes = {
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    exp: PropTypes.number,
    path: PropTypes.string,
    // required array formate which is array of strings i.e ["key1", "key2", ...]
}

// Set multiple cookies. 
const setBulkItems = (keyValArrayOfObjects = []) => {
    return keyValArrayOfObjects.map(({ key, value, exp = 1, path = "/" }) => setItem(key, value, exp, path));
}

setBulkItems.propTypes = {
    keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
}

// Remove particular single cookie.
const removeItem = (key, path = '/') => {
    return jsCookie.remove(key, { path });
}
removeItem.propTypes = {
    key: PropTypes.string.isRequired,
    path: PropTypes.string,
}

// Remove miltiple cookies.
const removeBulkItems = (keyValArrayOfObjects = []) => {
    return keyValArrayOfObjects.map(({ key, path = "/" }) => removeItem(key, path));
}
removeBulkItems.propTypes = {
    keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export {
    getItem,
    getBulkItems,
    setItem,
    setBulkItems,
    removeItem,
    removeBulkItems
} */