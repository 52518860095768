import { Typography, Box } from '@mui/material'
import React from 'react'

export const WaitingUsersCount = ({count}) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: '5px 20px 5px 20px'
        }}>
            <Typography sx={{ color: '#727374', fontSize: '14px', fontWeight: 500 }}>
                Waiting to Join
            </Typography>
            <Typography sx={{ color: '#727374' }}>
                {count}
            </Typography>
        </Box>
    )
}
