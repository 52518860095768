import React, { memo } from 'react';
import { connect } from 'react-redux';
import { withRoomContext } from '../../../context/RoomContext';
import Notifications from '../../../components/Notifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, IconButton, } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import ScreenShare from './ScreenShare/ScreenShare';
import Chat from './Chat';
import { renderErrorMessage } from '../../../components/ToastNotification';
import FooterWebinar from './Footer';
import TopBar from './TopBar';
import Me from './PeerView/Me';
import Peers from './PeerView/Peers';
import Participants from './Participants/Participants';
import RoomCloseModal from './Modal/RoomCloseModal';
import NetworkErrorModal from './Modal/NetworkErrorModal';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PermissionBlockModal from './Modal/PermissionBlockModal';
import { axiosPostCall } from '../../../services/apisCall';
import { WaitingRoomContext } from '../../../context/waitingRoomContext';
import { RecordContext } from '../../../context/recordContext';
import WaitingRoom from './waitingRoom';
import Recording from './Recording';


class MobileRoom extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openChat: false,
            anchorElMore: null,
            moreOpenStatus: null,
            endOpenStatus: null,
            openEndAll: false,
            openLeave: false,
            fromPeers: 0,
            toPeers: 6,
            endSuccessLoading: false,
            disableWebCamIcon: false,
            permissionModal: false,
            promoteToHostState: false,
            openWaitingRoomList: false,
            openRecording: false,
        }
    }

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleChatDrawerClose = () => {
        this.setState({ openChat: false })
    };

    handleDrawerOpen = () => {
        this.setState({ open: true, openChat: false, openWaitingRoomList: false })
    };

    handleChatDrawerOpen = () => {
        this.setState({ openChat: true, open: false, openWaitingRoomList: false })
    };
    // fn to set display peers page status for next.
    onNextPeersList = () => {
        this.setState({ fromPeers: this.state.toPeers, toPeers: this.state.toPeers + 6 })
    }

    // fn to set display peers page status for previous.
    onPrevPeersList = () => {
        this.setState({ fromPeers: this.state.fromPeers - 6, toPeers: this.state.toPeers - 6 })
    }

    // fn to end webinar for all peers
    endWebinar = async (e) => {
        e.preventDefault();
        this.setState({ endSuccessLoading: true });
        try {
            const { _roomId: roomId, _peerId: peerId } = this.props.roomClient;
            await axiosPostCall("endCall", { roomId, peerId })
            this.setState({ endSuccessLoading: false })
        } catch (error) {
            this.setState({ endSuccessLoading: false })
            console.error(error.message);
            renderErrorMessage(error.message)
        }
    }

    //fn to handle private chat redirect from panelist to chat direct.
    redirectToPrivateChat = (peerId) => {
        this.setState({ open: false, openChat: true, forceOpenChat: true, chatPeerId: peerId })
    }

    checkUserPermissions = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            this.setState({ permissionModal: false })
        } catch (error) {
            this.setState({ permissionModal: true })
        }
    }

    openWaitingRoomlist = () => {
        this.setState({ open: false, openChat: false, openWaitingRoomList: true })
    }

    closeWaitingRoomlist = () => {
        this.setState({ openWaitingRoomList: false })
    }

    toggleRecording = (status) => {
        this.setState({ openRecording: status })
    }

    render() {
        const {
            roomClient,
            me,
            audioProducer,
            videoProducer,
            // screenShare,
            // theme,
            share,
            peersLength,
            isNewMessage,
            focus,
            isLandscape,
            promoteToHost
        } = this.props;
        const { open, toPeers, openChat, forceOpenChat, chatPeerId, permissionModal, openRecording, openWaitingRoomList } = this.state;
        let micState;

        if (!me.canSendMic)
            micState = 'unsupported';
        else if (audioProducer && !audioProducer?.paused) {
            micState = 'on';
            // this.setState({ disableWebCamIcon: false })
        } else
            micState = 'off';

        let webcamState;

        if (!me.canSendWebcam)
            webcamState = 'unsupported';
        else if (videoProducer && videoProducer.type !== 'share')
            webcamState = 'on';
        else
            webcamState = 'off';

        // let shareState;

        // if (screenShare && screenShare.type === 'share')
        //     shareState = 'on';
        // else
        //     shareState = 'off';

        return (
            <>
                <Box className='MobileWraper' /* sx={{ height: 'calc(var(--vh, 1vh) * 100)' }} */>
                    <Notifications />
                    <ToastContainer />

                    <Grid sx={{ height: window.innerHeight/* '100vh' */ }}>
                        <Box className="ScrollBlock">
                            <Box className='ContentBlock' sx={{
                                background: '#151515',
                                height: 'calc(100% - 71px)',
                            }}>
                                {/* Screens Block */}
                                {!permissionModal && <Box className='ScreensBlock' sx={{
                                    display: 'flex',
                                    flexDirection: isLandscape ? 'row-reverse' : 'column',
                                    gap: '2px',
                                    height: isLandscape ? 'calc(100% - 6px)' : 'calc(100% - 3px)',
                                    position: 'relative',
                                }}>

                                    {share && <ScreenShare roomId={roomClient._roomId} peerId={roomClient._peerId} />}

                                    {/* Peers Screens */}
                                    {!share && <Peers
                                        roomId={roomClient._roomId}
                                        fromPeers={this.state.fromPeers}
                                        toPeers={this.state.toPeers}
                                        role={roomClient?._role}
                                    />}

                                    {/* Self Screen */}
                                    {(roomClient?._role === 'produce' || promoteToHost) &&
                                        <Me micState={micState} roomId={roomClient._roomId} />}
                                    {roomClient?._role === 'produce' && <>
                                        {open && <Participants
                                            open={open}
                                            handleDrawerClose={this.handleDrawerClose}
                                            redirectToPrivateChat={this.redirectToPrivateChat}
                                            roomId={roomClient._roomId}
                                        />}
                                        {openChat && <Chat
                                            open={openChat}
                                            handleDrawerClose={this.handleChatDrawerClose}
                                            roomId={roomClient._roomId}
                                            peerId={roomClient._peerId}
                                            displayName={me?.displayName}
                                            forceOpenChat={forceOpenChat}
                                            data={chatPeerId}
                                            updateForceOpenChat={() => this.setState({ forceOpenChat: false })}
                                        />}
                                    </>
                                    }
                                    {openWaitingRoomList && <WaitingRoom
                                        open={openWaitingRoomList}
                                        handleDrawerClose={this.closeWaitingRoomlist}
                                    />}

                                    <RecordContext.Provider value={{ toggleRecording: this.toggleRecording, autoRecord: this.props.autoRecord, disableRecordPlayPause: this.props.disableRecordPlayPause }}>
                                        {openRecording && <Recording
                                            toggleRecording={this.toggleRecording}
                                        />}
                                    </RecordContext.Provider>

                                    {/* Show previus and next icon in main screen. */}
                                    {(peersLength > 6 && !share && !focus) && <>
                                        <Box sx={{
                                            position: 'absolute',
                                            zIndex: 9999999,
                                            left: 5,
                                            top: '50%'
                                        }}>
                                            {toPeers > 6 && <IconButton onClick={() => this.onPrevPeersList()} sx={{
                                                left: 0,
                                                bgcolor: '#454545',
                                                color: '#FFFFFF',
                                                '&:hover': {
                                                    bgcolor: '#111111',
                                                }
                                            }}>
                                                <NavigateBeforeIcon />
                                            </IconButton>}
                                        </Box>
                                        <Box sx={{
                                            position: 'absolute',
                                            zIndex: 9999999,
                                            right: 5,
                                            top: '50%'
                                        }}>
                                            {peersLength > toPeers && <IconButton onClick={() => this.onNextPeersList()} sx={{
                                                right: 0,
                                                bgcolor: '#454545',
                                                color: '#FFFFFF',
                                                '&:hover': {
                                                    bgcolor: '#111111',
                                                }
                                            }}>
                                                <NavigateNextIcon />
                                            </IconButton>}
                                        </Box>

                                    </>}

                                    {/* Upr Icons */}
                                    <RecordContext.Provider value={{ toggleRecording: this.toggleRecording, openRecording: this.state.openRecording, record: this.props.record }}>
                                        <WaitingRoomContext.Provider value={{ openWaitingRoomlist: this.openWaitingRoomlist, closeWaitingRoomlist: this.closeWaitingRoomlist }}>
                                            <TopBar roomClient={roomClient} />
                                        </WaitingRoomContext.Provider>
                                    </RecordContext.Provider>
                                </Box>}
                            </Box>
                        </Box>
                    </Grid>

                    {/* Webinar Footer Menu */}
                    <WaitingRoomContext.Provider value={{ openWaitingRoomlist: this.openWaitingRoomlist, closeWaitingRoomlist: this.closeWaitingRoomlist }}>
                        <FooterWebinar
                            micState={micState}
                            webcamState={webcamState}
                            roomClient={roomClient}
                            openParticipantsTab={(bool) => bool ? this.setState({ open: true, openChat: false }) : this.setState({ open: false })}
                            participantsTabStatus={open}
                            openChatTab={(bool) => bool ? this.setState({ openChat: true, open: false }) : this.setState({ openChat: false })}
                            chatTabStatus={openChat}
                            endSuccessLoading={this.state.endSuccessLoading}
                            endWebinar={this.endWebinar}
                            isNewMessage={isNewMessage}
                        />
                    </WaitingRoomContext.Provider>
                </Box>

                {/* room closed modal */}
                <RoomCloseModal
                    webinarState={this.props.webinarState}
                />

                {/* Network error modal */}
                <NetworkErrorModal
                    networkError={this.props.networkError}
                />

                <PermissionBlockModal
                    open={permissionModal}
                    handleClose={() => this.setState({ permissionModal: false })}
                    selfRole={roomClient._role}
                />
            </>
        );
    }

    componentDidMount() {
        const { roomClient } = this.props;

        roomClient.join();

        this.checkUserPermissions()
        // this.setState({
        //     dimensions: {
        //         width: this.container.offsetWidth,
        //         height: this.container.offsetHeight,
        //     },
        // });
    }

    componentDidUpdate() {
        const { focus, roomClient, promoteToHost } = this.props;
        const { fromPeers, promoteToHostState } = this.state;
		const { recordingStatus } = this.props;
        if (focus && fromPeers !== 0) { this.setState({ fromPeers: 0, toPeers: 6 }) }
        if (promoteToHost && !promoteToHostState) {
            this.setState({ promoteToHostState: true });
            roomClient.promoteAttendeeToHost()
        }

        if (!promoteToHost && promoteToHostState) {
            this.setState({ promoteToHostState: false });
        }
		if (["recording", "pause"].includes(recordingStatus) && !this.state.openRecording) this.toggleRecording(true);
    }
}

const mapStateToProps = (state) => {
    const producersArray = Object.values(state.producers);
    const peersArray = Object.values(state.peers);
    const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
    const videoProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type !== "share");
    const screenShareProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type === "share");

    const checkShare = Object.values(state.consumers).find(({ type }) => type === "share");
    const checkFocus = peersArray.find(({ isFocused }) => isFocused === true);

    const isNewMessage = state.publicChat?.newMsg ? true : state.privateChat?.newMsg ? true : false;
    const isLandscape = state.deviceInfo?.isLandscape || false;
    const { promoteToHost } = state.media;
    const { status: recordingStatus } = state?.recording;
	const { record, autoRecord, disableRecordPlayPause } = state?.webinarInfo;

    return {
        networkError: state.room?.state === "disconnected" /* || state.room?.state === "closed" */ ? true : false,
        me: state.me,
        amActiveSpeaker: state.me.id === state.room.activeSpeakerId,
        audioProducer: audioProducer,
        videoProducer: videoProducer,
        screenShare: screenShareProducer,
        share: checkShare ? true : screenShareProducer ? true : null,
        focus: checkFocus ? true : false,
        peersLength: peersArray.length || 0,
        isNewMessage,
        webinarState: state.room?.webinarState === "closed" ? true : false,
        isLandscape,
        promoteToHost,
        recordingStatus,
        record,
		autoRecord,
		disableRecordPlayPause
    };
};

const RoomContainer = withRoomContext(connect(
    mapStateToProps,
)(MobileRoom));

export default memo(RoomContainer);
