import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AddOrRemove = () => {
    return (
        <>
            <Heading
                text='Add or Remove participants from the Waiting Room'
            />

            <ImageComp
                name={"addorRemoveparticipantsWaitingRoom2"}
            />

            <ListComp
                data={[

                    {
                        name: (
                            <>
                                When you are in a meeting, click on the <MoreVertIcon style={{ verticalAlign: 'middle' }} />
                                icon in the toolbar.
                            </>
                        ),
                        id: '13'
                    },
                    {
                        name: 'Now, click on the ‘Waiting Room.’  ',
                        id: '14',

                    }, {
                        name: 'This will open the list of all the participants in the waiting room.  ',
                        id: '15',

                    }, {
                        name: 'Go to the participant you want to add or remove ',
                        id: '16',
                        child: [{
                            name: 'Click ‘Allow’ to add the participant ',
                            id: '12'
                        }, {
                            name: 'Click ‘Decline’ to remove the participant ',
                            id: '13'
                        },
                        ]

                    },

                ]}

            />

            <ImageComp
                name={"addorRemoveparticipantsWaitingRoom"}

            />

            <ListComp
                data={[

                    {
                        name: 'As soon as a participant requests to join the meeting, you will get a pop-up as shown in the image.            ',
                        id: '13',

                    },
                    {
                        name: 'Here, you can simply allow or decline the participant from the notification.  ’  ',
                        id: '14',

                    },

                ]}

            />

        </>
    );
};

export default AddOrRemove;
