import { IconButton, Typography, Button } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import RoomContext from '../../../../context/RoomContext';
import { renderErrorMessage, renderSuccessMessage } from '../../../ToastNotification';
import { useSelector } from 'react-redux';
import { RecordContext } from '../../../../context/recordContext';
import store from './../../../../store';
import { setRecordingInfo } from '../../../../redux/actions/recordingActions';
import { recordingToggle } from '../../../../services/commonApis';

export default function Recording() {
    const location = useLocation();
    const [openChip, setOpenChip] = useState(false);
    const [timeOutId, setTimeoutId] = useState("");
    const [intervalId, setIntervalId] = useState("");
    const [seconds, setSeconds] = useState(0);
    const [paused, setPaused] = useState(false);
    const roomContext = useContext(RoomContext);
    const { url } = useSelector(state => state?.webinarInfo);
    const { status, duration } = useSelector(state => state?.recording);
    const { toggleRecording, autoRecord, disableRecordPlayPause } = useContext(RecordContext);


    useEffect(() => {
        let intervalIdCurrent;

        if (!paused) {
            intervalIdCurrent = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);

            return setIntervalId(intervalIdCurrent);
        }
        clearInterval(intervalId);
        // eslint-disable-next-line
    }, [paused]);

    useEffect(() => {
        let timeOutIdCurrent;
        if (openChip && !timeOutId) {
            timeOutIdCurrent = setTimeout(() => {
                setOpenChip(false);
            }, 6000);
            return setTimeoutId(timeOutIdCurrent);
        }

        clearTimeout(timeOutId);

        // eslint-disable-next-line
    }, [openChip]);

    useEffect(() => {
        setSeconds(duration);
        if (status === "recording") return setPaused(false);
        if (status === "pause") return setPaused(true);
        if (status === "stop") return toggleRecording(false);
        // eslint-disable-next-line
    }, [status]);

    const switchRecording = async (actionType) => {
        try {

            const { message } = await recordingToggle(actionType, roomContext._roomId, location?.state?.peerId, url);
            if (message) {
                renderSuccessMessage(message || "Recording started successfully!");

                if (actionType === "resume") {
                    store.dispatch(setRecordingInfo({ status: "recording", duration: seconds }))
                    setPaused(false);
                }

                if (actionType === "pause") {
                    store.dispatch(setRecordingInfo({ status: "pause", duration: seconds }))
                    setPaused(true);
                }
                
                if (actionType === "stop") {
                    store.dispatch(setRecordingInfo({ status: "stop", duration: 0 }))
                }
            }
        } catch (error) {
            return renderErrorMessage(error?.message)
        }
    }

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const toggleOpenChip = () => {
        if (autoRecord && disableRecordPlayPause) return setOpenChip(false);
        setOpenChip(!openChip);
    }

    return (
        <Box sx={{
            display: 'flex',
            position: 'absolute',
            left: '42px',
            top: '17px',
            alignItems: 'center',
            backgroundColor: openChip ? "#757575" : '',
            p: '5px 10px 5px 10px',
            gap: '10px',
            borderRadius: '30px',
            transition: openChip ? 'all 1s ease-in-out' : "none",
            zIndex: 9999
        }}>
            <IconButton sx={{
                height: '24px',
                width: '24px',
                backgroundImage: 'linear-gradient(135deg, #DF2525 14.64%, #C02A2A 85.35%)',
                color: '#fff',
            }} onClick={() => toggleOpenChip()}>
                <RadioButtonCheckedIcon sx={{ height: '16px', width: '16px', }} />
            </IconButton>
            <Typography sx={{
                fontSize: '13px',
                lineHeight: '16px',
                fontWeight: 400
            }}>
                {formatTime(seconds)}
            </Typography>
            {openChip && <>
                <IconButton
                    onClick={() => switchRecording(paused ? "resume" : "pause")}
                    sx={{
                        height: '24px',
                        width: '24px',
                        // backgroundImage: 'linear-gradient(135deg, #DF2525 14.64%, #C02A2A 85.35%)',
                        color: '#fff',
                    }}>
                    {!paused && <PauseCircleOutlineIcon />}
                    {paused && <PlayCircleOutlineIcon />}
                </IconButton>

                <Button
                    variant='contained'
                    color="error"
                    size='small'
                    sx={{
                        height: '25px',
                        borderRadius: '12px'
                    }}
                    onClick={() => switchRecording("stop")}
                >
                    Stop
                </Button>
            </>}
        </Box>
    )
}
