const initState = {
    audioStart: false,
    audioStop: false,
    videoStart: false,
    videoStop: false,
    promoteToHost: false,
    informWss: false
};

const media = (state = initState, method) => {
    switch (method.type) {
        case 'MANAGE_MEDIA': {
            const { action, type } = method.payload;
            if (type === 'audio' && (action === 'start' || action === 'stop')) {
                if (action === 'start') {
                    return { ...state, audioStart: true }
                }
                return { ...state, audioStop: true }
            }
            if (type === 'video' && (action === 'start' || action === 'stop')) {
                if (action === 'start') {
                    return { ...state, videoStart: true }
                }
                return { ...state, videoStop: true }
            }
            return state
        }
        case 'MANAGE_MEDIA_STOP': {
            const { type } = method.payload;
            if (type === "audioStart") { return { ...state, audioStart: false } }
            if (type === "audioStop") { return { ...state, audioStop: false } }
            if (type === "videoStart") { return { ...state, videoStart: false } }
            if (type === "videoStop") { return { ...state, videoStop: false } }

            return state;
        }

        case 'PROMOTE_ROLE': {
            return {...state, promoteToHost: true };
        }

        case 'DEMOTE_ROLE': {
            return {...state, promoteToHost: false, informWss: true };
        }

        case 'UPDATE_WSS': {
            return {...state, informWss: false };
        }

        default:
            return state;
    }
}

export default media;