import React from 'react';
import { Box, } from '@mui/material';
import Heading from './components/heading';
import Pragraph from './components/pragraph';
import ImageComp from './components/imageComp';

const WhiteBoard = () => {
    return (
        <>
            <Heading
                text='Whiteboard'
            />
            <ImageComp
                name={"Whiteboard"}

            />

            <Box sx={{ width: '80%' }}>
                <Pragraph
                    text='To access the whiteboard feature, click the ‘More Options’  button in the meeting control bar.
                    In this menu, click on the Whiteboard option.
                    This will open the whiteboard on your screen which will appear like the screenshot below;
                    '
                />
            </Box>

            <ImageComp
                name={"whiteboardView"}

            />

        </>
    );
};

export default WhiteBoard;


