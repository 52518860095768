import { Grid, Box, Typography, Modal, Button, TextField, Container, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage } from '../../../components/ToastNotification';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { configFile } from '../../../config';
import { validateDescription } from '../../../utils/common';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { axiosPostCall } from '../../../services/apisCall';

export default function MobileFeedback() {
    const { imagesUrl } = configFile;
    const [type, setType] = useState('');
    const [activeIcon, setActiveIcon] = useState(false);
    const [message, setMessage] = useState('');
    const [sendSuccess, setSendSuccess] = useState(false);
    const [descError, setDescError] = useState(false)
    const navigate = useNavigate()

    // Handle send feedback submit button.
    const shareFeedback = async (e) => {
        e.preventDefault();

        // validate data before send to backend.
        if (!type) {
            return renderErrorMessage("Select feedback type!");
        }
        if (!message) return renderErrorMessage("Enter message!");

        if (!validateDescription(message)) {
            setDescError(true);
            return renderErrorMessage("Please enter a valid Feedback!");
        }

        // Send data to backend.
        try {
           await axiosPostCall("feedback",{feedbackType: type, message, ts: +new Date()})

            // renderSuccessMessage(res || "Feedback send successfully!");
            setSendSuccess(true);
            setMessage('');
            setType('');
        } catch (error) {
            renderErrorMessage(error?.message || "Internel server error!")
        }
    };

    useEffect(() => {
        if (type) return setActiveIcon(true)
    }, [type])

    return (
        <Box className='MobileWraper' sx={{ overflowX: 'hidden' }}>
            <ToastContainer />
            <Container maxWidth="xl" className='FeedbackContainer'>
                <Box sx={{ p: 1 }} className='FeedbackGlobalBox'>
                    <Box sx={{
                        flexDirection: 'row',
                        pt: 3,
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }} className='FeedbackGlobalBox'>
                        <IconButton sx={{ width: '28px', height: '28px', color: '#1976d2' }} className='FeedbackIconBtn'>
                            <RateReviewIcon />
                        </IconButton>
                        <Typography className='FeedbackTitle' sx={{
                            fontWeight: 600,
                            fontSize: '24px',
                            lineHeight: '18px',
                            color: '#141919',
                            ml: 1
                        }}>
                            Share your Feedback
                        </Typography>
                    </Box>
                    <Typography className='FeedbackSubTitle' sx={{
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#757575',
                        pt: 4
                    }}>
                        Please help us to improve the experience by giving us
                        your feedback.
                    </Typography>
                    <Typography className='FeedbackQuestion' sx={{
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#444444',
                        pt: 4
                    }}>
                        How would you rate the overall meeting experience?
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        pt: 3
                    }} className='FeedbackRatingGlobal'>
                        <Box className='FeedbackRatingBad' sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            gap: '15px'
                        }} onClick={() => setType("Bad")}>
                            <Typography sx={{
                                fontSize: 40,
                                opacity: (activeIcon && type !== "Bad") ? 0.5 : (type === "Bad" ? 1: 0.5),
                            }}>&#128544;</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '13px',
                                color: '#000000',
                                opacity: (activeIcon && type !== "Bad") ? 0.5 : (type === "Bad" ? 1: 0.5),
                            }}>Bad</Typography>
                        </Box>
                        <Box className='FeedbackRatingAverage' sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: 2,
                            cursor: 'pointer',
                            gap: '15px'
                        }} onClick={() => setType("Average")}>
                            <Typography sx={{
                                fontSize: 40,
                                opacity: (activeIcon && type !== "Average") ? 0.5 :(type === "Average" ? 1: 0.5),
                            }}>&#128533;</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '13px',
                                color: '#000000',
                                opacity: (activeIcon && type !== "Average") ? 0.5 :(type === "Average" ? 1: 0.5),
                            }} >Average</Typography>
                        </Box>
                        <Box className='FeedbackRatingGood' sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: 2,
                            cursor: 'pointer',
                            gap: '15px'
                        }} onClick={() => setType("Good")}>
                            <Typography sx={{
                                fontSize: 40,
                                opacity: (activeIcon && type !== "Good") ? 0.5 : (type === "Good" ? 1: 0.5),
                            }}>&#128528;</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '13px',
                                color: '#000000',
                                opacity: (activeIcon && type !== "Good") ? 0.5 : (type === "Good"? 1: 0.5),
                            }}>Good</Typography>
                        </Box>
                        <Box className='FeedbackRatingVeryGood' sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: 2,
                            cursor: 'pointer',
                            gap: '15px'
                        }} onClick={() => setType("Very Good")}>
                            <Typography sx={{
                                fontSize: 40,
                                opacity: (activeIcon && type !== "Very Good") ? 0.5 : (type === "Very Good" ? 1: 0.5),
                            }}>&#128522;</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '13px',
                                color: '#000000',
                                opacity: (activeIcon && type !== "Very Good") ? 0.5 : (type === "Very Good" ? 1: 0.5),
                            }}>Very Good</Typography>
                        </Box>
                        <Box className='FeedbackRatingAwesome' sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: 2,
                            cursor: 'pointer',
                            gap: '15px'
                        }} onClick={() => setType("Awesome")}>
                            <Typography sx={{
                                fontSize: 40,
                                opacity: (activeIcon && type !== "Awesome") ? 0.5 : (type === "Awesome" ? 1: 0.5),
                            }}>&#128512;</Typography>
                            <Typography sx={{
                                fontWeight: 500,
                                fontSize: '13px',
                                lineHeight: '13px',
                                color: '#000000',
                                opacity: (activeIcon && type !== "Awesome") ? 0.5 : (type === "Awesome" ? 1: 0.5),
                            }}>Awesome</Typography>
                        </Box>
                    </Box>

                    <Typography className='FeedbackMessageTitle' sx={{
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '19px',
                        color: '#757575',
                        pt: 4
                    }}>
                        Could you tell us why?
                    </Typography>

                    <Box className='FeedbackMessageInputBox' sx={{ pt: 1 }}>
                        <TextField
                            id="outlined-multiline-static"
                            // label="Multiline"
                            className='FeedbackMessageTextField'
                            multiline
                            rows={4}
                            placeholder="Enter your comment..."
                            onChange={(e) => setMessage(e.target.value)}
                            onClick={() => setDescError(false)}
                            value={message}
                            sx={{
                                width: '100%',
                                backgroundColor: '#F4F4F4',
                            }}
                            error={descError ? true : false}
                        />
                    </Box>
                    <Box className='FeedbackMessageBtnBox' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pt: 2
                    }}>
                        <Button className='FeedbackMessageBtn' variant='contained' size='large' color='primary' sx={{
                            width: '100%',
                            textTransform: 'none',
                            boxShadow: 'none',
                            mb: '60px'
                        }} onClick={(e) => shareFeedback(e)}>Submit</Button>
                    </Box>
                </Box>
            </Container>

            {/* Show pupup on send feed back success. */}
            <Modal
                open={sendSuccess}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 350,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                    p: 4,
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={imagesUrl?.feedbackPopup} alt="Feedback success" />
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '29px',
                        color: '#444444',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3
                    }}>
                        Thank You!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{
                        mt: 2, mx: 1,
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: '#BBBBBA',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        For sharing your thoughts we appreciate
                        your feedback!
                    </Typography>

                    <Grid item={true} xs={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 5
                    }}>
                        <LoadingButton
                            loading={false ? true : null}
                            color='success'
                            loadingPosition="center"
                            variant='contained'
                            onClick={(e) => navigate('/')}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none'
                            }}
                        >
                            Go to home
                        </LoadingButton>

                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}
