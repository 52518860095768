import Logger from "./Logger";
import store from '../store';
import { configFile } from '../config';

const logger = new Logger("checkNetworkStats");
const { NETWORK_IMAGE } = configFile;

const checkNetworkStats = (room, updateNetworkStrength) => {
    try {
        let arrTimes = [];
        let i = 0; // start
        let timesToTest = 5;
        let strongThreshold = 70; //ms
        let avgThreshold = 250; //ms
        let dummyImage = new Image();
        let isConnectedFast = false;

        testLatency(function (avg) {
            let nStrength = 2;
            if (avg <= strongThreshold) nStrength = 3;
            else if (avg > strongThreshold && avg <= avgThreshold) nStrength = 2;
            else nStrength = 1;

            store.dispatch(updateNetworkStrength({ networkStrength: nStrength }))
            isConnectedFast = (avg <= strongThreshold);
            /** output */
            logger.debug(`Time: ${(avg.toFixed(2))} ms - isConnectedFast? ${isConnectedFast}`);
            room._protoo.request("networkStats", { latency: avg.toFixed(2), timStamp: new Date().toISOString() });
        });

        /** test and average time took to download image from server, called recursively timesToTest times */
        function testLatency(cb) {
            let tStart = new Date().getTime();
            if (i < timesToTest - 1) {
                dummyImage.src = NETWORK_IMAGE + '?t=' + tStart;
                dummyImage.onload = function () {
                    let tEnd = new Date().getTime();
                    let tTimeTook = tEnd - tStart;
                    arrTimes[i] = tTimeTook;
                    testLatency(cb);
                    i++;
                };

                dummyImage.onerror = function () {
                    store.dispatch(updateNetworkStrength({ networkStrength: 4 }))
                };
            } else {
                /** calculate average of array items then callback */
                let sum = arrTimes.reduce(function (a, b) { return a + b; });
                let avg = sum / arrTimes.length;
                cb(avg);
            }
        }
    } catch (error) {
        logger.error(error);
    }
}

export default checkNetworkStats;