import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as appPropTypes from '../appPropTypes';
import { withRoomContext } from '../../../../context/RoomContext';
import * as stateActions from '../../../../redux/actions/stateActions';
import PeerView from './PeerView';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';


class Me extends React.PureComponent {
	constructor(props) {
		super(props);

		this._mounted = false;
		this._rootNode = null;
		// this.initalMicState = true;
	}

	render() {
		const { /* roomClient, */ me, audioProducer, videoProducer, roomId, micState/*  connected,  faceDetection, onSetStatsPeerId */ } = this.props;
		const videoVisible = Boolean(videoProducer) && !videoProducer.paused;

		return (
			<>
				<Draggable bounds="parent" >
					<Box sx={{
						bgcolor: 'text.disabled',
						position: 'absolute',
						backgroundSize: 'cover',
						height: '150px',
						width: '250px',
						borderRadius: '10px',
						right: '10px',
						bottom: '10px',
						zIndex: '9999',
						backgroundColor: 'transparent',
						overflow: 'hidden'
					}}>
						<div >
							<PeerView
								isMe
								peer={me}
								audioProducerId={audioProducer ? audioProducer.id : null}
								videoProducerId={videoProducer ? videoProducer.id : null}
								audioRtpParameters={audioProducer ? audioProducer.rtpParameters : null}
								videoRtpParameters={videoProducer ? videoProducer.rtpParameters : null}
								audioTrack={audioProducer ? audioProducer.track : null}
								videoTrack={videoProducer ? videoProducer.track : null}
								videoVisible={videoVisible}
								audioCodec={audioProducer ? audioProducer.codec : null}
								videoCodec={videoProducer ? videoProducer.codec : null}
								audioScore={audioProducer ? audioProducer.score : null}
								videoScore={videoProducer ? videoProducer.score : null}
								roomId={roomId}
								micState={micState}
							// faceDetection={faceDetection}
							// onChangeDisplayName={(displayName) => {
							// 	roomClient.changeDisplayName(displayName);
							// }}
							// onChangeMaxSendingSpatialLayer={(spatialLayer) => {
							// 	roomClient.setMaxSendingSpatialLayer(spatialLayer);
							// }}
							// onStatsClick={onSetStatsPeerId}
							/>
						</div>
					</Box>
				</Draggable>
			</>
		);
	}

	// componentDidMount() {

	// 	this._mounted = true;
	// 	setTimeout(() => {
	// 		if (!this._mounted || this.props.me.displayNameSet)
	// 			return;

	// 		ReactTooltip.show(this._rootNode);
	// 	}, 4000);
	// }

	componentWillUnmount() {
		this._mounted = false;
	}

	/* componentDidUpdate(prevProps) {
		let { audioProducer, roomClient } = this.props;

		// //Custom code to mute mic initially.
		// if (audioProducer && !audioProducer?.paused && this.initalMicState) {
		// 	roomClient.muteMic()
		// 	this.initalMicState = false;
		// }
		// if (!prevProps.me.displayNameSet && this.props.me.displayNameSet)
		// 	ReactTooltip.hide(this._rootNode);
	} */
}

Me.propTypes =
{
	connected: PropTypes.bool.isRequired,
	me: appPropTypes.Me.isRequired,
	audioProducer: appPropTypes.Producer,
	videoProducer: appPropTypes.Producer,
	onSetStatsPeerId: PropTypes.func.isRequired
	// roomClient: PropTypes.any.isRequired,
	// faceDetection: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	const producersArray = Object.values(state.producers);
	const audioProducer = producersArray.find((producer) => producer.track.kind === 'audio');
	const videoProducer = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type !== "share");
	// const screenShare = producersArray.find((producer) => producer.track.kind === 'video' && producer?.type === "share");

	return {
		connected: state.room.state === 'connected',
		me: state.me,
		audioProducer: audioProducer,
		videoProducer: videoProducer,
		// screenShare: screenShare,
		// faceDetection: state.room.faceDetection
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetStatsPeerId: (peerId) => dispatch(stateActions.setRoomStatsPeerId(peerId))
	};
};

const MeContainer = withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps
)(Me));

export default memo(MeContainer);
