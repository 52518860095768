import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { renderErrorMessage } from '../../components/ToastNotification';
import { axiosPostCall } from '../../services/apisCall';

const DeleteWebinarLoading = styled(LoadingButton)(() => ({
    display: "flex",
    width: "50px",
    height: "22px",
    padding: "5px 30px",
    borderRadius: "5px",
}));

const DeleteWebinarButton = ({ roomId, active, fetchList, setWList }) => {
    const [deleteWLoading, setDeleteWLoading] = useState(false);

    const deleteWebinar = async (roomId) => {
        setDeleteWLoading(true)
        try {
            await axiosPostCall("cancelWebinar", { roomId, deleted: true })
            setDeleteWLoading(false)
            // Refresh Webinar List on delete webinar
            const data = await fetchList(0, 10, active);
            setWList(data);
        } catch (error) {
            setDeleteWLoading(false)
            renderErrorMessage("Unable to fetch meet details!");
        }
    }

    return (
        <DeleteWebinarLoading
            variant='outlined'
            color='error'
            size='small'
            loading={deleteWLoading ? true : null}
            loadingPosition="center"
            onClick={() => deleteWebinar(roomId)}
        > Delete
        </DeleteWebinarLoading>
    )
}

export default DeleteWebinarButton;