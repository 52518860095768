// For websocket base and dynamic url
export function getProtooUrl({ roomId, peerId }) {
  const hostname = window.location.hostname;
  return `wss://${hostname}/meet-media/?roomId=${roomId}&peerId=${peerId}`;
}

export function aesConfig() {
  return {
    key: "dsfsdfsd@@$$7876gfgffbdfgd@@33##",
    splitKey: ".@#a61.",
  };
}

export function iceServers() {
  const iceServers = [
    // { urls: "turn:turn-webinar.sarv.com?transport=udp", username: "testname", credential: "testpass" },
    // { urls: "stun:turn.alertsijon.in:3478?transport=udp" },
    // { urls: "turn:turn.alertsijon.in:3478?transport=udp", username:"testname", credential: "testpass",  auth: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiI2M2ZkOWE0NDMzMDkzN2MxZDFlNmZlOWEiLCJwZWVySWQiOiJhbnNoLnZAc2Fydi5jb20iLCJpYXQiOjE2Nzc1NjQ0OTksImV4cCI6MTY3NzU4Njk5OX0.v2vfydaZEeJvkZc-m6v4VB0IHbqoJFop6byDjFsQw38" },
    // { urls: "turn:turn.alertsijon.in?transport=udp", username:"testname", credential: "testpass" },
  ];
  return iceServers;
}

export function iceServersIP() {
  return ""
}

// All apis base url
export function getApiUrl() {
  return `https://${window.location.hostname}/meet-api/`;
}

// sse event receive base url
export function sseEventUrl() {
  return `https://${window.location.hostname}/meet-api/events`;
}

// Get api endpoints dynamically
export function endPoints(title) {
  let version = "v1/";
  let routes = {
    fetchAttendees: `${version}room/fetchAttendees`,
    pinScreen: `${version}room_action/pinScreen`,
    joinRoom: `${version}room/joinroom`,
    endCall: `${version}room/endcall`,
    chat: `${version}room_action/chat`,
    loginWithGoogle: `${version}auth/login_with_google`,
    scheduleWebinar: `${version}webinar/schedule_webinar`,
    listWebinar: `${version}webinar/list`,
    detailsWebinar: `${version}webinar/fetch_webinar`,
    cancelWebinar: `${version}webinar/cancel`,
    feedback: `${version}webinar/feedback`,
    fetchChat: `${version}room_action/fetchChatMessages`,
    reportWebinar: `${version}webinar/report`,
    renewToken: `${version}auth/renewToken`,
    manageMedia: `${version}room/manageMedia`,
    kickPeer: `${version}room/kickPeer`,
    promoteAttendee: `${version}room/promoteAttendee`,
    fetchKickedPeers: `${version}room/fetchKickedPeers`,
    allowKickedPeer: `${version}room/allowKickedPeer`,
    quickMeeting: `${version}meet/quickMeeting`,
    waitingRoomPeerActions: `${version}room/waitingRoomPeerActions`,
    startRecording: `${version}room/startRecording`,
    stopRecording: `${version}room/stopRecording`,
    userGuide: `${version}auth/userGuide`,
    waitForStart: `${version}room/roomStatus`,
  };

  let baseUrl = getApiUrl();
  let routeEndPoint = Object.entries(routes).find((item) => item[0] === title);
  return `${baseUrl}${routeEndPoint[1]}`;
}

// Congig or some params for google oauth2.
export const google_config = {
  // gClientID: "912740304686-g0eve7ad5797400kvglvdt650hhe5o2g.apps.googleusercontent.com",
  gClientID: "260883182254-ocms377kdscmpnve27d65n0mglfo2m2o.apps.googleusercontent.com"
  // gClientID:
  //   "722824660365-7858n44d8mjlpmhfl53ugfss4drpjevc.apps.googleusercontent.com",
};

export const webinarRole = {
  produce: "produce",
  consumer: "consumer",
};

export const logoUrl = {
  logoMain: "./images/wave-logo-grey.png",
  logoMobile: "./images/logoMobile.svg"
};
export const imagesUrl = {
  feedbackPopup: "./images/thankyou.svg",
  profilePage: "./images/profilePage.svg",
  noInternet: "./images/noInternet.svg",
  loading: "./images/loading.svg",
  noScheduleWebinar: "./images/noScheduleWebinar.svg",
  noMoreReports: "./images/noMoreReports.svg",
  loginPageUser: "./images/authShow.svg",
  micAndVideoBlock: "./images/micAndVideoBlock.svg",
  micAndVideoBlockMobile: "./images/micAndVideoBlockMobile.svg",
  waitingRoom: "./images/waitingRoom.svg",

  whiteboardPng: "./images/whiteboard.png",

  signup: "./images/HELP&FAQ/web/signup.png",
  login: "./images/HELP&FAQ/web/login.png",
  forgotPassword: "./images/HELP&FAQ/web/forgotPassword.png",
  joinMeeting: "./images/HELP&FAQ/web/joinMeeting.png",
  quickMeeting: "./images/HELP&FAQ/web/quickMeeting.png",
  chat: "./images/HELP&FAQ/web/chat.png",
  invitePeopleMeeting: "./images/HELP&FAQ/web/invitePeopleMeeting.png",
  meetingControls: "./images/HELP&FAQ/web/meetingControls.png",
  scheduleMeetings: "./images/HELP&FAQ/web/scheduleMeetings.png",
  shareScreen: "./images/HELP&FAQ/web/shareScreen.png",
  waitingRoomFaq: "./images/HELP&FAQ/web/waitingRoom.png",
  recordMeeting1: "./images/HELP&FAQ/web/recordMeeting1.png",
  recordMeeting2: "./images/HELP&FAQ/web/recordMeeting2.png",
  addorRemoveparticipantsWaitingRoom: "./images/HELP&FAQ/web/addorRemoveparticipantsWaitingRoom.png",
  addorRemoveparticipantsWaitingRoom2: "./images/HELP&FAQ/web/addorRemoveparticipantsWaitingRoom2.png",
  checkAllMeeting: "./images/HELP&FAQ/web/checkAllMeeting.png",
  enableOrDisablePermissions: "./images/HELP&FAQ/web/enableOrDisablePermissions.png",
  whiteboardToolBar: "./images/HELP&FAQ/web/whiteboardToolBar.png",
  Whiteboard: "./images/HELP&FAQ/web/Whiteboard.png",
  whiteboardView: "./images/HELP&FAQ/web/whiteboardView.png",
  virtualBackgroundOption: "./images/HELP&FAQ/web/virtualBackgroundOption.png",
  howtosetvirtualbackground: "./images/HELP&FAQ/web/howtosetvirtualbackground.png",
  howToChat2: "./images/HELP&FAQ/web/howToChat2.png",
  downloadOrSaveRecordMeeting: "./images/HELP&FAQ/web/downloadOrSaveRecordMeeting.png",
  shareYourScreen: "./images/HELP&FAQ/web/shareYourScreen.png",
  welcome: "./images/HELP&FAQ/web/welcome.png",
  scheduleMeetings2: "./images/HELP&FAQ/web/scheduleMeetings2.png",


  virtualBackground1: "./images/virtualBackground1.png",
  virtualBackground2: "./images/virtualBackground2.png",
  virtualBackground3: "./images/virtualBackground3.png",
  virtualBackground4: "./images/virtualBackground4.png",
  blurBackground: "./images/blurBackground.png",
  visualEffectIcon: "./images/visualEffects.svg"
};

export const renewTokenTime = +new Date(+new Date() + 2 * 60 * 60 * 1000);
export const removeMicOnWarningTime = 30 * 1000;
export const showMicOnWarningTime = 2 * 60 * 1000;

export const roomAutoClose = {
  showModalTime: 300000, // 5min 5*60*1000
  autoCloseTime: 60 // 1 min
}

export const SANTRY = true;
export const NETWORK_IMAGE = `https://${window.location.hostname}/meet/images/wave-logo-grey.png`;

export const audioPath = {
  join: "./audio/join.mp3",
};

export const consoleUrl = `https://${window.location.hostname}`;
export const consoleApiUrl = `https://${window.location.hostname}/console-api`;
export const consoleApiRoutes = {
  branding: '/v1/branding/brandingDetails'
}