import React, { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton, Popper, MenuItem, MenuList, Grow, Paper, ClickAwayListener } from '@mui/material';

export default function ParticipantTabHeader({ handleDrawerClose, peerState, DrawerHeader, theme, changeApiFetchData }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [heading, setHeading] = useState("Participants");

    // Open more menu option.
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // Close menu on close hit.
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const changeTabTitle = (index) => {
        setSelectedIndex(index)
        setOpen(false);
        if (index === 1) {
            setHeading("Participants")
            return changeApiFetchData("participants")
        }
        if (index === 2) {
            setHeading("Blocked Participants")
            return changeApiFetchData("blockedParticipants")
        }
    }

    return (
        <>
            <DrawerHeader sx={{ color: "#000000" }}>
                {heading}{selectedIndex === 1 && ` (${Object.keys(peerState).length + 1})`}
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle()}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: 0,
                        marginLeft: 'auto'
                    }}>
                    <ArrowDropDownIcon />
                </IconButton>
                <IconButton onClick={() => handleDrawerClose()}>
                    {theme === 'rtl' ? <CloseIcon sx={{
                        width: '18px',
                        height: '18px',
                    }} /> : <CloseIcon sx={{
                        width: '18px',
                        height: '18px',
                    }} />}
                </IconButton>
            </DrawerHeader>

            <Popper
                sx={{
                    zIndex: 1,
                    p: 0,
                    margin: 0
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper sx={{
                            border: '1px solid #F0F0F0'
                        }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem
                                        key="allowToTalk"
                                        // disabled={index === 2}
                                        selected={selectedIndex === 1}
                                        onClick={() => changeTabTitle(1)}
                                        sx={{ color: '#242628' }}
                                    >
                                        Participants
                                    </MenuItem>
                                    <MenuItem
                                        key="removePeer"
                                        // disabled={index === 2}
                                        selected={selectedIndex === 2}
                                        onClick={() => changeTabTitle(2)}
                                        sx={{ color: '#242628' }}
                                    >
                                        Blocked Participants
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
