import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import URLParse from 'url-parse';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';
import { createRedirectPath } from '../../../utils/common';

export default function KickPeer() {
    const navigate = useNavigate();
    const urlParser = new URLParse(window.location.href, true)

    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            if (event.state) {
                window.location.href = createRedirectPath('/');
            }
        }, false);

        return () => {
            // logger.debug('Webinar() useEffect component crash error handler');
            window.location.href = createRedirectPath('/');
        }

    }, [])

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
        }}>
            <Box sx={{
                backgroundColor: 'card.main',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                height: '423px',
                width: '523px',
                flexDirection: 'column',
                borderRadius: '10px'
            }}>
                <MeetLogoSvg width='120px' />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '25px',
                    mb: 3,
                    mt: 3,
                    color: "grey.70"
                }}>
                    {`You are removed from the room by ${urlParser?.query?.n || "Host"}`}
                </Typography>

                <Typography sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: 'grey.400',
                    mb: 3
                }}>
                    You will be forwarded back to the home screen
                </Typography>


                <Button
                    variant="contained"
                    color='primary'
                    size='large'
                    sx={{
                        mt: 3,
                        borderRadius: '10px',
                        textTransform: 'none',
                        width: '50%',
                        boxShadow: 'none'
                    }}
                    onClick={() => navigate('/')}
                >Go to home</Button>
            </Box>

        </Grid>
    )
}
