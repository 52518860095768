export const setRoomUrl = (url) => {
	return {
		type: 'SET_ROOM_URL',
		payload: { url }
	};
};

export const setRoomState = (state) => {
	return {
		type: 'SET_ROOM_STATE',
		payload: { state }
	};
};

export const setWebinarState = (state) => {
	return {
		type: 'SET_WEBINAR_STATE',
		payload: { state }
	};
};

export const setRoomActiveSpeaker = (peerId) => {
	return {
		type: 'SET_ROOM_ACTIVE_SPEAKER',
		payload: { peerId }
	};
};

export const setRoomStatsPeerId = (peerId) => {
	return {
		type: 'SET_ROOM_STATS_PEER_ID',
		payload: { peerId }
	};
};

export const setRoomFaceDetection = (flag) => {
	return {
		type: 'SET_FACE_DETECTION',
		payload: flag
	};
};

export const setMe = ({ peerId, displayName, displayNameSet, device }) => {
	return {
		type: 'SET_ME',
		payload: { peerId, displayName, displayNameSet, device }
	};
};

export const setMediaCapabilities = ({ canSendMic, canSendWebcam }) => {
	return {
		type: 'SET_MEDIA_CAPABILITIES',
		payload: { canSendMic, canSendWebcam }
	};
};

export const setCanChangeWebcam = (flag) => {
	return {
		type: 'SET_CAN_CHANGE_WEBCAM',
		payload: flag
	};
};

export const setDisplayName = (displayName) => {
	return {
		type: 'SET_DISPLAY_NAME',
		payload: { displayName }
	};
};

export const setAudioOnlyState = (enabled) => {
	return {
		type: 'SET_AUDIO_ONLY_STATE',
		payload: { enabled }
	};
};

export const setAudioOnlyInProgress = (flag) => {
	return {
		type: 'SET_AUDIO_ONLY_IN_PROGRESS',
		payload: { flag }
	};
};

export const setAudioMutedState = (enabled) => {
	return {
		type: 'SET_AUDIO_MUTED_STATE',
		payload: { enabled }
	};
};

export const setRestartIceInProgress = (flag) => {
	return {
		type: 'SET_RESTART_ICE_IN_PROGRESS',
		payload: { flag }
	};
};

export const addProducer = (producer) => {
	return {
		type: 'ADD_PRODUCER',
		payload: { producer }
	};
};

export const removeProducer = (producerId) => {
	return {
		type: 'REMOVE_PRODUCER',
		payload: { producerId }
	};
};

export const setProducerPaused = (producerId) => {
	return {
		type: 'SET_PRODUCER_PAUSED',
		payload: { producerId }
	};
};

export const setProducerResumed = (producerId) => {
	return {
		type: 'SET_PRODUCER_RESUMED',
		payload: { producerId }
	};
};

export const setProducerTrack = (producerId, track) => {
	return {
		type: 'SET_PRODUCER_TRACK',
		payload: { producerId, track }
	};
};

export const setProducerScore = (producerId, score) => {
	return {
		type: 'SET_PRODUCER_SCORE',
		payload: { producerId, score }
	};
};

export const addDataProducer = (dataProducer) => {
	return {
		type: 'ADD_DATA_PRODUCER',
		payload: { dataProducer }
	};
};

export const removeDataProducer = (dataProducerId) => {
	return {
		type: 'REMOVE_DATA_PRODUCER',
		payload: { dataProducerId }
	};
};

export const setWebcamInProgress = (flag) => {
	return {
		type: 'SET_WEBCAM_IN_PROGRESS',
		payload: { flag }
	};
};

export const setShareInProgress = (flag) => {
	return {
		type: 'SET_SHARE_IN_PROGRESS',
		payload: { flag }
	};
};

export const addPeer = (peer) => {
	return {
		type: 'ADD_PEER',
		payload: { peer }
	};
};

export const removePeer = (peerId) => {
	return {
		type: 'REMOVE_PEER',
		payload: { peerId }
	};
};

export const setPeerDisplayName = (displayName, peerId) => {
	return {
		type: 'SET_PEER_DISPLAY_NAME',
		payload: { displayName, peerId }
	};
};

export const addConsumer = (consumer, peerId) => {
	return {
		type: 'ADD_CONSUMER',
		payload: { consumer, peerId }
	};
};

export const removeConsumer = (consumerId, peerId) => {
	return {
		type: 'REMOVE_CONSUMER',
		payload: { consumerId, peerId }
	};
};

export const removeConsumerById = (consumerId) => {
	return {
		type: 'REMOVE_CONSUMER_BY_ID',
		payload: { consumerId }
	};
};

export const setConsumerPaused = (consumerId, originator) => {
	return {
		type: 'SET_CONSUMER_PAUSED',
		payload: { consumerId, originator }
	};
};

export const setConsumerResumed = (consumerId, originator) => {
	return {
		type: 'SET_CONSUMER_RESUMED',
		payload: { consumerId, originator }
	};
};

export const setConsumerCurrentLayers = (consumerId, spatialLayer, temporalLayer) => {
	return {
		type: 'SET_CONSUMER_CURRENT_LAYERS',
		payload: { consumerId, spatialLayer, temporalLayer }
	};
};

export const setConsumerPreferredLayers = (consumerId, spatialLayer, temporalLayer) => {
	return {
		type: 'SET_CONSUMER_PREFERRED_LAYERS',
		payload: { consumerId, spatialLayer, temporalLayer }
	};
};

export const setConsumerPriority = (consumerId, priority) => {
	return {
		type: 'SET_CONSUMER_PRIORITY',
		payload: { consumerId, priority }
	};
};

export const setConsumerTrack = (consumerId, track) => {
	return {
		type: 'SET_CONSUMER_TRACK',
		payload: { consumerId, track }
	};
};

export const setConsumerScore = (consumerId, score) => {
	return {
		type: 'SET_CONSUMER_SCORE',
		payload: { consumerId, score }
	};
};

export const addDataConsumer = (dataConsumer, peerId) => {
	return {
		type: 'ADD_DATA_CONSUMER',
		payload: { dataConsumer, peerId }
	};
};

export const removeDataConsumer = (dataConsumerId, peerId) => {
	return {
		type: 'REMOVE_DATA_CONSUMER',
		payload: { dataConsumerId, peerId }
	};
};

export const addNotification = (notification) => {
	return {
		type: 'ADD_NOTIFICATION',
		payload: { notification }
	};
};

export const removeNotification = (notificationId) => {
	return {
		type: 'REMOVE_NOTIFICATION',
		payload: { notificationId }
	};
};

export const removeAllNotifications = () => {
	return {
		type: 'REMOVE_ALL_NOTIFICATIONS'
	};
};


export const totalWatching = (count) => {
	return {
		type: 'TOTAL_WATCHING',
		payload: { count }
	};
};

export const webinarInfo = (info) => {
	return {
		type: 'SET_WEBINAR_INFO',
		payload: { info }
	};
};

export const deviceType = (info) => {
	return {
		type: 'DEVICE_TYPE',
		payload: { info }
	};
};

export const selectedSpeakerId = (info) => {
	return {
		type: 'SELECTED_SPEAKER',
		payload: { info }
	};
};

export const setMicAndCamPermissions = () => {
	return {
		type: 'SET_MIC_AND_CAM_PERMISSIONS',
		payload: {}
	};
};

export const manageMedia = (info) => {
	return {
		type: 'MANAGE_MEDIA',
		payload: { ...info }
	}
}

export const manageMediaStop = (type) => {
	return {
		type: 'MANAGE_MEDIA_STOP',
		payload: { type }
	}
}

export const promoteRole = () => {
	return {
		type: 'PROMOTE_ROLE',
		payload: {}
	}
}

export const demoteRole = () => {
	return {
		type: 'DEMOTE_ROLE',
		payload: {}
	}
}

export const demoteRoleUpdateWss = () => {
	return {
		type: 'UPDATE_WSS',
		payload: {}
	}
}

export const updateNetworkStrength = (info) => {
	return {
		type: 'UPDATE_NETWORK_STRENGTH',
		payload: { ...info }
	}
}

export const updateWhiteboard = (data) => {
	return {
		type: 'UPDATE_WHITEBOARD_DATA',
		payload: data
	}
}

export const updatePDFUrl = (data) => {
	return {
		type: 'UPDATE_PDF_URL',
		payload: data
	}
}

export const undoRedoWhiteboard = (data) => {
	return {
		type: 'UNDO_REDO_WHITEBOARD',
		payload: data
	}
}

export const whiteboardScroll = (data) => {
	return {
		type: "WHITEBOARD_SCROLL",
		payload: data
	}
}

export const udpateWhitebaordOpenStatus = (data) => {
	return {
		type: "WHITEBOARD_STATUS",
		payload: data
	}
}

export const setPeerWhiteboardAccess = (data) => {
	return {
		type: 'SET_WHITEBOARD_ACCESS',
		payload: data
	};
};

export const updateWhiteboardAccess = (data) => {
	return {
		type: 'UPDATE_WHITEBOARD_ACCESS',
		payload: data
	}
}

export const enableVirtualBackground = (data) => {
	console.log(data);
	return {
		type: 'VIRTUAL_BACKGROUND',
		payload: data,
	}
}

export const audioNotification = (data) => {
	return {
		type: 'ADD_AUDIO_NOTIFICATIONS',
		payload: data
	};
};
export const removeAudioNotification = (data) => {
	return {
		type: 'REMOVE_AUDIO_NOTIFICATIONS',
		payload: data
	};
};

