import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const ParticipantsInWaitingRoom = () => {
  return (
    <>
      <Heading
        text='Participants in the Waiting Room '
      />

      <ImageComp
        name={"waitingRoomFaq"}

      />

      <ListComp

        data={[

          {
            name: 'If your host has activated the waiting room, all participants will first connect in a waiting room.',
            id: '13',

          },
          {
            name: 'The waiting room screen will show the countdown to start the meeting with meeting description and meeting ID.',
            id: '14',

          }, {
            name: 'As soon as the host allow a participant, the participant will automatically connect to the meeting.',
            id: '15',

          }

        ]}

      />
    </>
  );
};

export default ParticipantsInWaitingRoom;
