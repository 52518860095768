import React, { memo } from 'react';
import { Chip } from '@mui/material';

function DisplayName({isMe, displayName}) {
    return (
        <Chip
            label={isMe ? "You" : displayName}
            sx={{
                position: 'absolute',
                bottom: 10,
                left: 10,
                bgcolor: 'rgba(0,0,0,0.5)',
                color: '#ffffff',
                fontSize: '12px',
                height: '24px'
            }} />
    )
}

export default memo(DisplayName);
