import React from 'react';
import { IconButton, Typography, Modal, Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

export default function EndWebinarModal({ openEndAll, setOpenEndAll, endSuccessLoading, endWebinar }) {
    return (
        <Modal
            open={openEndAll}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 330,
                bgcolor: '#222222',
                boxShadow: 24,
                borderRadius: '10px',
                p: 4,

            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: '10px',
                }}>
                    <IconButton sx={{
                        color: '#D3232F',
                        bgcolor: "rgba(211, 35, 47, 0.1)",
                        // fontSize: '24px',
                        fontSize: '30xp',
                        height: '10vh',
                        width: '10vh',
                        "&:hover": {
                            color: '#D3232F',
                            bgcolor: "rgba(211, 35, 47, 0.1)",
                        }
                    }}>
                        <LocalPhoneOutlinedIcon />
                    </IconButton>

                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#FFFFFF', mt: 2 }}>
                        Do you want to end this session ?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ color: '#CCCCCC', mt: 2 }}>
                        Room will be closed for everyone
                    </Typography>

                    <Grid xs={12} item sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        width: '100%'
                    }}>
                        <LoadingButton
                            loading={endSuccessLoading ? true : null}
                            color='error'
                            loadingPosition="center"
                            variant="contained"
                            onClick={(e) => endWebinar(e)}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none',
                            }}
                        >
                            End for all
                        </LoadingButton>
                        <LoadingButton
                            loadingPosition="center"
                            onClick={() => setOpenEndAll()}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none',
                                bgcolor: '#454545',
                                color: '#FFFFFF',
                                '&:hover': {
                                    bgcolor: '#454545',
                                    color: '#FFFFFF'
                                }
                            }}
                        >
                            Cancel
                        </LoadingButton>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    )
}
