const initState = {
    networkStrength: 3,
};

const networkStrength = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_NETWORK_STRENGTH':
            const { networkStrength = 2 } = action?.payload;
            return { ...state, networkStrength }
        default:
            return state;
    }
}

export default networkStrength;