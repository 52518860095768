import React, { useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';

export default function MobileEndWebianr() {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.hash = "no-back-button";

        // Again because Google Chrome doesn't insert
        // the first hash into the history
        window.location.hash = "Again-No-back-button";

        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            overflow: "hidden"
        }}>
            <Box sx={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: '10px',
                marginTop: '-75px',
                gap: '15px'
            }}>
                <MeetLogoSvg width='120px' />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    padding: "10px 10px 0px 10px",
                    color: "grey.70"
                }}>
                    Thank You for Joining the Meeting.
                </Typography>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: "grey.400"
                }}>
                     You can manage your meeting sessions by Login
                </Typography>


                <Button
                    variant="contained"
                    color='primary'
                    size='large'
                    sx={{
                        borderRadius: '10px',
                        textTransform: 'none',
                        width: '70%',
                    }}
                    onClick={() => navigate('/')}
                >Go to home</Button>
            </Box>

        </Grid>
    )
}
