import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const WhiteBoradToolBar = () => {
  return (
    <>
      <Heading
        text='WhiteBorad Tool Bar'
      />

      <ImageComp
        name={"whiteboardToolBar"}
      />

      <ListComp
        data={[

          {
            name: 'Pencil: To draw or mark with the free hand.',
            id: '1',

          },
          {
            name: 'Text: Click on it to write text on the whiteboard.',
            id: '2',

          },
          {
            name: 'Eraser: Erase anything on the whiteboard.',
            id: '3',

          },
          {
            name: 'Line: To draw lines.',
            id: '4',

          },
          {
            name: 'Rectangle: Draw rectangle or square shapes.',
            id: '5',

          },
          {
            name: 'Ellipse: Draw ellipses or circles on the page.',
            id: '6',

          },
          {
            name: 'Triangle: Use it to draw triangles.',
            id: '7',

          },
          {
            name: 'Undo: Use this to undo your last actions.',
            id: '8',

          },
          {
            name: 'Redo: To redo your last undo actions.',
            id: '9',

          },
          {
            name: 'Filled Box: To create filled shapes, activate this button.',
            id: '10',

          },
          {
            name: 'Change Color: Select the color of your shape and text.',
            id: '11',

          },
          {
            name: 'Brush Size: Set the width of the stroke.',
            id: '12',

          },
          {
            name: 'Delete Page: Clears the whole page or you can say it resets the whole page.',
            id: '13',

          },
          {
            name: 'Save as Image: Save the whiteboard on your computer as an image.',
            id: '14',

          },
          {
            name: 'Upload: To upload an image or PDF on the whiteboard',
            id: '15',

          },
          {
            name: 'Open Whiteboard for All: This will open the whiteboard on all participants’ screens.',
            id: '16',

          },
          {
            name: 'Peers: This shows a list of all the candidates that are active on the whiteboard.',
            id: '17',

          },

        ]}
        listType="ordered"
      />

    </>
  );

};

export default WhiteBoradToolBar;






