import React from 'react';
import { MenuItem, Menu, Divider, ListItemIcon } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EditIcon from '@mui/icons-material/Edit';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { removeBulkItems } from '../../lib/cookiesManager';

export default function ProfileMenu({ anchorElEnd, handleCloseProfileMenu }) {
    const navigate = useNavigate();

    const logout = () => {
        removeBulkItems(["sessionToken", 'exp', 'name', 'token'])
        navigate('/login');
    }

    return (
        <Menu
            anchorEl={anchorElEnd}
            id="profile-menu"
            open={Boolean(anchorElEnd)}
            onClose={() => handleCloseProfileMenu()}
            onClick={() => handleCloseProfileMenu()}
            PaperProps={{
                elevation: 0,
                sx: {
                    mt: -3,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        bottom: -10,
                        right: 15,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                }
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            <MenuItem onClick={() => navigate('/profile')}>
                <ListItemIcon>
                    <AccountBoxIcon />
                </ListItemIcon>
                Profile
            </MenuItem>
            <MenuItem disabled>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                Edit Profile
            </MenuItem>

            <Divider />

            <MenuItem onClick={(e) => logout()}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    )
}
