import * as finalConfigFile from './config.js'
import * as configJson from './config.json'

// const env = process.env.REACT_APP_PRODUCT_ENV;

const currentEnv = () => process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";


// const ENVIRONMENT = currentEnv();

// const configJson = await import(`./${env}/config.json`);
// const finalConfigFile = await import(`./${env}/config.${ENVIRONMENT}`);

const fetchConfig = () => finalConfigFile;


const configFile = fetchConfig();

export {
  currentEnv,
  configFile,
  configJson
};
