import { Avatar } from '@mui/material';
import { Divider, Box, Typography, Button } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { WaitingUsersCount } from './count'
import { axiosPostCall } from '../../../../services/apisCall';
import { renderErrorMessage } from '../../../../components/ToastNotification';

export const WaitingUsersList = ({searchTxt}) => {
    const { users } = useSelector(state => state.waitingRoom);

    const location = useLocation();

    const { roomId = "" } = location?.state || {};

    const handleAllowClick = async (peerId, action) => {
        try {
            return await axiosPostCall("waitingRoomPeerActions", { roomId, peerId, action });
        } catch (error) {
            return renderErrorMessage(error?.message || `Unable to send ${action} request`);
        }
    };

    const searchFilterPanelist = (item) => {
        if (searchTxt.replace(/\s+/g, " ").trim() === "") {
            return item;
        } else if (item?.displayName?.toLowerCase()?.includes(searchTxt.replace(/\s+/g, " ")?.trim()?.toLowerCase())) {
            return item;
        }
    };

    return (
        <Box sx={{ pt: 1 }}>
            {/* Show waiting peers count */}
            <WaitingUsersCount count={users?.length || 0} />

            {/* Render waiting peers list */}
            {users.length > 0 && users.filter((item) => searchFilterPanelist(item)).map(({ peerId, displayName }) => (
                <Box key={peerId}>
                    <Divider />
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: '5px 15px 5px 15px',
                        alignItems: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Avatar sx={{ color: '#fff', backgroundColor: '#D37F18', textTransform: 'uppercase' }}>
                                {displayName ? displayName[0] : ''}
                            </Avatar>
                            <Typography sx={{ color: '#727374', fontSize: '14px', fontWeight: 500 }}>
                                {displayName}
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: '10px'
                        }}>
                            <Button variant='outlined' size='small' sx={{
                                color: '#0C8CE9',
                                borderColor: '#0C8CE9',
                                width: '100%',
                                height: '30px'
                            }} onClick={() => handleAllowClick(peerId, 'allow')}
                            >
                                Allow
                            </Button>
                            <Button variant='outlined' size='small' sx={{
                                color: '#E16C77',
                                borderColor: '#E16C77',
                                width: '100%',
                                height: '30px'
                            }}
                                onClick={() => handleAllowClick(peerId, 'decline')}
                            >
                                Decline
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ))}

            <Divider />

        </Box>
    )
}
