import { Grid, Box, Typography, Modal, Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react';
// import SideBar from '../../../layouts/sideBar';
// import { fontSize, Stack, width } from '@mui/system';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage } from '../../../components/ToastNotification';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import BrandingSection from '../../../components/brandingSection';
import { configFile } from '../../../config';
import { validateDescription } from '../../../utils/common';
import { axiosPostCall } from '../../../services/apisCall';
// import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export default function Feedback() {
    const { imagesUrl } = configFile;
    const [type, setType] = useState('');
    const [activeIcon, setActiveIcon] = useState(false);
    const [message, setMessage] = useState('');
    const [sendSuccess, setSendSuccess] = useState(false);
    const [descError, setDescError] = useState(false)
    const navigate = useNavigate()

    // Handle send feedback submit button.
    const shareFeedback = async (e) => {
        e.preventDefault();

        // validate data before send to backend.
        if (!type) {
            return renderErrorMessage("Select feedback type!");
        }
        if (!message) return renderErrorMessage("Enter message!");

        if (!validateDescription(message)) {
            setDescError(true);
            return renderErrorMessage("Please enter a valid Feedback!");
        }

        // Send data to backend.
        try {
            await axiosPostCall("feedback", { feedbackType: type, message, ts: +new Date() })

            // renderSuccessMessage(res || "Feedback send successfully!");
            setSendSuccess(true);
            setMessage('');
            setType('');
        } catch (error) {
            renderErrorMessage(error?.message || "Internel server error!")
        }
    };

    useEffect(() => {
        if (type) return setActiveIcon(true)
    }, [type])

    return (
        // <Grid item={true} xs={12} container>

        //     <SideBar />
        <Grid item={true} xs={12} container sx={{
            height: '100vh',
            backgroundColor: '#cccccc66',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            pt: '64px',
        }}>
            <ToastContainer />
            <Grid item={true} xs={6} sx={{
                // bgcolor: '#EDEDED',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src='./images/feedback_img.svg' alt='feedback' />

            </Grid>
            <Grid item={true} xs={6} sx={{
                bgcolor: '#FFFFFF',
                width: '100%',
                display: 'flex',
                borderRadius: '10px',
                // alignItems: 'center',
                // justifyContent: 'space-between',
                flexDirection: 'column',
            }}>
                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '24px',
                    lineHeight: '29px',
                    color: '#444444',
                    mx: 2,
                    my: 3
                }}>
                    Share your Feedback
                </Typography>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '18px',
                    color: '#757575',
                    mx: 2,
                    // my: 3
                }}>
                    Please help us to improve the experience by giving us
                    your feedback.
                </Typography>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#000000',
                    mx: 2,
                    my: 3
                }}>
                    How would you rate the overall meeting experience?
                </Typography>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 2,
                        cursor: 'pointer',
                        gap: '15px'
                    }} onClick={() => setType("Bad")}>
                        <Typography sx={{
                            fontSize: 40,
                            opacity: (activeIcon && type !== "Bad") ? 0.5 : (type === "Bad" ? 1 : 0.5),
                        }}>&#128544;</Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '13px',
                            color: '#000000',
                            opacity: (activeIcon && type !== "Bad") ? 0.5 : (type === "Bad" ? 1 : 0.5),
                        }}>Bad</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 2,
                        cursor: 'pointer',
                        gap: '15px'
                    }} onClick={() => setType("Average")}>
                        <Typography sx={{
                            fontSize: 40,
                            opacity: (activeIcon && type !== "Average") ? 0.5 : (type === "Average" ? 1 : 0.5),
                        }}>&#128533;</Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '13px',
                            color: '#000000',
                            opacity: (activeIcon && type !== "Average") ? 0.5 : (type === "Average" ? 1 : 0.5),
                        }} >Average</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 2,
                        cursor: 'pointer',
                        gap: '15px'
                    }} onClick={() => setType("Good")}>
                        <Typography sx={{
                            fontSize: 40,
                            opacity: (activeIcon && type !== "Good") ? 0.5 : (type === "Good" ? 1 : 0.5),
                        }}>&#128528;</Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '13px',
                            color: '#000000',
                            opacity: (activeIcon && type !== "Good") ? 0.5 : (type === "Good" ? 1 : 0.5),
                        }}>Good</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 2,
                        cursor: 'pointer',
                        gap: '15px'
                    }} onClick={() => setType("Very Good")}>
                        <Typography sx={{
                            fontSize: 40,
                            opacity: (activeIcon && type !== "Very Good") ? 0.5 : (type === "Very Good" ? 1 : 0.5),
                        }}>&#128522;</Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '13px',
                            color: '#000000',
                            opacity: (activeIcon && type !== "Very Good") ? 0.5 : (type === "Very Good" ? 1 : 0.5),
                        }}>Very Good</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ml: 2,
                        cursor: 'pointer',
                        gap: '15px'
                    }} onClick={() => setType("Awesome")}>
                        <Typography sx={{
                            fontSize: 40,
                            opacity: (activeIcon && type !== "Awesome") ? 0.5 : (type === "Awesome" ? 1 : 0.5),
                        }}>&#128512;</Typography>
                        <Typography sx={{
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '13px',
                            color: '#000000',
                            opacity: (activeIcon && type !== "Awesome") ? 0.5 : (type === "Awesome" ? 1 : 0.5),
                        }}>Awesome</Typography>
                    </Box>
                </Box>

                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#000000',
                    mx: 2,
                    my: 3
                }}>
                    Could you tell us why?
                </Typography>

                <Box sx={{ mx: 2 }}>
                    <TextField
                        id="outlined-multiline-static"
                        // label="Multiline"
                        multiline
                        rows={4}
                        placeholder="Enter description..."
                        onChange={(e) => setMessage(e.target.value)}
                        onClick={() => setDescError(false)}
                        value={message}
                        sx={{
                            width: '100%',
                            backgroundColor: '#F4F4F4',
                        }}
                        error={descError ? true : false}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: 2
                }}>
                    <Button variant='outlined' color='success' sx={{
                        mt: 4,
                        mb: 5,
                        width: '100%',
                        textTransform: 'none'
                    }} onClick={(e) => shareFeedback(e)}>Share feedback</Button>
                </Box>
            </Grid>

            {/* Branding section component. */}
            <BrandingSection />

            {/* Show pupup on send feed back success. */}
            <Modal
                open={sendSuccess}
                // onClose={() => setSendSuccess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 350,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                    p: 4,
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img src={imagesUrl?.feedbackPopup} alt="Feedback success" />
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '29px',
                        color: '#444444',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3
                    }}>
                        Thank You!
                    </Typography>
                    {/* <Divider id="modal-modal-title" sx={{ mt: 3 }} /> */}
                    <Typography id="modal-modal-description" sx={{
                        mt: 2, mx: 1,
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: '#BBBBBA',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        For sharing your thoughts we appreciate
                        your feedback!
                    </Typography>

                    <Grid item={true} xs={12} sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 5
                    }}>
                        <LoadingButton
                            loading={false ? true : null}
                            color='success'
                            loadingPosition="center"
                            variant='contained'
                            onClick={(e) => navigate('/')}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                width: '100%',
                                textTransform: 'none'
                            }}
                        >
                            Go to home
                        </LoadingButton>

                    </Grid>
                </Box>
            </Modal>
        </Grid>


        // </Grid>
    )
}
