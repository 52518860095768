import { combineReducers } from 'redux';
import room from './room';
import me from './me';
import producers from './producers';
import peers from './peers';
import consumers from './consumers';
import notifications from './notifications';
import auth from './authReducers';
import pinFocus from './pinFocusReducers';
import totalWatching from './totalWatching';
import publicChat from './publicChat';
import privateChat from './privateChat';
import webinarInfo from './webinarInfo';
import deviceInfo from './deviceInfo';
import speaker from './selectedDevice';
import micAndCamPermissions from './micAndCamPermission';
import media from './media';
import waitingRoom from './waitingRoom';
import recording from './recording';
import networkStrength from './networkStrength';
import whiteboard from './whiteboard';
import pdfPage from './pdfPage';
import virtualBackground from './virtualBackground';
import audioNotifications from './audioNotifications';
import branding from "./branding";

const reducers = combineReducers(
	{
		room,
		me,
		producers,
		peers,
		consumers,
		notifications,
		auth,
		pinFocus,
		totalWatching,
		publicChat,
		privateChat,
		webinarInfo,
		deviceInfo,
		speaker,
		micAndCamPermissions,
		media,
		waitingRoom,
		recording,
		networkStrength,
		whiteboard,
		pdfPage,
		virtualBackground,
		audioNotifications,
		branding,
	});

export default reducers;
