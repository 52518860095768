import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';


const JoinMeeting = () => {
  const baseurl = window.location.origin

  return (
    <>
      <Heading
        text='Join Meeting '
      />

      <ListComp
        headingText=' To join a meeting from the panel'
        data={[

          {
            name: `Visit '${baseurl}' and log in to your account.`,
            id: '13',
          },
          {
            name: 'Here, click the ‘Join’ button on the screens left half.',
            id: '14',

          }, {
            name: 'Paste the invite link you received from the host.',
            id: '15',

          }, {
            name: 'Enter a name to display over your video feed.',
            id: '16',

          }, {
            name: 'Click the ‘Join Now’ button.',
            id: '17',

          },

        ]}

      />

      <ListComp
        headingText='To join a meeting from the invite email'
        data={[

          {
            name: 'If you have received the invitation over the email, then open that email with a meeting invite.',
            id: '13',

          },
          {
            name: 'Look for the meeting link..',
            id: '14',

          }, {
            name: 'Click on the link..',
            id: '15',

          }, {
            name: 'Enter a name to display over your video feed.',
            id: '16',

          }, {
            name: 'Click the ‘Join Now’ button.',
            id: '17',

          },

        ]}

      />

      <ImageComp
        name={"joinMeeting"}

      />

    </>
  );

};

export default JoinMeeting;




