import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import store from '../../store';
import Logger from '../../lib/Logger';
import { sseClose, WaitingRoomSseConnectEvents } from '../../lib/WaitingRoomSse';
import { createRedirectPath } from '../../utils/common';

const logger = new Logger("waitingRoom");

export default function WaitingRoom() {
    const location = useLocation();
    const navigate = useNavigate()

    const [meetingTime, setMeetingTime] = useState('');
    const [meetingDay, setMeetingDay] = useState('');
    const [timeRemaining, setTimeRemaining] = useState('');
    const [rejectMessage, setRejectMessage] = useState('');
    const [acceptMessage, /*setAcceptMessage*/] = useState('');

    const { actions = "" } = useSelector(state => state.waitingRoom);
    const { endDate = "", startDate = "", roomId = "", peerId = "", title = "", name, e2eKey = "" } = location?.state || {};

    useEffect(() => {
        try {
            if (!roomId) return window.location.href = createRedirectPath("/")
            WaitingRoomSseConnectEvents(peerId, store, roomId, name);

        } catch (error) {
            WaitingRoomSseConnectEvents(peerId, store, roomId, name);
        }

        return () => sseClose()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let redirectTimer;
        if (actions === "allow") {
            let redirectURL = `/meet?roomId=${roomId}&peerId=${peerId}`;
            if (e2eKey) {
                redirectURL = redirectURL + `&key=${encodeURIComponent(e2eKey.toString())}`;
            }
            navigate(redirectURL, { state: location?.state || {} });
        }
        if (actions === "decline") {
            setRejectMessage("Your request has been rejected by host. You will be redirect to home in 10 seconds!")
            redirectTimer = setTimeout(() => {
                navigate('/')
            }, 10000);
        }

        return () => clearTimeout(redirectTimer)
        // eslint-disable-next-line
    }, [actions])

    useEffect(() => {

        const newDate = new Date(startDate);

        const formatTime = (date) =>
            date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        const startMeetingTime = formatTime(newDate);
        const endMeetingTime = formatTime(new Date(endDate));
        setMeetingTime(`${startMeetingTime} to ${endMeetingTime}`);


        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        const formattedStartDate = newDate.toLocaleDateString(undefined, options);
        setMeetingDay(formattedStartDate);

        const startTime = newDate;
        const updateTimer = () => {
            const currentTime = new Date();
            const timeDifference = startTime - currentTime;

            if (timeDifference <= 0) {
                setTimeRemaining('');
            } else {
                const hours = Math.floor(timeDifference / 3600000);
                const minutes = Math.floor((timeDifference % 3600000) / 60000);
                const seconds = Math.floor((timeDifference % 60000) / 1000);

                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                setTimeRemaining(formattedTime);
            }
        }

        updateTimer();
        const timerInterval = setInterval(updateTimer, 1000);
        return () => {
            clearInterval(timerInterval);
            logger.debug('Webinar() useEffect component crash error handler');
            // window.location.href = '/';
        }
        // eslint-disable-next-line
    }, [location, timeRemaining]);

    return (
        <>
            <Box
                id="waitingRoom"
                sx={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{
                    display: "flex",
                    p: '60px 40px 20px 40px',
                    height: '40%',
                }}>

                    <Box sx={{
                        display: 'flex',
                        justifyContent: ["center", "center", 'space-between', 'space-between', 'space-between'],
                        flexDirection: ["column", "column", "row", "row", "row"],
                        alignItems: ["center", "center", "flex-start", "flex-start", "flex-start"],
                        width: '100%',
                        p: '60px 40px 20px 40px',
                        height: '40%',
                        gap: '25px'
                    }}>
                        <Typography variant='h6' sx={{ color: 'grey.0', mb: [3, 3] }}>
                            Waiting Room
                        </Typography>

                        {/* Room Title and timing section */}
                        <Box sx={{
                            display: 'flex',
                            // justifyContent: 'flex-start',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                            // width: '100%',

                        }}>
                            <Typography variant='h6' sx={{ color: 'grey.0' }}>
                                {title}
                            </Typography>

                            <Typography sx={{ color: 'grey.600'/* 'grey.300' */, fontSize: '12px', fontWeight: 400 }}>
                                {meetingDay}
                            </Typography>

                            <Typography sx={{ color: 'grey.600'/* 'grey.300' */, fontSize: '12px', fontWeight: 400 }}>
                                {meetingTime}
                            </Typography>
                        </Box>


                        {/* Room Id section */}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>

                            <Typography sx={{ color: 'grey.0'/* 'grey.300' */, fontSize: '12px', fontWeight: 500 }}>
                                Webinar ID:
                            </Typography>

                            <Typography variant='h6' sx={{ color: 'grey.600'/* 'grey.100' */, fontWeight: 500 }}>
                                {roomId}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Start timing sections & message section */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%'
                }}>

                    <Typography sx={{ color: 'grey.600'/* 'grey.100' */, fontSize: '14px', fontWeight: 400 }}>
                        Webinar will start in
                    </Typography>

                    <Typography variant='h6' sx={{ color: 'grey.500' }}>
                        {timeRemaining}
                    </Typography>

                    {/* Message section */}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        gap: '10px',
                        pt: ['100px', '100px', '150px', '150px', '150px']
                    }}>

                        <Typography sx={{ color: 'grey.500'/* 'grey.300' */, fontSize: '12px', fontWeight: 400 }}>
                            Message from Host
                        </Typography>

                        {acceptMessage && <Box sx={{
                            p: '10px 20px 10px 20px',
                            backgroundColor: 'grey.100'/* 'grey.800' */,
                            borderRadius: '20px',
                            gap: '10px'
                        }}>
                            <Typography sx={{ color: 'grey.800'/* 'grey.100' */, fontSize: '14px', fontWeight: 400 }}>
                                {acceptMessage}
                            </Typography>
                        </Box>
                        }

                        {rejectMessage && <Box sx={{
                            p: '10px 20px 10px 20px',
                            backgroundColor: 'grey.800'/* 'grey.800' */,
                            borderRadius: '20px',
                            gap: '10px'
                        }}>
                            <Typography sx={{ color: 'grey.100'/* 'grey.100' */, fontSize: '14px', fontWeight: 400 }}>
                                {rejectMessage}
                            </Typography>
                        </Box>
                        }

                    </Box>

                </Box >

            </Box>
            {/* Branding section. */}
            {/* < BrandingSection /> */}
        </>
    )
}
