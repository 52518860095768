import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs, Page, Document } from 'react-pdf';
import './index.css';
import Pagination from '@mui/material/Pagination';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import RoomContext from '../../../../../context/RoomContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({ fileReaderInfo, updateFileReaderInfo }) => {
  const roomClient = useContext(RoomContext) || {};
  const { id = "" } = useSelector(state => state.pdfPage);

  function onRenderSuccess() {
    const importPDFCanvas = document.querySelector('.import-pdf-page canvas');
    const pdfAsImageSrc = importPDFCanvas.toDataURL();

    updateFileReaderInfo({ currentPage: pdfAsImageSrc });
  }

  function onDocumentLoadSuccess({ numPages }) {
    updateFileReaderInfo({ totalPages: numPages });
  }

  function changePage(offset) {
    roomClient.sendWhiteBoardData({ clearCanvas: false, data: { type: "pdf", shadow: { color: id }, pdfPage: offset } })
    updateFileReaderInfo({ currentPageNumber: /* fileReaderInfo.currentPageNumber + */ offset });
  }

  // const nextPage = () => changePage(1);
  // const previousPage = () => changePage(-1);

  return (
    <div className={"pdfReader"}>
      <div className={"fileContainer"}>
        <Document
          className={"document react-pdf__Page"}
          file={fileReaderInfo.file}
          // style={{width: '300'}}
          onLoadSuccess={onDocumentLoadSuccess}
          // width={100}
          // scale={0.5}
          // rotate={90}
          onLoadProgress={({ loaded, total }) =>
            console.log('Loading a document: ' + (loaded / total) * 100 + '%')
          }
        >
          <Page
            className="import-pdf-page"
            // style={{width: '50', overflow: 'hidden', overflowX: 'scroll'}}
            size="A4"
            onRenderSuccess={onRenderSuccess}
            pageNumber={fileReaderInfo.currentPageNumber}
            width={1900}
            height={2200}
            scale={1}
          />
        </Document>
      </div>

      {fileReaderInfo.totalPages && <Pagination
        count={fileReaderInfo.totalPages}
        page={fileReaderInfo.currentPageNumber}
        onChange={(e, value) => changePage(value)}
      />
      }

      {/* <div className={"pageInfo"}>
        <span>
          Page {fileReaderInfo.currentPageNumber} of {fileReaderInfo.totalPages || '--'}
        </span>
        <button
          type="button"
          disabled={fileReaderInfo.currentPageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={fileReaderInfo.currentPageNumber >= fileReaderInfo.totalPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default PDFReader;
