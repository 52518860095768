import { useEffect } from 'react';
import Logger from "./Logger";
import { useDispatch } from "react-redux";
import { deviceType } from "../redux/actions/stateActions";

const logger = new Logger("ResponsiveCheck.js")

const ResponsiveCheck = () => {
    const dispatch = useDispatch();
    // const [view, setView] = useState(true);

    window.onresize = function () {
        const width = window.innerWidth;
        checkDeviceType(width)
    };

    const checkDeviceType = (width) => {
        const typeObj = {
            isDesktopOrLaptop: false,
            isBigScreen: false,
            isTablet: false,
            isTabletMin: false,
            isMobile: false,
            isPortrait: false,
            isLandscape: false,
        }

        if (width >= 992) {
            // !view && setView(true);
            typeObj.isDesktopOrLaptop = true;
            typeObj.isTablet = true;

            if (width >= 1824) typeObj.isBigScreen = true;

        } else {
            // view && setView(false);
            typeObj.isTabletMin = true;
            typeObj.isMobile = true;
            typeObj.isPortrait = true;

            if (window.outerWidth > window.outerHeight) typeObj.isLandscape = true;

        }

        logger.debug("@ResponsiveCheck.js, mediaType: ", typeObj, width);

        dispatch(deviceType(typeObj))
    }

    useEffect(() => {
        const width = window.innerWidth;
        checkDeviceType(width);

        // eslint-disable-next-line
    }, []);
    return;
}

export default ResponsiveCheck;