export const publicChat = (data) => {
	return {
		type: 'PUBLIC_CHAT',
		payload: { data }
	};
};
export const privateChat = (data) => {
	return {
		type: 'PRIVATE_CHAT',
		payload: { data }
	};
};

export const removeNewPublicChat = () => {
	return {
		type: 'REMOVE_NEW_PRIVATE_CHAT',
		payload: { }
	};
};

export const removeNewPrivateChat = (data) => {
	return {
		type: 'REMOVE_NEW_PRIVATE_CHAT',
		payload: { data }
	};
};