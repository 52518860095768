import React from 'react';
import { IconButton, Typography, Modal, Box, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

export default function NetworkErrorModal({ networkError }) {
    return (
        <Modal
            open={networkError}
            // handleClose={() => this.setState({ openEndAll: false })}
            // onClose={() => this.setState({openEndWeb: false})}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: '#222222',
                // border: '2px solid #000',
                boxShadow: 24,
                borderRadius: '10px',
                p: 4,

            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: '10px',
                }}>
                    <IconButton sx={{
                        color: '#DCA000',
                        bgcolor: "rgba(220, 160, 0, 0.1)",
                        // fontSize: '24px',
                        fontSize: '30xp',
                        height: '10vh',
                        width: '10vh',
                        "&:hover": {
                            color: '#DCA000',
                            bgcolor: "rgba(220, 160, 0, 0.1)",
                        }
                    }}>
                        <WarningAmberOutlinedIcon />
                    </IconButton>

                    <Typography variant="h6" component="h6" sx={{ color: '#7E7E7E', mt: 2 }}>
                        It looks like unable to connect. Please check your
                        network connection and try again.
                    </Typography>
                    <Grid xs={12} item sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 3,
                        width: '100%'
                    }}>
                        <LoadingButton
                            // loading={this.state.endSuccessLoading ? true : null}
                            color='primary'
                            loadingPosition="center"
                            variant="contained"
                            onClick={() => window.location.reload(false)}
                            sx={{
                                ml: 1,
                                borderRadius: '10px',
                                // width: '100%',
                                textTransform: 'none',
                            }}
                        >
                            Try again
                        </LoadingButton>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    )
}
