import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const ChatWithParticipants = () => {
  return (
    <>
      <Heading
        text='How to Chat with participants during a video call? '
      />

      <ImageComp
        name={"chat"}
      />
      <ListComp

        data={[
          {
            name: 'While you are in a meeting, click on the Chat icon in the Meeting Control Bar. ',
            id: '13',

          },
          {
            name: ' A new screen will appear from the left side of the screen. ',
            id: '14',

          }, {
            name: 'Here you will see two tabs, public and private. ',
            id: '15',

          }, {
            name: 'To send message to all participants, send your message into the Public tab.',
            id: '16',


          }, {
            name: 'To send message to a particular participant, click on the private tab.',
            id: '17',

          },
          {
            name: 'It will show the list of participants.',
            id: '18',

          },
          {
            name: 'Click on the name of participant you want to chat with. ',
            id: '19',

          },
          {
            name: 'Now send the message. ',
            id: '39',

          },
          {
            name: 'If you are the one on the receiving end of the chat, it will highlight the chat icon like this screenshot.',
            id: '40',

          },
          {
            name: 'Clicking on the button will open the menu from the right, highlighting the active chat with green dot. ',
            id: '41',

          },
        ]
        }

      />

      <ImageComp
        name={"howToChat2"}
      />

    </>
  );
};

export default ChatWithParticipants;
