import React, { memo } from 'react';
import { connect } from 'react-redux';
// import * as appPropTypes from './appPropTypes';
import AudioOnlyPeerView from './AudioOnlyPeerView';
import { withRoomContext } from '../../../../context/RoomContext';

const AudioOnlyPeer = (props) => {
	const { audioConsumer, speaker } = props;
	return ( /* peerType === "host"  &&  */ audioConsumer?.track && <AudioOnlyPeerView audioTrack={audioConsumer ? audioConsumer.track : null}  speaker={speaker}/>);
};

const mapStateToProps = (state, { id }) => {
	const peer = state.peers[id];
	const consumersArray = peer.consumers.map((consumerId) => state.consumers[consumerId]);
	const audioConsumer = consumersArray.find((consumer) => consumer.track.kind === 'audio');
	const speaker = state.speaker || "";
	return { audioConsumer, speaker };
};

const PeerContainer1 = withRoomContext(connect(
	mapStateToProps,
)(AudioOnlyPeer));

export default memo(PeerContainer1)
