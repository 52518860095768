import { useState } from 'react';
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import RecordingMenu from "./recordingMenu";

const ReportActionsBox = styled(Box)(() => ({
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   gap: "7px"
}));

const ReportRecordingTypography = styled(Typography)(({ theme }) => ({
   color: theme.palette.grey[400],
   fontSize: '14px',
   [theme.breakpoints.down('md')]: {
      fontSize: '11px',
    },
}));

const ReportKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
   color: theme.palette.grey[400],
   fontSize: '16px',
   [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
}));

const ReportActionsJsx = ({ recordings, totalRecordingSize }) => {
   const [anchorEl, setAnchorEl] = useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   }

   const handleClose = () => {
      setAnchorEl(null);
   }

   return (
      <ReportActionsBox>
         {!recordings.length ?
            <Box sx={{ color: "grey.200" }}>---</Box> :
            <>
               <ReportRecordingTypography>
                  {`${totalRecordingSize} (${recordings.length} ${recordings.length > 1 ? 'files' : 'file'})`}
               </ReportRecordingTypography>
               <ReportKeyboardArrowDownIcon
                  aria-controls={Boolean(anchorEl) ? "recording-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                  onClick={handleClick}
               />
               <RecordingMenu recordings={recordings} anchorEl={anchorEl} handleClose={handleClose} />
            </>}
      </ReportActionsBox>
   )
}

export default ReportActionsJsx;