// import waitingRoom from "./waitingRoom";

const initialState =
{
    title: '',
    url: '',
    description: '',
    meetType: 'webinar',
    userId: '',
    isBt: false,
    record: true,
    autoRecord: false,
    disableRecordPlayPause: false,
    e2ee: false,
    whiteboardAccess: false,
    waitingRoom:false,
    isWaitingRoom:false
};

const webinarInfo = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_WEBINAR_INFO':
            {
                const { title = "", url = "", description="", meetType, userId, isBt, record, autoRecord, disableRecordPlayPause, e2ee, whiteboardAccess, waitingRoom, isWaitingRoom} = action.payload?.info;

                return { ...state, title, url, description, meetType, userId, isBt, record, autoRecord, disableRecordPlayPause, e2ee, whiteboardAccess, waitingRoom, isWaitingRoom };
            }
        
        case 'UPDATE_WHITEBOARD_ACCESS':
            {
                const { whiteboardAccess } = action.payload;
                return { ...state, whiteboardAccess };
            }

        default:
            return state;
    }
};

export default webinarInfo;
