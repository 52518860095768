// import React from 'react'
import { privateChat, publicChat } from '../redux/actions/chatActions';
import { demoteRole, manageMedia, promoteRole } from '../redux/actions/stateActions';
import * as pinFocusAction from '../redux/actions/pinFocusAction';
// import { totalWatching } from '../redux/actions/stateActions';
import { configFile } from '../config';
import Logger from './Logger';
import { setUsers, setWaitingUsers } from '../redux/actions/waitingRoomActions';
import { setRecordingInfo } from '../redux/actions/recordingActions';
import { createRedirectPath } from '../utils/common';

const logger = new Logger("SSE")

let es = null;
export function SSE_connect_events(peerId, store, roomId) {
    try {
        if (typeof EventSource !== "undefined") {
            const { sseEventUrl } = configFile;
            const url = sseEventUrl();
            es = new EventSource(`${url}?peerId=${peerId}&roomId=${roomId}`);

            es.onopen = function () {
                logger.debug("sse connected");
                es.addEventListener("pinnedScreens", function (event) {
                    logger.debug("pinnedScreens message:", event.data);
                    if (JSON.parse(event.data).includes(peerId)) {
                        store.dispatch(pinFocusAction.setPinSelfScreen())
                    } else {
                        store.dispatch(pinFocusAction.removePinSelfScreen())
                    }
                    store.dispatch(pinFocusAction.setPinVideoScreens(event.data))
                });

                // No more in use yet.
                es.addEventListener("pinnedVideoScreens", function (event) {
                    logger.debug("pinnedVideoScreens message:", event.data);
                    // store.dispatch(pinFocusAction.setPinVideoScreens(event.data))
                });

                es.addEventListener("pinnedShareScreens", function (event) {
                    logger.debug("pinnedShareScreens message:", event.data);
                    store.dispatch(pinFocusAction.setPinShareScreens(event.data));
                });

                es.addEventListener("focusScreens", function (event) {
                    // logger.debug("focusScreens event:", event);
                    logger.debug("focusScreens message:", event.data);
                    if (JSON.parse(event.data).includes(peerId)) {
                        store.dispatch(pinFocusAction.setFocusSelfScreen())
                    } else {
                        store.dispatch(pinFocusAction.removeFocusSelfScreen())
                    }

                    store.dispatch(pinFocusAction.setFocusScreens(event.data));
                });

                // es.addEventListener("totalWatching", function (event) {
                //     logger.debug("totalWatching message:", event.data);
                //     store.dispatch(totalWatching(event.data));
                // });

                es.addEventListener("groupChat", function (event) {
                    logger.debug("group_chat message:", event.data);
                    store.dispatch(publicChat(event.data))
                });

                es.addEventListener("closeExisitngPeer", function (event) {
                    logger.debug("closeExisitngPeer:", event.data);
                    window.location.href = createRedirectPath("/");
                });

                es.addEventListener("privateChat", function (event) {
                    logger.debug("private_chat message:", event.data);
                    store.dispatch(privateChat(event.data))
                });

                es.addEventListener("endCall", function (event) {
                    logger.debug("endCall:", event.data);
                    window.location.href = createRedirectPath('/end_meet')
                });

                es.addEventListener("manageMedia", function (event) {
                    logger.debug("manageMedia:", event.data);
                    const { peerId: userId, type, action } = JSON.parse(event.data); //type = "audio/video", action ="start/stop";
                    if (peerId === userId && (type === 'audio' || type === 'video') && (action === 'start' || action === 'stop')) {
                        store.dispatch(manageMedia({ type, action }))
                    }
                });

                es.addEventListener("kickPeer", function (event) {
                    logger.debug("kickPeer:", event.data);
                    const { peerId: userId } = JSON.parse(event.data);
                    if (peerId !== userId) return;
                    window.location.href = createRedirectPath('/kick_peer')
                });

                es.addEventListener("updateRole", function (event) {
                    logger.debug("updateRole:", event.data);
                    const { peerId: userId, action = "", /* promotedActions=[] */ } = JSON.parse(event.data);
                    if (peerId !== userId) return;
                    if (action === 'promote') {
                        return store.dispatch(promoteRole())
                    }

                    if (action === 'demote') {
                        return store.dispatch(demoteRole())
                    }
                });

                // es.addEventListener("heartBeat", function (event) {
                //     logger.debug("heartBeat:", event.data);
                //     return store.dispatch(promoteRole())
                // });

                es.addEventListener("waitingRoomPeers", function (event) {
                    logger.debug("SET_USERS", event.data);
                    store.dispatch(setUsers(JSON.parse(event.data || [])))
                })

                es.addEventListener("waitingRoomHostAction", function (event) {
                    logger.debug("SET_WAITING_USERS", event.data);
                    store.dispatch(setWaitingUsers(JSON.parse(event.data || [])))
                })

                es.addEventListener("recording", function (event) {
                    logger.debug("recording: ", event.data);
                    store.dispatch(setRecordingInfo(JSON.parse(event.data || "{}")));
                });
            };

            es.onerror = (error) => {
                logger.error("Error : sse is not supported ", error); //ERR_NETWORK_CHANGED ERR_INTERNET_DISCONNECTED
            };
        } /* else {
            logger.debug("Error sse is not supported");
            return false
        } */
    } catch (error) {
        logger.error("Error sse is not supported! Error: ", error);
        return false
    }
}

export function sseClose() {
    es.close();
}
