const initialState =
{
	isDesktopOrLaptop: true, 
	isBigScreen :false, 
	isTablet :false, 
	isTabletMin:false, 
	isMobile:false, 
	isPortrait:false, 
	isLandscape:false
};

const deviceInfo = (state = initialState, action) => {
	switch (action.type) {
		case 'DEVICE_TYPE':
			{
				const deviceType = action.payload.info;
					return { ...state, ...deviceType};
			}
            
		default:
			return state;
	}
};

export default deviceInfo;
