import React from 'react';
import Logger from '../../../../lib/Logger';
import { Chip, Box, Stack, IconButton } from '@mui/material';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
// import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import { axiosPostCall } from '../../../../services/apisCall';
import { renderErrorMessage } from '../../../ToastNotification';



const logger = new Logger('ScreenShareView');

export default class ScreenShare extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            videoResolutionWidth: null,
            videoResolutionHeight: null,
            videoCanPlay: false,
            videoElemPaused: false,
            maxSpatialLayer: null,
        };

        this._videoTrack = null;

        // Periodic timer for reading video resolution.
        this._videoResolutionPeriodicTimer = null;

        // requestAnimationFrame for face detection.
        this._faceDetectionRequestAnimationFrame = null;
    }

    pinShareScreen = async (peerId, roomId, action) => {
        try {
            await axiosPostCall("pinScreen",{peerId, roomId, action, type: 'pinShareScreen'})
        } catch (error) {
            logger.error("@ScreenShareView.jsx pinShareScreen ",error);
            renderErrorMessage(error.message)
        }

    }

    render() {
        let { byMe, shareSx, showName, displayName, showPin, peerId, roomId, showUnpin, nameSx, pinned } = this.props;
        return (
            <Box sx={shareSx} className={`video-container share`}>

                <video
                    ref='videoElem'
                    width={'100%'}
                    height={'100%'}
                    style={{ borderRadius: '10px', maxWidth: '100%' }}
                    // className={'show is_me_video'}
                    autoPlay
                    playsInline
                    muted
                    controls={false}
                />

                {/* {(maxSpatialLayer) &&
                    <Avatar variant="square"
                        // draggable="false"
                        src="../../images/avatar.png"
                        sx={{
                            width: '100%',
                            height: '100%',
                            bgcolor: '#333333',
                            borderRadius: '10px',
                            alignItems: 'center',
                            position: 'absolute',
                            color: '#454545'
                        }}
                    />
                } */}

                {showName &&
                    <Stack direction="row"  >
                        <Chip label={byMe ? "You" : displayName || ''} sx={nameSx} />
                        {!pinned && <IconButton sx={{
                            position: 'absolute',
                            left: 10,
                            top: 10,
                            bgcolor: '#1976d2',
                            color: '#ffffff',
                            p: .5,
                        }}
                            disableRipple
                        >
                            <ScreenShareOutlinedIcon sx={{
                                width: '16px',
                                height: '16px',
                            }} />
                        </IconButton>}
                    </Stack>
                }

                {showPin &&
                    <IconButton className='pin' sx={{
                        bgcolor: 'rgba(0,0,0,0.5)',
                        color: '#ffffff',
                        fontSize: '30',
                    }}
                        onClick={() => this.pinShareScreen(peerId, roomId, 'add')}
                    >
                        <AddToQueueIcon fontSize='10%' />
                    </IconButton>
                }

                {showUnpin &&
                    <IconButton className='pin' sx={{
                        bgcolor: 'rgba(33, 150, 243, 1)',
                        color: 'rgba(255,255,255,1)',
                        fontSize: '30',
                        '&:hover': {
                            bgcolor: 'rgba(33, 150, 243, 1) !important',
                            color: 'rgba(255,255,255,1)',
                        }
                    }}
                        onClick={() => this.pinShareScreen(peerId, roomId, 'remove')}
                    >
                        <RemoveFromQueueIcon fontSize='10%' />
                    </IconButton>
                }

            </Box>

        );
    }

    componentDidMount() {
        const { videoTrack } = this.props;

        this._setTracks(videoTrack);
    }

    componentWillUnmount() {
        clearInterval(this._videoResolutionPeriodicTimer);
        cancelAnimationFrame(this._faceDetectionRequestAnimationFrame);

        const { videoElem } = this.refs;

        if (videoElem) {
            videoElem.oncanplay = null;
            videoElem.onplay = null;
            videoElem.onpause = null;
        }
    }

    UNSAFE_componentWillUpdate() {
        const { videoTrack, videoRtpParameters } = this.props;
        const { maxSpatialLayer } = this.state;

        if (videoRtpParameters && maxSpatialLayer === null) {
            this.setState(
                {
                    maxSpatialLayer: videoRtpParameters.encodings.length - 1
                });
        }
        else if (!videoRtpParameters && maxSpatialLayer !== null) {
            this.setState({ maxSpatialLayer: null });
        }

        this._setTracks(videoTrack);
    }

    _setTracks(videoTrack) {
        if (this._videoTrack === videoTrack) return;

        this._videoTrack = videoTrack;
        this._stopVideoResolution();

        const { videoElem } = this.refs;

        if (videoTrack) {
            const stream = new MediaStream();

            stream.addTrack(videoTrack);
            videoElem.srcObject = stream;

            videoElem.oncanplay = () => this.setState({ videoCanPlay: true });

            videoElem.onplay = () => {
                this.setState({ videoElemPaused: false });
            };

            videoElem.onpause = () => this.setState({ videoElemPaused: true });

            videoElem.play()
                .catch((error) => logger.warn('videoElem.play() failed:%o', error));

            this._startVideoResolution();
        }
        else {
            videoElem.srcObject = null;
        }
    }

    _startVideoResolution() {
        this._videoResolutionPeriodicTimer = setInterval(() => {
            const { videoResolutionWidth, videoResolutionHeight } = this.state;
            const { videoElem } = this.refs;

            if (
                videoElem?.videoWidth !== videoResolutionWidth ||
                videoElem?.videoHeight !== videoResolutionHeight
            ) {
                this.setState(
                    {
                        videoResolutionWidth: videoElem?.videoWidth,
                        videoResolutionHeight: videoElem?.videoHeight
                    });
            }
        }, 500);
    }

    _stopVideoResolution() {
        clearInterval(this._videoResolutionPeriodicTimer);

        this.setState(
            {
                videoResolutionWidth: null,
                videoResolutionHeight: null
            });
    }
}