const initState = {
    chat: null,
    newMsg: false,
    ids: []
};

const privateChat = (state = initState, action) => {
    switch (action.type) {
        case 'PRIVATE_CHAT':
            state = {
                ...state,
                chat: JSON.parse(action?.payload?.data),
                newMsg: true,
                ids: [...state?.ids, JSON.parse(action?.payload?.data)?.from]
            }
            break;
        case 'REMOVE_NEW_PRIVATE_CHAT':
            state = {
                ...state,
                chat: null,
                newMsg: false,
                ids: state?.ids.filter(item=> item !== action?.payload?.data)
            }
            // console.warn(state)
            break;
        default:
            break;
    }
    return state;
}

export default privateChat;