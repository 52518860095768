import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicNoneIcon from '@mui/icons-material/MicNone';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
// import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import { pink } from '@mui/material/colors';
import {
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Tabs,
    Tab,
    Button,
    Stack,
    Typography,
    Drawer,
    Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { renderErrorMessage, renderSuccessMessage } from '../../../../components/ToastNotification';
import AttendeeMoreMenu from './AttendeeMoreMenu';
import ParticipantTabHeader from './ParticipantTabHeader';
import SearchBar from './SearchBar';
import ProducersListOptions from './ProducersListOptions';
import { axiosGetCall,axiosPostCall } from '../../../../services/apisCall';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`panellistpanel-${index}`}
            aria-labelledby={`panellist-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `panellist-${index}`,
        'aria-controls': `panellistpanel-${index}`,
    };
};

function Participants({ open, drawerWidth, theme, handleDrawerClose, redirectToPrivateChat, DrawerHeader, roomId }) {
    const [value, setValue] = useState(0);
    const [micState, setMicState] = useState('off');
    const [webcamState, setWebcamState] = useState('off');
    const [shareState, setShareState] = useState('off');
    const peerState = useSelector(state => state.peers);
    const me = useSelector(state => state.me);
    const consumers = useSelector(state => state.consumers);
    let producersArray = useSelector(state => state.producers);
    const [producers, setProducers] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [attendeLoading, setAttendeLoading] = useState(true);
    const [fDataParams, setFDataParams] = useState({ from: 0, size: 20 });
    const [attendePrevDis, setattendePrevDis] = useState(true);
    const [panelistSearchTxt, setPanelistSearchTxt] = useState("");
    const [fetchDataTitle, setFetchDataTitle] = useState("participants");
    const [kickedProducers, setKickedProducers] = useState([]);
    const { meetType } = useSelector(state => state.webinarInfo);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getAttendeeData = () => {
        setAttendeLoading(true);
        fetchAttendeesInitialData(roomId, 0, 20, true)
        setFDataParams({ from: 0, size: 20 })
    }

    const iconSx = { width: '16px', height: '16px' };

    async function fetchAttendeesInitialData(roomId, from = 0, size = 20, isInitialLoading = false) {
        try {
            const allAttendes = await axiosGetCall("fetchAttendees", { roomId, size, from })
            // let allAttendes = await fetchAttendees(roomId, from, size)
            if (typeof allAttendes?.attendees === 'object') {
                if (isInitialLoading) setAttendeLoading(false);
                setAttendees(allAttendes);
            }
        } catch (error) {

        }
    }

    // Next button acction code for attendee list.
    const attendeeNext = () => {
        fetchAttendeesInitialData(roomId, fDataParams.from + 20, fDataParams.size, false);
        setFDataParams({ from: fDataParams.from + 20, size: 20 });
        setattendePrevDis(false);
    }

    // Previous button acction code for attendee list.
    const attendeePrev = () => {
        if (fDataParams.from === 20) {
            setattendePrevDis(true);
        }
        fetchAttendeesInitialData(roomId, fDataParams.from - 20, fDataParams.size, false);
        setFDataParams({ from: fDataParams.from - 20, size: 20 });
    }

    // Pink color import
    const customTheme = createTheme({
        palette: {
            pink: {
                // This is Pink.A500 as hex.
                main: pink[500],
            },
        },
    });

    // Filter data from panelist data according to search text.
    const searchFilterPanelist = (item) => {
        if (panelistSearchTxt.replace(/\s+/g, " ").trim() === "") {
            return item;
        } else if (item.name.toLowerCase().includes(panelistSearchTxt.replace(/\s+/g, " ").trim().toLowerCase())) {
            return item
        }
    }

    async function handleMediaRequests(roomId, peerId, type, action, displayName) {
        try {
            const res = await axiosPostCall("manageMedia",{ roomId, peerId, type, action });
            if (res?.message) {
                renderSuccessMessage(res?.message)
            }
        } catch (error) {
            renderErrorMessage(error?.message)
        }
    }
    async function kickPeer(roomId, peerId, displayName,role ="host") {
        try {
            const res = await axiosPostCall("kickPeer",{roomId, peerId, peerName: displayName})
            if (res?.message) {
                renderSuccessMessage(res?.message)
                getAttendeeData(role);

            }
        } catch (error) {
            renderErrorMessage(error?.message)
        }
    }

    // Api call for promote attendee role.
    async function promoteRole(roomId, peerId, displayName, action = "promote") {
        try {
            const res = await axiosPostCall("promoteAttendee",{ roomId, peerId, peerName: displayName, action });
            if (res?.message) {
                renderSuccessMessage(res?.message)
            }
        } catch (error) {
            renderErrorMessage(error?.message)
        }
    }

    // Stuff to copy text in clip board.
    /*  async function copyToClipboard(e, text, index) {
         try {
             await navigator.clipboard.writeText(text);
             setCopySuccess(index);
             setTimeout(() => {
                 setCopySuccess(0)
             }, 9000);
         } catch (err) {
             setCopySuccess(0);
         }
     }; */

    async function fetchKickPeersInitialData(roomId, from = 0, size = 200, isInitialLoading = false, role) {
        try {
            role === "host" ? setKickedProducers([]) : setAttendees([]);
            const allPeers = await axiosGetCall("fetchKickedPeers",{roomId, from, size, role})
            if (typeof allPeers === 'object') {
                if (role === "host") {
                    return setKickedProducers(allPeers)
                }
                if (isInitialLoading) setAttendeLoading(false);
                setAttendees(allPeers);
            }
        } catch (error) {
            renderErrorMessage(error?.message || "Unable to fetch kicked peers!")
        }
    }

    // Block user data fn call.
    const getBlockedPeerData = (role) => {
        setAttendeLoading(true);
        fetchKickPeersInitialData(roomId, 0, 200, true, role)
        setFDataParams({ from: 0, size: 200 })
    }

    const changeApiFetchData = (type) => {
        if (type === "participants") {
          setValue(0)
            return setFetchDataTitle("participants")
        }
        if (type === "blockedParticipants") {
            setFetchDataTitle("blockedParticipants")
            setValue(0)
            return getBlockedPeerData("host");
        }
    }

    // handle unblock peer
    const unblockHandler = async (roomId, peerId, displayName,role) => {
        try {
            const res = await axiosPostCall("allowKickedPeer",{roomId, peerId, peerName:displayName})
            renderSuccessMessage(res?.message);
            return getBlockedPeerData(role);
        } catch (error) {
            renderErrorMessage(error?.message || "Unable to unblock peer!");
        }
    }

    // Update self producer(me) data state i.e mic, webcam, share.
    useEffect(() => {
        const audioProducer = Object.values(producersArray).find((producer) => producer.track.kind === 'audio');
        const videoProducer = Object.values(producersArray).find((producer) => producer.track.kind === 'video' && producer?.type !== "share");
        const screenShare = Object.values(producersArray).find((producer) => producer.track.kind === 'video' && producer?.type === "share");

        if (!me.canSendMic)
            setMicState('unsupported');
        else if (audioProducer && !audioProducer?.paused)
            setMicState('on');
        else
            setMicState('off');

        if (!me.canSendWebcam)
            setWebcamState('unsupported');
        else if (videoProducer && videoProducer.type !== 'share')
            setWebcamState('on');
        else
            setWebcamState('off');

        if (screenShare && screenShare.type === 'share')
            setShareState('on');
        else
            setShareState('off');

    }, [producersArray, me])

    // Update producers data when data change and default initial producers data.
    useEffect(() => {
        let host = [];
        // let attendee = [];
        Object.entries(peerState).forEach((peer) => {
            const consumersArray = peer[1].consumers.map((consumerId) => consumers[consumerId]);
            if (peer[1]?.role !== "attendee" || consumersArray[0]?.role !== "attendee") {
                const audioConsumer = consumersArray.find((consumer) => consumer.track.kind === 'audio');
                const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === 'video' && consumer?.type !== "share");
                const share = consumersArray.find((consumer) => consumer.track.kind === 'video' && consumer?.type === "share");

                const audioEnabled = (Boolean(audioConsumer) && !audioConsumer.locallyPaused && !audioConsumer.remotelyPaused);
                const videoVisible = (Boolean(videoConsumer) && !videoConsumer.locallyPaused && !videoConsumer.remotelyPaused);
                const name = peer[1].displayName;
                const peerId = peer[1].id;
                host.push({ name, audioEnabled, videoVisible, share, peerId, role: peer[1]?.role })
                return;
            }
            return;
        })
        setProducers(host);
    }, [peerState, consumers])

    return (
        <Drawer sx={{
            width: drawerWidth,
            flexShrink: 0,
            // overflowY: "hidden",
            '& .MuiDrawer-paper': { width: drawerWidth },
        }}
            variant="persistent"
            anchor="right"
            open={open}>
            <ToastContainer />
            <ParticipantTabHeader
                peerState={peerState}
                handleDrawerClose={handleDrawerClose}
                DrawerHeader={DrawerHeader}
                theme={theme}
                changeApiFetchData={changeApiFetchData}
            />
            <Divider />

            {/* Title of side bar tabs */}
            <Tabs value={value} onChange={handleChange} aria-label="Participants tabs ">
                <Tab label={`Panelists (${fetchDataTitle === 'participants' ? producers.length + 1 : kickedProducers?.length})`} {...a11yProps(0)} onClick={() => fetchDataTitle === "participants" ? "" : getBlockedPeerData("host")} sx={{
                    width: '50%'
                }} />
                {meetType !== 'meet' && <Tab label={`Attendees`} {...a11yProps(1)} onClick={() => fetchDataTitle === "participants" ? getAttendeeData() : getBlockedPeerData("attendee")} sx={{
                    width: '50%'
                }} />}
            </Tabs>
            <Divider />

            {/* Show all Panelist heare */}
            <TabPanel value={value} index={0}>
                {fetchDataTitle === 'participants' &&
                    <SearchBar
                        placeholder={"Search panelists here..."}
                        searchTxt={(value) => setPanelistSearchTxt(value)} />
                }
                <List sx={{
                    height: window.innerHeight - 180, /* '100%',// '592px', */
                    overflowY: 'scroll',
                    paddingBottom: 6,
                }} >
                    {/* Render self entry in panelist */}
                    {((panelistSearchTxt.replace(/\s+/g, " ").trim() === "" || panelistSearchTxt.replace(/\s+/g, " ").trim().toLowerCase() === "you") && fetchDataTitle === 'participants') &&
                        <ListItem sx={{ '&:hover': { bgcolor: '#F4F4F4' } }}>
                            <ListItemIcon sx={{
                                minWidth: 46,
                            }}>
                                <Avatar alt="You" src="./images/01.png" sx={{
                                    width: 32,
                                    height: 32,
                                }} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{
                                    fontWeight: '500',
                                    color: '#454545'
                                }}>You</Typography>
                            </ListItemText>
                            <Stack direction="row">
                                {shareState === "on" &&
                                    <IconButton>
                                        <ScreenShareOutlinedIcon sx={iconSx} />
                                    </IconButton>
                                }
                                <IconButton>
                                    {micState === "on" ? <MicNoneIcon sx={iconSx} /> : <MicOffOutlinedIcon sx={{ ...iconSx, color: 'red' }} />}
                                </IconButton>
                                <IconButton>
                                    {webcamState === "on" ? <VideocamOutlinedIcon sx={iconSx} /> : <VideocamOffOutlinedIcon sx={{ ...iconSx, color: 'red' }} />}
                                </IconButton>
                            </Stack>
                        </ListItem>}
                    {
                        (producers.length > 0 && fetchDataTitle === 'participants') && producers.filter((item) => searchFilterPanelist(item)).map((data, index) => (
                            <ListItem key={index} sx={{ '&:hover': { bgcolor: '#F4F4F4' } }}>
                                <ListItemIcon sx={{
                                    minWidth: 46,
                                }}>
                                    <Avatar alt={data.name} src="./images/01.png" sx={{
                                        width: 32,
                                        height: 32,
                                    }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography sx={{
                                        fontWeight: '500',
                                        color: '#454545',
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>{data.name}</Typography>
                                </ListItemText>
                                <ProducersListOptions
                                    data={data}
                                    promoteRole={promoteRole}
                                    roomId={roomId}
                                    kickPeer={kickPeer}
                                    redirectToPrivateChat={redirectToPrivateChat}
                                    handleMediaRequests={handleMediaRequests}
                                />
                                {/* <Stack direction="row">
                                    
                                    {data?.share && <IconButton>
                                        <ScreenShareOutlinedIcon sx={iconSx} />
                                    </IconButton>
                                    }
                                    {data?.role === 'tempCoHost' && <IconButton onClick={() => promoteRole(roomId, data?.peerId, data.name, "demote")}>
                                        <LogoutIcon sx={iconSx} />
                                    </IconButton>}
                                    <IconButton onClick={() => kickPeer(roomId, data?.peerId, data.name)}>
                                        <LogoutIcon sx={iconSx} />
                                    </IconButton>
                                    <IconButton onClick={() => redirectToPrivateChat(data?.peerId)}>
                                        <ChatBubbleOutlineIcon sx={iconSx} />
                                    </IconButton>
                                    <IconButton onClick={() => handleMediaRequests(roomId, data?.peerId, "audio", `${data?.audioEnabled ? "stop" : "start"}`, data.name)}>
                                        {data?.audioEnabled ? <MicNoneIcon sx={iconSx} />
                                            : <MicOffOutlinedIcon sx={{ ...iconSx, color: 'red' }} />}
                                    </IconButton>
                                    <IconButton onClick={() => handleMediaRequests(roomId, data?.peerId, "video", `${data?.videoVisible ? "stop" : "start"}`, data.name)}>
                                        {data?.videoVisible ? <VideocamOutlinedIcon sx={iconSx} />
                                            : <VideocamOffOutlinedIcon sx={{ ...iconSx, color: 'red' }} />}
                                    </IconButton>
                                </Stack> */}
                            </ListItem>
                        ))}
                    {(kickedProducers.length > 0 && fetchDataTitle === 'blockedParticipants') && kickedProducers.map((data, index) => (
                        <ListItem key={index}>
                            <ListItemIcon sx={{
                                minWidth: 46,
                            }}>
                                <Avatar alt={data?.displayName} src="./images/01.png" sx={{
                                    width: 32,
                                    height: 32,
                                }} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{
                                    fontWeight: '500',
                                    color: '#454545',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{data?.displayName}</Typography>
                            </ListItemText>
                            <Stack direction="row">
                                <Button sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: '#454545',
                                    textTransform: 'none',
                                    borderRadius: '20px'
                                }} onClick= { () => unblockHandler(roomId, data?.peerId, data?.displayName,"host")}>
                                    Unblock
                                </Button>
                            </Stack>
                        </ListItem>
                    ))
                    }
                </List>

                {/*  Show all available option buttons. */}
                {/* <ThemeProvider theme={customTheme}>
                    <Stack direction="row" spacing={1} sx={{
                        px: 2,
                        py: 1,
                        background: '#ffffff',
                        position: 'fixed',
                        bottom: '0',
                        width: '-webkit-fill-available',
                    }}>
                        <Button variant="outlined" color="info" size="medium" sx={{
                            px: .8,
                            fontSize: 12,
                            width: '100%',
                        }}
                            onClick={() => setOpenInviteModel(true)}
                        >Invite</Button>
                        <Button variant="outlined" disabled color="pink" size="medium" startIcon={<MicOffOutlinedIcon />} sx={{
                            px: .8,
                            fontSize: 12,
                            width: '100%',
                        }}>Mute All</Button>
                    </Stack>
                </ThemeProvider> */}
            </TabPanel>

            {/* Show all Attendees heare */}
            <TabPanel value={value} index={1}>
                {fetchDataTitle === 'participants' &&
                    <SearchBar
                        placeholder={"Search attendees here..."}
                        searchTxt={(value) => ""} />}

                <List sx={{
                    // height: '592px',
                    height: window.innerHeight - 220,
                    overflowY: 'scroll',
                    paddingBottom: 1,
                }}>
                    {(attendees.length > 0 && fetchDataTitle === 'participants') && attendees.map((data, index) => (
                        <ListItem key={index} sx={{ '&:hover': { bgcolor: '#F4F4F4' } }}>
                            <ListItemIcon sx={{
                                minWidth: 46,
                            }}>
                                <Avatar alt={data?.displayName} src="./images/01.png" sx={{
                                    width: 32,
                                    height: 32,
                                }} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{
                                    fontWeight: '500',
                                    color: '#454545',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{data?.displayName}</Typography>
                            </ListItemText>
                            <Stack direction="row" spacing={.5}>
                                <AttendeeMoreMenu
                                    kickPeer={kickPeer}
                                    displayName={data?.displayName}
                                    peerId={data?.peerId}
                                    roomId={roomId}
                                    promoteRole={promoteRole}
                                />
                            </Stack>
                        </ListItem>
                    ))}

                    {(attendees.length > 0 && fetchDataTitle === 'blockedParticipants') && attendees.map((data, index) => (
                        <ListItem key={index} sx={{ '&:hover': { bgcolor: '#F4F4F4' } }}>
                            <ListItemIcon sx={{
                                minWidth: 46,
                            }}>
                                <Avatar alt={data?.displayName} src="./images/01.png" sx={{
                                    width: 32,
                                    height: 32,
                                }} />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{
                                    fontWeight: '500',
                                    color: '#454545',
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{data?.displayName}</Typography>
                            </ListItemText>
                            <Stack direction="row">
                                <Button sx={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    color: '#454545',
                                    textTransform: 'none',
                                    borderRadius: '20px'
                                }} onClick= { () => unblockHandler(roomId, data?.peerId, data?.displayName,"attendee")}>
                                    Unblock
                                </Button>
                            </Stack>
                        </ListItem>
                    ))}

                    {(attendeLoading && fetchDataTitle === 'participants') &&
                        <ListItem>
                            <ListItemText>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }} >
                                    <img src="./images/loading.svg" alt="loading..." />
                                </Box>
                            </ListItemText>
                        </ListItem>}

                    {(!attendees.length && fetchDataTitle === 'participants' && !attendeLoading) && <ListItem>
                        <ListItemText>
                            <Typography sx={{
                                fontWeight: '500',
                                color: '#454545',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                userSelect: "none"
                            }}>No More Attendees!</Typography>
                        </ListItemText>
                    </ListItem>}
                </List>

                {(attendees.length > 0 && fetchDataTitle === 'participants') &&
                    <ThemeProvider theme={customTheme}>
                        <Stack direction="row" spacing={1} sx={{
                            px: 2,
                            py: 1,
                            background: '#ffffff',
                            position: 'fixed',
                            bottom: '0',
                            width: '-webkit-fill-available',
                        }}>
                            <Button variant="outlined" color="info" size="medium" sx={{
                                px: .8,
                                fontSize: 12,
                                width: '100%',
                            }}
                                onClick={() => attendeePrev()}
                                disabled={attendePrevDis}
                            >Prev</Button>
                            <Button variant="outlined" color="pink" size="medium" sx={{
                                px: .8,
                                fontSize: 12,
                                width: '100%',
                            }}
                                onClick={() => attendeeNext()}
                                disabled={attendees.length < 20 ? true : false}
                            >Next</Button>
                        </Stack>
                    </ThemeProvider>
                }

            </TabPanel>
            {/* <DialogModel
                open={openInviteModel}
                handleClose={() => setOpenInviteModel(false)}
                title={"Here's the link to your meeting"}
                actionSx={{
                    justifyContent: 'flex-start',
                    
                }}
                content={<>
                    <Typography gutterBottom color="gray" sx={{
                        pb: 2,
                    }}>
                        Copy this link and send it to people that you want to connect with.
                    </Typography>
                    <Box sx={{
                        pb: 2,
                    }}>
                         <Box sx={{
                            background: 'rgba(220, 160, 0, 0.1)',
                            borderRadius: '20px',
                            // mt:1
                        }}>
                            <Typography sx={{
                                color: '#DCA000',
                                fontSize: '12px',
                                ml: 1
                                // mb: 2
                            }}> <IconButton>
                                    <WarningAmberIcon sx={{color: '#DCA000'}}/>
                                </IconButton>Note: Login required to join as a host.</Typography>
                        </Box>
                        <Typography variant="body1" display="block" gutterBottom sx={{ p: '10px',}}>Team Invite Link</Typography>
                        <Box sx={{
                            p: 2,
                            background: 'rgba(0,0,0,.1)',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: "#CCCCCC"
                        }}>
                            <Typography component="span" display="block" pr={2} >{webinarInfo?.url}</Typography>
                            <ContentCopyIcon
                                onClick={(e) => copyToClipboard(e, webinarInfo?.url, 1)}
                                sx={{ color: copySuccess === 1 ? 'green' : '' }}
                            />
                        </Box>
                    </Box>
                   
                </>}
            // actions={<Button variant="outlined" startIcon={<ContentCopyIcon />}>Copy joining link</Button>}
            /> */}
        </Drawer>
    )
}

export default memo(Participants)
