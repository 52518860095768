const initialState = {};
const sseState = { pin: [] }

const peers = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ROOM_STATE':
			{
				const roomState = action.payload.state;

				if (roomState === 'closed')
					return {};
				else
					return state;
			}

		case 'ADD_PEER':
			{
				const { peer } = action.payload;
				if (!peer?.id) peer.id = peer?.peerId

				if (state.hasOwnProperty(peer?.id)) {
					const updatePeerData = { ...state[peer?.id], ...peer };
					return { ...state, [peer?.id]: updatePeerData };
				}

				// Check for pin focus
				if (sseState?.pin?.includes(peer?.id)) peer.isPined = true;
				if (sseState?.focus?.includes(peer?.id)) peer.isFocused = true;

				return { ...state, [peer?.id]: peer };
			}

		case 'REMOVE_PEER':
			{
				const { peerId } = action.payload;
				const newState = { ...state };

				delete newState[peerId];

				return newState;
			}

		// Not in use
		case 'SET_PEER_DISPLAY_NAME':
			{
				const { displayName, peerId } = action.payload;
				const peer = state[peerId];

				if (!peer)
					throw new Error('no Peer found');

				const newPeer = { ...peer, displayName };

				return { ...state, [newPeer.id]: newPeer };
			}

		case 'ADD_CONSUMER':
			{
				const { consumer, peerId } = action.payload;
				const peer = state[peerId];
				// console.warn("track", peer, peerId, state, consumer, action.payload)

				if (!peer) {
					const newConsumers = [consumer.id];
					const newPeer = { ...peer, id: peerId, consumers: newConsumers };
					// console.warn("state track", { ...state, [newPeer.id]: newPeer })
					return { ...state, [newPeer.id]: newPeer };
					// throw new Error('no Peer found for new Consumer');
				}

				const newConsumers = [...peer.consumers, consumer.id];
				const newPeer = { ...peer, consumers: newConsumers };

				return { ...state, [newPeer.id]: newPeer };
			}

		case 'REMOVE_CONSUMER':
			{
				const { consumerId, peerId } = action.payload;
				const peer = state[peerId];

				// NOTE: This means that the Peer was closed before, so it's ok.
				if (!peer) {
					return state;
				}
				const idx = peer.consumers.indexOf(consumerId);

				if (idx === -1)
					throw new Error('Consumer not found');

				const newConsumers = peer.consumers.slice();

				newConsumers.splice(idx, 1);

				const newPeer = { ...peer, consumers: newConsumers };

				return { ...state, [newPeer.id]: newPeer };
			}

		case 'SET_PIN_PEERS':
			{
				let pinPeers = action.payload?.data;
				if (pinPeers) {
					pinPeers = JSON.parse(pinPeers)
					sseState.pin = pinPeers
					let newModifyObj = {};

					Object.keys(state).map((item) => {
						state[item].isPined = false
						return state
					})

					pinPeers.map((item) => {
						if (state[`${item}`]?.id) {
							newModifyObj[item] = state[`${item}`]
							newModifyObj[item].isPined = true
							return newModifyObj;
						}
						return null
					})
					// console.warn("ddddd", state, { ...newModifyObj, ...state })
					return { ...newModifyObj, ...state }
				}
				return state
			}

		case 'SET_FOCUS_SCREENS':
			{
				let focusPeers = action.payload?.data;
				if (focusPeers) {
					focusPeers = JSON.parse(focusPeers)
					sseState.focus = focusPeers;
					let newModifyObj = {};

					Object.keys(state).map((item) => {
						state[item].isFocused = false
						return state
					})

					focusPeers.map((item) => {
						if (state[`${item}`]?.id) {
							newModifyObj[item] = state[`${item}`]
							newModifyObj[item].isFocused = true
							return newModifyObj;
						}
						return null
					})
					return { ...newModifyObj, ...state }
				}
				return state
			}

		case 'SET_WHITEBOARD_ACCESS':
			const { peerId, whiteboardAccess } = action?.payload;
			const peer = state[peerId];

			if (!peer)
				throw new Error('no Peer found');

			const newPeer = { ...peer, whiteboardAccess };

			return { ...state, [newPeer.id]: newPeer };

		default:
			{
				return state;
			}
	}
};

export default peers;
