import { Box, TextField, Typography, Container, IconButton, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReportList from '../../../components/mobile/reports/Reportlist';
import DescriptionIcon from '@mui/icons-material/Description';
import { axiosGetCall } from '../../../services/apisCall';

export default function MobileReports() {
    const theme = useTheme();
    const [wList, setWList] = useState([]);
    const [loadingWList, setLoadingWList] = useState(true)
    const [/* hasMore */, setHasMore] = useState(true)
    // const monthsStr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [fromDate, setFromDate] = useState(null);//'2014-08-18T21:11:54'
    const [toDate, setToDate] = useState(null);
    const [apiError, setApiError] = useState(false)

    // Fetch schedule webinar list.
    const fetchList = async (skip = 0, size = 20, search = null) => {
        try {
            let data = { skip, size };
            if (search) data.search = search;
            let res = await axiosGetCall("reportWebinar", data)
            if (res?.result?.length !== 20) setHasMore(false);
            setLoadingWList(false)
            setWList(res?.result)
        } catch (error) {
            setApiError(true)
            setLoadingWList(false)
            renderErrorMessage("Unable to fetch meeting reports!")
        }
    }

    const handleChangeFrom = (newValue) => {
        setFromDate(newValue);
    };
    const handleChangeTo = (newValue) => {
        setToDate(newValue);
    };

    useEffect(() => {
        fetchList(0, 100);
    }, [])

    return (
        <Box className='MobileWraper' sx={{
            overflowY: "hidden",
            height: '100%',
            display: 'grid',
            backgroundColor: 'background.default'
        }}>
            <ToastContainer />
            <Container maxWidth="xl" className='ListContainer' sx={{ pb: 2 }}>
                <Box sx={{
                    flexDirection: 'row',
                    pt: 4,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} className='ListGlobalBox'>

                    <IconButton sx={{ width: '28px', height: '28px', color: '#1976d2' }} className='ListIconBtn'>
                        <DescriptionIcon />
                    </IconButton>
                    <Typography className='ListTitle' sx={{
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '18px',
                        color: 'grey.200',
                        ml: 1
                    }}>
                        Reports
                    </Typography>
                </Box>
                <Typography className='ReportRangeFilterTitle' sx={{
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: 'grey.400',
                    pt: 4
                }}>
                    Choose date range
                </Typography>

                <Box className='ReportDateInputBox' sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    pt: 1,
                    gap: '10px'
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="From"
                                inputFormat="MM/DD/YYYY"
                                value={fromDate}
                                size="small"
                                disabled
                                onChange={handleChangeFrom}
                                renderInput={(params) =>
                                    <TextField
                                        size="small"
                                        sx={{ bgcolor: 'grey.800', borderRadius: '5px' }}
                                        inputProps={{ sx: { color: "grey.400" } }}
                                        {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="To"
                                inputFormat="MM/DD/YYYY"
                                value={toDate}
                                size="small"
                                disabled
                                onChange={handleChangeTo}
                                renderInput={(params) =>
                                    <TextField
                                        size="small"
                                        sx={{ bgcolor: 'grey.800', borderRadius: '5px' }}
                                        inputProps={{ sx: { color: "grey.400" } }}
                                        {...params}
                                    />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Box>
            </Container>

            <Box sx={{
                backgroundColor: theme.palette.mode === 'dark' ? 'grey.1000' : '#E4E7F4',
                borderRadius: '8px 8px 0px 0',
                height: 'calc(100vh - 174px)',
                padding: '0px 14px 70px 14px',
                display: 'flex',
                flexDirection: 'column',
                gap: "10px",
                overflowY: 'scroll',
                overflowX: 'hidden',
                borderTopWidth: '10px',
                borderColor: theme.palette.mode === 'dark' ? 'grey.1000' : '#E4E7F4',
                borderStyle: 'solid',
                pt: 1
            }}>
                <ReportList wList={wList} loadingWList={loadingWList} apiError={apiError} />
            </Box>
        </Box>
    )
}
