import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, Avatar, MenuItem, Menu, ListItemIcon, Divider } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

//Icons
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Logout from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
// import FeedbackIcon from '@mui/icons-material/Feedback';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';

// import { configFile } from '../../config';
import { getItem, setItem, removeBulkItems } from '../../lib/cookiesManager';
import { getConosleEnv } from "../../config/Enums";
import MeetLogoSvg from '../../assets/svgs/webinarLogo';

export default function Navbar() {
    // const { logoUrl } = configFile;
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null)
    const [profileUrl, setProfileUrl] = useState(null);
    const [showProfile, setShowProfile] = useState(false);
    const openProfile = Boolean(anchorEl);
    const consoleEnv = getConosleEnv();

    const logout = () => {
        removeBulkItems(["sessionToken", 'exp', 'name', 'token'])
        navigate('/login');
    }

    const fetchUserInfo = async () => {
        try {
            let data = getItem("token")
            let info = jwt(data);
            setItem("name", info?.name)
            setProfileUrl(info?.picture)
        } catch (error) {
            // renderErrorMessage(error.message)
        }
    }

    useEffect(() => {
        if (!consoleEnv.includes(process.env.REACT_APP_ENV)) setShowProfile(true);
        fetchUserInfo()
        // eslint-disable-next-line
    }, [])

    const onIconClick = (pathname) => navigate(pathname);

    const homeSideBar = [{
        title: 'Home',
        icon: <HomeIcon />,
        active: location?.pathname === '/' ? true : false,
        path: "/",
        disabled: false,
    },
    {
        title: 'Meeting',
        icon: <VideoCameraFrontIcon />,
        active: location?.pathname === '/list' || location?.pathname === '/schedule_webinar' ? true : false,
        path: "/list",
        disabled: false,
    },
    {
        title: 'Report',
        icon: <DescriptionIcon />,
        active: location?.pathname === '/reports' ? true : false,
        path: "/reports",
        disabled: false,
    },

    {
        title: 'Help',
        icon: <HelpCenterIcon />,
        active: location?.pathname === '/help' ? true : false,
        path: "/help",
        disabled: false,
    },
    /* {
        title: 'Feedback',
        icon: <FeedbackIcon />,
        active: location?.pathname === '/feedback' ? true : false,
        path: "/feedback",
        disabled: false,
    }, */]


    return (
        <>
            <Box sx={{
                flexDirection: 'flex-end',
                position: 'fixed',
                width: '100%',
                color: '#fff',
                zIndex: 2,
                boxShadow: '68px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
            }}>
                <AppBar position="static" component="nav" sx={{
                    backgroundColor: 'card.main',
                    boxShadow: 'none'
                }}>
                    <Toolbar sx={{ marginLeft: '-7px' }}>
                        <MeetLogoSvg />

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            width: '100%',
                            gap: '20px',
                            pr: '20px',
                            alignItems: 'center'
                        }}>
                            {homeSideBar.map(({ title, icon, active, path }) => (
                                <Button key={title} onClick={() => onIconClick(path)} sx={{
                                    color: active ? "grey.70" : "grey.600",
                                    height: '34px',
                                    "&:hover": {
                                        color: active ? "grey.70" : "grey.600",
                                        bgcolor: "transparent",
                                    }
                                }} startIcon={icon}>
                                    {title}
                                </Button>
                            ))}

                            {showProfile && <Box
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                sx={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    pl: '30px'
                                }}>
                                <Avatar variant="square"
                                    src={profileUrl ? profileUrl : "../../images/avatar.png"}
                                    sx={{
                                        bgcolor: '#F3F3F3',
                                        borderRadius: '10px',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        color: '#454545',
                                    }}
                                />
                            </Box>}

                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openProfile}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 3.9,
                        ml: 3,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 20,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/profile')}>
                    <ListItemIcon>
                        <AccountBoxIcon />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem disabled>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    Edit Profile
                </MenuItem>

                <Divider />

                <MenuItem onClick={(e) => logout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>

        </>

    )
}
