import React, { useState, memo, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Grow, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function AttendeeMoreMenu({ kickPeer, displayName, peerId, roomId, promoteRole }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    // For remove attendee from room.
    const removePeer = () => {
        kickPeer(roomId, peerId, displayName, "attendee")
        setOpen(false);
    };

    // Promote attendee to co-host.
    const AllowToTalkAttendee = () => {
        promoteRole(roomId, peerId, displayName)
        setOpen(false);
    };

    // Open more menu option.
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // Close menu on close hit.
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <IconButton ref={anchorRef} size='small'
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => handleToggle()}
            >
                <MoreVertIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>

            <Popper
                sx={{
                    zIndex: 1,
                    p: 0,
                    margin: 0
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper sx={{
                            border: '1px solid #F0F0F0'
                        }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    <MenuItem
                                        key="allowToTalk"
                                        // disabled={index === 2}
                                        // selected={index === selectedIndex}
                                        onClick={() => AllowToTalkAttendee()}
                                        sx={{ color: '#727374' }}
                                    >
                                        Allow to Talk
                                    </MenuItem>
                                    <MenuItem
                                        key="removePeer"
                                        // disabled={index === 2}
                                        // selected={index === selectedIndex}
                                        onClick={() => removePeer()}
                                        sx={{ color: '#727374' }}
                                    >
                                        Remove
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default memo(AttendeeMoreMenu);
