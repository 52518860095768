const initialState =
{   id: "",
    url: "",
};

const pdfPage = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PDF_URL':
            {
                const { id, url } = action.payload;
                return { ...state, id, url };
            }

        default:
            return state;
    }
};

export default pdfPage;
