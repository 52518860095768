import UrlParse from 'url-parse';
import React, { /*useContext*/ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { styled } from "@mui/material/styles";

import { Typography, Modal, Box, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import { renderErrorMessage } from '../../../ToastNotification';
import E2eModalIcon from '../../../../assets/svgs/e2eModalIcon';

const MainOuterBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "350px",
    height: "340px",
    backgroundColor: '#2C2D2F',
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    borderRadius: "12px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
        width: "100%"
    }
}));

const MainInnerBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center',
    width: "100%",
    gap: '30px',
    padding: '0px 25px'
}));

const IconContainerBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
}));

const IconBox = styled(Box)(() => ({
    backgroundColor: "#242628",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "#434546",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px'
}));

const FormBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: "center",
    gap: "30px",
    width: "100%",
    height: "100%"
}));

const ButtonGrid = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
}))

export default function E2eModal({ openModal, setOpenModal, initial }) {
    const urlParser = new UrlParse(window.location.href, true);
    
    const [e2eValue, setE2eValue] = useState(urlParser.query.key||"");
    const [e2eError, setE2eError] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleClose = () => {
        if (!e2eValue) return renderErrorMessage("Please enter valid E2E Key!");
        urlParser.query.key = encodeURIComponent(e2eValue.toString());
        window.history.pushState('', '', urlParser.toString());
        setOpenModal(false);
        const state = location?.state;
        const path = window.location.search;
        if (!initial) window.location.reload();
        return navigate(path, { state, replace: true });
    }

    return (
        <Modal
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <MainOuterBox>
                <Box>
                    {!initial &&
                        <CloseIcon
                            sx={{
                                height: "30px",
                                width: "30px",
                                p: '4px',
                                color: "#A1A2A3",
                                marginRight: '10px',
                            }}
                            onClick={() => setOpenModal(false)}>
                        </CloseIcon>}
                </Box>
                <MainInnerBox>
                    <IconContainerBox>
                        <IconBox>
                            <E2eModalIcon />
                        </IconBox>
                    </IconContainerBox>
                    <FormBox>
                        <Typography
                            component="h6"
                            sx={{ color: '#F0F0F0', fontSize: '20px', fontWeight: 500 }}>
                            Enter your E2E Key
                        </Typography>

                        <TextField
                            id="outlined-size-small"
                            placeholder="Please type here.."
                            size="small"
                            sx={{
                                width: '100%',
                                backgroundColor: '#242628',
                                borderRadius: '6px'
                            }}
                            className='ListTextFieldSearch'
                            inputProps={{ sx: { color: "#B8B9BA" } }}
                            onChange={(e) => setE2eValue(e.target.value)}
                            onClick={() => setE2eError(false)}
                            value={e2eValue}
                            error={e2eError}
                        />

                        <ButtonGrid xs={12} item>
                            <LoadingButton
                                loading={null}
                                color='primary'
                                loadingPosition="center"
                                variant="contained"
                                onClick={() => handleClose()}
                                sx={{
                                    borderRadius: '6px',
                                    width: '100%',
                                    textTransform: 'none',
                                }}
                            >
                                Submit
                            </LoadingButton>
                        </ButtonGrid>
                    </FormBox>
                </MainInnerBox>
            </MainOuterBox>
        </Modal>
    )
}