export function getConosleEnv() {
    return ["prod", "dev", "local"];
}

export const meetCode = 102;

const LOCAL_STORAGE = {
    BRANDING: 'branding'
}

export {
    LOCAL_STORAGE
}
