import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';

const RemoveVirtualBackGround = () => {
  return (
    <>
      <Heading
        text='How to Remove a Virtual Background during a Meeting?  '
      />

      <ImageComp
        name={"howtosetvirtualbackground"}

      />

      <ListComp

        data={[

          {
            name: 'To do so, click on the ‘More Option’ icon on the ‘Meeting Control Bar.’',
            id: '13',

          },
          {
            name: 'Click on the ‘Virtual Background’ option.',
            id: '14',

          },

        ]}

      />

      <ImageComp
        name={"virtualBackgroundOption"}

      />

      <ListComp

        data={[

          {
            name: 'Here, you will see ‘Remove Filter’ option on the bottom of the pop-up.',
            id: '13',

          },
          {
            name: 'Click on it to remove Blur effect or image set on the background.',
            id: '14',

          },

        ]}

      />
      
    </>
  );
};

export default RemoveVirtualBackGround;
