import React, { memo, useState } from 'react';
import {
    IconButton,
    Divider,
    Drawer,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { WaitingRoomSearchBar } from '../../../../sections/common/room/waitingRoom/searchBar';
import { WaitingUsersCount } from '../../../../sections/common/room/waitingRoom/count';
import { WaitingRoomActions } from '../../../../sections/common/room/waitingRoom/actionsButtons';
import { WaitingUsersList } from '../../../../sections/common/room/waitingRoom/list';

function WaitingRoom({ open, drawerWidth, theme, handleDrawerClose, DrawerHeader }) {
    const [searchTxt, setSearchTxt] = useState("");

    return (
        <>
            <Drawer sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': { width: drawerWidth },
            }}
                variant="persistent"
                anchor="right"
                open={open}>
                <DrawerHeader sx={{ color: "#000000" }}>
                    Waiting Room
                    <IconButton onClick={() => handleDrawerClose()}>
                        {theme === 'rtl' ? <CloseIcon sx={{
                            width: '18px',
                            height: '18px',
                        }} /> : <CloseIcon sx={{
                            width: '18px',
                            height: '18px',
                        }} />}
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <WaitingRoomSearchBar searchTxt={(value) => setSearchTxt(value)} />

                <WaitingUsersCount />

                <WaitingRoomActions />

                <WaitingUsersList
                    searchTxt={searchTxt}
                />

            </Drawer>


        </>
    )
}

export default memo(WaitingRoom)
