const initialState =
{
	branding: {}
};

const branding = (state = initialState, action) => {
	switch (action.type) {
		case 'BRANDING':
			{
				const branding = action.payload;
					return { ...state, branding };
			}
            
		default:
			return state;
	}
};

export default branding;